import { emptyCart } from 'pages/cart/action';
import actionCreator from 'utils/actionCreator';
import history from 'utils/history';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';
import {showMessage} from 'actions/app';

export const actions = keyMirror('CHECKOUT_VIEW', {
    LOADING: null,
    SET_BASKET_ITEMS: null,
    SET_SHIPPING_METHODS: null,
    SET_SHIPPING_ADDRESSES: null,
    SET_PLAN_DOCUMENTS: null,
    SET_CONTACT_INFO: null,
    SET_BALANCE: null,
    SET_MS_X_CC: null,
    SET_USED: null,
    SET_EST_DELIVERY_DATE: null,
    CLEAR_DATA: null,
    LOADING_MESSAGE: null,
    SET_ITEM_INVENTORY: null
});

export default actions;

const setLoading = actionCreator(actions.LOADING, 'loading');
const setLoadingMessage = actionCreator(actions.LOADING_MESSAGE, 'loadingMessage');
const setBasketItems = actionCreator(actions.SET_BASKET_ITEMS, 'basketItems');
const setMarketSegmentXCostCenters = actionCreator(actions.SET_MS_X_CC, 'marketSegmentXCostCenters');
const setShippingMethods = actionCreator(actions.SET_SHIPPING_METHODS, 'shippingMethods');
export const setContactInfo = actionCreator(actions.SET_CONTACT_INFO, 'contactInfo');
const setShippingAddresses = actionCreator(actions.SET_SHIPPING_ADDRESSES, 'shippingAddresses');
const setPlanDocuments = actionCreator(actions.SET_PLAN_DOCUMENTS, 'planDocuments');
const setBalance = actionCreator(actions.SET_BALANCE, 'balance');
const setUsed = actionCreator(actions.SET_USED, 'used');
const setDeliveryDates = actionCreator(actions.SET_EST_DELIVERY_DATE, 'estimatedDeliveryDate', 'deliveryDate', 'hasUserDeliveryDate');
export const clearData = actionCreator(actions.CLEAR_DATA);
const setItemInventory= actionCreator(actions.SET_ITEM_INVENTORY, 'itemInventory');

export const GetEstimatedDeliveryDate = (inputs) => async (dispatch, getState) => {
    dispatch(setLoadingMessage("Calculating Delivery Date..."))
    dispatch(setLoading(true));
    await http.post('store/estimate-delivery-date', inputs).then((r) => {
        const { basketItems, deliveryDate, estimatedDeliveryDate, hasUserDeliveryDate } = getState().checkoutView;
        let currDeliveryDate = estimatedDeliveryDate;
        let currEstDelDate = null;
        for (const edd of r) {
            for (const bi of basketItems) {
                if (bi.lineId === edd.lineId) {
                    for (const s of bi.customFields.shippings) {
                        if (s.shippingAddress === edd.addressId || edd.addressId === 0) {
                            s.estimatedDeliveryDate = new Date(edd.deliveryDate.deliveryDate).toLocaleDateString();
                            currEstDelDate = s.estimatedDeliveryDate;

                            if(!s.deliveryDate || new Date(edd.deliveryDate.deliveryDate).getTime() > new Date(s.deliveryDate.deliveryDate).getTime() || !hasUserDeliveryDate) {
                                s.deliveryDate = edd.deliveryDate;
                                currDeliveryDate = edd.deliveryDate.deliveryDate;
                            } 
                            
                            s.deliveryDate.slaDays = edd.deliveryDate.slaDays;
                            s.deliveryDate.daysInTransit = edd.deliveryDate.daysInTransit;
                        }
                    }
                }
            }
        }

        const currDelDateObj = new Date(currDeliveryDate);
        const delDateObj =  new Date(deliveryDate);
        const userDeliveryDateChanged = deliveryDate && currDelDateObj > delDateObj && hasUserDeliveryDate;
        

        dispatch(setBasketItems([...basketItems]));
        dispatch(setDeliveryDates(currEstDelDate, currDeliveryDate, false));

        if(userDeliveryDateChanged) {
            dispatch(updateShippingsDeliveryDate(currDelDateObj > delDateObj ? currDeliveryDate : deliveryDate, false));
            dispatch(showMessage('The requested delivery date has changed', 'warning'));
        } else if (hasUserDeliveryDate) {
            dispatch(updateShippingsDeliveryDate(deliveryDate));
        }
    });
    dispatch(setLoadingMessage(""));
    dispatch(setLoading(false));
};

export const getCheckoutItems = () => (dispatch) => {
    dispatch(setMarketSegmentXCostCenters(null));
    dispatch(setBasketItems(null));
    dispatch(setLoading(true));
    http.get('store/get-checkout-items')
        .then(({
            basketItems, shippingMethods, shippingAddresses, balance, usage, planDocuments, contactInfo, marketSegmentXCostCenters, itemInventory
        }) => {
            for (const bi of basketItems) {
                if (bi.customFields && typeof bi.customFields === 'string') {
                    bi.customFields = JSON.parse(bi.customFields);
                } else {
                    bi.customFields = {};
                }

                if (!bi.customFields.shippings) {
                    bi.customFields.shippings = [{
                        shippingAddress: shippingAddresses && shippingAddresses.length > 0
                            ? shippingAddresses[0].id
                            : '',
                        shippingMethod: shippingMethods[0].code,
                        quantity: bi.quantity
                    }];
                }
            }

            dispatch(setShippingMethods(shippingMethods));
            dispatch(setShippingAddresses(shippingAddresses));
            dispatch(setPlanDocuments(planDocuments));
            if (contactInfo) dispatch(setContactInfo(contactInfo));
            dispatch(setBalance(balance));
            dispatch(setUsed(usage));
            dispatch(setBasketItems(basketItems));
            dispatch(setMarketSegmentXCostCenters(marketSegmentXCostCenters));
            dispatch(setItemInventory(itemInventory));
        }).finally(() => dispatch(setLoading(false)));
};

export const saveAddress = (address, newAddressTarget) => (dispatch, getState) => {
    dispatch(setLoading(true));
    const currentState = getState();
    const { checkoutView: { basketItems, shippingAddresses: old } } = currentState;
    http.post('customer/save-address', address)
        .then((shippingAddresses) => {
            dispatch(setShippingAddresses(shippingAddresses));
            const newId = shippingAddresses.filter((s) => !old.some((o) => o.id === s.id))[0].id;
            basketItems.find((b) => b.lineId === newAddressTarget.lineId).customFields.shippings[newAddressTarget.index].shippingAddress = newId;
            dispatch(setBasketItems(basketItems));
        }).finally(() => dispatch(setLoading(false)));
};

export const saveAddressSimple = (address, cb) => (dispatch) => {
    dispatch(setLoading(true));
    http.post('customer/save-address', address).then((shippingAddresses) => {
        dispatch(setShippingAddresses(shippingAddresses));
        cb && cb();
    }).finally(() => dispatch(setLoading(false)));
};

export const addShipping = (lineId) => (dispatch, getState) => {
    const currentState = getState();
    const { checkoutView: { basketItems, shippingMethods, shippingAddresses } } = currentState;
    const item = basketItems.find((b) => b.lineId === lineId);
    if (!item.customFields.isPlanDocument) {
        const master = item.customFields.shippings.find((s) => s.quantity > 1);
        master.quantity -= 1;
    }
    item.customFields.shippings.push({
        shippingAddress: shippingAddresses && shippingAddresses.length > 0
            ? shippingAddresses[0].id
            : '',
        shippingMethod: shippingMethods[0].code,
        quantity: 1
    });
    dispatch(setBasketItems([...basketItems]));
};

export const removeShipping = (lineId, i) => (dispatch, getState) => {
    const currentState = getState();
    const { checkoutView: { basketItems } } = currentState;
    const item = basketItems.find((b) => b.lineId === lineId);
    item.customFields.shippings.splice(i, 1);
    let restCount = 0;
    for (let x = 1; x < item.customFields.shippings.length; x += 1) {
        restCount += item.customFields.shippings[x].quantity;
    }

    item.customFields.shippings[0].quantity = item.originalQty - restCount;
    item.quantity = item.customFields.shippings.reduce((prev, cur) => {
        return prev + cur.quantity;
    }, 0);
    dispatch(setBasketItems([...basketItems]));
};

export const applyShippingQty = (lineId, index, qty) => (dispatch, getState) => {
    const currentState = getState();
    const { checkoutView: { basketItems } } = currentState;
    const item = basketItems.find((b) => b.lineId === lineId);
    if (!item.originalQty) {
        item.originalQty = item.quantity;
    }
    item.customFields.shippings[index].quantity = parseInt(qty, 10);
    let total = 0;
    for (const s of item.customFields.shippings) {
        total += s.quantity;
    }
    item.quantity = total;
    dispatch(setBasketItems([...basketItems]));
};

export const applyShippingMethod = (lineId, index, code) => (dispatch, getState) => {
    const currentState = getState();
    const { checkoutView: { basketItems } } = currentState;
    const item = basketItems.find((b) => b.lineId === lineId);
    item.customFields.shippings[index].shippingMethod = code;
    dispatch(setBasketItems([...basketItems]));
};

export const applyShippingMethodP2 = (code) => (dispatch, getState) => {
    const currentState = getState();
    const { checkoutView: { basketItems } } = currentState;

    for(const item in basketItems) {
        if(item.customFields && item.customFields.shippings) {
            for(let shipping in item.customFields.shippings) {
                shipping.shippingMethod = code;
                shipping.estimatedDeliveryDate = null
            }
        }
    }

    dispatch(setBasketItems([...basketItems]));
};

export const applyShippingMethodData = (lineId, index, data) => async (dispatch, getState) => {
    const currentState = getState();
    const { checkoutView: { basketItems } } = currentState;
    const item = basketItems.find((b) => b.lineId === lineId);
    item.customFields.shippings[index].shippingMethodData = data;
    item.customFields.shippings[index].estimatedDeliveryDate = null;
    await dispatch(setBasketItems([...basketItems]));
};

export const applyShippingAddress = (lineId, index, addressId) => (dispatch, getState) => {
    const currentState = getState();
    const { checkoutView: { basketItems } } = currentState;
    const item = basketItems.find((b) => b.lineId === lineId);
    item.customFields.shippings[index].shippingAddress = addressId;
    item.customFields.shippings[index].estimatedDeliveryDate = null;
    dispatch(setBasketItems([...basketItems]));
};

export const submitOrder = (status, reason, used, address, contactInfo, obo, shippingNotes, totalPrice, costCenter, marketSegment) => (dispatch, getState) => {
    const currentState = getState();
    const { checkoutView: { basketItems, shippingAddresses }, appView: { user } } = currentState;
    const thumbnails = basketItems.map((b) => ({ code: b.code, thumbnail: b.customFields.thumbnail }));
    for (const b of basketItems) {
        if (address) {
            b.shippings = [{ 
                CustomerAddress: address, 
                quantity: b.quantity, 
                shippingMethod: address.shippingMethodCode || 'default', 
                deliveryDate: b.customFields.shippings && b.customFields.shippings.length > 0 && b.customFields.shippings[0].deliveryDate,
                estimatedDeliveryDate: b.customFields.shippings && b.customFields.shippings.length > 0 && b.customFields.shippings[0].estimatedDeliveryDate
            }];
        } else {
            b.shippings = b.customFields.shippings.map((s) => {
                const ss = { ...s, customerAddress: shippingAddresses.find((a) => a.id == s.shippingAddress) };
                delete ss.shippingAddress;
                return ss;
            });

            delete b.createdDate;
            delete b.basket;
        }
    }

    const productOrderData = {
        basketItems,
        status,
        customFields: {
            thumbnails,
            reason,
            lob: user.customFields.userType === 'telesales'
                ? 'p1a'
                : (user.customFields.npn
                    ? 'p1a'
                    : (
                        user.customFields.p2_Catalog
                            ? 'p2'
                            : ''
                    )),
            jobType: user.customFields.npn ? 'Broker'
                : (user.customFields.userType || ''),
            used,
            contactInfo,
            obo,
            shippingNotes,
            totalPrice,
            costCenter,
            marketSegment
        }
    };

    http.post('store/submit-product-order', productOrderData)
        .then((oid) => {
            dispatch(emptyCart());
            history.push(`/order/${oid}/c`);
        });
};

export const updateShippingsDeliveryDate = (newDeliveryDate, hasUserDeliveryDate = true) => async (dispatch, getState) => {
    const currentState = getState();
    const { checkoutView: { basketItems, shippingAddresses, estimatedDeliveryDate}, appView: { user, settings } } = currentState;

    const currDeliveryDateObj = basketItems[0].customFields.shippings[0].deliveryDate;
    const basketEstDelDate = new Date(basketItems[0].customFields.shippings[0].estimatedDeliveryDate);
    const currEstimatedDeliveryDate = estimatedDeliveryDate && new Date(estimatedDeliveryDate).getTime() !== basketEstDelDate.getTime() ? new Date(estimatedDeliveryDate) : basketEstDelDate;

    const newShipmentDate = new Date(newDeliveryDate);
    
    calcShipmentDate(newShipmentDate, currDeliveryDateObj.daysInTransit, settings);

    //Update basketitems shippings with new dates
    for(const item of basketItems) {
        for(const shipping of item.customFields.shippings) {
            shipping.deliveryDate.deliveryDate = new Date(newDeliveryDate).toLocaleDateString();
            shipping.deliveryDate.shipmentDate = new Date(newShipmentDate).toLocaleDateString();
        }
    }

    dispatch(setBasketItems([...basketItems]));
    dispatch(setDeliveryDates(new Date(currEstimatedDeliveryDate).toLocaleDateString(), new Date(newDeliveryDate).toLocaleDateString(), hasUserDeliveryDate));
};

const calcShipmentDate = (date, daysInTransit, settings) => {
    for (let n = 0; n < daysInTransit; n += 1) {
        do {
            date.setDate(date.getDate() - 1);
        } while (date.getDay() === 0 || date.getDay() === 6 || (settings && settings.holidays && settings.holidays.includes(formatDate(date))));
    }

    return date.toLocaleDateString();
};

const formatDate = (date) => {
    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : `0${month}`;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : `0${day}`;

    return `${month}/${day}/${year}`;
};