import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deletePlanSponsor, getPlanSponsors, savePlanSponsor } from './action';
import ListView from './ListView';

const mapStateToProps = ({ planSponsorView, appView: { user } }) => {
    return { ...planSponsorView, user };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getPlanSponsors, savePlanSponsor, deletePlanSponsor
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListView);
