import {
    Box, Button, ButtonGroup, Container, CssBaseline,

    Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid,
    IconButton, InputLabel, MenuItem, Paper, Select, TextField,
    Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    DialogContentText
} from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/Remove';
import WarningIcon from '@material-ui/icons/Warning';
import React, { PureComponent } from 'react';
import http from 'utils/http';
import AddressModal from './AddressModal';
import BasketItemsView from './BasketItemsView';
import BrokerView from './BrokerView';
import P2ContactInfo from './P2ContactInfo';
import P2OBO from './P2OBO';
import SingleAddressView from './SingleAddressView';
import Validator from 'utils/ValidateUtil';
import _ from 'lodash';

export default class CheckoutView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            openAddress: false,
            reason: '',
            additionalReason: '',
            address: null,

            inputAddress: { shippingMethodCode: 'Standard' },
            validating: false,

            oboRequired: false,
            obo: null,

            shippingNotes: '',

            showAddressCorrectedDialog: false,

            confirmShippingMethod: false,
            nextShippingMethod: null,
            nextLineId: null,
            nextIndex: null,

            newAddressTarget: null,

            totalPrices: null,

            errorMessage: null,

            addressValidated: false,
            recommendedAddress: undefined,
            invalidAddressCount: 0,

            showRemovedItemsDialog: false,
            basketItemsValidated: false,
            itemsRemoved: [],

            marketSegmentOptions: [], // user.customFields.marketSegments || [],
            marketSegment: '',
            costCenter: '',
            costCenters: [],

            isSaveAddress: false,
            addressSaved: false,
            isPOBox: false,

            validations: {}
        };
    }

    componentDidMount() {
        this.setState({ totalPrices: null });
        const { getCheckoutItems } = this.props;
        getCheckoutItems();
    }

    componentWillUnmount() {
        const {clearData} = this.props;

        clearData();
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const pricingInfo = this.props.pricing || nextProps.pricing;
        const basketItems = this.props.basketItems || nextProps.basketItems;
        if (pricingInfo && basketItems && basketItems[0].customFields.program === 'OE') {
            let total = 0;
            for (const item of basketItems) {
                const { quantity, code } = item;

                let matchedPrice;
                const itemPricing = pricingInfo.filter((p) => p.description.includes(code));
                for (const ip of itemPricing) {
                    const qtyRange = ip.quantity.indexOf('+') > 0
                        ? [parseInt(ip.quantity.replace('+', ''), 10), 99999999999]
                        : ip.quantity.split('–').map((q) => parseInt(q, 10));

                    if (item.quantity >= qtyRange[0] && item.quantity <= qtyRange[1]) {
                        matchedPrice = ip.printPricePerUnit;
                    }
                }
                if (matchedPrice) {
                    total += (matchedPrice * quantity);
                }
            }

            if (total) {
                this.setState({ totalPrices: total.toFixed(2) });
            }
        }

        const { inputAddress } = this.state;
        if (nextProps.shippingMethods && nextProps.shippingMethods.length && !nextProps.shippingMethods.some((m) => m.code === inputAddress.shippingMethodCode)) {
            this.setState({ inputAddress: { ...inputAddress, shippingMethodCode: nextProps.shippingMethods[0].code } });
        }

        const { marketSegmentXCostCenters } = this.props;
        if (nextProps.marketSegmentXCostCenters && !marketSegmentXCostCenters) {
            const { user: { customFields: { costCenters } } } = nextProps;
            const marketSegmentOptions = [...new Set(nextProps.marketSegmentXCostCenters.map((x) => x.marketSegment).filter((ms) => ms !== 'MCARE'))];
            // const { costCenter } = nextProps.marketSegmentXCostCenters[0];
            // const { marketSegment } = nextProps.marketSegmentXCostCenters[0];
            // const costCenters = nextProps.marketSegmentXCostCenters.filter((x) => x.marketSegment === marketSegment).map((x) => x.costCenter);
            if (costCenters && costCenters.length > 0) {
                // default
                const defaultValue = nextProps.marketSegmentXCostCenters.find((x) => costCenters.includes(x.costCenter.value));
                if (defaultValue) {
                    const cc = nextProps.marketSegmentXCostCenters.filter((x) => x.marketSegment === defaultValue.marketSegment).map((x) => x.costCenter);
                    this.setState({
                        marketSegmentOptions,
                        costCenter: defaultValue.costCenter.value,
                        marketSegment: defaultValue.marketSegment,
                        costCenters: cc
                    });

                } else {

                    this.setState({
                        marketSegmentOptions
                    });
                }
            }
            else {

                this.setState({
                    marketSegmentOptions
                });
            }
        }

        if (!this.props.basketItems && nextProps.basketItems) {
            this.refreshEstimatedDeliveryDate(nextProps.basketItems);
        }
    }

    componentDidUpdate() {
        const { getPricing, pricing } = this.props;
        if (!pricing) { getPricing(); }
    }

    refreshEstimatedDeliveryDate = (nextBasketItems, isUserDate = false) => {
        const { shippingAddresses, GetEstimatedDeliveryDate, user } = this.props;
        const { inputAddress } = this.state;
        let { basketItems } = this.props;
        const isP2 = user && user.customFields && !!user.customFields.p2_Catalog;
        if (nextBasketItems) basketItems = nextBasketItems;

        if (basketItems && basketItems.length
            && basketItems[0].customFields
            && (basketItems[0].customFields.program === 'OE' 
            || isP2)) {
            const edd = [];
            for (const bi of basketItems) {
                const { shippings } = bi.customFields;
                for (let n = 0; n < shippings.length; n += 1) {
                    if (!isP2 && shippings[n].shippingAddress) {
                        const sa = shippingAddresses.find((sa) => sa.id == shippings[n].shippingAddress);
                        edd.push({
                            lineId: bi.lineId,
                            addressId: shippings[n].shippingAddress,
                            code: bi.code,
                            isKit: false,
                            quantity: shippings[n].quantity,
                            state: sa.state,
                            address: sa.address,
                            city: sa.city,
                            zip: sa.zip,
                            country: sa.country,
                            shippingMethodId: shippings[n].shippingMethod,
                            productId: bi.productId,
                            isUserDate: isUserDate
                        });
                    }
                    else if (isP2 && !!(inputAddress.address
                        && inputAddress.city
                        && inputAddress.state
                        && inputAddress.zip
                        && inputAddress.country
                        && inputAddress.shippingMethodCode
                    )) {
                        edd.push({
                            addressId: 0,
                            lineId: bi.lineId,
                            code: bi.code,
                            isKit: bi.isKit,
                            quantity: bi.quantity,
                            state: inputAddress.state,
                            address: inputAddress.address,
                            city: inputAddress.city,
                            zip: inputAddress.zip,
                            country: inputAddress.country,
                            shippingMethodId: inputAddress.shippingMethodCode,
                            productId: bi.productId,
                            isUserDate: isUserDate
                        });
                    }
                }
            }

            if (edd.length) {
                GetEstimatedDeliveryDate(edd);
            }
        }
    }

    changeShippingQty = (lineId, index, qty) => {
        const { applyShippingQty } = this.props;
        applyShippingQty(lineId, index, qty);
        this.refreshEstimatedDeliveryDate();
    }

    changeMarketSegment = (e) => {
        this.setState({ marketSegment: e.target.value });
        this.setState({ costCenter: '', costCenters: [] });
        if (e.target.value) {
            const { marketSegmentXCostCenters } = this.props;
            if (marketSegmentXCostCenters) {
                const costCenters = marketSegmentXCostCenters.filter((x) => x.marketSegment === e.target.value).map((x) => x.costCenter);
                this.setState({
                    costCenters,
                    costCenter: costCenters.length === 1 ? costCenters[0] : ''
                });
            } else {
                http.get(`store/get-cost-centers/${e.target.value}`)
                    .then((costCenters) => this.setState({
                        costCenters: costCenters.map((cc) => cc.value),
                        costCenter: costCenters.length === 1 ? costCenters[0] : ''
                    }));
            }
        }
    }

    changeCostCenter = (e) => {
        this.setState({ costCenter: e.target.value });
    }

    onReasonChange = (reason) => {
        this.setState({ reason });
    }

    onShippingAddressChange = (lineId, index, addressId) => {
        if (addressId === '-1') {
            this.setState({ newAddressTarget: { lineId, index } });
            this.openAddressModal();
        } else {
            const { applyShippingAddress } = this.props;
            applyShippingAddress(lineId, index, addressId);
        }

        this.refreshEstimatedDeliveryDate();
    }

    closeConfirmShippingMethod = () => {
        this.setState({
            nextShippingMethod: null, nextLineId: null, nextIndex: null, confirmShippingMethod: false
        });
    }

    saveConfirmShippingMethod = () => {
        const { nextShippingMethod, nextLineId, nextIndex } = this.state;
        const { applyShippingMethod } = this.props;
        applyShippingMethod(nextLineId, nextIndex, nextShippingMethod);
        this.closeConfirmShippingMethod();

        this.refreshEstimatedDeliveryDate();
    }

    onShippingMethodChange = (lineId, index, code) => {
        if (code === '2nd Day Air' || code === 'Overnight') {
            this.setState({
                nextShippingMethod: code, nextLineId: lineId, nextIndex: index, confirmShippingMethod: true
            });
        } else {
            const { applyShippingMethod } = this.props;
            applyShippingMethod(lineId, index, code);
        }
        this.refreshEstimatedDeliveryDate();
    }

    onShippingMethodDataChange = (lineId, index, data) => {
        const { applyShippingMethodData } = this.props;
        applyShippingMethodData(lineId, index, data);
    }

    addShipping = (lineId) => {
        const { addShipping } = this.props;
        addShipping(lineId);
        this.refreshEstimatedDeliveryDate();
    }

    removeShipping = (lineId, i) => {
        const { removeShipping } = this.props;
        removeShipping(lineId, i);
        this.refreshEstimatedDeliveryDate();
    }

    closeAddressModal = () => {
        this.setState({ openAddress: false, newAddressTarget: null });
    }

    saveAddress = (addr) => {
        const { saveAddress } = this.props;
        const { newAddressTarget } = this.state;
        saveAddress(addr, newAddressTarget);
        this.setState({ openAddress: false, newAddressTarget: null });
    }

    openAddressModal = () => {
        this.setState({ openAddress: true });
    }

    placeOrder = async () => {
        this.setState({ validating: true, errorMessage: null });
        const {
            inputAddress, obo: oboData, oboRequired, shippingNotes, totalPrices, addressValidated, basketItemsValidated
        } = this.state;
        const { showMessage, user, contactInfo, invalidAddressCount } = this.props;
        const { submitOrder, used } = this.props;

        const fieldsValidation = this.validate();

        if(!fieldsValidation.isValid) {
            showMessage('Validation Failed', 'warning');
            this.setState({ validating: false });
            return;
        }

        if (inputAddress && inputAddress.address) {
            if (addressValidated) {
                if (user.customFields.p2_Catalog) {
                    let obo = null;
                    if (oboRequired && oboData && oboData.firstName) {
                        obo = oboData;
                    }

                    let basketValidated = await this.validateBasketItems();

                    if(!basketItemsValidated && !basketValidated) {
                        return;
                    }

                    submitOrder(''/* status generated backend */, null, null, inputAddress, contactInfo, obo, shippingNotes);
                    return;
                }

                const exceeded = this.getExceeded();

                const { reason: reasonType, additionalReason } = this.state;
                const reason = reasonType === 'Other' ? additionalReason : reasonType;
                submitOrder((exceeded ? 'Pending' : 'Approved'), reason, used, inputAddress, null, null, shippingNotes);
            } else {
                if ((Validator.isPOBox(inputAddress.address) || Validator.isPOBox(inputAddress.address2))) {
                    this.showPoBoxMessage();
                    return;
                }

                this.setState({isPOBox: false});
                http.post('store/validate-address', {
                    Address1: inputAddress.address,
                    Address2: inputAddress.address2,
                    City: inputAddress.city,
                    State: inputAddress.state,
                    Zipcode: inputAddress.zip,
                    Country: inputAddress.country
                }).then(async (r) => {
                    // {"StatusMessage":"Not Found","Success":false,"POBox":false}
                    const isValid = r.Success && !r.POBox;
                    this.setState({ addressValidated: r.POBox ? false : true });
                    if (isValid || (user.customFields.p2_Catalog && invalidAddressCount > 0 && !r.POBox)) {
                        if (inputAddress.address !== r.Address1
                            || (!!inputAddress.address2 && !!r.Address2 && inputAddress.address2 !== r.Address2)
                            || inputAddress.city !== r.City
                            || inputAddress.state !== r.State
                            || inputAddress.zip !== r.Zipcode
                            // || inputAddress.country !== r.Country
                        ) {

                            if (user.customFields.p2_Catalog) {
                                this.setState({ recommendedAddress: r, showAddressCorrectedDialog: true, addressValidated: true });
                            } else {
                                inputAddress.address = r.Address1;
                                inputAddress.address2 = r.Address2;
                                inputAddress.city = r.City;
                                inputAddress.state = r.State;
                                inputAddress.zip = r.Zipcode;
                                // inputAddress.country = r.Country;

                                this.setState({ inputAddress: { ...inputAddress }, showAddressCorrectedDialog: true, addressValidated: true });
                            }

                            return;
                        }

                        const exceeded = this.getExceeded();
                        if (user.customFields.p2_Catalog) {
                            let obo = null;

                            if (oboRequired && oboData && oboData.firstName) {
                                obo = oboData;
                            }

                            let basketValidated = await this.validateBasketItems();

                            if(!basketItemsValidated && !basketValidated) {
                                return;
                            }

                            submitOrder((exceeded ? 'Pending' : 'Approved'), null, null, inputAddress, contactInfo, obo, shippingNotes);
                            return;
                        }

                        const { reason: reasonType, additionalReason } = this.state;
                        const reason = reasonType === 'Other' ? additionalReason : reasonType;
                        submitOrder((exceeded ? 'Pending' : 'Approved'), reason, used, inputAddress, null, null, shippingNotes);
                    } else if (r.POBox) {
                        this.showPoBoxMessage();
                    } else {
                        if (user.customFields.p2_Catalog) {
                            this.setState({invalidAddressCount: invalidAddressCount + 1, showAddressCorrectedDialog: true});
                        } else {
                            showMessage('Invalid address', 'warning');
                        }
                    }
                }).finally(() => this.setState({ validating: false }));
            }
        } else {
            const {
                basketItems
            } = this.props;
            let planDocumentExceeded = false;
            let maxPerAddress = null;
            for (const item of basketItems) {
                if (item.isPlanDocument && item.customFields.maxPerAddress) {
                    maxPerAddress = maxPerAddress || item.customFields.maxPerAddress;
                    for (const s of item.customFields.shippings) {
                        if (s.quantity > item.customFields.maxPerAddress) {
                            planDocumentExceeded = true;
                        }
                    }
                }
            }

            if (planDocumentExceeded) {
                this.setState({ errorMessage: `Benefit Summary or Enrollment Form is limited to ${maxPerAddress} per address.` });
                return;
            }

            const exceeded = this.getExceeded();

            const
                {
                    reason: reasonType, additionalReason, costCenter, marketSegment
                } = this.state;
            const reason = reasonType === 'Other' ? additionalReason : reasonType;
            submitOrder((exceeded ? 'Pending' : 'Approved'), reason, used, null, null, null, shippingNotes, totalPrices, costCenter, marketSegment);
        }
    }

    validateBasketItems = async () => {
        const { basketItems, user } = this.props;
        const { basketItemsValidated } = this.state;
        let itemsRemoved = [];
        let result = false;

        if (user && user.customFields.p2_Catalog && !basketItemsValidated) {
            await http.get('store/validate-basket-items')
                .then((c) => {
                    itemsRemoved = c;

                    if (c && c.length > 0) {
                        this.setState({ itemsRemoved, showRemovedItemsDialog: true, basketItemsValidated: false });
                    } else {
                        this.setState({ basketItemsValidated: true });
                        result = true;
                    }
                });
        }

        return result;
    }

    setAddress = (value) => {
        const { inputAddress, recommendedAddress, isSaveAddress } = this.state;
        const refreshDeliveryDate = !_.isEqual(inputAddress, recommendedAddress); // inputAddress.state !== recommendedAddress.state || inputAddress.shippingMethodCode !== recommendedAddress.shippingMethodCode;

        if(value) {
            inputAddress.address = recommendedAddress.Address1;
            inputAddress.address2 = recommendedAddress.Address2;
            inputAddress.city = recommendedAddress.City;
            inputAddress.state = recommendedAddress.State;
            inputAddress.zip = recommendedAddress.Zipcode;
        }

        this.setState({ inputAddress: { ...inputAddress }, showAddressCorrectedDialog: false});

        if(isSaveAddress) {
            this.saveAddressToAddressBook();
            this.setState({ isSaveAddress: false });
        }

        if(refreshDeliveryDate) {
            this.refreshEstimatedDeliveryDate();
        }
    }

    fallbackSrc = (e) => {
        e.target.src = '//cdndev.memberdoc.com/f/img_no_thumb.jpg';
        e.target.style.width = '60px';
    }

    saveNotes = (shippingNotes) => {
        this.setState({ shippingNotes });
    }

    getExceeded = () => {
        const {
            user,
            used, basketItems
        } = this.props;

        if (user && user.customFields.p2_Catalog) {
            const hasItemLimit = user.customFields.itemLimit && (user.customFields.passthroughLogin || !user.customFields.isAdminUser);
            return user.customFields.itemLimit
                && basketItems
                && basketItems.some((b) => {
                    const itemLimit = b.customFields.itemLimit && b.customFields.itemLimit.length > 0 && !user.customFields.isAdminUser ? b.customFields.itemLimit : user.customFields.itemLimit;

                    return hasItemLimit && b.quantity > itemLimit;
                });
        }
        let exceeded = false;
        if (basketItems && used) {
            for (const item of basketItems) {
                if ((used[item.code] || 0) + item.quantity > 15) {
                    exceeded = true;
                }
            }
        }
        return exceeded;
    }

    changeAdditionalReason = (e) => {
        const additionalReason = e.target.value;
        this.setState({ additionalReason });
    }

    onInputAddressChange = async (newInputAddress, callback) => {
        const { inputAddress } = this.state;
        const { user, applyShippingMethodP2, updateShippingsDeliveryDate } = this.props;
        
        this.setState({ inputAddress: newInputAddress, selectedAddressId: newInputAddress.id, validating: false, isPOBox: false }, () => {
            callback && callback();
        });

        if(user.customFields.p2_Catalog) {
            this.setState({             
                addressValidated: false,
                recommendedAddress: undefined,
                invalidAddressCount: 0
            });

            if(newInputAddress.UserDeliveryDate && newInputAddress.UserDeliveryDate !== inputAddress.UserDeliveryDate) {
                updateShippingsDeliveryDate(newInputAddress.UserDeliveryDate);
            }

            await applyShippingMethodP2(newInputAddress.shippingMethodCode);
        }
    }

    onOBOChange = (obo) => {
        this.setState({ obo });
    }

    onContactInfoChange = (contactInfo) => {
        const { setContactInfo } = this.props;
        setContactInfo(contactInfo);
    }

    toggleOBO = (_, expanded) => {
        this.setState({ oboRequired: expanded });
    }

    getEstimatedDeliveryDateContent = (shippingMethods, s) => {
        const theM = shippingMethods.find((me) => me.code === s.shippingMethod);

        return theM && theM.estimatedDeliveryDate
            ? <p>Estimgated delivery date: {shippingMethods.find((me) => me.code === s.shippingMethod).estimatedDeliveryDate}</p>
            : (s.estimatedDeliveryDate
                ? (<p>Estimated delivery date: {s.estimatedDeliveryDate}</p>)
                : <p />
            );
    }

    getPrice = (itemCode, itemQuantity) => {
        const { pricing } = this.props;
        if (!pricing) return null;
        const itemPricing = pricing.filter((p) => p.description.includes(itemCode));
        let prices = [];
        for (const ip of itemPricing) {
            const qtyRange = ip.quantity.indexOf('+') > 0
                ? [parseInt(ip.quantity.replace('+', ''), 10), 99999999999]
                : ip.quantity.split('–').map((q) => parseInt(q, 10));

            if (itemQuantity >= qtyRange[0] && itemQuantity <= qtyRange[1]) {
                const p = itemQuantity * ip.printPricePerUnit;
                prices = [`$${p.toFixed(2)} ${(ip.spec ? ` (${ip.spec})` : '')}`];
                // prices.push(`$${p.toFixed(2)} ${(ip.spec ? ` (${ip.spec})` : '')}`);
            }
        }
        if (prices.length) {
            return prices.map((p) => <p key={p}>{p}</p>);
        }

        return null;
    };

    clearAddress = () => {
        const { clearData } = this.props;
        const inputAddress = { shippingMethodCode: 'Standard' };

        clearData();
        this.setState({isSaveAddress: false, inputAddress: inputAddress, selectedAddressId: ''});
    }

    showPoBoxMessage = () => {
        const { showMessage } = this.props;
        this.setState({isPOBox: true});
        showMessage('PO Box is not allowed', 'warning');
    }

    saveAddressToAddressBook = () => {
        const {
            inputAddress, invalidAddressCount
        } = this.state;
        const { showMessage, user, saveAddressSimple } = this.props;

        if ((Validator.isPOBox(inputAddress.address) || Validator.isPOBox(inputAddress.address2))) {
            this.showPoBoxMessage();
            return;
        }

        this.setState({ validating: true, errorMessage: null, isSaveAddress: false, addressSaved: false, isPOBox: false });

        http.post('store/validate-address', {
            Address1: inputAddress.address,
            Address2: inputAddress.address2,
            City: inputAddress.city,
            State: inputAddress.state,
            Zipcode: inputAddress.zip,
            Country: inputAddress.country
        }).then((r) => {
            const validationError = !!this.validate().validations.shippingEmail;
            const isValid = r.Success && !r.POBox;
            if (!isValid && (!invalidAddressCount || invalidAddressCount === 0) || validationError) {
                if (r.POBox) {
                    this.showPoBoxMessage();
                } else {
                    if (user.customFields.p2_Catalog && !isValid) {
                        showMessage('Suggested address not found. Address may be invalid.', 'warning');
                    } 
                    else if (user.customFields.p2_Catalog && validationError) {
                    } else {
                        showMessage('Invalid address, please check your input.', 'warning');
                    }
                }

                if (user.customFields.p2_Catalog) {
                    this.setState({invalidAddressCount: invalidAddressCount + 1, showAddressCorrectedDialog: false});
                }
            } else {
                if (inputAddress.address !== r.Address1
                    || inputAddress.address2 !== r.Address2
                    || inputAddress.city !== r.City
                    || inputAddress.state !== r.State
                    || inputAddress.zip !== r.Zipcode
                    || (!user.customFields.p2_Catalog && inputAddress.country !== r.Country)) {

                    if (user.customFields.p2_Catalog && (!invalidAddressCount || invalidAddressCount === 0)) {
                        this.setState({recommendedAddress: r, invalidAddressCount: invalidAddressCount + 1, showAddressCorrectedDialog: true, isSaveAddress: true });
                        return;
                    } else if (!user.customFields.p2_Catalog) {
                        inputAddress.address = r.Address1;
                        inputAddress.address2 = r.Address2;
                        inputAddress.city = r.City;
                        inputAddress.state = r.State;
                        inputAddress.zip = r.Zipcode;
                        inputAddress.country = r.Country;
                    }
                }

                saveAddressSimple(inputAddress, () => {
                    const { shippingAddresses } = this.props;
                    const { addressSaved } = this.state;

                    if(!inputAddress.id) {
                        const newAddr = shippingAddresses.sort((a, b) => b.id - a.id)[0];
                        this.setState({selectedAddressId: newAddr.id});
                    }

                    this.setState({addressSaved: !addressSaved});

                    showMessage('Address Saved', 'info'); 
                });
            }
        }).finally(() => this.setState({ validating: false }));
    }

    validate = () => {
        const { obo, inputAddress, validations: origValidations } = this.state;
        const { contactInfo } = this.props;
        let isValid = true;
        let validations = {...origValidations};
        
        if (inputAddress.email && inputAddress.email.length > 0 && !Validator.validateEmail(inputAddress.email)) {
            validations.shippingEmail = "Invalid email address format";
            isValid = false;
        } else {
            validations.shippingEmail = null;
        }

        if (obo && obo.email && obo.email.length > 0 && !Validator.validateEmail(obo.email)) {
            validations.oboEmail = "Invalid email address format";
            isValid = false;
        } else {
            validations.oboEmail = null;
        }

        if (contactInfo && contactInfo.email && contactInfo.email.length > 0 && !Validator.validateEmail(contactInfo.email)) {
            validations.contactEmail = "Invalid email address format";
            isValid = false;
        } else {
            validations.contactEmail = null;
        }

        this.setState({validations: validations});

        return { isValid: isValid, validations: validations };
    }

    render() {
        const {
            basketItems, shippingAddresses, shippingMethods, showMessage, user, used, submitOrder, contactInfo, estimatedDeliveryDate, deliveryDate, isLoading, loadingMessage,
            itemInventory
        } = this.props;

        const {
            shippingNotes, openAddress, address, reason, additionalReason, inputAddress, validating, showAddressCorrectedDialog, 
            confirmShippingMethod, totalPrices, errorMessage, recommendedAddress, showRemovedItemsDialog, itemsRemoved, validations, addressSaved, isPOBox
        } = this.state;

        if (!user) return null;
        const exceeded = this.getExceeded();

        const finalReason = reason === 'Other' ? additionalReason : reason;
        const allowSpouse = user && user.customFields.userType === 'telesales';

        if (user && user.customFields && user.customFields.npn) {
            return (
                <BrokerView
                    basketItems={basketItems}
                    used={used}
                    user={user}
                    showMessage={showMessage}
                    submitOrder={submitOrder}
                />
            );
        }

        if (user.customFields.userType === 'telesales') {
            const requiredFieldsFilled = !!(inputAddress
                && inputAddress.firstName
                && inputAddress.lastName
                && inputAddress.phone
                && inputAddress.phone.indexOf(' ') < 0
                && inputAddress.address
                && inputAddress.city
                && inputAddress.state
                && inputAddress.zip
                && inputAddress.country);

            const merged = basketItems;//
            //     [];
            // if (basketItems && basketItems.length) {
            //     for (const item of basketItems) {
            //         if (!(m)erged.find(m => m.code == item.code)) {
            //             merged.push(item);
            //         }
            //     }
            // }
            return (
                <Container fixed className="shop-cart-view">
                    <Box component="div" mb={4}>
                        <Typography variant="h3">
                            CHECKOUT
                        </Typography>
                    </Box>
                    <Box>
                        {merged && <BasketItemsView noThumbnail basketItems={merged} />}
                    </Box>
                    <Box mt={2} mb={2} p={2} component={Paper}>
                        <SingleAddressView
                            addr={inputAddress}
                            onAddressChange={this.onInputAddressChange}
                            spouseSupported
                            showMessage={showMessage}
                            phoneRequired
                            shippingMethods={shippingMethods}
                            disableShippingMethod
                            validations={validations}
                            addressSaved={addressSaved}
                        />
                    </Box>
                    <Box>
                        <ButtonGroup className="list-btn-group">
                            <Button
                                className="btn place-order-btn"
                                disabled={!requiredFieldsFilled || validating}
                                variant="contained"
                                color="primary"
                                onClick={this.placeOrder}
                            >
                                Place Order
                            </Button>
                        </ButtonGroup>
                    </Box>

                    <Dialog open={showAddressCorrectedDialog} onClose={() => this.setState({ showAddressCorrectedDialog: false })} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Address Corrected</DialogTitle>
                        <DialogContent>
                            The address you entered has been corrected.
                        </DialogContent>
                    </Dialog>
                </Container>
            );
        } else if (user.customFields.p2_Catalog) {
            const isAddressFilled = !!(inputAddress.firstName
                && inputAddress.lastName
                && inputAddress.address
                && inputAddress.city
                && inputAddress.state
                && inputAddress.zip
                && inputAddress.phone && (!inputAddress.phone.includes(' '))
                && inputAddress.email
            );

            const sorted = basketItems
                ? [...basketItems.filter((b) => b.customFields.materialType === 'kit'), ...basketItems.filter((b) => b.customFields.materialType !== 'kit')]
                : [];

            const { oboRequired, obo } = this.state;
            const oboOK = !oboRequired
                || !obo
                || !!(obo.firstName
                    && obo.lastName
                    && obo.agentWritingNumber
                    && obo.email
                )
                || (!obo.firstName
                    && !obo.lastName
                    && !obo.agentWritingNumber
                    && !obo.email
                );
            const ciFilled = !!contactInfo && !!(
                contactInfo.firstName
                && contactInfo.lastName
                && contactInfo.agentWritingNumber
                && contactInfo.email
            );

            return (

                <Container fixed className="shop-cart-view" id="CheckoutContainer">
                    {isLoading?<div className="loading-overlay">
                        <span>{loadingMessage}</span>
                    </div> : null}
                    <Box component="div" mb={4}>
                        <Typography variant="h3">
                            CHECKOUT
                        </Typography>
                    </Box>
                    <Box>
                        {sorted.length > 0 && <BasketItemsView noThumbnail hasQty showMaterialType basketItems={sorted} itemInventory={itemInventory} />}
                    </Box>
                    <Box mt={2} mb={2} p={2} component={Paper}>
                        <SingleAddressView
                            key={`sav_${estimatedDeliveryDate}`}
                            addr={inputAddress}
                            onAddressChange={this.onInputAddressChange}
                            showMessage={showMessage}
                            phoneRequired
                            emailRequired
                            shippingMethods={shippingMethods}
                            user={user}
                            selectedAddressId={this.state.selectedAddressId}
                            saveToAddressBookEnabled={true}
                            saveAddressToAddressBook={this.saveAddressToAddressBook}
                            clear={this.clearAddress}
                            isAddressFilled={isAddressFilled}
                            validations={validations}
                            addressSaved={addressSaved}
                            estimatedDeliveryDate={estimatedDeliveryDate}
                            deliveryDate={deliveryDate}
                            refreshEstimatedDeliveryDate={this.refreshEstimatedDeliveryDate}
                        />
                    </Box>
                    <Box>
                        <ExpansionPanel onChange={this.toggleOBO}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Ordering on Behalf of</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <P2OBO onOBOChange={this.onOBOChange} validations={validations} />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Box>
                    <Box mt={2} mb={2} p={2} component={Paper}>
                        {!!contactInfo && <P2ContactInfo onContactInfoChange={this.onContactInfoChange} contactInfo={contactInfo} isAddressFilled={isAddressFilled} validations={validations} />}
                    </Box>
                    <Box>
                        {exceeded
                            && (
                                <Box mt={1}>
                                    <WarningIcon style={{ color: '#ff9800' }} />
                                    {/* <span>{`You are ordering items more than ${user.customFields.itemLimit}, it will be routed for approval.`}</span> */}
                                    <span>You are ordering more than the limit allowed. Your order will be routed for approval.</span>
                                </Box>
                            )}
                        <ButtonGroup className="list-btn-group">
                            <Button
                                className="btn place-order-btn"
                                disabled={isLoading ||validating || (!isAddressFilled) || (!oboOK) || !ciFilled || isPOBox}
                                variant="contained"
                                color="primary"
                                onClick={this.placeOrder}
                            >
                                Place Order
                            </Button>
                        </ButtonGroup>
                    </Box>

                    <Dialog open={showAddressCorrectedDialog} onClose={() => this.setState({ showAddressCorrectedDialog: false })} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{recommendedAddress ? "Address Corrected" : ""}</DialogTitle>
                        <DialogContent>
                            {recommendedAddress ?
                                <Box>
                                    <Box pb={2}>
                                        <Typography>
                                            The following address correction is recommended. Would you like to use the recommended address?
                                        </Typography>
                                    </Box>
                                    <Grid item xs={12}>
                                        <Grid item xs={12}>
                                            {recommendedAddress.Address1}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {recommendedAddress.Address2}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {recommendedAddress.City}, {recommendedAddress.State} {recommendedAddress.Zipcode}
                                        </Grid>
                                    </Grid>
                                </Box>
                                : <Box>
                                    <Box pb={2}>
                                        <Typography>
                                            Suggested address not found. Address may be invalid
                                        </Typography>
                                    </Box>
                                </Box>}

                        </DialogContent>
                        {recommendedAddress ?
                            <DialogActions>
                                <Button color="primary" onClick={() => { this.setAddress(true); }}>
                                    Yes
                            </Button>
                                <Button color="default" onClick={() => { this.setAddress(false); }}>
                                    No
                            </Button>
                            </DialogActions> :
                            <DialogActions>
                                <Button color="primary" onClick={() => { this.setState({ showAddressCorrectedDialog: false }); }}>
                                    Ok
                                </Button>
                            </DialogActions>
                        }
                    </Dialog>

                    <Dialog open={showRemovedItemsDialog} onClose={() => this.setState({ showRemovedItemsDialog: false })} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Items Removed</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                The following kits/items are no longer available and must be deleted from cart to continue:
                        </DialogContentText>
                            <TableContainer component={Paper} style={{ maxHeight: '400px' }}>
                                <Table aria-label="items removed table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Item</TableCell>
                                            <TableCell>Description</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {itemsRemoved && itemsRemoved.map((row) => (
                                            <TableRow key={row.code + row.name}>
                                                <TableCell component="th" scope="row">
                                                    {row.code}
                                                </TableCell>
                                                <TableCell>{row.name} </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>
                    </Dialog>
                </Container>
            );
        }

        const isOE = basketItems
            && basketItems.length
            && basketItems[0].customFields
            && basketItems[0].customFields.program === 'OE';

        const {
            costCenters, costCenter, marketSegment, marketSegmentOptions
        } = this.state;
        const costCenterRequired = basketItems && basketItems.some((b) => b.customFields.outputType === 'Print Only');
        const missingCostCenter = isOE && costCenterRequired && !costCenter;
        return (
            <Container fixed className="shop-cart-view">
                <CssBaseline />
                <Box component="div" mb={4}>
                    <Typography variant="h3">
                        CHECKOUT
                    </Typography>
                </Box>
                <Box component="div">
                    <Divider style={{ height: '3px' }} />
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={1}
                        style={{ padding: '20px 0' }}
                    >
                        <Grid item xs={5}>

                            <Typography variant="h6">
                                Product
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="h6">
                                Shipping
                            </Typography>
                        </Grid>
                        <Grid item xs={5} />
                    </Grid>
                    <Divider />
                    {basketItems && basketItems.length > 0 && basketItems.map((item) => {
                        return (
                            <Grid key={item.lineId} container direction="row" justify="flex-start" alignItems="center" spacing={1} style={{ padding: '20px 0' }}>
                                <Grid item xs={5}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <img
                                                onError={this.fallbackSrc}
                                                src={item.customFields.thumbnail || '//cdndev.memberdoc.com/f/img_no_thumb.jpg'}
                                                alt={item.name}
                                                style={{ width: '150px' }}
                                            />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <b>{item.customFields.alias || item.name}</b> <br />
                                            {item.code}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={7}>
                                    {item.customFields.outputType !== 'Digital Only' && item.customFields.shippings.map((s, i) => (
                                        <Grid container key={i} spacing={3}>
                                            {!item.isPlanDocument && item.originalQty && item.originalQty !== item.quantity && <Grid item xs={12}><WarningIcon style={{ color: '#FF9800' }} />Total qty of this item is {item.originalQty}</Grid>}
                                            <Grid item xs={3}>
                                                <TextField
                                                    label="Qty"
                                                    size="small"
                                                    type="number"
                                                    min={1}
                                                    inputProps={{
                                                        min: 1,
                                                        readOnly: !item.customFields.isPlanDocument && item.customFields.shippings.length === 1
                                                    }}

                                                    style={{ width: '6rem' }}
                                                    value={s.quantity}
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                    onChange={(e) => this.changeShippingQty(item.lineId, i, e.target.value)}
                                                />
                                                {isOE && !item.isPlanDocument && this.getPrice(item.code, s.quantity)}
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                >
                                                    <InputLabel>Send To</InputLabel>
                                                    <Select
                                                        value={s.shippingAddress}
                                                        label="Send To"
                                                        onChange={(e) => this.onShippingAddressChange(item.lineId, i, e.target.value)}
                                                    >
                                                        <MenuItem value="">
                                                            <em>Select</em>
                                                        </MenuItem>
                                                        {
                                                            shippingAddresses.map((addr) => <MenuItem key={addr.id} value={addr.id}>{addr.name}</MenuItem>)
                                                        }
                                                        <MenuItem value="-1">{'<New>'}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                >
                                                    <InputLabel>Shipping Method</InputLabel>
                                                    <Select
                                                        value={s.shippingMethod}
                                                        label="Shipping Method"
                                                        onChange={(e) => this.onShippingMethodChange(item.lineId, i, e.target.value)}
                                                    >
                                                        <MenuItem value="">
                                                            <em>Select</em>
                                                        </MenuItem>
                                                        {
                                                            shippingMethods.map((m) => <MenuItem key={m.code} value={m.code}>{m.code}</MenuItem>)
                                                        }
                                                    </Select>
                                                    {this.getEstimatedDeliveryDateContent(shippingMethods, s)}

                                                    {s.shippingMethod === 'Estimated Delivery Date' && (
                                                        <TextField
                                                            label="Estimated Delivery Date"
                                                            required
                                                            size="small"
                                                            variant="outlined"
                                                            style={{ marginTop: '.5rem' }}
                                                            fullWidth
                                                            name="ddd"
                                                            type="date"
                                                            value={s.shippingMethodData || ''}
                                                            onChange={(e) => this.onShippingMethodDataChange(item.lineId, i, e.target.value)}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                        />

                                                    )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center"
                                                >
                                                    {!(i === 0 && item.customFields.shippings.length === 1) && (
                                                        <IconButton
                                                            aria-label="RemoveIcon"
                                                            aria-describedby="remove"
                                                            variant="contained"
                                                            onClick={() => this.removeShipping(item.lineId, i)}
                                                        >
                                                            <RemoveIcon className="action-btn remove-icon" />
                                                        </IconButton>
                                                    )}
                                                    {(!(item.quantity == item.customFields.shippings.length) || item.customFields.isPlanDocument) && (
                                                        <IconButton
                                                            aria-label="AddIcon"
                                                            aria-describedby="add"
                                                            variant="contained"
                                                            onClick={() => this.addShipping(item.lineId)}
                                                        >
                                                            <AddIcon className="action-btn add-icon" />
                                                        </IconButton>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider />
                                    <Dialog
                                        open={confirmShippingMethod}
                                        onClose={this.closeConfirmShippingMethod}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">UPS Ground is Aetna’s preferred shipping method. You have selected expedited shipping at a significantly higher cost. Shipping selections are monitored and tracked for reporting purposes. Do you wish to continue?</DialogTitle>
                                        <DialogActions>
                                            <Button onClick={this.closeConfirmShippingMethod} color="primary">
                                                Cancel
                                            </Button>
                                            <Button onClick={this.saveConfirmShippingMethod} color="primary">
                                                OK
                                            </Button>
                                        </DialogActions>
                                    </Dialog>

                                </Grid>
                            </Grid>
                        );
                    })}
                </Box>
                {exceeded && (
                    <Grid container spacing={3}>
                        <Grid item xs={7}>
                            <Typography variant="h5">
                                Routing justification
                            </Typography>
                            <Typography variant="body1">
                                Thank you for your interest in Aetna Medicare Products. Your order exceeds the monthly allocation limit for this item. During the checkout process please explain your need for additional supplies and your local sales team will review the order. Thank you!
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>

                            <FormControl
                                variant="outlined"
                                size="small"
                            >
                                <InputLabel>Select Reason</InputLabel>

                                <Select
                                    required
                                    value={reason}
                                    style={{ width: '15rem' }}
                                    label="Select Reason"
                                    size="small"
                                    onChange={(e) => this.onReasonChange(e.target.value)}
                                >
                                    <MenuItem value="">
                                        <em>Select</em>
                                    </MenuItem>
                                    <MenuItem value="Community or large event">
                                        Community or large event
                                    </MenuItem>
                                    <MenuItem value="Replenish stock">
                                        Replenish stock
                                    </MenuItem>
                                    <MenuItem value="Initial stock not received">
                                        Initial stock not received
                                    </MenuItem>
                                    <MenuItem value="Other">
                                        Other...
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {reason === 'Other'
                            && (
                                <Grid item xs={7}>

                                    <TextField
                                        label="Please provide a justification to support the above reason(s)"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        required
                                        size="small"
                                        value={additionalReason}
                                        onChange={this.changeAdditionalReason}
                                        variant="outlined"
                                    />
                                </Grid>

                            )}
                    </Grid>
                )}
                {isOE && (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label="Special Instruction"
                                multiline
                                fullWidth
                                rows={4}
                                column={20}
                                value={shippingNotes}
                                onChange={(e) => this.saveNotes(e.target.value)}
                                variant="outlined"
                            />

                        </Grid>
                        <Grid item xs={6}>
                            {totalPrices && (<Box mb={1} style={{ textAlign: 'right' }}>Total Print Price: <b>${totalPrices || ''}</b> </Box>)}
                            <Box
                                width={2 / 5}
                                m={1}
                                style={{ float: 'right' }}
                                display={(costCenterRequired ? 'block' : 'none')}
                            >
                                <FormControl
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    required
                                >
                                    <InputLabel id="cost-center-select-outlined-label">Cost Center</InputLabel>
                                    <Select
                                        labelId="cost-center-select-outlined-label"
                                        id="cost-center-select-outlined"
                                        value={costCenter}
                                        name="costCenter"
                                        onChange={this.changeCostCenter}
                                        label="Cost Center"
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        {costCenters.map((s) => <MenuItem key={s.value} value={s.value}>{s.value}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box
                                m={1}
                                width={2 / 5}
                                style={{ float: 'right' }}
                                display={(costCenterRequired ? 'block' : 'none')}
                            >
                                <FormControl
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    required
                                >
                                    <InputLabel id="market-segment-select-outlined-label">Market Segment</InputLabel>
                                    <Select
                                        labelId="market-segment-select-outlined-label"
                                        id="market-segment-select-outlined"
                                        value={marketSegment}
                                        name="marketSegment"
                                        onChange={this.changeMarketSegment}
                                        label="Market Segment"
                                    >

                                        <MenuItem value="">Select</MenuItem>
                                        {marketSegmentOptions.map((s) => <MenuItem key={s} value={s}>{s}</MenuItem>)}
                                    </Select>
                                </FormControl>

                            </Box>

                        </Grid>

                    </Grid>

                )}
                {errorMessage && <span style={{ color: 'red', float: 'right', fontWeight: 'bold' }}>{errorMessage}</span>}
                {isOE && missingCostCenter && <span style={{ color: 'red', float: 'right', fontWeight: 'bold' }}>Can&apos;t place order without cost center.</span>}
                <ButtonGroup className="list-btn-group">
                    <Button
                        className="btn place-order-btn"
                        disabled={
                            (exceeded && !finalReason)
                            || (basketItems && basketItems.some((b) => !b.customFields.isPlanDocument && b.originalQty && b.originalQty != b.quantity))
                            || (isOE && missingCostCenter)
                        }
                        variant="contained"
                        color="primary"
                        onClick={this.placeOrder}
                    >
                        Place Order
                    </Button>
                </ButtonGroup>

                {openAddress && (
                    <AddressModal
                        open={openAddress}
                        address={address}
                        closeAddress={this.closeAddressModal}
                        saveAddress={this.saveAddress}
                        showMessage={showMessage}
                        allowSpouse={allowSpouse}
                    />
                )}
            </Container>
        );
    }
}
