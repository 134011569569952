import {
    Backdrop, Badge,

    Button, CircularProgress, Link,

    Paper,

    TableFooter, TextField
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import RootRef from '@material-ui/core/RootRef';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ToolTip from '@material-ui/core/ToolTip';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import Alert from '@material-ui/lab/Alert';
import ProofingModal from 'components/ProofingModal';
import QuickviewModal from 'pages/catalog/QuickviewModal';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import http from 'utils/http';
import { getItemTypes, getKitItems, getP1BItems, getWHS, setKitItems, updateItems } from '../action';
import EditFormModal from './edit-form-modal';
import { loadFormSchema } from './edit-form-modal/action';
import KitModal from './KitModal';
import OptionalItemPanel from './SelectItem/OptionalItemPanel';
import SelectItemFileModal from './SelectItem/SelectItemFileModal';
import FindItemModal from './SelectItem/SelectItemModal';

const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,

    ...(isDragging && {
        background: 'rgb(235,235,235)'
    })
});

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    }
}));

const DenseCell = withStyles((theme) => ({
    body: {
        padding: '.2rem'
    }
}))(TableCell);

const SelectItemStep = (props) => {
    const [openCI, setOpenCI] = useState(false);
    const classes = useStyles();
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const [openProofPreview, setOpenProofPreview] = useState(false);
    const [proofingItemId, setProofingItemId] = useState(null);
    const [previewPdf, setPreviewPdf] = useState(null);
    const [ciItemId, setCIItemId] = useState(null);
    const [line1, setLine1] = useState('');
    const [line2, setLine2] = useState('');

    const [orderingOnHoldItem, setOrderingOnHoldItem] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openFileModal, setOpenFileModal] = useState(false);
    const [proofingItem, setProofingItem] = useState(null);
    const [openFormModal, setOpenFormModal] = useState(false);
    const [init, setInit] = useState(false);
    const {
        jobItems, attachments, loadFormSchema, lob: lobValue, jobType: jobTypeValue, screenData, attachmentSettings,
        user, getP1BItems, disableChild, state, disableAttachment, settings, spmLoaded, onlyState
    } = props;
    const { jobType } = useParams();
    let { lob } = useParams();
    if (!lob) lob = lobValue || 'OE';
    const needQty = jobType !== 'AJEKT' && jobType !== 'IM';
    const allowAttachment = lob !== 'p1a' && lob !== 'p1b' && !disableAttachment;
    const supportChild = !disableChild && user && !(user.customFields.userType === 'fsr' && lob === 'p1a') && lob !== 'p1b';
    const supportRequiredCode = user && user.customFields.userType !== 'p1a_fsr';

    const kitMustApprove = lob === 'p1b' || lob === 'p1a';

    const attachmentLimit = screenData && screenData.marketSegment && attachmentSettings
        ? attachmentSettings[screenData.marketSegment.toLowerCase()] || 1
        : 1;

    const removeItem = (sequence) => {
        jobItems[sequence].quantity = '';
        for (let i = sequence; i < jobItems.length; i += 1) {
            jobItems[i].sequence -= 1;
        }

        if (jobItems[sequence].requiredCode) {
            jobItems[sequence].selected = false;
        } else {
            jobItems.splice(sequence, 1);
        }

        props.setKitItems([...jobItems]);
    };

    const startPreviewDocument = (id, code, paperSize, ci) => {
        if (lob === 'cml' || code.length >= 4) {
            startEditFormModal(id, code, paperSize);
        } else {
            composePodBook(id, ci);
        }
    };

    const startEditFormModal = (id, code, paperSize) => {
        loadFormSchema(code);
        setProofingItem({ id, code, paperSize });
        setOpenFormModal(true);
    };
    const composePodBook = (id, ci) => {
        setGeneratingPdf(true);
        setProofingItemId(id);

        http.post(`direct-order/preview-p1a-kit/${id}`, ci || { line1: '', line2: '' })
            .then((dl) => {
                setPreviewPdf(dl);
                setOpenProofPreview(true);
            })
            .finally(() => setGeneratingPdf(false));
    };
    const stopGenerating = () => {
        setGeneratingPdf(false);
        setProofingItemId(null);
        setOpenProofPreview(false);
    };
    const approvePdf = () => {
        const approvedItem = jobItems.find((ji) => ji.id === proofingItemId);
        approvedItem.approved = true;
        if (previewPdf.ids) {
            approvedItem.proofFileIds = previewPdf.ids;
        } else {
            approvedItem.proofFileId = previewPdf.id;
        }
        approvedItem.preview = previewPdf.preview;
        props.setKitItems([...jobItems]);
        setOpenFormModal(false);

        setProofingItemId(null);
        setOpenProofPreview(false);
    };
    const closeProof = () => {
        setPreviewPdf(null);
        setOpenProofPreview(false);
        setProofingItemId(null);
    };

    const onFormApproved = (formData) => {
        const approvedItem = jobItems.find((ji) => ji.id === proofingItem.id);
        approvedItem.approved = true;
        approvedItem.formData = formData;
        approvedItem.proofFileId = formData.proofFileId;
        props.setKitItems([...jobItems]);
        setOpenFormModal(false);
    };

    const handleQtyChange = (seq, qty, max) => {
        if (qty === '') {
            jobItems[seq].quantity = '';
            props.setKitItems([...jobItems]);
            return;
        }
        // eslint-disable-next-line no-restricted-properties
        if (window.isNaN(qty)) return;
        let qtyInput = parseInt(qty, 10);
        if (qtyInput < 1) return;
        let limit = 10;

        if (jobType === 'AJEPP' && (lob === 'cml' || lob === 'p1b')) {
            limit = Number.MAX_VALUE;
        }

        if (max) limit = max;

        const isSU = user && user.customFields && user.customFields.userType === 'su';
        if (isSU) {
            limit = Number.MAX_VALUE;
        }

        if (jobType === 'FJE' && qtyInput > limit) {
            qtyInput = limit;
        }

        if (limit && qtyInput > limit) {
            qtyInput = limit;
        }

        jobItems[seq].quantity = qtyInput;
        props.setKitItems([...jobItems]);
    };

    const startCI = (itemId) => {
        const c = jobItems.find((ji) => ji.id === itemId);
        if (c && c.ci) {
            setLine1(c.ci.line1);
            setLine2(c.ci.line2);
        }
        setCIItemId(itemId);
        setOpenCI(true);
    };
    const onCIClose = () => {
        setCIItemId(null);
        setLine1('');
        setLine2('');
        setOpenCI(false);
    };
    const saveCI = (applyToAll) => {
        if (applyToAll) {
            for (const item of jobItems) {
                if (item.isKit) {
                    item.ci = { line1, line2 };
                    item.approved = false;
                }
            }
        } else {
            const item = jobItems.find((ji) => ji.id === ciItemId);
            item.ci = { line1, line2 };
            item.approved = false;
        }
        props.setKitItems([...jobItems]);
        onCIClose();
    };

    let yItems = [];
    let nItems = [];
    if (jobItems) {
        yItems = jobItems.filter((j) => j.selected);
        nItems = jobItems.filter((j) => !j.selected);
    }
    const missingEN = (lob === 'cml' && (jobType === 'AJEKT' || jobType === 'IM') && yItems && yItems.length > 0 && yItems.every((y) => y.type !== 'EN'));
    useEffect(() => {
        if (!init) {
            if (lob === 'cml' && (jobType === 'AJEKT' || jobType === 'IM') && !spmLoaded) {
                props.getKitItems();
            } else {
                // props.setKitItems([]);
            }
            if (lob) {
                const kitOnly = lob === 'p1b' && (jobType === 'AJEKT' || jobType === 'IM');
                props.getItemTypes(lob, kitOnly);
                props.getWHS(lob);
            }
            setInit(true);
        }

        const jis = jobItems || [];
        const selected = lob === 'p1b' ? jis : jis.filter((ji) => ji.selected);
        const isValid = selected.length > 0
            && selected.every((ji) => (!needQty || (ji.quantity && ji.quantity > 0)) && ((!ji.vp || ji.vp === 'N' || ji.vp === 'PD') || !!ji.formData))
            && (!attachments || (attachments.length === 0 || attachments.every((a) => a.approved)))
            && (!kitMustApprove || selected.every((s) => !s.isKit || s.approved))
            && (lob !== 'cml' || selected.every((s) => !settings || !settings.sponsorItemTypes.includes(s.type) || s.approved))
            && !missingEN
            && (selected.every((item) => !(item.dp === 'N' && ((!item.inventoryQuantity || item.inventoryQuantity < 1) || item.quantity > item.inventoryQuantity) && item.disallowBackOrder)));
        props.setIsNextReady(isValid);
    }, [attachments, init, jobItems, jobType, kitMustApprove, lob, needQty, props, settings, user, missingEN, spmLoaded]);

    const onDragEnd = (result) => {
        if (result.destination && result.destination.index !== result.source.index) {
            jobItems[result.source.index].sequence = result.destination.index;
            if (result.destination.index < result.source.index) {
                for (let i = result.destination.index; i < result.source.index; i += 1) {
                    jobItems[i].sequence += 1;
                    if (!jobItems[result.source.index].selected && jobItems[i].selected) {
                        jobItems[result.source.index].selected = true;
                    }
                }
            } else {
                for (let i = result.source.index + 1; i <= result.destination.index; i += 1) {
                    jobItems[i].sequence -= 1;
                }
                if (jobItems[result.source.index].selected) {
                    let allExcluded = true;
                    for (let i = result.destination.index; i < jobItems.length; i += 1) {
                        if (jobItems[i].selected) {
                            allExcluded = false;
                            break;
                        }
                    }
                    if (allExcluded) {
                        jobItems[result.source.index].selected = false;
                    }
                }
            }
            jobItems.sort((a, b) => a.sequence - b.sequence);
            props.setKitItems([...jobItems]);
        }
    };

    const addNItem = (id) => {
        jobItems.find((ji) => ji.id === id).selected = true;
        const selected = [];
        const n = [];
        for (let i = 0; i < jobItems.length; i += 1) {
            const ji = jobItems[i];
            if (ji.selected) {
                ji.sequence = selected.length;
                selected.push(ji);
            }
        }
        for (let i = 0; i < jobItems.length; i += 1) {
            const ji = jobItems[i];
            if (!ji.selected) {
                ji.sequence = selected.length + n.length;
                n.push(ji);
            }
        }
        props.setKitItems([...selected, ...n]);
    };

    const [showKitModal, setShowKitModal] = useState(false);
    const [showKitModalItem, setShowKitModalItem] = useState(null);
    const openKitModal = (id) => {
        const ji = jobItems.find((j) => j.id === id);
        setShowKitModalItem(ji);
        if (!ji.items) {
            getP1BItems(id);
        }
        setShowKitModal(true);
    };
    const closeKitModal = () => {
        setShowKitModal(false);
        setShowKitModalItem(null);
    };
    const supportKitModal = lob === 'p1b';

    const openItemPreview = (id) => {
        setQuickViewPdf(`${props.settings.quickviewRoot}/${lob}/${id}`);
        setOpenQuickView(true);
    };
    const [openQuickView, setOpenQuickView] = useState(false);
    const [quickViewPdf, setQuickViewPdf] = useState(null);

    const allowToOrderSpecial = (user && user.customFields.isAdmin === true); // testing .

    const reviewSponsorItem = (item) => {
        setProofingItemId(item.id);
        setPreviewPdf({ preview: `${props.settings.quickviewRoot}/${lob}/${item.id}` });
        setOpenProofPreview(true);
    };

    const buildName = (item) => {
        if (item.isKit || !item.hasResource) {
            return <span>{item.name}</span>;
        }

        if (lob === 'cml' && settings.sponsorItemTypes.includes(item.type) && !item.approved) {
            return (
                <ToolTip title="Preview Item">
                    <Button
                        size="small"
                        style={{ color: (item.approved) ? 'green' : 'red' }}
                        onClick={() => reviewSponsorItem(item)}
                    >
                        <span>{item.name}</span>
                    </Button>
                </ToolTip>
            );
        }

        return (
            <ToolTip title="Preview Item">
                <Button size="small" color="secondary" onClick={() => openItemPreview(item.id)}>
                    <span>{item.name}</span>
                </Button>
            </ToolTip>
        );
    };

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(dropProvided) => (
                        <RootRef rootRef={dropProvided.innerRef}>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label=" table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ padding: '.5rem' }}>Item Code</TableCell>
                                            <TableCell style={{ padding: '.5rem' }}>Version</TableCell>
                                            <TableCell style={{ padding: '.5rem' }}>WHS</TableCell>
                                            <TableCell style={{ padding: '.5rem' }}>INV</TableCell>
                                            <TableCell style={{ padding: '.5rem' }}>Name</TableCell>
                                            <TableCell style={{ padding: '.5rem', textAlign: 'center' }}>CI</TableCell>
                                            <TableCell style={{ padding: '.5rem' }}>VP</TableCell>
                                            <TableCell style={{ padding: '.5rem' }}>DP</TableCell>
                                            <TableCell style={{ padding: '.5rem' }}>TYPE</TableCell>
                                            {supportChild && <TableCell style={{ padding: '.5rem' }}>Child</TableCell>}
                                            {(jobType === 'AJEKT' || (jobType === 'IM' && supportRequiredCode))
                                                && lob !== 'p1b'
                                                && (<TableCell style={{ padding: '.5rem' }}>Default Item</TableCell>)}
                                            <TableCell style={{ padding: '.5rem' }}>
                                                <IconButton
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => {
                                                        setOrderingOnHoldItem(false);
                                                        setOpenModal(true);
                                                    }}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                                {allowAttachment && (
                                                    <IconButton
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => setOpenFileModal(true)}
                                                    >
                                                        {attachments && attachments.length > 0 && (
                                                            <Badge badgeContent={`${attachments.filter((a) => a.approved).length}/${attachments.length}`} color="primary">
                                                                <AttachFileIcon />
                                                            </Badge>
                                                        )}
                                                        {attachments && attachments.length === 0 && (
                                                            <AttachFileIcon />
                                                        )}
                                                    </IconButton>
                                                )}
                                                {allowToOrderSpecial && (
                                                    <ToolTip title="Select HOLD Item">
                                                        <IconButton
                                                            size="small"
                                                            color="primary"
                                                            onClick={() => {
                                                                setOrderingOnHoldItem(true);
                                                                setOpenModal(true);
                                                            }}
                                                        >
                                                            <AddCircleOutlineIcon />
                                                        </IconButton>
                                                    </ToolTip>
                                                )}

                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {yItems.map((item, index) => (

                                            <Draggable key={item.id} draggableId={item.id + item.code} index={index}>
                                                {(dragProvided, snapshot) => (
                                                    <>
                                                        <TableRow
                                                            key={item.id}
                                                            ref={dragProvided.innerRef}
                                                            {...dragProvided.draggableProps}
                                                            {...dragProvided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                dragProvided.draggableProps.style
                                                            )}
                                                        >
                                                            <TableCell style={{ padding: '.5rem' }}>
                                                                {supportKitModal && item.isKit
                                                                    ? (
                                                                        <Link
                                                                            component="button"
                                                                            variant="body2"
                                                                            style={{ color: (kitMustApprove && !item.approved) ? 'red' : 'green' }}
                                                                            onClick={() => openKitModal(item.id)}
                                                                        >
                                                                            {item.code}
                                                                        </Link>
                                                                    )
                                                                    : <span>{item.code}</span>}
                                                            </TableCell>
                                                            <TableCell style={{ padding: '.5rem' }}>{item.version}</TableCell>
                                                            <TableCell style={{ padding: '.5rem' }}>{item.whs}</TableCell>
                                                            <DenseCell
                                                                style={{ color: (item.dp === 'N' && ((!item.inventoryQuantity || item.inventoryQuantity < 1) || item.quantity > item.inventoryQuantity) && item.disallowBackOrder ? 'red' : '') }}
                                                            >{item.dp === 'Y' || ((lob === 'p1a' || lob === 'p1b') && item.isKit) ? 'N/A' : (item.inventoryQuantity !== undefined ? Math.max(0, item.inventoryQuantity) : '')}
                                                            </DenseCell>
                                                            <TableCell style={{ padding: '.5rem' }}>
                                                                {buildName(item)}
                                                            </TableCell>
                                                            <TableCell style={{ padding: '.5rem', textAlign: 'center' }}>
                                                                {item.isKit && (
                                                                    <Button size="small" color="secondary" onClick={() => startCI(item.id)}>
                                                                        <span style={{ color: (item.ci ? 'blue' : 'brown') }}><b>CI</b></span>
                                                                    </Button>

                                                                )}
                                                            </TableCell>
                                                            <TableCell style={{ padding: '.5rem' }}>
                                                                {item.vp === 'N'
                                                                    ? 'N'
                                                                    : (
                                                                        <Link
                                                                            component="button"
                                                                            variant="body2"
                                                                            style={{ color: (item.approved ? 'green' : 'red') }}
                                                                            onClick={() => {
                                                                                startPreviewDocument(item.id, item.vp, item.paperSize, item.ci);
                                                                            }}
                                                                        >
                                                                            {item.vp}
                                                                        </Link>
                                                                    )}

                                                            </TableCell>
                                                            <TableCell style={{ padding: '.5rem' }}> {item.dp}</TableCell>
                                                            <TableCell style={{ padding: '.5rem' }}>{item.type}</TableCell>

                                                            {supportChild && <TableCell style={{ padding: '.5rem' }}>{item.children && item.children.length > 0 ? '' : 'Y'}</TableCell>}

                                                            {(jobType === 'AJEKT' || (jobType === 'IM' && supportRequiredCode))
                                                                && lob !== 'p1b'
                                                                && <TableCell style={{ padding: '.5rem' }}>{item.requiredCode}</TableCell>}
                                                            <TableCell style={{ padding: '.5rem' }}>
                                                                {needQty
                                                                    && (
                                                                        <TextField
                                                                            style={{ width: '2rem', textAlign: 'center' }}
                                                                            size="small"
                                                                            display="inline"
                                                                            error={item.dp === 'N' && ((!item.inventoryQuantity || item.inventoryQuantity < 1) || item.quantity > item.inventoryQuantity) && item.disallowBackOrder}
                                                                            type="text"
                                                                            value={item.quantity}
                                                                            onChange={(e) => handleQtyChange(item.sequence, e.target.value, item.qtyLimit || item.max)}
                                                                        />
                                                                    )}
                                                                {item.requiredCode !== 'R'
                                                                    && (
                                                                        <IconButton
                                                                            size="small"
                                                                            aria-label="delete"
                                                                            display="inline"
                                                                            m={1}
                                                                            onClick={() => removeItem(item.sequence || index)}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    )}

                                                                <IconButton
                                                                    size="small"
                                                                    aria-label="delete"
                                                                    display="inline"
                                                                    style={{ cursor: 'grab' }}
                                                                    m={1}
                                                                >
                                                                    <DragHandleIcon />
                                                                </IconButton>
                                                            </TableCell>

                                                        </TableRow>

                                                        {item.children && item.children.length > 0 && item.children.map((ic) => (
                                                            <TableRow
                                                                key={ic.id}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    dragProvided.draggableProps.style
                                                                )}
                                                            >
                                                                <TableCell style={{ padding: '.5rem' }}>{ic.code}</TableCell>
                                                                <TableCell style={{ padding: '.5rem' }}>{ic.version}</TableCell>
                                                                <TableCell style={{ padding: '.5rem' }}>{ic.whs}</TableCell>
                                                                <DenseCell>{ic.dp === 'Y' || ((lob === 'p1a' || lob === 'p1b') && item.isKit) ? 'N/A' : (ic.inventoryQuantity ? Math.max(0, ic.inventoryQuantity) : '')}</DenseCell>
                                                                <TableCell style={{ padding: '.5rem' }}>{ic.name}</TableCell>
                                                                <TableCell style={{ padding: '.5rem' }} />
                                                                <TableCell style={{ padding: '.5rem' }}>{ic.vp}</TableCell>
                                                                <TableCell style={{ padding: '.5rem' }}> {ic.dp}</TableCell>
                                                                <TableCell style={{ padding: '.5rem' }}>{ic.type}</TableCell>

                                                                {supportChild && <TableCell style={{ padding: '.5rem' }}>Y</TableCell>}
                                                                {(jobType === 'AJEKT' || (jobType === 'IM' && supportRequiredCode))
                                                                    && lob !== 'p1b'
                                                                    && <TableCell style={{ padding: '.5rem' }}>{item.requiredCode}</TableCell>}
                                                                <TableCell style={{ padding: '.5rem' }} />

                                                            </TableRow>

                                                        ))}
                                                    </>
                                                )}
                                            </Draggable>

                                        ))}
                                        {dropProvided.placeholder}
                                    </TableBody>
                                    {missingEN
                                        && (
                                            <TableFooter>
                                                <TableRow>
                                                    <TableCell colSpan={20}>
                                                        <Alert severity="error">Envelope (EN) is required.</Alert>
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        )}
                                </Table>
                            </TableContainer>
                        </RootRef>
                    )}
                </Droppable>
            </DragDropContext>
            {
                nItems.length > 0 && <OptionalItemPanel jobType={jobType} lob={lob} nItems={nItems} addNItem={addNItem} />
            }
            <FindItemModal
                open={openModal}
                needQty={needQty}
                onlyWhs={props.onlyWhs}
                onlyState={props.onlyState}
                lob={lobValue}
                jobType={jobTypeValue}
                p1afsrLimit={user.customFields.p1afsrLimit}
                userType={user.customFields.userType}
                msoFJEJobItemLimit={user.customFields.msoFJEJobItemLimit}
                orderingOnHoldItem={orderingOnHoldItem}
                state={state}
                handleClose={() => { setOrderingOnHoldItem(false); setOpenModal(false); }}
            />
            <SelectItemFileModal
                open={openFileModal}
                disableItemType={props.disableItemType || lob === 'cml'}
                handleClose={() => setOpenFileModal(false)}
                lob={lobValue}
                jobType={jobTypeValue}
                user={user}
                attachmentLimit={props.shellLimit || attachmentLimit}
            />
            <EditFormModal
                open={openFormModal}
                proofingItem={proofingItem}
                onApproved={onFormApproved}
                handleClose={() => setOpenFormModal(false)}
            />
            <KitModal
                open={showKitModal}
                kit={showKitModalItem}
                handleClose={closeKitModal}
            />
            <Dialog open={openCI} onClose={onCIClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Contact Information</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Line 1"
                        type="text"
                        fullWidth

                        value={line1}
                        onChange={(e) => setLine1(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Line 2"
                        type="text"
                        fullWidth

                        value={line2}
                        onChange={(e) => setLine2(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onCIClose()} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => saveCI()} color="primary">
                        Update
                    </Button>
                    <Button onClick={() => saveCI(true)} color="primary">
                        Copy All
                    </Button>
                </DialogActions>
            </Dialog>

            <Backdrop className={classes.backdrop} open={generatingPdf} onClick={stopGenerating}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <ProofingModal
                open={openProofPreview}
                pdf={previewPdf && previewPdf.preview}
                onApprove={approvePdf}
                onCancel={closeProof}
            />

            {openQuickView && (
                <QuickviewModal
                    open={openQuickView}
                    closeQuickview={() => { setOpenQuickView(false); setQuickViewPdf(null); }}
                    quickviewPDF={quickViewPdf}
                />
            )}
        </>
    );
};

const mapStateToProps = ({
    directOrderView: {
        stepView: {
            jobItems, attachments, attachmentSettings, screenData, addresses,
            spmLoaded
        }
    },
    appView: {
        user, settings
    }
}) => ({
    jobItems, attachments, attachmentSettings, screenData, user, settings, state: (addresses && addresses.length > 0 ? addresses[0].state : ''), spmLoaded
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getKitItems, updateItems, setKitItems, loadFormSchema, getItemTypes, getP1BItems, getWHS
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectItemStep);
