import {
 Box, Checkbox, Grid, Paper, Typography
} from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
 getFavorites, getFilters, getLimits, getProductMeta, getRelatedProducts, loadProducts, saveSelection, setFavoritesOnly, setFilters, setSelectionOpen
} from './action';
import BrokerSelect from './EditBrokerSelect';
import FilterBar from './FilterBar';
import MessageBoard from './MessageBoard';
import TableView from './ProductTableView';
import ProductView from './ProductView';
import SelectionBar from './SelectionBar';

class BrokerView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            product: null
        };
    }

    componentDidMount() {
        const {
            setSelectionOpen, selection, filters, getFilters, favorites, getFavorites, limits, getLimits
        } = this.props;
        if (!selection) {
            setSelectionOpen(true);
        }
        if (!filters) {
            getFilters();
        }
        if (!favorites) {
            getFavorites();
        }
        if (!limits) {
            getLimits();
        }
    }

    onSelectBarChange = () => {
        const {
            setSelectionOpen: openSelection
        } = this.props;
        openSelection(true);
    }

    onToggleFilter = (filterCode) => {
        const { setFilters: saveFilters, filters, loadProducts } = this.props;
        const changed = filters.find((f) => f.code === filterCode);
        changed.selected = !changed.selected;
        saveFilters([...filters]);
        loadProducts();
    }

    onSelectionChanged = (selection) => {
        const { saveSelection: save, loadProducts } = this.props;
        save(selection);
        loadProducts();
    }

    openProduct = (product) => {
        this.setState({ product });
        const { getProductMeta, getRelatedProducts } = this.props;
        getProductMeta(product.customFields.materialType, product.code);
        getRelatedProducts(product.id, product.itemType);
    }

    closeProduct = () => {
        this.setState({ product: null });
    }

    toggleFavoritesOnly = () => {
        const { setFavoritesOnly: toggle, favoritesOnly, loadProducts } = this.props;
        toggle(!favoritesOnly);
        loadProducts();
    }

    render() {
        const { product } = this.state;
        const {
            editSelection, broker, selection, filters, settings, favoritesOnly
        } = this.props;
        if (product) {
            return <ProductView product={product} goBack={this.closeProduct} token={broker.token} />;
        }

        let brokerMessage;
        if (settings) {
            if (settings.messageBoard && settings.messageBoard.length) {
                [brokerMessage] = settings.messageBoard;
            } else if (settings.useDefaultMsg) {
                brokerMessage = 'Welcome to our new Broker Ordering Module (BOM)! Our new ordering portal is designed to be easier and more efficient to use. You can learn the new features of our ordering portal by downloading our User Guide from the Need Help page by clicking the question mark icon.';
            }
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Box m={2} component={Paper}>
                        {!editSelection && filters && <FilterBar filters={filters} onToggle={this.onToggleFilter} />}
                    </Box>
                    {!editSelection && (
                        <Box m={2} p={2} component={Paper}>
                            <Box>
                                <Checkbox
                                    edge="start"
                                    tabIndex={-1}
                                    checked={favoritesOnly}
                                    color="primary"
                                    onChange={this.toggleFavoritesOnly}
                                />
                                Favorites
                            </Box>
                        </Box>
                    )}
                    <Box m={2} p={2} component={Paper}>
                        {brokerMessage && <MessageBoard content={brokerMessage} />}
                    </Box>
                    <Box m={2} p={2} component={Paper}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            <BuildIcon /> Don&apos;t Forget!
                        </Typography>
                        <Typography variant="body1">
                            Click on the Need Help icon to download our new User Guide.
                        </Typography>
                    </Box>
                    <Box m={2} p={2} component={Paper}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            <ShoppingBasketIcon /> Order Reminders
                        </Typography>
                        <Typography variant="body1">
                            Here are your saved or pending orders awaiting completion:
                        </Typography>
                        <Box align="center">
                            {broker && broker.customFields && (
                                <Link
                                    to="/saved-order"
                                    underline="none"
                                    color="primary"
                                >
                                    User and System Saved ({broker.customFields.savedOrderCount})
                                </Link>
                            )}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            {broker && editSelection && (
                                <BrokerSelect
                                    meta={broker.customFields.brokerMeta}
                                    saveBrokerSelection={this.onSelectionChanged}
                                />
                            )}
                            {broker && !editSelection && selection && (
                                <SelectionBar
                                    selection={selection}
                                    onChangeClick={this.onSelectBarChange}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            {!editSelection && selection && <TableView openProduct={this.openProduct} />}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = ({
    brokerView: {
        editSelection, selection, filters, favorites, favoritesOnly, limits
    },
    appView: { user: broker, settings }
}) => ({
    editSelection, selection, broker, filters, favorites, favoritesOnly, limits, settings
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setSelectionOpen,
            saveSelection,
            getFilters,
            setFilters,
            loadProducts,
            getProductMeta,
            getFavorites,
            setFavoritesOnly,
            getLimits,
            getRelatedProducts
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(BrokerView);
