import actionCreator from 'utils/actionCreator';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('EDIT_FORM_VIEW', {
    GENERATING_FORM_PREVIEW: null,
    SET_FORM_PROOF_PREVIEW_OPEN: null,
    SET_FORM_SCHEMA: null,
    SET_FORM_PROOF_PREVIEW: null
});

export default actions;

const setFormSchema = actionCreator(actions.SET_FORM_SCHEMA, 'schema');
export const loadFormSchema = (code) => (dispatch) => {
    return http.get(`store/form-schema/${code}`).then((r) => {
        const schema = typeof (r) === 'string' ? JSON.parse(r) : r;
        dispatch(setFormSchema(schema));
    });
};

export const setGeneratingForm = actionCreator(actions.GENERATING_FORM_PREVIEW, 'generating');

const setFormProofPreview = actionCreator(actions.SET_FORM_PROOF_PREVIEW, 'preview');
export const setFormProofPreviewOpen = actionCreator(actions.SET_FORM_PROOF_PREVIEW_OPEN, 'open');
export const generateFormProof = ({ lob, id, code }, formData) => (dispatch) => {
    return http.post(`store/generate-form-proof/${(lob || 'cml')}/${id}/${code}`, formData).then((preview) => {
        dispatch(setFormProofPreview(preview));
        dispatch(setFormProofPreviewOpen(true));
    }).finally(() => dispatch(setGeneratingForm(false)));
};
