import brokerActions from './action';

export default (
    state = {
        loading: false,

        favoritesOnly: false,

        error: null,
        editSelection: false,
        selection: null,
        filters: [{
            code: 'kit',
            name: 'Kit',
            selected: true
        }, {
            code: 'plan_guide',
            name: 'Plan Guide',
            selected: true
        }, {
            code: 'formularies',
            name: 'Formularies',
            selected: true
        }
        ],

        listQuery: {
            pageNumber: 1,
            pageSize: 24,
            sortedBy: 'Description'
        },
        listResult: null,
        productMeta: null,
        relatedProducts: null,

        favorites: null,
        limits: null,

        searchToken: null
    },
    action
) => {
    switch (action.type) {
        case brokerActions.LOADING:
            return { ...state, loading: action.loading };
        case brokerActions.SEARCH_TOKEN:
            return { ...state, searchToken: action.searchToken };
        case brokerActions.SET_SELECTION:
            return {
                ...state,
                selection: action.selection,
                editSelection: false,
                listQuery: { ...state.listQuery, pageNumber: 1 }
            };
        case brokerActions.SET_EDIT_SELECTION:
            return { ...state, editSelection: action.open };
        case brokerActions.SET_LIST_RESULT:
            return { ...state, listResult: action.result };
        case brokerActions.SET_PRODUCT_META:
            return { ...state, productMeta: action.meta };
        case brokerActions.SET_FAVORITES_ONLY:
            return { ...state, favoritesOnly: action.favoritesOnly };
        case brokerActions.SET_FAVORITES:
            return { ...state, favorites: action.favorites };
        case brokerActions.SET_RELATED_PRODUCTS:
            return { ...state, relatedProducts: action.products };
        case brokerActions.SET_LIMITS:
            return { ...state, limits: action.limits };
        case brokerActions.SET_FILTERS:
            return {
                ...state,
                filters: action.filters.map((f) => ({
                    code: f.code,
                    name: f.name,
                    selected: typeof f.selected === 'undefined' ? true : f.selected,
                    listQuery: { ...state.listQuery, pageNumber: 1 }
                }))
            };
        default:
            return state;
    }
};
