import jobActions from './action';

const initialState = {
    loading: false,
    uploading: false,
    isNextDomStepReady: false,
    validates: {
        shippingAddress: false,
        selectItems: true,
        reviewSubmit: true,
        selectCustomer: true,
        selectScreen: false,
        jobInformation: false
    },

    addressFileIds: [],
    totalAddressCount: 0,
    addresses: [],
    addressInfo: {
        first1: '',
        middle1: '',
        last1: '',
        first2: '',
        middle2: '',
        last2: '',
        companyName: '',
        address: '',
        address2: '',
        address3: '',
        zip: '',
        city: '',
        state: '',
        country: 'United States',
        phoneNumber: '',
        ext: '',
        qty: 1
    },

    // DOM: customer input
    customers: null,
    customer: null,

    // DOM: cost center input
    screenData: {
        marketSegment: '',
        costCenter: '',
        language: '',
        product: '',
        state: '',
        model: ''
    },

    products: null,
    costCenters: null,
    stateModels: null,

    // DOM: select item
    jobItems: null,

    jobInfo: {
        firstName: '',
        middleName: '',
        lastName: '',
        phone: '',
        ext: '',
        email: '',
        dateReq: '',
        costCenter: '',
        officeLocation: '',
        jobReason: '',
        quantity: 1,
        email1: '',
        email2: ''
    },

    deliveryDate: null,
    attachments: [],
    attachmentSettings: null,
    attachmentError: null,
    shippingNotes: '',
    itemTypes: null,
    whs: null,

    savedId: null,
    savedName: null,

    submitting: false,
    spmLoaded: false,
    shippingMethod: null,

    marketSegmentXCostCenters: null,
    imInfo: null
};

export default (state = initialState, action) => {
    let addrs;
    let dates;
    let earliest;
    switch (action.type) {
        case jobActions.SET_MARKET_SEGMENT_COST_CENTERS:
            return { ...state, marketSegmentXCostCenters: action.marketSegmentXCostCenters };
        case jobActions.SET_SUBMITTING:
            return { ...state, submitting: action.submitting };
        case jobActions.SET_IM_INFO:
            return { ...state, imInfo: action.imInfo };
        case jobActions.SET_SPMLOADED:
            return { ...state, spmLoaded: action.spmLoaded };
        case jobActions.SET_SAVED_ID:
            return { ...state, savedId: action.savedId };
        case jobActions.SET_SAVED_NAME:
            return { ...state, savedName: action.savedName };
        case jobActions.SET_NEXT_DOM_STEP_READY:
            return { ...state, isNextDomStepReady: action.isReady };
        case jobActions.SET_SHIPPING_NOTES:
            return { ...state, shippingNotes: action.shippingNotes };

        case jobActions.SET_ITEM_TYPES:
            return { ...state, itemTypes: action.itemTypes };
        case jobActions.SET_WHS:
            return { ...state, whs: action.whs };
        case jobActions.SET_UPLOADING:
            return { ...state, uploading: action.uploading };

        case jobActions.SET_SHIPPING_METHOD:
            return { ...state, shippingMethod: action.shippingMethod };

        case jobActions.SET_CUSTOMERS:
            return { ...state, customers: action.customers };
        case jobActions.SET_DELIVERY_DATE:
            addrs = state.addresses;
            dates = action.deliveryDate.map((d) => new Date(d));
            earliest = new Date(Math.min.apply(null, dates));
            for (let i = 0; i < action.deliveryDate.length; i += 1) {
                addrs[i].deliveryDate = new Date(action.deliveryDate[i]);
            }

            return {
                ...state, addresses: [...addrs], deliveryDate: action.deliveryDate, jobInfo: { ...state.jobInfo, dateReq: earliest.toLocaleDateString() }
            };
        case jobActions.SELECT_CUSTOMER:
            return { ...state, customer: action.customer, isNextDomStepReady: true };
        case jobActions.SET_ADDRESS_FIELD:
            return { ...state, addressInfo: { ...state.addressInfo, [action.name]: action.value } };
        case jobActions.SET_SHIPPING_ADDRESS:
            return {
                ...state,
                addressInfo: { ...initialState.addressInfo },
                addresses: action.singleOnly
                    ? [action.selectedAddress] : [...state.addresses, action.selectedAddress]
            };

        case jobActions.SET_SCREEN_FIELD:
            return {
                ...state,
                screenData: { ...state.screenData, [action.name]: action.value },
                jobInfo: {
                    ...state.jobInfo,
                    costCenter: action.name === 'costCenter'
                        ? action.value
                        : state.jobInfo.costCenter
                }
            };
        case jobActions.SET_PRODUCTS:
            return { ...state, products: action.products };
        case jobActions.SET_ATTACHMENT_SETTINGS:
            return { ...state, attachmentSettings: action.attachmentSettings };
        case jobActions.SET_COST_CENTERS:
            return { ...state, costCenters: action.costCenters };
        case jobActions.SET_STATE_MODELS:
            return { ...state, stateModels: action.stateModels };

        case jobActions.SET_KIT_ITEMS:
            return {
                ...state,
                jobItems: action.items
            };

        case jobActions.UPDATE_JOB_INFO:
            return { ...state, jobInfo: action.jobInfo };

        case jobActions.RESET_INPUT:
            return { ...initialState, ...(action.jobData || {}) };

        case jobActions.SET_VALIDATE:
            return { ...state, validates: { ...state.validates, [action.name]: action.value } };

        case jobActions.ADD_UPLOAD_ADDRESSES:
            return {
                ...state,
                addresses: [...state.addresses, ...action.addressData.addresses],
                totalAddressCount: state.addresses.length + action.addressData.total,
                addressFileIds: [...state.addressFileIds, action.addressData.fileId].filter((i) => !!i)
            };
        case jobActions.UPDATE_ADDRESS_QTY:
            addrs = state.addresses;
            addrs[action.index].qty = action.qty;
            return { ...state, addresses: [...addrs] };

        case jobActions.REMOVE_ADDRESS:
            addrs = state.addresses;
            addrs.splice(action.index, 1);
            return {
                ...state,
                addresses: [...addrs],
                totalAddressCount: state.totalAddressCount - 1
            };

        case jobActions.REFRESH_ADDRESSES:
            return { ...state, addresses: [...action.addresses] };

        case jobActions.APPROVE_ATTACHMENT:
            addrs = state.attachments;
            addrs.find((a) => a.preview === action.preview).approved = true;
            return { ...state, attachments: [...addrs] };

        case jobActions.SET_ATTACHMENT_QUANTITY:
            addrs = state.attachments;
            addrs.find((a) => a.id === action.id).quantity = action.quantity;
            return { ...state, attachments: [...addrs] };

        case jobActions.ADD_ATTACHMENT:
            return { ...state, attachments: [...state.attachments, action.attachment] };

        case jobActions.REMOVE_ATTACHMENT:
            return { ...state, attachments: state.attachments.filter((a) => a.id !== action.id) };

        case jobActions.SET_ATTACHMENT_ERROR:
            return { ...state, attachmentError: action.attachmentError };

        case jobActions.GENERATING_FORM_PREVIEW:
            return { ...state, generating: action.generating };

        case jobActions.SET_FORM_PROOF_PREVIEW:
            return { ...state, formProofPreview: action.preview };

        case jobActions.SET_FORM_PROOF_PREVIEW_OPEN:
            return { ...state, openFormProofPreview: action.open };

        case jobActions.UPDATE_JOB_ITEM:
            return {
                ...state,
                jobItems: state.jobItems.map((i) => (i.id === action.jobItem.id
                    ? action.jobItem
                    : i))
            };

        case jobActions.SET_P1B_ITEMS:
            // eslint-disable-next-line no-case-declarations
            const newItems = [...state.jobItems];
            newItems.find((n) => n.id === action.id).items = action.items;
            return { ...state, jobItems: [...newItems] };

        case jobActions.ADD_TO_ADDRESSES:
            return {
                ...state,
                addresses: action.singleOnly ? [state.addressInfo] : [...state.addresses, state.addressInfo],
                addressInfo: {
                    first1: '',
                    middle1: '',
                    last1: '',
                    first2: '',
                    middle2: '',
                    last2: '',
                    companyName: '',
                    address: '',
                    address2: '',
                    address3: '',
                    zip: '',
                    city: '',
                    state: '',
                    country: 'United States',
                    phone: '',
                    ext: '',
                    qty: 1
                },
                totalAddressCount: action.singleOnly ? 1 : (state.totalAddressCount + 1)
            };
        default:
            return state;
    }
};
