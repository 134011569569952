import { Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FavoriteIcon from '@material-ui/icons/Favorite';
import React from 'react';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        position: 'relative'
    },
    media: {
        height: 140,
        backgroundPosition: 'top'
    }
});

export default function ProductCard(props) {
    const {
        data, onProductClick, onQuickViewClick, hideQuickView, favorites, addToFavorites, removeFromFavorites, user
    } = props;
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    return (
        <Card
            className={classes.root}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
        >
            <CardActionArea
                onClick={onProductClick}
            >
                <CardMedia
                    className={classes.media}
                    image={data.thumbnail}
                    title={data.name}
                />
                <CardContent>
                    {props.showCode ? (
                        <Typography gutterBottom variant="subtitle2" style={{ height: '4.0rem' }}>
                            <Box fontWeight="fontWeightBold" >{data.name}</Box>
                            {data.code} 
                        </Typography>

                    ) : (
                        <Typography gutterBottom variant="subtitle2" style={{ height: '2.5rem' }}>
                            {data.name}
                        </Typography>
                )}
                </CardContent>
            </CardActionArea>

            {(open) && (
                <div style={{ position: 'absolute', right: '0', top: '0' }}>
                    <div style={{ margin: '.5rem' }}>
                        {user && user.customFields && user.customFields.p2_Catalog ? <Fab style={{ float: 'right' }} aria-label="add to favorite" size="small">
                            {favorites && favorites.includes(data.code)
                                ? <FavoriteIcon onClick={() => removeFromFavorites(data.code)} style={{ color: 'red' }} />
                                : <FavoriteIcon onClick={() => addToFavorites(data.code)} />}
                        </Fab> : null}
                    </div>
                    {/*<div style={{ margin: '.5rem' }}>
                        <Fab color="primary" style={{ float: 'right' }} aria-label="add to cart" size="small">
                            <ShoppingCartIcon />
                        </Fab>
                    </div> */}
                    {data.sample
                        && (
                            <div style={{ margin: '.5rem' }}>
                                <Fab color="primary" style={{ float: 'right' }} aria-label="add" size="small">
                                    <VisibilityIcon onClick={onQuickViewClick} />
                                </Fab>
                            </div>
                        )}
                </div>
            )}
        </Card>
    );
}
