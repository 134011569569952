import { Grid, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AttachmentIcon from '@material-ui/icons/Attachment';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import FallbackSrc from 'utils/fallbackSrc';

function Alert(props) {
    return <MuiAlert elevation={0} variant="outlined" {...props} />;
}

export default function BasketItemsView({
    basketItems, noThumbnail, hasQty, showMaterialType, itemInventory
}) {

    const renderItemInventoryWarning = (item) => {
        if (itemInventory &&
            itemInventory.length > 0 &&
            // item.isKit &&
            itemInventory.some(iv => iv.length > 0 && iv[0].id === item.productId)
        ) 
        {
            const productInventory = itemInventory.find(iv => iv.length > 0 && iv[0].id === item.productId).filter(x => x.count < item.quantity);

            if(!productInventory || productInventory.length <= 0) {
                return null;
            }

            // return (<Alert severity="warning">{productInventory.map(x => {
            //     return <p>{`We don't have enough inventory for one or more items from your order. We'll adjust the ship quantity accordingly: ${x.itemCode}`}</p>
            // })}</Alert>);
            return (<Alert severity="warning"><p>{`We don't have enough inventory for one or more items from your order. We'll adjust the ship quantity accordingly.`}</p></Alert>);
        } else {
            return null;
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="basket item table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="h5">
                                Materials
                            </Typography>
                        </TableCell>
                        <TableCell />
                        <TableCell>
                            {hasQty && (<span>Quantity</span>)}
                        </TableCell>
                        {showMaterialType
                            && <TableCell>Material Type</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {basketItems.map((item) => (
                        <TableRow key={item.lineId}>
                            <TableCell component="th" scope="row">
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'top' }}>
                                    <div style={{maxWidth: '50%'}}><b>{item.customFields.alias || item.name}</b></div>
                                    <div style={{maxWidth: '50%'}}>
                                        {
                                            renderItemInventoryWarning(item)
                                        }
                                    </div>
                                </div>
                                <br />
                                {item.customFields && item.customFields.fileInfo && (<p><AttachmentIcon color="primary" />{item.customFields.fileInfo.UploadFileName}</p>)}
                            </TableCell>
                            <TableCell>
                                {!noThumbnail && (
                                    <Grid item xs={5}>
                                        <img
                                            onError={FallbackSrc}
                                            src={item.customFields.thumbnail || '//cdndev.memberdoc.com/f/img_no_thumb.jpg'}
                                            alt={item.name}
                                            style={{ width: '150px' }}
                                        />
                                    </Grid>
                                )}
                            </TableCell>
                            <TableCell>
                                {hasQty && (<span>{item.quantity}</span>)}
                            </TableCell>
                            {showMaterialType
                                && (
                                    <TableCell>{
                                        item.customFields.materialType ? item.customFields.materialType.toUpperCase() : ''
                                    }
                                    </TableCell>
                                )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
