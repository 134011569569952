import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    message: {
        top: 'calc(50vh - 50px)'
    },
}));

const NotifyMessage = (props) => {
    const classes = useStyles();
    const { type = 'success', message = '' } = props;
    return (
        <Snackbar
            open={true}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            className={classes.message}
        >
            <Alert severity={type}>{message}</Alert>
        </Snackbar>
    )
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Toast = (props) => {
    const message = props.message || '';
    const toastProps = {
        show: true,
        type: props.type || 'success',
        message
    }


    const component = NotifyMessage;
    const div = document.createElement('div');
    document.body.appendChild(div);

    function setProps(partialProps) {
        const t = React.createElement(component, { ...toastProps, ...partialProps });
        ReactDOM.render(t, div);
    }

    function close() {
        setProps({ show: false });
        destroy();
    }

    function destroy() {
        ReactDOM.unmountComponentAtNode(div);
    }


    ReactDOM.render(React.createElement(component, toastProps), div);

    setTimeout(() => {
        close();
    }, props.duration || 3000);

    return {
        close,
        destroy
    }
}

const info = (msg) => {
    return Toast({ type: 'info', message: msg })
}

const error = (msg) => {
    return Toast({ type: 'error', message: msg })
}

const warning = (msg) => {
    return Toast({ type: 'warning', message: msg })
}

const success = (msg) => {
    return Toast({ type: 'success', message: msg })
}

const message = { info, error, warning, success }

export default message;