import React from 'react';
import Attachments from './Attachments';
import BackorderItems from './BackorderItems';
import ShippingAddressView from './ShippingAddressView';
import SpecialInstruction from './SpecialInstruction';

export default function JobOrderConfirmView({ orderData }) {
    const { jobItems: items, totalAddressCount, attachments } = orderData.customFields;
    const backorderItems = orderData.customFields.jobItems.filter(
        (ji) => ji.dp !== 'Y' && ji.inventoryQuantity !== undefined && ji.quantity > ji.inventoryQuantity,
    );
    const aaa = orderData.orderShipping.map((s) => ({
        ...s,
        items: s.orderShippingItem.map((osi) => ({
            ...osi,
            ...JSON.parse(orderData.orderItem.find((oi) => oi.id === osi.orderItemId).customFields)
        }))
    }));

    const isPartial = totalAddressCount && aaa.length < totalAddressCount;

    return (
        <div className="order-confirm-view">
            <div className="container">
                <div className="table">
                    {aaa.map((a, i) => <ShippingAddressView a={a} i={i} key={i} isPartial={isPartial} total={totalAddressCount} />)}
                    <Attachments attachments={attachments} />
                    <SpecialInstruction shippingNotes={orderData.customFields.shippingNotes} />
                    <BackorderItems backorderItems={backorderItems} />
                </div>
            </div>
        </div>
    );
}
