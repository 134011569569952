import {
    Button,
    CircularProgress, Grid, IconButton,
    Input, TextField
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import UploadIcon from '@material-ui/icons/Backup';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import SearchIcon from '@material-ui/icons/Search';
import { showMessage } from 'actions/app';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { addToAddresses, removeAddressAt, setAddressField, setValidate, updateAddressQty, uploadUserAddressFile } from '../action';
import AddressForm from './ShippingAddress/AddressForm';
import AddressTable from './ShippingAddress/AddressTable';
import ShippingAddressModal from './ShippingAddressModal';

const ShippingAddressStep = (props) => {
    const { lob, jobType } = useParams();
    const [canAdd, setCanAdd] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [uploadingAddresses, setUploadingAddresses] = useState(false);
    const {
        addressInfo, setAddressField: setFieldValue, showMessage, totalAddressCount,
        addToAddresses, addresses, uploadUserAddressFile, updateAddressQty, removeAddressAt
    } = props;

    useEffect(() => {
        setCanAdd(!!addressInfo.last1
            && !!addressInfo.address
            && !!addressInfo.zip
            && !!addressInfo.state
            && !!addressInfo.country);
        const isValid = (addresses
            && addresses.length
        );
        props.setIsNextReady(isValid);
    }, [addressInfo, addresses, props]);

    const handleFieldChange = (e) => {
        setFieldValue(e.target.name, e.target.value);
    };
    const clearAll = () => {
        for (const k in addressInfo) {
            setFieldValue(k, '');
        }
    };
    const fixZip = () => {
        const z = addressInfo.zip;
        if (z.length === 4) {
            setFieldValue('zip', `0${z}`);
        }
    };

    const handleQtyChange = (index, qty) => {
        if (qty) {
            const q = parseInt(qty, 10);
            if (q > 0) { updateAddressQty(index, q); }
        }
    };

    const handleRemoveAddr = (index) => {
        removeAddressAt(index);
    };

    const upload = (e) => {
        if (!e.target.files.length) return;
        const file = e.target.files[0];
        setUploadingAddresses(true);
        getBase64(file).then(async (fileData) => {
            await uploadUserAddressFile(fileData);
            setUploadingAddresses(false);
        });
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const hasAddressQty = jobType === 'AJEKT';
    const allowUpload = jobType === 'AJEKT' || (jobType === 'IM' && (lob === 'cml' || lob === 'p1b'));

    return (
        <>
            <Grid container>
                <Grid item style={{ marginTop: '1rem' }} xs={12}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <AddressForm addressInfo={addressInfo} handleFieldChange={handleFieldChange} fixZip={fixZip} />
                        {jobType === 'AJEKT' && (
                            <Grid item xs={1}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="QTY"
                                    type="text"
                                    variant="outlined"
                                    name="qty"
                                    value={addressInfo.qty || ''}
                                    onChange={handleFieldChange}
                                />
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <Button
                                size="large"
                                color="primary"
                                variant="contained"
                                fontWeight="fontWeightMedium"
                                disabled={!(canAdd)}
                                style={{ marginRight: '1rem' }}
                                onClick={() => addToAddresses(jobType === 'FJE')}
                            >
                                <AddIcon /> Add
                            </Button>

                            <Button
                                size="large"
                                color="primary"
                                variant="outlined"
                                fontWeight="fontWeightMedium"
                                style={{ marginRight: '1rem' }}
                                onClick={() => setOpenModal(true)}
                            >
                                <SearchIcon /> Find
                            </Button>

                            <Button
                                size="large"
                                color="primary"
                                variant="outlined"
                                fontWeight="fontWeightMedium"
                                style={{ marginRight: '1rem' }}
                                onClick={clearAll}
                            >
                                <ClearAllIcon /> Clear
                            </Button>
                            {allowUpload && (
                                <IconButton color="primary">
                                    <label htmlFor="user-address-upload" style={{ cursor: 'pointer' }}>
                                        {uploadingAddresses
                                            ? <CircularProgress color="secondary" size={12} />
                                            : <UploadIcon />}

                                        <Input type="file" inputProps={{ id: 'user-address-upload', accept: '.xlsx, .xls' }} style={{ display: 'none' }} onChange={upload} />
                                    </label>
                                </IconButton>
                            )}
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>

            <AddressTable
                addresses={addresses}
                handleQtyChange={handleQtyChange}
                onRemove={handleRemoveAddr}
                hasAddressQty={hasAddressQty}
                totalAddressCount={totalAddressCount}
            />
            <ShippingAddressModal open={openModal} handleClose={() => setOpenModal(false)} />
        </>
    );
};

const mapStateToProps = ({
    directOrderView: {
        stepView: { addressInfo, addresses, totalAddressCount }
    }
}) => ({
    addressInfo, addresses, totalAddressCount
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            showMessage, setAddressField, setValidate, addToAddresses, uploadUserAddressFile, updateAddressQty, removeAddressAt
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddressStep);
