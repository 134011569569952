import {
    Box, Breadcrumbs,
    Button, Grid,
    IconButton, Link, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField,
    Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import React, { PureComponent } from 'react';
import FormModal from './FormModal';

const pageSize = 10;
export default class ListView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            pageNumber: 1,
            openPlanSponsor: false,
            planSponsor: null,

            caseId: '',
            caseName: '',

            confirming: false,
            planSponsorId: null
        };
    }

    componentDidMount() {
        const { pageNumber, caseId, caseName } = this.state;
        const { getPlanSponsors, user, history } = this.props;

        if (user && !(user.customFields && user.customFields.userGroups && user.customFields.userGroups.length > 0 && user.customFields.userType === 'su')) {
            history.push('/');
        }
        getPlanSponsors(caseId, caseName, pageNumber, pageSize);
    }

    handleChangePage = (_event, pageNumber = 0) => {
        const { caseId, caseName } = this.state;
        const { getPlanSponsors } = this.props;
        getPlanSponsors(caseId, caseName, pageNumber + 1, pageSize);
    }

    closeModal = () => {
        this.setState({ openPlanSponsor: false });
    }

    savePlanSponsor = (formData) => {
        const { savePlanSponsor, getPlanSponsors } = this.props;
        const { pageNumber, caseId, caseName } = this.state;
        savePlanSponsor(formData, () => {
            getPlanSponsors(caseId, caseName, pageNumber, pageSize);
            this.setState({ openPlanSponsor: false });
        });
    }

    openModal = (id) => {
        const { planSponsors } = this.props;
        this.setState({
            openPlanSponsor: true,
            planSponsor: id ? planSponsors.results.find((a) => a.planSponsorId === id) : null
        });
    }

    confirmDelete = (planSponsorId) => {
        this.setState({ confirming: true, planSponsorId });
    }

    delete = () => {
        const {
            caseId, caseName, pageNumber, planSponsorId
        } = this.state;
        const { deletePlanSponsor, getPlanSponsors } = this.props;
        deletePlanSponsor(planSponsorId, () => {
            getPlanSponsors(caseId, caseName, pageNumber, pageSize);
            this.setState({ confirming: false, planSponsorId: null });
        });
    }

    render() {
        const { planSponsors, showMessage } = this.props;
        const { openPlanSponsor, planSponsor } = this.state;
        const { caseId, caseName, confirming } = this.state;
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <Box mb={2}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link color="inherit" href="#/account">
                                    Account
                                </Link>
                                <Typography color="textPrimary">Plan Sponsors</Typography>
                            </Breadcrumbs>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="order table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: '10rem' }}>
                                            <TextField
                                                label="Case ID"
                                                autoFocus
                                                value={caseId}
                                                onKeyPress={(e) => { if (e.key === 'Enter') { this.handleChangePage(); } }}
                                                onChange={(e) => this.setState({ caseId: e.target.value })}
                                            />
                                        </TableCell>
                                        <TableCell style={{ width: '10rem' }}>
                                            <TextField
                                                label="Case Name"
                                                autoFocus
                                                value={caseName}
                                                onKeyPress={(e) => { if (e.key === 'Enter') { this.handleChangePage(); } }}
                                                onChange={(e) => this.setState({ caseName: e.target.value })}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Button color="primary" onClick={() => this.handleChangePage()}>
                                                <SearchIcon /> Search
                                            </Button>
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>

                            </Table>
                            <Table aria-label="order table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Case ID</TableCell>
                                        <TableCell>Case Name</TableCell>
                                        <TableCell>Control Number</TableCell>
                                        <TableCell>Medicare Case</TableCell>
                                        <TableCell>Logo</TableCell>
                                        <TableCell>Phone</TableCell>
                                        <TableCell>Website Url</TableCell>
                                        <TableCell>Operating Hours</TableCell>
                                        <TableCell>
                                            <IconButton color="secondary" onClick={() => this.openModal()}>
                                                <AddIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {planSponsors && planSponsors.results.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row">
                                                {row.caseId}
                                            </TableCell>
                                            <TableCell> {row.caseName}</TableCell>
                                            <TableCell> {row.controlNumber}</TableCell>
                                            <TableCell> {row.medicareCase}</TableCell>
                                            <TableCell> {row.logo}</TableCell>
                                            <TableCell> {row.phone}</TableCell>
                                            <TableCell> {row.websiteUrl}</TableCell>
                                            <TableCell> {row.operatingHours}</TableCell>
                                            <TableCell>
                                                <IconButton color="primary" onClick={() => this.openModal(row.planSponsorId)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton color="primary" onClick={() => this.confirmDelete(row.planSponsorId)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {planSponsors
                            && (
                                <TablePagination
                                    component="div"
                                    count={planSponsors.totalCount}
                                    page={planSponsors.pageNumber - 1}
                                    rowsPerPage={planSponsors.pageSize}
                                    rowsPerPageOptions={[planSponsors.pageSize]}
                                    onChangePage={this.handleChangePage}
                                />
                            )}
                        {openPlanSponsor && (
                            <FormModal
                                open={openPlanSponsor}
                                sponsor={planSponsor}
                                onClose={this.closeModal}
                                onSave={this.savePlanSponsor}
                                showMessage={showMessage}
                            />
                        )}
                    </Grid>

                </Grid>

                <Dialog
                    open={confirming}
                    onClose={() => this.setState({ confirming: false, planSponsorId: null })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Are you sure you want to remove this plan sponsor?</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => this.setState({ confirming: false, planSponsorId: null })} color="primary">
                            No
                        </Button>
                        <Button onClick={() => this.delete()} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
