import {
    Box, Breadcrumbs, Grid, IconButton,

    Link, Paper,

    Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Tabs, Typography
} from '@material-ui/core';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import React, { PureComponent } from 'react';
import SavedJob from '../saved-job';

const pageSize = 10;
function TabPanel(props) {
    const {
        children, value, index, ...other
    } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

class ListView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            pageNumber: 1,

            tabValue: 0
        };
    }

    componentDidMount() {
        const { pageNumber } = this.state;
        const { getSavedOrders: loadData } = this.props;
        loadData(pageNumber, pageSize);
    }

    handleChangePage = (_event, pageNumber) => {
        const { getSavedOrders: loadData } = this.props;
        loadData(pageNumber + 1, pageSize);
    }

    addSavedItemToCart = (listId, itemId) => {
        const { addSavedItemToCart: addItemToCart } = this.props;
        addItemToCart(listId, itemId);
    }

    handleTabChange = (e, tabValue) => {
        this.setState({ tabValue });
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }

    render() {
        const { savedOrders, history, user } = this.props;

        const { tabValue } = this.state;
        const hideSavedEdit = user && user.customFields && !!user.customFields.p2_Catalog;
        return (
            <Box>
                <Box mb={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/account">
                            Account
                        </Link>
                        <Typography color="textPrimary">Saved Order</Typography>
                    </Breadcrumbs>
                </Box>
                <Grid container>
                    <Grid item xs={8}>
                        <Tabs value={tabValue} onChange={this.handleTabChange} aria-label="simple tabs example">
                            <Tab label="Saved Orders" {...this.a11yProps(0)} />
                            {!hideSavedEdit && <Tab label="Saved Edit" {...this.a11yProps(1)} />}
                        </Tabs>
                    </Grid>
                </Grid>
                <TabPanel value={tabValue} index={0}>
                    <Grid container spacing={4}>
                        {(!savedOrders || !savedOrders.length) && (
                            <Box m={2}>
                                <Typography variant="h6" gutterBottom component="body1">
                                    Your saved order is empty.
                                </Typography>
                            </Box>
                        )}
                        {savedOrders && savedOrders.map((o) => (
                            <Grid item container key={o.id}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom component="div">
                                        {o.name} (saved on: {new Date(o.createdDate).toLocaleDateString()})
                                    </Typography>

                                </Grid>
                                <Grid item container>
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Item Description</TableCell>
                                                    <TableCell>Item Code</TableCell>
                                                    <TableCell>Item Type</TableCell>
                                                    <TableCell>Quantity</TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {o.customerSavedItem.map((csi) => (
                                                    <TableRow key={csi.id}>
                                                        <TableCell>{csi.name}</TableCell>
                                                        <TableCell>{csi.code}</TableCell>
                                                        <TableCell>{csi.customFields.itemType}</TableCell>
                                                        <TableCell>{csi.quantity}</TableCell>
                                                        <TableCell>
                                                            <IconButton
                                                                onClick={() => this.addSavedItemToCart(o.id, csi.id)}
                                                            >
                                                                <AddShoppingCartIcon
                                                                    color="primary"
                                                                />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <SavedJob history={history} />
                </TabPanel>
            </Box>
        );
    }
}

export default ListView;
