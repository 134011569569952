import React from 'react';
import {
    Grid, Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';

export default function BackorderItems({ backorderItems }) {
    if (!backorderItems || !backorderItems.length) return null;
    return (
        <>
            <div className="table-title-content">
                <span style={{ fontSize: '20px', fontWeight: '700', marginRight: '30px' }}>
                    The following items on this order are on backorder:
                </span>
            </div>
            <Table component={Paper}>
                <TableBody>
                    {backorderItems.map((boi) => (
                        <TableRow key={boi.code + (boi.whs || '') + boi.name}>
                            <TableCell>   {boi.code}: {boi.name}</TableCell>
                        </TableRow>
                    ))}

                </TableBody>
            </Table>
        </>
    );
}
