import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getOrders, getSaves } from './action';
import ListView from './ListView';

const mapStateToProps = ({ orderManagementView: { orders, saves, loading }, appView: { user } }) => {
    return { orders, saves, loading, user };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getOrders, getSaves
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListView);
