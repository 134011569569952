import {
    Box,
    Breadcrumbs, Button,
    Divider, Fab, Grid,
    Link, Paper, TextField, Typography
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { addProductToCart } from 'pages/catalog/action';
import QuickviewModal from 'pages/catalog/QuickviewModal';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    addToFavorites,
    removeFromFavorites
} from './action';
import RelatedProducts from './RelatedProducts';

export const ProductView = ({
    product, goBack, productMeta, addProductToCart,
    favorites, addToFavorites, removeFromFavorites, limits, relatedProducts, token
}) => {
    const [qty, setQty] = useState(1);
    const [openQuickView, setOpenQuickView] = useState(false);
    const fallbackSrc = (e) => {
        e.target.src = '//cdndev.memberdoc.com/f/img_no_thumb.jpg';
        e.target.style.width = '';
    };
    const { customFields: { materialType } } = product;
    const limitValue = limits.monthlyLimits[materialType.toLowerCase()];

    const addToCart = () => {
        const {
            id, isKit, code, name, thumbnail, customFields: { materialType: itemType }
        } = product;
        const { used } = productMeta;

        addProductToCart({
            id,
            isKit,
            code,
            name,
            qty,
            customFields: {
                thumbnail,
                used,
                itemType,
                limit: limits.monthlyLimits[materialType.toLowerCase()],
                upperLimit: limits.upperLimits[materialType.toLowerCase()]
            }
        });
    };
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const lastDay = new Date(y, m + 1, 0);

    const contractPBP = [];
    if (productMeta) {
        for (const cp of productMeta.contractPBP) {
            contractPBP.push(<p key={cp.counties}><b>{cp.contractPBP}</b>: {cp.counties} </p>);
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Button
                        onClick={goBack}
                    >

                        {'<< Back'}
                    </Button>
                    <Typography color="textPrimary">{product && product.name}</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item align="center" xs={6}>
                <Paper style={{ overflow: 'hidden', marginBottom: '2rem' }}>
                    <img
                        src={product.thumbnail}
                        onError={fallbackSrc}
                        style={{ maxHeight: '32rem' }}
                        alt={product.name}
                    />
                    <div style={{ margin: '.5rem' }}>
                        <Fab style={{ float: 'right' }} aria-label="add to favorite" size="small">
                            {favorites && favorites.includes(product.code)
                                ? <FavoriteIcon onClick={() => removeFromFavorites(product.code)} style={{ color: 'red' }} />
                                : <FavoriteIcon onClick={() => addToFavorites(product.code)} />}

                        </Fab>
                    </div>
                </Paper>
                {
                    relatedProducts && relatedProducts.length > 0 && (
                        <>
                            <Box style={{ textAlign: 'left' }}>

                                <Typography variant="h5">
                                    Suggested Items
                                </Typography>
                            </Box>
                            <RelatedProducts products={relatedProducts} limits={limits} addProductToCart={addProductToCart} />
                        </>
                    )
                }

            </Grid>
            <Grid item xs={6}>
                {product && (
                    <>
                        <Typography variant="h4">
                            {product.name}
                        </Typography>
                        <Divider color="" style={{ height: '.2rem', marginTop: '1rem', marginBottom: '1rem' }} />
                        {productMeta && (
                            <>
                                <Typography variant="h6">
                                    Information
                                    <Link href={`${productMeta.downloadRoot}/${product.customFields.materialType}/${product.id}?Authorization=${token}`} target="_blank">
                                        <CloudDownloadIcon />
                                    </Link>
                                </Typography>
                                <Typography variant="subtitle2">
                                    Used: {productMeta.used} of {limitValue}
                                    &nbsp;(until {lastDay.toLocaleDateString()})
                                </Typography>
                                {contractPBP && contractPBP.length > 0 && (
                                    <>
                                        <Typography variant="subtitle1" style={{ marginTop: '1rem' }}>
                                            Contract PBPs:
                                        </Typography>
                                        <Typography variant="body1">
                                            {contractPBP}
                                        </Typography>
                                    </>
                                )}
                                <Divider color="" style={{ height: '.2rem', marginTop: '1rem', marginBottom: '1rem' }} />
                            </>
                        )}
                        <Grid
                            container
                            spacing={4}
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <TextField
                                    id="outlined-qty"
                                    label="Qty"
                                    size="small"
                                    type="number"
                                    min={1}
                                    style={{ width: '6rem' }}
                                    value={qty}
                                    onChange={(e) => setQty(parseInt(e.target.value, 10))}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item>

                                <Button
                                    type="button"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    onClick={addToCart}
                                >
                                    ADD TO CART
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
            {openQuickView && (
                <QuickviewModal
                    open={openQuickView}
                    closeQuickview={() => setOpenQuickView(false)}
                    quickviewPDF={product.sample}
                />
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    productMeta: state.brokerView.productMeta,
    limits: state.brokerView.limits,
    relatedProducts: state.brokerView.relatedProducts,
    favorites: state.brokerView.favorites
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            addProductToCart,
            addToFavorites,
            removeFromFavorites

        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);
