import { Box, Button, Checkbox, CircularProgress, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import BuildIcon from '@material-ui/icons/Build';
import React, { PureComponent } from 'react';
import MessageBoard from '../broker/MessageBoard';
import SelectBar from './SelectBar';

export default class ListView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            criteria: null,
            selected: []
        };
    }

    changeState = (e) => {
        if (e.target.value) {
            const { setState } = this.props;
            setState(e.target.value);

            const { loadMaterials } = this.props;
            loadMaterials(e.target.value, 1);
        }
    }

    handleChangePage = (_event, pageNumber) => {
        const { searchMaterials } = this.props;
        const { criteria } = this.state;
        searchMaterials(criteria, pageNumber + 1);
    };

    toggleSelected = (e) => {
        const { selected } = this.state;
        if (selected.includes(e.target.value)) {
            this.setState({ selected: selected.filter((s) => s !== e.target.value) });
        } else {
            this.setState({ selected: [...selected, e.target.value] });
        }
    }

    onAddClicked = (id, code, name, qty, customFields) => {
        const { addProductToCart } = this.props;
        addProductToCart({
            id, code, name, qty, customFields: { program: 'P1A Telesales', ...customFields || {} }, isKit: true
        }, false);
    }

    goToCart = () => {
        const { history } = this.props;
        history.push('/cart');
    }

    onAddSelectedClicked = () => {
        const {
            listResult, addProductsToCart
        } = this.props;
        const { selected } = this.state;

        const selectedItems = listResult.results.filter((row) => selected.includes(`${row.itemCode}:${row.pbpName}`))
            .map((row) => ({
                id: row.id,
                code: row.itemCode,
                name: `${row.planYear} ${row.contract} ${row.pbp} ${row.pbpName}`,
                qty: 1,
                customFields: { program: 'P1A Telesales', lob: 'p1a', ...row }
            }));

        addProductsToCart(selectedItems, false);
    }

    onSearch = (criteria) => {
        this.setState({ criteria, selected: [] });
        const { searchMaterials } = this.props;
        searchMaterials(criteria, 1);
    }

    render() {
        const {
            listResult, loading, user, settings
        } = this.props;

        let msg;
        if (settings) {
            if (settings.messageBoard && settings.messageBoard.length) {
                [msg] = settings.messageBoard;
            } else if (settings.useDefaultMsg) {
                msg = 'Welcome to ONEkit! Our new ordering portal is designed to be easier and more efficient to use. You can learn the new features of our ordering portal by downloading our User Guide from the Need Help page by clicking the question mark icon.';
            }
        }

        let criteria = null;
        if (user && user.customFields) {
            criteria = JSON.parse(user.customFields.criteria);
        }
        const { selected } = this.state;
        return (
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Box m={2} p={2} component={Paper}>
                        {msg && <MessageBoard content={msg} />}
                    </Box>
                    <Box m={2} p={2} component={Paper}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            <BuildIcon /> Don&apos;t Forget!
                        </Typography>
                        <ul>
                            <li>Narrow down the plan options for the caller.</li>
                            <li>Ask for permission to callback to follow up.</li>
                            <li>Copy the order # into your CRM tool.</li>
                        </ul>
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            {!!criteria && (
                                <SelectBar
                                    planYear={criteria.planYears[0]}
                                    planYears={criteria.planYears}
                                    languages={criteria.languages}
                                    products={criteria.products}
                                    onSearch={this.onSearch}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <Grid container spacing={2} component={Paper}>
                                <Grid item xs={12}>
                                    {!loading && listResult && (<h3>Found Items</h3>)}
                                </Grid>
                                {loading
                                    && (
                                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                                            <CircularProgress color="primary" />
                                        </Grid>
                                    )}
                                {!loading && listResult && listResult.results.map((row) => {
                                    const checked = selected.includes(`${row.itemCode}:${row.pbpName}`);
                                    return (
                                        <Grid item xs={12} key={row.itemCode + row.pbpName}>
                                            <Grid container>
                                                <Grid item xs={10}>
                                                    <Checkbox
                                                        edge="start"
                                                        tabIndex={-1}
                                                        disableRipple
                                                        checked={checked}
                                                        value={`${row.itemCode}:${row.pbpName}`}
                                                        onChange={this.toggleSelected}
                                                        color="primary"
                                                    />
                                                    {row.planYear} {row.contract} {row.pbp} {row.pbpName}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <IconButton
                                                        onClick={() => this.onAddClicked(row.id, row.itemCode,
                                                            `${row.planYear} ${row.contract} ${row.pbp} ${row.pbpName}`, 1, { itemInfo: row, lob: 'p1a' })}
                                                    >
                                                        <AddShoppingCartIcon
                                                            color="primary"
                                                        />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                                {listResult && (
                                    <>

                                        <Grid item xs={6}>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                disabled={selected.length === 0}
                                                onClick={this.onAddSelectedClicked}
                                            >
                                                Add Selected to Cart
                                            </Button>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                style={{ width: '13rem', marginLeft: '1rem' }}
                                                onClick={this.goToCart}
                                            >
                                                Go to Cart
                                            </Button>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TablePagination
                                                component="div"
                                                count={listResult.totalCount}
                                                page={listResult.pageNumber - 1}
                                                rowsPerPage={listResult.pageSize}
                                                rowsPerPageOptions={[listResult.pageSize]}
                                                onChangePage={this.handleChangePage}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
