import {
    FormControl, Grid,

    InputLabel, MenuItem, Select, TextField
} from '@material-ui/core';
import PhoneInput from 'components/PhoneInput';
import StateSelect from 'components/UsStateSelect';
import React from 'react';
import countries from '../CountryData.json';

export default function AddressForm({
    addressInfo, handleFieldChange, fixZip
}) {
    const buildInput = (
        name, label, value, x = 2
    ) => {
        return (
            <Grid item xs={x}>
                <TextField
                    fullWidth
                    size="small"
                    type="text"
                    variant="outlined"
                    name={name}
                    label={label}
                    value={value || ''}
                    onChange={handleFieldChange}
                />
            </Grid>
        );
    };

    return (
        <>
            {buildInput('first1', 'First Name 1', addressInfo.first1)}
            {buildInput('middle1', 'Middle Name 1', addressInfo.middle1)}
            {buildInput('last1', 'Last Name 1', addressInfo.last1)}
            {buildInput('first2', 'First Name 2', addressInfo.first2)}
            {buildInput('middle2', 'Middle Name 2', addressInfo.middle2)}
            {buildInput('last2', 'Last Name 2', addressInfo.last2)}
            {buildInput('address', 'Address 1', addressInfo.address, 3)}
            {buildInput('address2', 'Address 2', addressInfo.address2, 3)}
            {buildInput('address3', 'Address 3', addressInfo.address3, 3)}
            {buildInput('company', 'Company Name', addressInfo.company, 3)}

            <Grid item xs={2}>
                <TextField
                    label="Phone#"
                    size="small"
                    name="phoneNumber"
                    variant="outlined"
                    InputProps={{
                        inputComponent: PhoneInput,
                        value: addressInfo.phoneNumber || '',
                        onChange: (e) => handleFieldChange(e)
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            {buildInput('ext', 'Ext', addressInfo.ext, 1)}
            <Grid item xs={2}>
                <TextField
                    fullWidth
                    size="small"
                    required
                    label="Zip"
                    type="text"
                    variant="outlined"
                    name="zip"
                    value={addressInfo.zip || ''}
                    onChange={handleFieldChange}
                    onBlur={fixZip}
                />
            </Grid>
            {buildInput('city', 'City', addressInfo.city)}
            <Grid item xs={2}>
                <StateSelect
                    value={addressInfo.state || ''}
                    onChange={handleFieldChange}
                />
            </Grid>
            <Grid item xs={2}>
                <FormControl
                    fullWidth
                    size="small"
                    required
                    variant="outlined"
                >
                    <InputLabel id="country-select-outlined-label">Country</InputLabel>
                    <Select
                        labelId="country-select-outlined-label"
                        id="country-select-outlined"
                        fullWidth
                        name="country"
                        value={addressInfo.country || ''}
                        onChange={handleFieldChange}
                        label="Country"
                    >
                        <MenuItem value="">Select</MenuItem>
                        {countries.map((s) => <MenuItem key={s} value={s}>{s}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
        </>
    );
}
