import { showMessage } from 'actions/app';
import { getPricing } from 'pages/cart/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './action';
import CheckoutView from './CheckoutView';

const mapStateToProps = ({
    appView: { user },
    checkoutView: {
        isLoading, errorMessages, basketItems, itemInventory,
        shippingAddresses, shippingMethods, balance, used, planDocuments, contactInfo, marketSegmentXCostCenters, estimatedDeliveryDate, deliveryDate, loadingMessage
    },
    cartView: { pricing }
}) => {
    return {
        user,
        isLoading,
        errorMessages,
        basketItems,
        shippingAddresses,
        shippingMethods,
        balance,
        used,
        planDocuments,
        pricing,
        contactInfo,
        marketSegmentXCostCenters,
        estimatedDeliveryDate,
        deliveryDate,
        loadingMessage,
        itemInventory
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...actions,
            showMessage,
            getPricing
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CheckoutView);
