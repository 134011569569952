import DateFnsUtils from '@date-io/date-fns';
import {
    Checkbox, FormControl,
    FormControlLabel, Grid,

    InputLabel, MenuItem, Select, TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PhoneInput from 'components/PhoneInput';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getPossibleDeliveryDate, setDomNextStepReady, setValidate, updateJobInfo } from '../action';
import InvalidDeliveryDate from './InvalidDeliveryDate';

const useStyles = makeStyles((theme) => ({
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const JobInformationStep = (props) => {
    const [init, setInit] = useState(false);
    const [openDDD, setOpenDDD] = useState(false);
    const [ddd, setDDD] = useState('');
    const [lateAddresses, setLateAddresses] = useState([]);
    const {
        jobInfo, user, addresses, jobItems, marketSegmentXCostCenters, screenData, settings
    } = props;
    const { lob, jobType } = useParams();

    useEffect(() => {
        if (!init) {
            if (user && lob === 'p1a') {
                const info = { ...jobInfo, costCenter: '19086' };
                props.updateJobInfo(info);
            }

            if (lob === 'p1b' && user && user.customFields.userType === 'fsr' && (jobType === 'AJEKT' || jobType === 'IM')) {
                const info = {
                    ...jobInfo,
                    costCenter: jobItems[0].costCenter,
                    costCenterOptions: jobItems[0].costCenterOptions,
                    ccReadOnly: !jobItems[0].costCenterOptions || jobItems[0].costCenterOptions.length === 0
                };
                props.updateJobInfo(info);
            }
            if (lob === 'p1b' && user && user.customFields.userType === 'fsr' && jobType === 'AJEPP') {
                const info = { ...jobInfo, costCenter: '19159', ccReadOnly: true };
                props.updateJobInfo(info);
            }

            props.getPossibleDeliveryDate(lob, jobType);
            setInit(true);
        }
        const isValid = !!(jobInfo.firstName && jobInfo.lastName && jobInfo.dateReq && jobInfo.costCenter && (!jobInfo.oboBroker || !!jobInfo.npn));
        props.setIsNextReady(isValid);
    }, [init, jobInfo, props, user, lob, jobItems, jobType, marketSegmentXCostCenters, screenData]);

    const handleInputChange = (e) => {
        let value = e.target.name === 'oboBroker' ? e.target.checked : e.target.value;
        if (e.target.name === 'dateReq') {
            const expected = new Date(e.target.value);
            value = expected.toLocaleDateString();
        }

        const info = { ...jobInfo };
        info[e.target.name] = value;
        props.updateJobInfo(info);

        if (e.target.name === 'dateReq') {
            setDDD(value);
            const reqDate = new Date(e.target.value);
            const late = addresses.filter((a) => a.deliveryDate > reqDate);
            setLateAddresses(late);
            if (late.length) {
                setOpenDDD(true);
            }
        }
    };

    const classes = useStyles();
    const oboSupported = user && user.customFields.userType === 'fsr' && lob === 'p1a';

    const formatDate = (date) => {
        const year = date.getFullYear();

        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : `0${month}`;

        let day = date.getDate().toString();
        day = day.length > 1 ? day : `0${day}`;

        return `${month}/${day}/${year}`;
    };

    const disableWeekends = (date) => {
        return date.getDay() === 0 || date.getDay() === 6 || (settings && settings.holidays && settings.holidays.includes(formatDate(date)));
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        size="small"
                        required
                        label="First Name"
                        type="text"
                        variant="outlined"
                        name="firstName"
                        value={jobInfo.firstName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        fullWidth
                        size="small"
                        label="MI"
                        type="text"
                        variant="outlined"
                        name="middleName"
                        value={jobInfo.middleName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        size="small"
                        required
                        label="Last Name"
                        type="text"
                        variant="outlined"
                        name="lastName"
                        value={jobInfo.lastName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Phone#"
                        size="small"
                        name="phone"
                        variant="outlined"
                        InputProps={{
                            inputComponent: PhoneInput,
                            value: jobInfo.phone || '',
                            onChange: handleInputChange
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Ext"
                        type="text"
                        variant="outlined"
                        name="ext"
                        value={jobInfo.ext}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={2}>

                    {
                        jobInfo.costCenterOptions && jobInfo.costCenterOptions.length > 0
                            ? (
                                <FormControl
                                    fullWidth
                                    size="small"
                                    required
                                    variant="outlined"
                                >
                                    <InputLabel id="country-select-outlined-label">Cost Center</InputLabel>
                                    <Select
                                        labelId="cost-center-select-outlined-label"
                                        id="cost-center-select-outlined"
                                        name="costCenter"
                                        value={jobInfo.costCenter}
                                        label="Cost Center"
                                        fullWidth
                                        onChange={handleInputChange}
                                    >
                                        {jobInfo.costCenterOptions.map((o) => {
                                            if (lob === 'cml' && ['AJEPP', 'AJEKT', 'IM'].includes(jobType)) {
                                                return (
                                                    <MenuItem
                                                        key={o.value}
                                                        value={o.value}
                                                    >
                                                        {o.value} ({o.label})
                                                    </MenuItem>
                                                );
                                            }

                                            return (
                                                <MenuItem
                                                    key={o}
                                                    value={o}
                                                >
                                                    {o}
                                                </MenuItem>
                                            );
                                        })}

                                    </Select>
                                </FormControl>
                            )
                            : (
                                <TextField
                                    fullWidth
                                    size="small"
                                    required
                                    disabled={lob === 'p1a' || jobInfo.ccReadOnly || ((jobType === 'AJEPP' || jobType === 'AJEKT' || jobType === 'IM') && lob === 'cml')}
                                    label="Cost Center"
                                    type="text"
                                    variant="outlined"
                                    name="costCenter"
                                    value={jobInfo.costCenter}
                                    onChange={handleInputChange}
                                />
                            )
                    }

                </Grid>
                <Grid item xs={2}>
                    {jobInfo.dateReq && (
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            shouldDisableDate={disableWeekends}
                            id="date"
                            label="Date Req"
                            name="dateReq"
                            minDate={new Date()}
                            value={new Date(jobInfo.dateReq)}
                            inputVariant="outlined"
                            size="small"
                            required
                            onChange={(d) => {
                                handleInputChange({ target: { name: 'dateReq', value: d } });
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'Date Req'
                            }}
                        />

                    )}
                    {/* <TextField
                        id="date"
                        label="Date Req"
                        required
                        size="small"
                        variant="outlined"
                        fullWidth
                        name="dateReq"
                        type="date"
                        disabled={!addresses[0].deliveryDate}
                        min={new Date()}
                        className={classes.textField}
                        value={jobInfo.dataReq}
                        onChange={handleInputChange}
                        InputLabelProps={{
                            shrink: true
                        }}
                    /> */}
                </Grid>
                {oboSupported && (
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Checkbox checked={jobInfo.oboBroker || false} onChange={handleInputChange} name="oboBroker" />}
                            label="On Behalf of Broker"
                        />
                    </Grid>

                )}
                {
                    jobInfo.oboBroker && (
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                size="small"
                                required
                                label="NPN"
                                type="text"
                                variant="outlined"
                                name="npn"
                                value={jobInfo.npn || ''}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    )
                }
                {false && (
                    <>
                        <Grid item xs={2}>
                            <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <InputLabel>Office/Location</InputLabel>
                                <Select
                                    value={jobInfo.officeLocation}
                                    fullWidth
                                    onChange={handleInputChange}
                                    name="officeLocation"
                                    label="Office/Location"
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    <MenuItem value="Aetna">Aetna</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <InputLabel>Job Reason</InputLabel>
                                <Select
                                    value={jobInfo.jobReason}
                                    fullWidth
                                    onChange={handleInputChange}
                                    name="jobReason"
                                    label="Job Reason"
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    <MenuItem value="Wrong Item">Wrong Item</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={1}>
                            <TextField
                                fullWidth
                                size="small"
                                required
                                InputProps={{
                                    readOnly: jobType !== 'AJEKT'
                                }}
                                label="Quantity"
                                type="text"
                                variant="outlined"
                                name="quantity"
                                value={jobInfo.quantity}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </>

                )}

                <InvalidDeliveryDate open={openDDD} ddd={ddd} addresses={lateAddresses} onClose={() => setOpenDDD(false)} />
                {lob === 'p1b' && user.customFields.userType === 'fsr' && (
                    <>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Email 1"
                                type="email"
                                variant="outlined"
                                name="email1"
                                value={jobInfo.email1 || ''}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Email 2"
                                type="email"
                                variant="outlined"
                                name="email2"
                                value={jobInfo.email2 || ''}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </MuiPickersUtilsProvider>
    );
};

const mapStateToProps = ({
    appView: { user, settings },
    directOrderView: {
        stepView: {
            jobInfo,
            addresses,
            jobItems, marketSegmentXCostCenters, screenData
        }
    }
}) => ({
    jobInfo,
    user, settings,
    addresses,
    jobItems,
    marketSegmentXCostCenters,
    screenData
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateJobInfo, setDomNextStepReady, setValidate, getPossibleDeliveryDate
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(JobInformationStep);
