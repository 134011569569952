import orderActions from './action';

export default (
    state = {
        loading: false,
        orders: null,
        orderNumber: '',
        startDate: null,
        endDate: null,
        recipientName: ''
    },
    action
) => {
    switch (action.type) {
        case orderActions.LOADING:
            return { ...state, loading: action.loading };

        case orderActions.SET_ORDERS:
            return { ...state, orders: action.orders };
        case orderActions.SET_SEARCH_FILTER:
            return { ...state, [action.propertyName]: action.propertyValue };
        case orderActions.CLEAR_FILTER:
            return { ...state, orderNumber: '', startDate: null, endDate: null, recipientName: ''};
        default:
            return state;
    }
};
