import { IconButton, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
        zIndex: 10
    },
    paper: {
        padding: theme.spacing(1),
        zIndex: 10000
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);
export default function Relatedproducts({ products, limits, addProductToCart }) {
    const onAddClicked = (id, isKit, code, name, quantity, customFields) => {
        customFields.limit = limits.monthlyLimits[customFields.itemType.toLowerCase()];
        customFields.upperLimit = limits.upperLimits[customFields.itemType.toLowerCase()];
        addProductToCart({
            id, isKit, code, name, qty: quantity, customFields
        }, false);
    };

    const fallbackSrc = (e) => {
        e.target.src = '//cdndev.memberdoc.com/f/img_no_thumb.jpg';
        e.target.style.width = '100%';
    };
    // const openProduct = (r) => {
    // };

    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Item Description</StyledTableCell>
                        <StyledTableCell>Item Code</StyledTableCell>
                        <StyledTableCell align="right">Order</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.map((row) => {
                        return (
                            <TableRow key={row.id + row.code}>
                                <StyledTableCell component="th" scope="row">
                                    <Typography
                                        color="primary"
                                        style={{ fontWeight: 'bold' }}
                                        aria-haspopup="true"
                                    >
                                        {row.name}
                                    </Typography>
                                    {/* <br />
                                    <Button
                                        onClick={() => openProduct(row)}
                                    >View Counties and Contract/PBPs
                                    </Button> */}
                                </StyledTableCell>
                                <StyledTableCell>{row.code}</StyledTableCell>
                                <StyledTableCell align="right">
                                    <IconButton
                                        onClick={() => onAddClicked(row.id, row.isKit, row.code, row.name, 1,
                                            { itemType: row.customFields.materialType, thumbnail: row.thumbnail })}
                                    >
                                        <AddShoppingCartIcon
                                            color="primary"
                                        />
                                    </IconButton>
                                </StyledTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
