import React from 'react';
import {
    MenuItem,
    Select, FormControl, InputLabel, TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function UsStateSelect({
    value, onChange, size, required, displayFullStateName = false
}) {
    const stateCodes = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS',
        'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH',
        'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

    const fullStateNames = [{'display': 'Alabama', 'value': 'AL'},{'display': 'Alaska', 'value': 'AK'}, {'display': 'Arizona', 'value': 'AZ'}, {'display': 'Arkansas', 'value': 'AR'}, {'display': 'California', 'value': 'CA'}, 
    {'display': 'Colorado', 'value': 'CO'}, {'display': 'Connecticut', 'value': 'CT'}, {'display': 'Delaware', 'value': 'DE'}, {'display': 'District of Columbia', 'value': 'DC'}, 
    {'display': 'Florida', 'value': 'FL'}, {'display': 'Georgia', 'value': 'GA'}, {'display': 'Hawaii', 'value': 'HI'}, {'display': 'Idaho', 'value': 'ID'}, 
    {'display': 'Illinois', 'value': 'IL'}, {'display': 'Indiana', 'value': 'IN'}, {'display': 'Iowa', 'value': 'IA'}, {'display': 'Kansas', 'value': 'KS'}, 
    {'display': 'Kentucky', 'value': 'KY'}, {'display': 'Louisiana', 'value': 'LA'}, {'display': 'Maine', 'value': 'ME'}, {'display': 'Maryland', 'value': 'MD'}, 
    {'display': 'Massachusetts', 'value': 'MA'}, {'display': 'Michigan', 'value': 'MI'}, {'display': 'Minnesota', 'value': 'MN'}, {'display': 'Mississippi', 'value': 'MS'}, 
    {'display': 'Missouri', 'value': 'MO'}, {'display': 'Montana', 'value': 'MT'}, {'display': 'Nebraska', 'value': 'NE'}, {'display': 'Nevada', 'value': 'NV'}, 
    {'display': 'New Hampshire', 'value': 'NH'}, {'display': 'New Jersey', 'value': 'NJ'}, {'display': 'New Mexico', 'value': 'NM'}, {'display': 'New York', 'value': 'NY'}, 
    {'display': 'North Carolina', 'value': 'NC'}, {'display': 'North Dakota', 'value': 'ND'}, {'display': 'Ohio', 'value': 'OH'}, {'display': 'Oklahoma', 'value': 'OK'}, 
    {'display': 'Oregon', 'value': 'OR'}, {'display': 'Pennsylvania', 'value': 'PA'}, {'display': 'Rhode Island', 'value': 'RI'}, {'display': 'South Carolina', 'value': 'SC'}, 
    {'display': 'South Dakota', 'value': 'SD'}, {'display': 'Tennessee', 'value': 'TN'}, {'display': 'Texas', 'value': 'TX'}, {'display': 'Utah', 'value': 'UT'}, 
    {'display': 'Vermont', 'value': 'VT'}, {'display': 'Virginia', 'value': 'VA'}, {'display': 'Washington', 'value': 'WA'}, {'display': 'West Virginia', 'value': 'WV'}, 
    {'display': 'Wisconsin', 'value': 'WI'}, {'display': 'Wyoming', 'value': 'WY'}
    ]

    let newVal = value;

    if(displayFullStateName && value && value.length > 0) {
        newVal = fullStateNames.find(x => x.value === value);
    }

    return (

        <FormControl
            fullWidth
            size={size || 'small'}
            variant="outlined"
            required={required}
        >
            <Autocomplete
                value={newVal || {}}
                options={displayFullStateName ? fullStateNames : stateCodes }
                getOptionLabel={(option) => { 
                    return option.display  ? option.display : "";
                }}
                onChange={(e, val) => { 
                    const target = displayFullStateName && val ? {target:{name:"state",value:val.value || ""}} : {target:{name:"state",value:val || ""}};
                    onChange(target); 
                }}
                renderInput={(params) => { 
                    return <TextField {...params} label="State" variant="outlined" required={required} /> 
                }}
                variant="outlined"
                size="small"
            />
        </FormControl>
    );
}
