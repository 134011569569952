import { Box, Grid, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AttachmentIcon from '@material-ui/icons/Attachment';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import fallbackSrc from 'utils/fallbackSrc';

export default function P2OrderView({ orderData }) {
    const { orderShipping, orderItem } = orderData;
    const { customFields: { basketItems, p2OBO, customFields: { contactInfo } } } = orderData;

    for (const s of orderShipping) {
        for (const si of s.orderShippingItem) {
            const bi = basketItems.find((b) => b.code === si.code);
            si.name = bi.name;
            si.materialType = bi.customFields.materialType;
            si.uploadFileName = bi.customFields.fileInfo ? bi.customFields.fileInfo.UploadFileName : '';
        }

        s.orderShippingItem.sort((i) => (i.materialType === 'kit' ? -1 : 1));
    }

    const showRoutedForApproval = orderData.orderStatus === 'Pending';

    return (
        <div className="order-confirm-view">

            {showRoutedForApproval && (
                <Box mt={1}>
                    <WarningIcon style={{ color: '#ff9800' }} />
                    <span>Your order has been routed for approval since it exceeds maximum.</span>
                </Box>
            )}
            {orderShipping.map((a, i) => (
                <Grid key={a.id} container spacing={3} style={{ marginTop: '2rem' }}>
                    {/* <Grid item xs={3}>
                        <Typography variant="h6">
                            Address {i + 1}
                        </Typography>
                    </Grid> */}
                    <Grid item xs={12}>
                        <span className="table-description">
                            <span>
                                {`Ship to: ${a.firstName} ${a.lastName} `}
                            </span>
                            {a.email
                                && <span className="email">{`<${a.email}>`}</span>}
                            {a.phone
                                && <span className="email">{a.phone ? ' | ' : ''}{`${a.phone}`}</span>}
                            {a.address
                                && <span className="email">{a.address ? ' | ' : ''}{`${a.address}`}</span>}
                            {a.address2
                                && <span className="email">{a.address2 ? ' | ' : ''}{`${a.address2}`}</span>}
                            <span>
                                {` | ${a.city} | ${a.state} ${a.zip} ${a.country || 'United States'}`}
                            </span>
                        </span>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid container spacing={3} style={{ borderTop: '4px #ddd solid', borderBottom: '1px #ddd solid', marginBottom: '1rem' }} className="table-title order-detail-item-list">
                                <Grid item xs={7}>
                                    Product
                                </Grid>
                                <Grid item xs={1}>
                                    Quantity
                                </Grid>
                                <Grid item xs={1}>
                                    Status
                                </Grid>
                                <Grid item xs={1}>
                                    Material Type
                                </Grid>
                                <Grid item xs={2}>
                                    Uploaded File
                                </Grid>
                            </Grid>
                            <Grid container className="table-content">
                                {
                                    a.orderShippingItem.map((c) => {
                                        let thumbnail;
                                        if (!c.thumbnail && orderItem) {
                                            c.thumbnail = orderItem.filter((x) => x.id === c.orderItemId).map((i) => {
                                                const customFields = JSON.parse(i.customFields);
                                                if (customFields) { thumbnail = customFields.thumbnail; }
                                            });
                                        }

                                        thumbnail = (c.thumbnail && !Array.isArray(c.thumbnail)) || thumbnail;

                                        return (
                                            <Grid className="order-detail-item-list" key={c.code} item xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={7} className="table-item order-detail-item-thumb">

                                                        <Grid container spacing={2}>
                                                            <Grid item xs={5}>
                                                                <img onError={fallbackSrc} src={thumbnail || '//cdndev.memberdoc.com/f/img_no_thumb.jpg'} alt={c.name} style={{ width: '150px' }} />
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                                <b>{c.name}</b> <br />
                                                                {c.code}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1} align="center" className="table-item">
                                                        {orderItem.find((x) => x.id === c.orderItemId).quantity}
                                                    </Grid>
                                                    <Grid item xs={1} className="table-item">
                                                        {orderData.orderStatus}
                                                    </Grid>
                                                    <Grid item xs={1} className="table-item">
                                                        {c.materialType.toUpperCase()}
                                                    </Grid>
                                                    <Grid item xs={2} className="table-item">
                                                        {c.uploadFileName && (<p><AttachmentIcon color="primary" />{c.uploadFileName}</p>)}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            ))}

            {(p2OBO || contactInfo) && (
                <Grid container spacing={2} className="order-detail-contact">
                    {p2OBO && (
                        <Grid item xs={6}>
                            <Typography style={{ flex: '1 1 100%' }} variant="h6" component="div">
                                Ordering on Behalf of
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table aria-label="Ordering on behalf of">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>First Name</TableCell>
                                            <TableCell>Last Name</TableCell>
                                            <TableCell>Agent Writing Number</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Phone</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableCell>{p2OBO.firstName}</TableCell>
                                        <TableCell>{p2OBO.lastName}</TableCell>
                                        <TableCell>{p2OBO.agentWritingNumber}</TableCell>
                                        <TableCell>{p2OBO.email}</TableCell>
                                        <TableCell>{p2OBO.phone}</TableCell>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}

                    {contactInfo && (
                        <Grid item xs={6}>
                            <Typography style={{ flex: '1 1 100%' }} variant="h6" component="div">
                                Contact Info
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table aria-label="Ordering on behalf of">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>First Name</TableCell>
                                            <TableCell>Last Name</TableCell>
                                            <TableCell>Agent Writing Number</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Phone</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableCell>{contactInfo.firstName}</TableCell>
                                        <TableCell>{contactInfo.lastName}</TableCell>
                                        <TableCell>{contactInfo.agentWritingNumber}</TableCell>
                                        <TableCell>{contactInfo.email}</TableCell>
                                        <TableCell>{contactInfo.phone}</TableCell>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}

                </Grid>

            )}
        </div>
    );
}
