import {
    Button,

    Checkbox,

    CircularProgress,

    FormControl, Grid,

    IconButton, InputAdornment,

    InputLabel, MenuItem, Paper,
    Select, Table,
    TableBody,
    TableCell,
    TableContainer,

    TableHead,
    TableRow, TextField
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import http from 'utils/http';
import { setKitItems } from '../../action';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 960,
        minHeight: 320,
        maxHeight: 600,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    }
}));

const FindItemModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);
    const [itemCode, setItemCode] = useState('');
    const [itemName, setItemName] = useState('');
    const [whs, setWHS] = useState('');
    const [itemType, setItemType] = useState('');
    const [foundItems, setFoundItems] = useState([]);
    const [modalStyle] = React.useState(getModalStyle);
    const {
        open, handleClose, needQty, jobItems, itemTypes, customer, orderingOnHoldItem, userType, whs: whsItems
    } = props;

    const { lob: lobQuery, jobType: jobTypeQuery } = useParams();
    let { lob, jobType } = props;
    lob = lob || lobQuery;
    jobType = jobType || jobTypeQuery;
    const singleKitOnly = lobQuery === 'p1b' && (jobType === 'AJEKT' || jobType === 'IM'); // 1 kit only
    const keepSingleKit = lobQuery === 'p1b' && jobType === 'AJEPP'; // 1 kit + x items

    let onlyWHS = props.onlyWhs || '';
    if (lob === 'cml' && (jobType === 'AJEKT' || jobType === 'IM') && jobItems && jobItems.length > 0) {
        const w = jobItems.find((j) => !!j.whs);
        if (w) onlyWHS = w.whs;
    }

    const classes = useStyles();

    const handleSearch = async () => {
        if (!open) return;
        setLoading(true);
        setSelected([]);
        const formData = new FormData();
        formData.append('lob', lob);
        formData.append('jobType', jobType);
        formData.append('itemCode', itemCode);
        formData.append('itemName', itemName);
        formData.append('itemType', itemType);
        formData.append('userType', userType);
        formData.append('whs', onlyWHS || whs);
        formData.append('state', props.onlyState || props.state);
        formData.append('orderingOnHoldItem', orderingOnHoldItem ? 'Y' : 'N');
        if (lobQuery === 'p1b') {
            if (customer) formData.append('sponsor', customer.customerName);
            if (jobType === 'AJEKT' || jobType === 'IM') {
                formData.append('kitOnly', 'yes');
            }
        }
        const found = await http.post('direct-order/find-items', formData);

        if (jobType !== 'AJEPP' && lob === 'cml') { // qty limit only works in cml/pp (at least for now)
            for (const item of found) {
                item.qtyLimit = null;
            }
        }

        if (props.p1afsrLimit && lobQuery === 'p1a' && userType === 'fsr') {
            for (const item of found) {
                let limit = props.p1afsrLimit.others;
                if (item.isKit) {
                    limit = props.p1afsrLimit.kits;
                } else if (props.p1afsrLimit[item.type.toLowerCase()]) {
                    limit = props.p1afsrLimit[item.type.toLowerCase()];
                }
                item.max = limit;
            }
        }

        setFoundItems(found);
        setLoading(false);
    };

    const handleAdd = (close) => {
        const items = foundItems.filter((i) => selected.includes(i.id));
        for (const fi of items) {
            fi.selected = true;
            if (needQty) fi.quantity = 1;
        }
        if (singleKitOnly) {
            props.setKitItems(items);

            if (close) {
                setSelected([]);
                handleClose();
            }
            return;
        }
        if (jobItems && jobItems.length) {
            let lastSelectedIndex = 0;
            for (let i = 0; i < jobItems.length; i += 1) {
                if (jobItems[i].selected) { lastSelectedIndex = i; } else { break; }
            }
            jobItems.splice(lastSelectedIndex + 1, 0, ...items);
            for (let i = 0; i < jobItems.length; i += 1) {
                jobItems[i].sequence = i;
            }

            let newItems = [...jobItems];
            if (keepSingleKit) {
                const firstK = newItems.find((k) => k.isKit);
                if (firstK) {
                    newItems = newItems.filter((n) => !n.isKit || n.id === firstK.id);
                }
            }

            if (jobType === 'FJE' && props.msoFJEJobItemLimit
                && newItems.length > props.msoFJEJobItemLimit) {
                newItems = newItems.slice(0, props.msoFJEJobItemLimit);
            }
            props.setKitItems(newItems);
        } else {
            for (let i = 0; i < items.length; i += 1) {
                items[i].sequence = i;
            }

            let newItems = [...items];
            if (keepSingleKit) {
                const firstK = newItems.find((k) => k.isKit);
                if (firstK) {
                    newItems = newItems.filter((n) => !n.isKit || n.id === firstK.id);
                }
            }

            if (jobType === 'FJE' && props.msoFJEJobItemLimit
                && newItems.length > props.msoFJEJobItemLimit) {
                newItems = newItems.slice(0, props.msoFJEJobItemLimit);
            }
            props.setKitItems(newItems);
        }

        if (close) {
            setSelected([]);
            handleClose();
        }
    };

    const handleToggleCheck = (id) => {
        if (selected.includes(id)) {
            const rest = selected.filter((s) => s !== id);
            setSelected([...rest]);
        } else if (singleKitOnly) {
            setSelected([id]);
        } else {
            selected.push(id);
            setSelected([...selected]);
        }
    };
    let itemTypeOptions;
    if (itemTypes && itemTypes.length) {
        itemTypeOptions = itemTypes;
    } else {
        itemTypeOptions = [{ code: 'BS', name: 'Benefit Summary' }, { code: 'EF', name: 'Enrollment Form' }];
    }

    let maxItemCountReached = false;
    const { msoFJEJobItemLimit } = props;
    if (jobType === 'FJE' && msoFJEJobItemLimit && ((jobItems || []).length + selected.length) === msoFJEJobItemLimit) {
        maxItemCountReached = true;
    }

    return (
        <Modal
            open={open}
            onClose={() => {
                setFoundItems([]);
                handleClose();
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <TableContainer component={Paper} style={{ maxHeight: '400px' }}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow hover>
                                <TableCell />
                                <TableCell>
                                    <TextField
                                        label="Item Code"
                                        autoFocus
                                        value={itemCode}
                                        onKeyPress={(e) => { if (e.key === 'Enter') { handleSearch(); } }}
                                        onChange={(e) => setItemCode(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton aria-label="search" onClick={handleSearch}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </TableCell>
                                <TableCell>Version</TableCell>
                                <TableCell>
                                    {
                                        lob === 'OE' || (lob === 'cml' && jobType === 'AJEPP')
                                            ? <span>WHS</span>
                                            : (
                                                <FormControl
                                                    fullWidth
                                                    size="small"
                                                >
                                                    <InputLabel>WHS</InputLabel>
                                                    <Select
                                                        label="WHS"
                                                        value={onlyWHS || whs}
                                                        disabled={!!onlyWHS}
                                                        onChange={(e) => setWHS(e.target.value)}
                                                    >
                                                        <MenuItem value="">
                                                            <em>Select</em>
                                                        </MenuItem>
                                                        {(whsItems || []).map((w) => <MenuItem key={w} value={w}>{w}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            )
                                    }

                                </TableCell>
                                <TableCell>INV</TableCell>
                                <TableCell>
                                    <TextField
                                        label="Item Name"
                                        value={itemName}
                                        onKeyPress={(e) => { if (e.key === 'Enter') { handleSearch(); } }}
                                        onChange={(e) => setItemName(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton aria-label="search" onClick={handleSearch}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={itemType}
                                        onChange={(e) => setItemType(e.target.value)}
                                        fullWidth
                                        label="Item Type"
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        {itemTypeOptions.map((t) => <MenuItem key={t.code || t} value={t.code || t}>{t.name || t}</MenuItem>)}

                                    </Select>
                                </TableCell>
                                <TableCell>DP</TableCell>
                                <TableCell>VP</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ minHeight: '50rem' }}>
                            {loading && (
                                <TableRow>
                                    <TableCell
                                        colSpan={20}
                                        style={{ textAlign: 'center', padding: '1rem' }}
                                    >
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!loading && foundItems && foundItems.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={20}>
                                        <span style={{ color: '#999', fontWeight: 'bold', fontSize: '1.2rem' }}> No Items</span>
                                    </TableCell>

                                </TableRow>
                            )}
                            {!loading && foundItems && foundItems.length > 0 && foundItems.map((row) => {
                                const checked = selected.includes(row.id);
                                const itemDisabled = (maxItemCountReached && !checked)
                                    || (jobItems && jobItems.some((ji) => ji.id === row.id));
                                return (
                                    <TableRow
                                        key={row.id}
                                    >
                                        <TableCell scope="row">
                                            <Checkbox
                                                color="primary"
                                                disabled={itemDisabled}
                                                onClick={() => handleToggleCheck(row.id)}
                                                checked={checked}
                                            />
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.code}
                                        </TableCell>
                                        <TableCell scope="row" align="center">
                                            {row.version}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.whs}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.dp === 'Y' || ((lob === 'p1a' || lob === 'p1b') && row.isKit) ? 'N/A' : (row.inventoryQuantity !== undefined ? Math.max(0, row.inventoryQuantity) : '')}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.type}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.dp}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.vp}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {foundItems && foundItems.length > 0 && (

                    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                        {!singleKitOnly && (
                            <Grid item xs={3}>
                                <Button
                                    variant="contained"
                                    onClick={() => handleAdd(false)}
                                >
                                    Add Item/Stay
                                </Button>
                            </Grid>
                        )}
                        <Grid item xs={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleAdd(true)}
                                className={classes.button}
                            >
                                Add Item/Close
                            </Button>
                        </Grid>
                    </Grid>

                )}
            </div>
        </Modal>
    );
};

const mapStateToProps = ({
    directOrderView: {
        stepView: {
            jobItems, itemTypes, customer, whs
        }
    }
}) => ({
    jobItems, itemTypes, customer, whs
});
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { setKitItems },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FindItemModal);
