import actionCreator from 'utils/actionCreator';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('PLAN_SPONSOR_VIEW', {
    LOADING: null,
    SAVING: null,

    SET_PLAN_SPONSOR: null,
    SET_PLAN_SPONSORS: null
});

export default actions;

const setLoading = actionCreator(actions.LOADING, 'loading');
const setSaving = actionCreator(actions.SAVING, 'saving');

export const setPlanSponsor = actionCreator(actions.SET_PLAN_SPONSOR, 'planSponsor');
const setPlanSponsors = actionCreator(actions.SET_PLAN_SPONSORS, 'planSponsors');
export const getPlanSponsors = (caseId, caseName, pageNumber, pageSize) => (dispatch) => {
    dispatch(setLoading(true));
    const fd = new FormData();
    fd.append('caseId', caseId);
    fd.append('caseName', caseName);
    http.post(`su/plan-sponsors/${pageNumber}/${pageSize}`, fd)
        .then((data) => dispatch(setPlanSponsors(data)))
        .finally(() => dispatch(setLoading(false)));
};

export const savePlanSponsor = (planSponsor, cb) => (dispatch) => {
    dispatch(setSaving(true));
    http.post('su/save-plan-sponsor', planSponsor).then((a) => {
        cb();
    }).finally(() => dispatch(setSaving(false)));
};

export const deletePlanSponsor = (planSponsorId, cb) => (dispatch) => {
    dispatch(setSaving(true));
    http.post(`su/delete-plan-sponsor/${planSponsorId}`).then((a) => {
        cb();
    }).finally(() => dispatch(setSaving(false)));
};
