import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {
    Button, Grid, TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Checkbox,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import http from 'utils/http';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 600,
        minHeight: 220,
        maxHeight: 600,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    }
}));

const ArrivalsModal = (props) => {
    const [keyword, setKeyword] = useState('');
    const [data, setData] = useState({});
    const [updateToggle, setUpdateToggle] = useState(false);
    const [modalStyle] = React.useState(getModalStyle);
    const [snackOpen, setSnackOpen] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(true);
    const {
        open, handleClose
    } = props;

    useEffect(() => {
        async function getProfile() {
            http.get('p2/arrivals')
                .then((d) => setData(d));
        }

        if(open) {
            getProfile();
        }
    }, [updateToggle, open]);

    const classes = useStyles();

    const publish = async (row) => {
        await http.put(`p2/arrival/${row.itemCrosswalkId}`)
            .then(() => {
                setUpdateStatus(true);
            })
            .catch(() => {
                setUpdateStatus(false);
            });

        setUpdateToggle(!updateToggle);
    };

    const closeSnack = () => {
        setSnackOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <TableContainer component={Paper} style={{ marginTop: '2rem' }}>
                    <Table size="small" aria-label="simple table" stickyHeader style={{ maxHeight: '800px' }}>
                        <TableHead>
                            <TableRow hover>
                                <TableCell>Item Crosswalk Id</TableCell>
                                <TableCell>Component Item Number</TableCell>
                                <TableCell></TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ minHeight: '50rem' }}>
                            {data && data.length > 0 && data.map((row) => (
                                <TableRow key={row.id} hover>
                                    <TableCell>{row.itemCrosswalkId}</TableCell>
                                    <TableCell>{row.componentItemNumber}</TableCell>
                                    <TableCell>
                                        <Button e="small"
                                            color="primary"
                                            variant="outlined" 
                                            onClick={() => publish(row)}>Publish</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Snackbar open={snackOpen} autoHideDuration={5000} onClose={closeSnack}>
                <Alert onClose={closeSnack} severity={updateStatus ? "success" : "error"}>
                    {updateStatus ? "Arrival has been Published" : "Arrival Publish Failed"}
                </Alert>
            </Snackbar>
            </div>
        </Modal>
    );
};
export default ArrivalsModal;