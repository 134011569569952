import { setUserInfo } from 'pages/login/action';
import actionCreator from 'utils/actionCreator';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('ADDRESSES_VIEW', {
    LOADING: null,

    SET_MESSAGE: null
});

export default actions;

const setLoading = actionCreator(actions.LOADING, 'loading');

const setMessage = actionCreator(actions.SET_MESSAGE, 'message');
export const submitChange = (oldPassword, newPassword) => (dispatch, getState) => {
    const { user } = getState().appView;
    dispatch(setMessage(''));
    dispatch(setLoading(true));
    const f = new FormData();
    f.append('oldPassword', oldPassword);
    f.append('newPassword', newPassword);
    http.post('customer/change-password', f)
        .then((data) => {
            dispatch(setMessage(data));
            dispatch(setUserInfo({
                ...user,
                customFields: {
                    ...user.customFields,
                    isPasswordChangeRequired: false
                }
            }));
        })
        .finally(() => dispatch(setLoading(false)));
};
