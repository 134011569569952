import {
    Box, Button, ButtonGroup, Container, FormControl, Grid,
    InputLabel, MenuItem, Paper, Select, TextField,
    Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { PureComponent } from 'react';
import http from 'utils/http';
import BasketItemsView from './BasketItemsView';
import SingleAddressView from './SingleAddressView';

export default class BrokerView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            additionalReason: '',

            inputAddress: { shippingMethodCode: 'Standard' },
            validating: false,
            contactInfo: null,
            showAddressCorrectedDialog: false
        };
    }

    componentDidMount() {
        const { contactInfo } = this.state;
        const { user } = this.props;
        if (contactInfo === null) {
            this.setState({
                contactInfo: {
                    line1: user.customFields.name,
                    line2: user.customFields.phone
                }
            });
        }
    }

    changeAdditionalReason = (e) => {
        const additionalReason = e.target.value;
        this.setState({ additionalReason });
    }

    onInputAddressChange = (inputAddress) => {
        this.setState({ inputAddress });
    }

    onReasonChange = (reason) => {
        this.setState({ reason });
    }

    onFieldChange = (e) => {
        const { contactInfo } = this.state;
        this.setState({
            contactInfo: {
                ...contactInfo,
                [e.target.name]: e.target.value
            }
        });
    };

    getExceeded = () => {
        const {
            used, basketItems
        } = this.props;
        let exceededMonthlyLimit = false;
        if (basketItems && used) {
            for (const item of basketItems) {
                if ((used[item.code] || 0) + item.quantity > item.customFields.limit) {
                    exceededMonthlyLimit = true;
                }
            }
        }

        return exceededMonthlyLimit;
    }

    onSubmitOrder = () => {
        const { submitOrder } = this.props;
        this.setState({ validating: true });
        const { inputAddress, contactInfo } = this.state;
        const { showMessage } = this.props;
        if (inputAddress && inputAddress.address) {
            http.post('store/validate-address', {
                Address1: inputAddress.address,
                Address2: inputAddress.address2,
                City: inputAddress.city,
                State: inputAddress.state,
                Zipcode: inputAddress.zip,
                Country: inputAddress.country
            }).then((r) => {
                // {"StatusMessage":"Not Found","Success":false,"POBox":false}
                const isValid = r.Success && !r.POBox;
                if (isValid) {
                    if (inputAddress.address !== r.Address1
                        || (!!inputAddress.address2 && !!r.Address2 && inputAddress.address2 !== r.Address2)
                        || inputAddress.city !== r.City
                        || inputAddress.state !== r.State
                        || inputAddress.zip !== r.Zipcode
                    ) {
                        inputAddress.address = r.Address1;
                        inputAddress.address2 = r.Address2;
                        inputAddress.city = r.City;
                        inputAddress.state = r.State;
                        inputAddress.zip = r.Zipcode;

                        this.setState({ inputAddress: { ...inputAddress }, showAddressCorrectedDialog: true });

                        return;
                    }

                    const { used } = this.props;
                    const exceeded = this.getExceeded();
                    const { reason: reasonType, additionalReason } = this.state;
                    const reason = reasonType === 'Other' ? additionalReason : reasonType;
                    submitOrder('', reason, used, inputAddress, contactInfo);
                } else if (r.POBox) {
                    showMessage('PO Box is not allowed', 'warning');
                } else {
                    showMessage('Invalid address', 'warning');
                }
            }).finally(() => this.setState({ validating: false }));
        }
    }

    render() {
        const { basketItems, showMessage } = this.props;
        const exceeded = this.getExceeded();
        const {
            inputAddress, validating, reason, additionalReason, contactInfo, showAddressCorrectedDialog
        } = this.state;

        const isAddressFilled = !!(inputAddress.firstName && inputAddress.lastName && inputAddress.address && inputAddress.email
            && inputAddress.city && inputAddress.state && inputAddress.zip && inputAddress.country);

        return (
            <Container fixed className="shop-cart-view">
                <Box component="div" mb={4}>
                    <Typography variant="h3">
                        CHECKOUT
                    </Typography>
                </Box>
                <Box>
                    {basketItems && basketItems.length > 0 && <BasketItemsView hasQty basketItems={basketItems} />}
                </Box>
                <Box mt={2} mb={2} p={2} component={Paper}>
                    <SingleAddressView
                        addr={inputAddress}
                        disableShippingMethod
                        onAddressChange={this.onInputAddressChange}
                        showMessage={showMessage}
                        emailRequired
                    />
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box>
                            <Typography variant="h6">
                                Contact Information
                            </Typography>
                        </Box>
                        <Grid container spacing={1} style={{ marginTop: '1rem' }} className="row-item">
                            <Grid item xs={12}>
                                <TextField
                                    label="Line 1"
                                    name="line1"
                                    fullWidth
                                    value={contactInfo ? contactInfo.line1 : ''}
                                    onChange={this.onFieldChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Line 2"
                                    name="line2"
                                    fullWidth
                                    value={contactInfo ? contactInfo.line2 : ''}
                                    onChange={this.onFieldChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={6}>
                        {exceeded && (
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        Routing justification
                                    </Typography>
                                    <Typography variant="body1">
                                        Thank you for your interest in Aetna Medicare Products. Your order exceeds the monthly allocation limit for this item. During the checkout process please explain your need for additional supplies and your local sales team will review the order. Thank you!
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined">
                                        <InputLabel>Select Reason *</InputLabel>

                                        <Select
                                            required
                                            value={reason}
                                            size="small"
                                            style={{ width: '15rem' }}
                                            label="Select Reason"
                                            onChange={(e) => this.onReasonChange(e.target.value)}
                                        >
                                            <MenuItem value="">
                                                <em>Select</em>
                                            </MenuItem>
                                            <MenuItem value="Community or large event">
                                                Community or large event
                                            </MenuItem>
                                            <MenuItem value="Replenish stock">
                                                Replenish stock
                                            </MenuItem>
                                            <MenuItem value="Initial stock not received">
                                                Initial stock not received
                                            </MenuItem>
                                            <MenuItem value="Other">
                                                Other...
                                            </MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>
                                {reason === 'Other'
                                    && (
                                        <Grid item xs={12}>

                                            <TextField
                                                label="Please provide a justification to support the above reason(s)"
                                                multiline
                                                rows={4}
                                                fullWidth
                                                required
                                                value={additionalReason}
                                                onChange={this.changeAdditionalReason}
                                                variant="outlined"
                                            />
                                        </Grid>

                                    )}
                            </Grid>
                        )}
                    </Grid>

                </Grid>

                <Box>
                    <ButtonGroup className="list-btn-group">
                        <Button
                            className="btn place-order-btn"
                            disabled={validating || !isAddressFilled || (exceeded && !reason)}
                            variant="contained"
                            color="primary"
                            onClick={this.onSubmitOrder}
                        >
                            Place Order
                        </Button>
                    </ButtonGroup>
                </Box>

                <Dialog open={showAddressCorrectedDialog} onClose={() => this.setState({ showAddressCorrectedDialog: false })} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Address Corrected</DialogTitle>
                    <DialogContent>
                        The address you entered has been corrected.
                    </DialogContent>
                </Dialog>
            </Container>
        );
    }
}
