import {
    Box, Breadcrumbs, Button, CircularProgress,
    Divider, Grid,
    Link, TextField, Typography, IconButton
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CreateCustomizeComponent from 'components/customize/CreateCustomizeComponent';
import Pricing from 'components/Pricing';
import React, { PureComponent, Suspense } from 'react';
import history from 'utils/history';
import MaterialMedia from './MaterialMedia';
import OEOutputType from './OEOutputType';
import P2Items from './P2Items';
import FavoriteIcon from '@material-ui/icons/Favorite';

export default class DetailView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            qty: 1,
            maximum: null,

            outputType: 'Digital Only',

            fileName: null,
            fileData: null,

            openPricing: false,
            generating: false
        };
    }

    componentDidMount() {
        const {
            productCode, getProduct: getP, category, lineId, p2_id, product_type,
            getCrosswalk
        } = this.props;
        if (productCode) getP(productCode, category, p2_id, product_type);
        if (lineId) {
            getCrosswalk(lineId);
        }
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { productCode: oldCode, getProduct: getP, category } = this.props;
        const { productCode: newCode, productDetail } = nextProps;
        const { qty } = this.state;
        if (oldCode !== newCode && newCode) {
            getP(newCode, category);
        } else if (productDetail
            && productDetail.minQty
            && productDetail.minQty > qty) {
            this.setState({ qty: productDetail.minQty });
        }

        if (productDetail && productDetail.program === 'OE') {
            if (productDetail.customFields.disableDigital) {
                this.setState({ outputType: 'Print Only' });
            } else if (productDetail.customFields.disablePrint) {
                this.setState({ outputType: 'Digital Only', maximum: 1, qty: 1 });
            }
        }
    }

    componentWillUnmount() {
        const { setProduct } = this.props;
        setProduct(null);
    }

    showPricing = () => {
        this.setState({ openPricing: true });
    }

    hidePricing = () => {
        this.setState({ openPricing: false });
    }

    updateQty = (e) => {
        if (!e.target.value) {
            this.setState({ qty: '' });
            return;
        }
        const { outputType } = this.state;
        let { maximum } = this.state;
        let minQty = 1;
        const { productDetail } = this.props;
        if (productDetail.minQty) minQty = productDetail.minQty;

        if (productDetail.program === 'OE' && outputType === 'Digital Only') {
            minQty = 1;
            maximum = 1;
        }
        const qty = parseInt(e.target.value, 10);
        
        if(qty < 1) {
            this.setState({minQty});
        } else {
            this.setState({ qty });
        }

        // if (qty >= minQty && (!maximum || maximum >= qty)) {
        //     this.setState({ qty });
        // }
    }

    validateQty = () => {
        const { outputType } = this.state;
        let { maximum, qty } = this.state;
        let minQty = 1;
        const { productDetail } = this.props;
        if (productDetail.minQty) minQty = productDetail.minQty;

        if (productDetail.program === 'OE' && outputType === 'Digital Only') {
            minQty = 1;
            maximum = 1;
        }

        if (qty >= minQty && (!maximum || maximum >= qty)) {
            this.setState({ qty });
        }
        if (qty < minQty) {
            this.setState({ qty: minQty });
        } else if (maximum && qty > maximum) {
            this.setState({ qty: maximum });
        }
    }

    onOutputTypeChange = (e) => {
        this.setState({ outputType: e.target.value });
        const { qty } = this.state;
        const { productDetail, setNotReadyForCart } = this.props;
        if (e.target.value === 'Digital Only') {
            this.setState({ qty: 1 });
        } else if (qty < productDetail.minQty) {
            this.setState({ qty: productDetail.minQty });
        }
        setNotReadyForCart(true);
    }

    createMarkup = (cf) => {
        if (cf && cf.description) {
            return { __html: cf.description };
        } else {
            return { __html: '' };
        }
    }

    goBack = (e) => {
        e.preventDefault();
        history.goBack();
        return false;
    }

    setFile = (fileName, fileData) => {
        this.setState({ fileName, fileData });
    }

    /**
     * Add item to Cart
     *
     * @memberof DetailView
     */
    addToCart = () => {
        const {
            newThumbnail,
            productDetail: {
                id, isKit, code, name, thumbnail, program, sample, customFields: cf, minQty
            },
            addProductToCart: add,
            crosswalk, category, history: { location: { state } }
        } = this.props;
        const savedId = state && state.savedJob ? state.savedJob.savedId : null;
        const {
            qty, outputType, fileName, fileData
        } = this.state;
        const needQty = this.isQtyNeeded();
        const customFields = {
            thumbnail: newThumbnail || thumbnail,
            sample,
            crosswalk,
            program,
            category,
            minQty,
            needQty,
            savedId,
            materialType: cf.materialType,
            marketSegment: (crosswalk && crosswalk.marketSegment) ? crosswalk.marketSegment : null,
            items: program === "P2" && cf.items ? JSON.stringify(cf.items) : undefined
        };
        if (cf && cf.customizeCode) {
            customFields.customizeCode = cf.customizeCode;
        }

        if (program === 'OE') {
            customFields.outputType = outputType;
            if (crosswalk) {
                customFields.proofFileId = crosswalk.proofFileId;
            }
        }

        if(program === "P2") {
            customFields.itemLimit = cf.itemLimit;
        }

        const finalQty = needQty ? qty : 1;
        add({
            id, isKit, code, name, qty: finalQty, customFields, fileName, fileData
        });
    }

    isQtyNeeded = () => {
        let needed = true;
        const {
            productDetail
        } = this.props;
        const { outputType } = this.state;
        if (productDetail && productDetail.program === 'OE') {
            needed = outputType !== 'Digital Only';
        }
        return needed;
    }

    setGenerating = (generating) => {
        this.setState({ generating });
    }

    render() {
        const {
            productData, notReadyForCart, newThumbnail, editingCustomFields, lineId, history: { location }, history, addProductToCart, user, favorites,
            addToFavorites, removeFromFavorites
        } = this.props;
        let { productDetail, product } = this.props;
        if (productData && !productDetail) {
            productDetail = productData;
        }
        const {
            qty, outputType, openPricing, generating
        } = this.state;
        const showQty = this.isQtyNeeded();
        const CustomizeComponent = productDetail
            && productDetail.customFields
            && productDetail.customFields.customizeCode
            && CreateCustomizeComponent(productDetail.customFields.customizeCode);

        let disableAddToCart = notReadyForCart;
        if (productDetail && productDetail.program === 'OE' && !productDetail.customFields.customizeCode) {
            disableAddToCart = false;
        }

        const savedJob = location && location.state && location.state.savedJob;

        const customFieldItems = productDetail
            && productDetail.customFields
            && productDetail.customFields.items
            ? [...productDetail.customFields.items]
            : [];

        if (customFieldItems) {
            customFieldItems.thumbNail = product && product.thumbnail;
        }

        return (
            <Grid container spacing={3} style={{ marginBottom: '4rem' }}>
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="/catalog" onClick={this.goBack}>
                            {'<< Back'}
                        </Link>
                        <Typography color="textPrimary">{productDetail && productDetail.name}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item align="center" xs={6}>
                    {productDetail
                        ? <MaterialMedia newThumbnail={newThumbnail} productDetail={productDetail || product} user={user} />
                        : <CircularProgress />}
                </Grid>
                <Grid item xs={6}>
                    {productDetail && (
                        <>
                            <Typography variant="h4">
                                {productDetail.name}
                                {productDetail.program === 'P2' ? <IconButton color="primary" onClick={() => {
                                    favorites && favorites.includes(productDetail.code)
                                        ? removeFromFavorites(productDetail.code)
                                        : addToFavorites(productDetail.code)
                                }}>
                                    {favorites && favorites.includes(productDetail.code)
                                        ? <FavoriteIcon style={{ color: 'red' }} />
                                        : <FavoriteIcon style={{ color: 'black' }} />}
                                </IconButton>
                                    : null}
                            </Typography>
                            {
                                productDetail.customFields && productDetail.customFields.description && (
                                    <>
                                        <Divider color="" style={{ height: '.2rem', marginTop: '1rem', marginBottom: '1rem' }} />
                                        <Typography variant="h6">
                                            Information
                                        </Typography>
                                        {/* eslint-disable-next-line react/no-danger */}
                                        <div dangerouslySetInnerHTML={this.createMarkup(productDetail.customFields)} />

                                    </>
                                )
                            }
                            <Divider color="" style={{ height: '.2rem', marginTop: '1rem', marginBottom: '1rem' }} />
                            {
                                productDetail.program === 'P2'
                                && (
                                    <P2Items
                                        user={user}
                                        downloadRoot={productDetail.customFields.downloadRoot}
                                        setFile={this.setFile}
                                        id={productDetail.id}
                                        code={productDetail.code}
                                        addProductToCart={addProductToCart}
                                        itemType={productDetail.customFields.materialType.toUpperCase()}
                                        items={productDetail.customFields.items}
                                        productDetail={productDetail}
                                        outputType={outputType}
                                        onOutputTypeChange={this.onOutputTypeChange}
                                        showQty={showQty}
                                        quantity={qty}
                                        quantityOnChange={this.updateQty}
                                        quantityOnBlur={this.validateQty}
                                        showPricing={this.showPricing}
                                        openPricing={openPricing}
                                        addToCart={this.addToCart}
                                        disableAddToCart={disableAddToCart}
                                        hidePricing={this.hidePricing}
                                        goBack={() => history.goBack()}
                                    />
                                )
                            }
                            {
                                productDetail.program === 'OE'
                                && productDetail.customFields.customizeCode
                                && (!lineId || editingCustomFields)
                                && (
                                    <Box mt={2} mb={2}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <Box component="section" p={2}>
                                                <CustomizeComponent
                                                    user={user}
                                                    outputType={outputType}
                                                    customizeCode={productDetail.customFields.customizeCode}
                                                    crosswalk={savedJob}
                                                    lineId={lineId}
                                                    setGenerating={this.setGenerating}
                                                />
                                            </Box>
                                        </Suspense>
                                    </Box>
                                )
                            }
                            {
                                productDetail.program !== 'P2' && (

                                    <Grid
                                        container
                                        spacing={4}
                                        justify="space-between"
                                        alignItems="center"
                                    >
                                        <Grid item xs={6}>
                                            {productDetail.program === 'OE' && (
                                                <OEOutputType
                                                    outputType={outputType}
                                                    onOutputTypeChange={this.onOutputTypeChange}
                                                    disableDigital={productDetail.customFields.disableDigital}
                                                    disablePrint={productDetail.customFields.disablePrint}
                                                    disabled={generating}
                                                />
                                            )}
                                            {showQty && (
                                                <TextField
                                                    id="outlined-qty"
                                                    label="Qty"
                                                    size="small"
                                                    type="number"
                                                    min={productDetail.minQty || 1}
                                                    style={{ width: '6rem' }}
                                                    value={qty}
                                                    onChange={this.updateQty}
                                                    onBlur={this.validateQty}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    variant="outlined"
                                                />
                                            )}
                                        </Grid>

                                        <Grid item xs={6}>
                                            {productDetail.program === 'OE' && productDetail.customFields.pricing && (
                                                <>
                                                    <Button
                                                        type="button"
                                                        size="large"
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={this.showPricing}
                                                    >
                                                        <AttachMoneyIcon /> Pricing
                                                    </Button>
                                                    <Pricing open={openPricing} onClose={this.hidePricing} priceData={productDetail.customFields.pricing} />
                                                </>
                                            )}
                                            <Button
                                                type="button"
                                                size="large"
                                                variant="contained"
                                                color="primary"
                                                onClick={this.addToCart}
                                                disabled={disableAddToCart}
                                                style={{ float: 'right' }}
                                            >
                                                ADD TO CART
                                            </Button>
                                        </Grid>
                                    </Grid>

                                )
                            }

                        </>
                    )}
                </Grid>
            </Grid>
        );
    }
}
