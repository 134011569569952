import { CircularProgress, Grid, TablePagination } from '@material-ui/core';
import ProductCard from 'components/ProductCard';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from 'utils/history';
import { getProducts, searchP2 } from './action';
import P2SearchBar from './P2SearchBar';
import QuickviewModal from './QuickviewModal';
import {
    addToFavorites,
    removeFromFavorites
} from './action';

const pageSize = 24;

class ListView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            pageNumber: 1,
            // viewMode: 'table',
            openQuickView: false,
            quickviewPDF: null,

            itemCode: '',
            stateCode: ''
        };
    }

    componentDidMount() {
        const { pageNumber } = this.state;
        const { categoryCode: currentCategory, getProducts: loadProducts } = this.props;
        if (currentCategory) { loadProducts(currentCategory, pageNumber, pageSize); }
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { refresh: oldRefresh, categoryCode: oldCategory, getProducts: loadProducts } = this.props;
        const { refresh: newRefresh, categoryCode: newCategory } = nextProps;
        if (oldCategory !== newCategory || oldRefresh !== newRefresh) {
            this.setState({ pageNumber: 1 });
            loadProducts(newCategory, 1, pageSize);
        }
    }

    handleChangePage = (event, pageNumber) => {
        const {
            categoryCode: currentCategory, getProducts: loadProducts, searching, searchP2
        } = this.props;
        if (searching) {
            const { itemCode, stateCode } = this.state;
            searchP2(itemCode, stateCode, pageNumber + 1, pageSize);
        } else {
            loadProducts(currentCategory, pageNumber + 1, pageSize);
        }
    }

    onProductClick = (code, product) => {
        const { categoryCode, user } = this.props;
        if (user && user.customFields && user.customFields.p2_Catalog) {
            history.push(`/catalog/${categoryCode}/${(product.customFields.productType || product.customFields.materialType)}/${code}/${product.id}`);
        } else {
            history.push(`/catalog/${categoryCode}/${code}`);
        }
    }

    openQuickViewClick = (code) => {
        const {
            products, user, searching, searchResult
        } = this.props;
        let quickviewPDF = (searching ? searchResult : products).results.find((p) => p.code === code).sample;
        if (user && user.customFields && user.customFields.p2_Catalog) {
            quickviewPDF = `${quickviewPDF}?Authorization=${user.token}`;
        }
        this.setState({ openQuickView: true, quickviewPDF });
    }

    closeQuickView = () => {
        this.setState({ openQuickView: false, quickviewPDF: null });
    }

    searchP2 = (itemCode, stateCode) => {
        this.setState({ itemCode, stateCode });
        const { searchP2 } = this.props;
        searchP2(itemCode, stateCode, 1, pageSize);
    }

    render() {
        const {
            products: categoryProducts, user, searchResult, loading, favorites, addToFavorites, removeFromFavorites
        } = this.props;
        const { openQuickView, quickviewPDF } = this.state;

        const productData = searchResult || categoryProducts;
        return (
            <Grid container spacing={3}>
                {user && user.customFields && user.customFields.p2_Catalog && (
                    <>
                        {user.customFields.p2_Catalog === '*' && user.customFields.itemSearch && (
                            <Grid item xs={12}>
                                <P2SearchBar submitSearch={this.searchP2} />
                            </Grid>

                        )}
                    </>
                )}

                {loading && <CircularProgress />}

                {
                    !loading && productData && productData.results && productData.results.length > 0
                    && (
                        <>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    {productData.results.map((p) => (
                                        <Grid item key={p.id} xs={3}>
                                            <ProductCard
                                                onProductClick={() => this.onProductClick(p.code, p)}
                                                onQuickViewClick={() => this.openQuickViewClick(p.code)}
                                                data={p}
                                                showCode={user && user.customFields && user.customFields.p2_Catalog}
                                                favorites={favorites}
                                                addToFavorites={addToFavorites}
                                                removeFromFavorites={removeFromFavorites}
                                                user={user}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TablePagination
                                    component="div"
                                    count={productData.totalCount}
                                    page={productData.pageNumber - 1}
                                    rowsPerPage={productData.pageSize}
                                    rowsPerPageOptions={[productData.pageSize]}
                                    onChangePage={this.handleChangePage}
                                />
                            </Grid>
                        </>
                    )
                }
                {
                    openQuickView && (
                        <QuickviewModal
                            open={openQuickView}
                            closeQuickview={this.closeQuickView}
                            quickviewPDF={quickviewPDF}
                        />
                    )
                }
            </Grid>
        );
    }
}

const mapStateToProps = ({
    catalogView: {
        products, searchResult, searching, loading, favorites
    }, appView: { user, settings }
}) => ({
    products, user, searchResult, settings, searching, loading, favorites
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getProducts, searchP2, addToFavorites, removeFromFavorites
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
