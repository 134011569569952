import { Button, Grid, Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { connect } from 'react-redux';
import http from 'utils/http';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        minWidth: 800,
        minHeight: 640,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        padding: '2rem',
        boxShadow: theme.shadows[5]
    }
}));

const InventoryModal = ({
    open, onCancel, item
}) => {
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState('');
    const [inventoryData, setInventoryData] = React.useState(null);
    React.useEffect(() => {
        if (open) {
            http.get(`p2/inventory/${item.id}`).then((res) => {
                setInventoryData(res);
            }).catch((err) => {
                setError(err);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [open]);
    return (
        <>

            <Dialog
                maxWidth="xs"
                fullWidth
                open={open}
                onClose={() => onCancel()}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    <Grid container>
                        <Grid item xs={8}>
                            {item.code}
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                            <Button onClick={() => onCancel()}>Close</Button>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers style={{ textAlign: 'center' }}>
                    {loading && <p>Loading...</p>}
                    {
                        inventoryData != null && (
                            <TableContainer >
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="left">
                                                Quantity on Hand:
                                            </TableCell>
                                            <TableCell align="left">{inventoryData.qtyOnHand}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left">
                                                <Tooltip title={`${inventoryData.beginDateLastDay}`} placement="left">
                                                    <Typography variant="body2">Yesterday Usage:</Typography>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="left">{inventoryData.usageLastDay}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left">
                                                <Tooltip title={`${inventoryData.beginDateLastWeek} - ${inventoryData.endDateLastWeek}`} placement="left">
                                                    <Typography variant="body2">Last Week Usage:</Typography>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="left">{inventoryData.usageLastWeek}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left">
                                                <Tooltip title={`${inventoryData.beginDateLastMonth} - ${inventoryData.endDateLastMonth}`} placement="left">
                                                    <Typography variant="body2">Last Month Usage:</Typography>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="left">{inventoryData.usageLastMonth}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                    }
                </DialogContent>

            </Dialog>
        </>
    );
};

const mapStateToProps = ({ appView: { user } }) => ({
    user
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryModal);
