import {
    Box,

    Breadcrumbs, CircularProgress,
    Grid, Link, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import PrintIcon from '@material-ui/icons/Print';
import Alert from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import http from 'utils/http';
import JobOrderConfirmView from './JobOrderConfirmView';
import ProductOrderConfirmView from './ProductOrderConfirmView';

export default function OrderDetails(props) {
    const [loading, setLoading] = useState(true);
    const [orderData, setOrderData] = useState(null);
    const { match: { params: { orderId, type } }, user } = props;

    if (!orderData) {
        http.get(`store/order-data/${orderId}`).then((data) => {
            setOrderData(data);
        }).finally(() => setLoading(false));
    }

    if (loading || !orderData) {
        return (
            <Box>
                <Box mb={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/account">
                            Account
                        </Link>
                        <Link color="inherit" href="#/orders">
                            Order History
                        </Link>
                        <Typography color="textPrimary">{type ? 'Order Confirmation' : 'Order Details'}</Typography>
                    </Breadcrumbs>
                </Box>
                <Box>
                    <Typography variant="h5" gutterBottom component="div">
                        Order Details
                    </Typography>
                </Box>
                <Box m={4} style={{ textAlign: 'center' }}>
                    <CircularProgress />
                </Box>
            </Box>
        );
    }

    const flipBookViewer = 'https://cdndev.memberdoc.com/quickview.html';

    const orderCustomFields = JSON.parse(orderData.customFields);
    const digitalItems = orderCustomFields.basketItems ? orderCustomFields.basketItems.filter((bi) => bi.customFields && bi.customFields.outputType === 'Digital Only') : [];
    for (const d of digitalItems) {
        if (d.customFields && d.customFields.crosswalk && d.customFields.customizeCode && d.customFields.customizeCode.startsWith('OnlineBanner')) {
            d.isImg = true;
            const ss = d.customFields.crosswalk.preview.split('/');
            ss[ss.length - 1] = d.customFields.crosswalk.proofFileId;
            d.downloadLink = ss.join('/').replace('file/preview/', 'file/download/');
        }
    }
    let digitalPanel = null;
    let shippingMethod = '';

    if(orderData && orderData.orderShipping && orderData.orderShipping.length > 0) {
        shippingMethod = orderData.orderShipping[0].shippingMethodCode;
    }

    if (digitalItems.length > 0) {
        digitalPanel = (
            <Box mt={2} mb={2}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom component="div">
                            Digital
                        </Typography>

                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="address table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Action</TableCell>
                                        <TableCell align="left">Product</TableCell>
                                        <TableCell align="center">Output Type</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        digitalItems.map((di) => (
                                            <TableRow key={di.lineId}>
                                                <TableCell align="center">
                                                    {
                                                        di.isImg
                                                            ? (
                                                                <Link
                                                                    href={`${di.downloadLink}?Authorization=${user.token}`}
                                                                    target="_blank"
                                                                    style={{ marginLeft: '1rem' }}
                                                                    color="primary"
                                                                >
                                                                    <CloudDownloadIcon size="small" />
                                                                </Link>
                                                            )
                                                            : (
                                                                <Link
                                                                    href={`${flipBookViewer}?pdf=${di.customFields.crosswalk.preview.replace('file/preview/', (`file/flip/${di.customFields.proofFileId}&Authorization=${user.token}&p=`))}`}
                                                                    target="_blank"
                                                                    style={{ marginLeft: '1rem' }}
                                                                    color="primary"
                                                                >
                                                                    <ImportContactsIcon size="small" />
                                                                </Link>
                                                            )
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <Grid container>
                                                        <Grid item xs={2}>
                                                            <img style={{ maxWidth: '50px' }} alt={di.name} src={di.customFields.thumbnail} />
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            {di.name}
                                                        </Grid>
                                                    </Grid>

                                                </TableCell>
                                                <TableCell align="center">
                                                    {di.customFields.outputType}
                                                </TableCell>
                                            </TableRow>

                                        ))
                                    }
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    const od = { ...orderData, customFields: JSON.parse(orderData.customFields) };

    return (
        <Box className="order-detail">
            <Box mb={2}>
                <Breadcrumbs className="order-detail-header" aria-label="breadcrumb">
                    <Link color="inherit" href="#/account">
                        Account
                    </Link>
                    <Link color="inherit" href="#/orders">
                        Order History
                    </Link>
                    <Typography color="textPrimary">{type ? 'Order Confirmation' : 'Order Details'}</Typography>
                </Breadcrumbs>
            </Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid className="order-detail-confirmation" item xs={9}>

                        <Typography className="order-detail-print-btn" variant="h6" gutterBottom component="div">

                            {type ? 'Order Confirmation' : 'Order Details'}
                            <Tooltip
                                placement="top"
                                title="Print order confirmation"
                                className="custom-tooltip"
                            >
                                <PrintIcon onClick={() => window.print()} className="print-icon" />
                            </Tooltip>
                        </Typography>
                        {!!type && <Alert severity="info">Your order has been placed. You will receive an email confirmation soon.</Alert>}
                    </Grid>
                    <Grid className="order-detail-order-num" item xs={3}>
                        <Box style={{ textAlign: 'right' }}>
                            <div><b>Order #: </b><span>{orderData.orderNumber}</span></div>
                            <div><b>Date: </b><span>{new Date(orderData.orderedDate).toLocaleDateString()}</span></div>
                            { user && user.customFields && user.customFields.p2_Catalog ? <div><b>Shipping: </b><span>{shippingMethod}</span></div> : null }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                {digitalPanel}
            </Box>
            <Box>
                {
                    od.customFields.jobItems
                        ? <JobOrderConfirmView orderData={od} />
                        : <ProductOrderConfirmView user={user} orderData={od} />
                }

            </Box>

        </Box>
    );
}
