import { Box, Divider, Grid, IconButton, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import FallbackSrc from 'utils/fallbackSrc';

export default function P2CartView({
    basketItems, user, removeItem, changeItemQty
}) {
    if (!basketItems) return null;

    const kits = basketItems.filter((b) => b.customFields.materialType === 'kit');
    const components = basketItems.filter((b) => b.customFields.materialType !== 'kit');

    const sorted = [...kits, ...components];

    return (
        <Box>
            <Divider style={{ height: '3px' }} />
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={1}
                style={{ padding: '4px 0' }}
            >
                <Grid container item xs={1}>
                    Action
                </Grid>
                <Grid container item xs={6}>
                    Product
                </Grid>
                <Grid container item xs={1}>
                    Quantity
                </Grid>
                <Grid container item xs={1}>
                    Material Type
                </Grid>
            </Grid>
            <Divider />
            {sorted.length > 0 && sorted.map((item) => {
                const itemLimit = item.customFields.itemLimit && item.customFields.itemLimit.length > 0 && !user.customFields.isAdminUser ? item.customFields.itemLimit : user.customFields.itemLimit;
                const exceeded = !user.customFields.isAdminUser && itemLimit && item.quantity > itemLimit;
                return (
                    <Grid
                        key={item.lineId}
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={1}
                        style={{ padding: '4px 0' }}
                    >
                        {
                            exceeded
                            && (
                                <Grid item style={{ background: '#FFC107' }} xs={12}>
                                    <WarningIcon /> 
                                    {/* <span>{`You are ordering items more than ${itemLimit}, it will be routed for approval.`}</span> */}
                                    <span>You are ordering more than the limit allowed. Your order will be routed for approval.</span>
                                </Grid>
                            )
                        }
                        <Grid
                            item
                            xs={1}
                        >
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                            >
                                <IconButton
                                    aria-label="DeleteIcon"
                                    aria-describedby="remove"
                                    variant="contained"
                                    size="small"
                                    onClick={() => removeItem(item.lineId)}
                                >
                                    <DeleteIcon className="action-btn del-icon" />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}><img
                                    onError={FallbackSrc}
                                    src={item.customFields.thumbnail
                                        || '//cdndev.memberdoc.com/f/img_no_thumb.jpg'}
                                    alt={item.name}
                                    style={{ width: '100px', maxWidth: '100px' }}
                                />
                                </Grid>
                                <Grid item xs={9}>
                                    <b>{item.name}
                                    </b>
                                    {item.customFields.fileInfo
                                        && <p><i>{item.customFields.fileInfo.UploadedFileName || item.customFields.fileInfo.UploadFileName}</i></p>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                label="Qty"
                                size="small"
                                type="number"
                                min={1}
                                style={{ width: '6rem' }}
                                value={item.quantity}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                onChange={(e) => changeItemQty(item.lineId, e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            {
                                item.customFields.materialType ? item.customFields.materialType.toUpperCase() : ''
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                );
            })}
        </Box>
    );
}
