import psActions from './action';

export default (
    state = {
        loading: false,
        saving: false,
        planSponsor: null,
        planSponsors: null
    },
    action
) => {
    switch (action.type) {
        case psActions.LOADING:
            return { ...state, loading: action.loading };
        case psActions.SAVING:
            return { ...state, loading: action.saving };

        case psActions.SET_PLAN_SPONSOR:
            return { ...state, planSponsor: action.planSponsor };

        case psActions.SET_PLAN_SPONSORS:
            return { ...state, planSponsors: action.planSponsors };

        default:
            return state;
    }
};
