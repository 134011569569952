import {
    Button,

    CircularProgress, FormControlLabel, Grid,

    IconButton, InputAdornment,

    Link, Paper, Table,
    TableBody,
    TableCell,
    TableContainer,

    TableFooter, TableHead,

    TableRow
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import ProofingModal from 'components/ProofingModal';
import React, { PureComponent } from 'react';
import http from 'utils/http';
import EditFormModal from '../edit-form-modal';

export default class KitCrosswalk extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            item: null,

            codeValue: null,
            nameValue: null,
            openFind: false,
            finding: false,
            found: null,

            openProofPreview: false,
            previewPdf: null,

            openFormModal: false,
            proofingItem: null
        };
    }

    startPickItem = (item) => {
        this.setState({ item, openFind: true, found: null });
    }

    backToList = () => {
        this.setState({ item: null, openFind: false });
    }

    onCriteriaChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSearch = async () => {
        this.setState({ finding: true, found: null });
        const { item, codeValue, nameValue } = this.state;
        const formData = new FormData();
        formData.append('itemCode', codeValue || '');
        formData.append('itemName', nameValue || '');
        formData.append('itemType', item.itemType);
        http.post('direct-order/find-p1b-items', formData)
            .then((found) => this.setState({ found }))
            .finally(() => this.setState({ finding: false }));
    }

    selectComponent = (component) => {
        const { item } = this.state;
        const { saveJobItem, kit } = this.props;
        const { items } = kit;
        const t = items.find((o) => o.itemName === item.itemName);
        t.itemCode = component.code;
        t.version = component.version;
        t.vp = component.vp;
        if (component.vp && component.vp.length > 3) {
            t.approved = false;
            t.itemId = component.id;
        }

        const tv = items.find((o) => o.itemName === `${item.itemName}_Version`);
        if (tv) {
            tv.itemCode = component.version;
        }

        kit.items = [...items];
        saveJobItem(kit);
        this.setState({ openFind: false, found: null });
    }

    deselectItem = (item) => {
        const { saveJobItem, kit } = this.props;
        const { items } = kit;
        const t = items.find((o) => o.itemName === item.itemName);
        t.itemCode = null;
        t.version = null;
        t.vp = null;
        t.approved = null;
        t.itemId = null;
        t.formData = null;
        kit.items = [...items];
        saveJobItem(kit);
    }

    handleLogoChange = () => {
        const { saveJobItem, kit } = this.props;
        kit.useCustomerLogo = !kit.useCustomerLogo;
        saveJobItem(kit);
    }

    startEditFormModal = (id, index, code, formData) => {
        const { loadFormSchema } = this.props;
        loadFormSchema(code);
        this.setState({
            proofingItem: {
                id, index, code, formData
            },
            openFormModal: true
        });
    };

    onFormApproved = (formData) => {
        const { proofingItem } = this.state;
        const { saveJobItem, kit } = this.props;
        const approvedItem = this.filterIncludeItems(kit.items)[proofingItem.index];
        approvedItem.formData = { ...formData };
        approvedItem.approved = true;
        approvedItem.proofFileId = formData.proofFileId;
        kit.items = [...kit.items];
        saveJobItem(kit);
        this.setState({ proofingItem: null, openFormModal: false });
    }

    onPreview = () => {
        this.setState({ generating: true });
        const { items, kit, customer } = this.props;

        const itemData = [];

        for (const o of items) {
            if (!o.itemName.endsWith('_Version')) {
                const i = {
                    name: o.itemName, value: o.itemCode
                };
                if (o.formData) {
                    i.fileId = o.formData.proofFileId;
                }
                itemData.push(i);

                const iv = items.find((t) => t.itemName === `${i.name}_Version`);
                if (iv) {
                    itemData.push({
                        name: iv.itemName, value: iv.itemCode
                    });
                }
            }
        }

        const fd = new FormData();
        fd.append('components', JSON.stringify(itemData));
        fd.append('useCustomerLogo', !!(customer.logoInd && customer.logo && kit.useCustomerLogo));
        if (kit.ci) { fd.append('ci', JSON.stringify(kit.ci)); }

        http.post(`direct-order/preview-p1b-kit/${kit.id}/${customer.id || 0}`, fd)
            .then((dl) => this.setState({ openProofPreview: true, previewPdf: dl }))
            .finally(() => this.setState({ generating: false }));
    }

    approvePdf = () => {
        const { previewPdf } = this.state;
        const { saveJobItem, kit, onSave } = this.props;
        kit.approved = true;
        kit.approvedPdf = previewPdf.preview;
        if (previewPdf.ids) {
            kit.proofFileIds = previewPdf.ids;
        } else {
            kit.proofFileId = previewPdf.id;
        }
        saveJobItem(kit);
        this.closeProof();
        onSave();
    }

    closeProof = () => {
        this.setState({ openProofPreview: false, previewPdf: null });
    }

    filterIncludeItems = (items) => {
        return items.filter((i) => !i.itemName.endsWith('_Version') && i.itemName.indexOf('TOC_Description') < 0 && (i.itemCode || i.userInputPlaceholder));
    }

    render() {
        const {
            customer, kit, items, onSave
        } = this.props;
        const {
            openFind, found, codeValue, nameValue, finding, generating, openProofPreview, previewPdf
        } = this.state;
        const loading = !items;
        if (loading) {
            return (
                <div>
                    <CircularProgress />
                </div>
            );
        }

        if (openFind) {
            return (
                <TableContainer component={Paper} style={{ maxHeight: '400px' }}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow hover>
                                <TableCell />
                                <TableCell>
                                    <TextField
                                        label="Item Code"
                                        autoFocus
                                        value={codeValue}
                                        name="codeValue"
                                        onKeyPress={(e) => { if (e.key === 'Enter') { this.handleSearch(); } }}
                                        onChange={this.onCriteriaChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton aria-label="search" onClick={this.handleSearch}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </TableCell>
                                <TableCell>Version</TableCell>
                                <TableCell>WHS</TableCell>
                                <TableCell>
                                    <TextField
                                        label="Item Name"
                                        value={nameValue}
                                        name="nameValue"
                                        onKeyPress={(e) => { if (e.key === 'Enter') { this.handleSearch(); } }}
                                        onChange={this.onCriteriaChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton aria-label="search" onClick={this.handleSearch}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </TableCell>
                                <TableCell>DP</TableCell>
                                <TableCell>VP</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ minHeight: '50rem' }}>
                            {finding && (
                                <TableRow>
                                    <TableCell
                                        colSpan={20}
                                        style={{ textAlign: 'center', padding: '1rem' }}
                                    >
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!finding && found && found.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={20}>
                                        <span style={{ color: '#999', fontWeight: 'bold', fontSize: '1.2rem' }}> No Items</span>
                                    </TableCell>

                                </TableRow>
                            )}
                            {!finding && found && found.length > 0 && found.map((row) => {
                                return (
                                    <TableRow
                                        key={row.id}
                                    >
                                        <TableCell scope="row">
                                            <Button onClick={() => this.selectComponent(row)}>
                                                <AddIcon />
                                            </Button>
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.code}
                                        </TableCell>
                                        <TableCell scope="row" align="center">
                                            {row.version}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.whs}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.dp}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.vp}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={20}>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                        style={{ float: 'right', marginLeft: '1rem', marginRight: '1rem' }}
                                        onClick={this.backToList}
                                    >
                                        Back
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableFooter>

                    </Table>
                </TableContainer>
            );
        }

        const includedItems = this.filterIncludeItems(items);
        const missingRequiredItem = includedItems.some((i) => (i.required && !i.itemCode) || (i.itemId && !i.approved));
        const { openFormModal, proofingItem } = this.state;
        return (
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {customer.logoInd && customer.logo && (
                        <FormControlLabel
                            control={<Switch name="use_customer_logo" />}
                            label={`Use logo ${customer.logo}`}
                            checked={kit.useCustomerLogo || false}
                            onChange={this.handleLogoChange}
                        />
                    )}
                </Grid>
                <Grid item xs={6}>
                    <Button color="primary" variant="contained" style={{ float: 'right' }} onClick={onSave}>
                        Save
                    </Button>
                    <Button color="primary" variant="outlined" disabled={missingRequiredItem || generating} style={{ float: 'right', marginLeft: '1rem', marginRight: '1rem' }} onClick={this.onPreview}>
                        {generating && (
                            <CircularProgress
                                color="secondary"
                                size={12}
                            />
                        )}
                        Preview
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper} style={{ marginTop: '1rem', maxHeight: '30rem' }}>
                        <Table size="small" stickyHeader aria-label="found customers table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Item Type</TableCell>
                                    <TableCell>Item</TableCell>
                                    <TableCell align="right" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {includedItems.map((row, i) => {
                                    return (
                                        <TableRow
                                            hover

                                            key={row.itemName}
                                        >
                                            <TableCell>{row.itemName} {row.required && (<span style={{ color: 'red' }}>*</span>)}</TableCell>
                                            <TableCell>
                                                {row.itemCode}
                                                {row.vp && row.vp.length > 3 && (
                                                    <Link
                                                        component="button"
                                                        variant="body2"
                                                        style={{ color: (row.approved ? 'green' : 'red'), marginLeft: '1rem' }}
                                                        onClick={() => {
                                                            this.startEditFormModal(row.itemId, i, row.vp, row.formData);
                                                        }}
                                                    >
                                                        {row.vp}
                                                    </Link>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {row.userInputPlaceholder
                                                    ? (
                                                        <Button onClick={() => this.startPickItem(row)}>
                                                            <EditIcon />
                                                        </Button>

                                                    )
                                                    : null}
                                                {row.userInputPlaceholder && row.itemCode && <Button onClick={() => this.deselectItem(row)}><DeleteIcon /></Button>}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>

                    <ProofingModal
                        open={openProofPreview}
                        pdf={previewPdf && previewPdf.preview}
                        onApprove={this.approvePdf}
                        onCancel={this.closeProof}
                    />
                    {!!proofingItem && openFormModal && (

                        <EditFormModal
                            open={openFormModal}
                            proofingItem={proofingItem}
                            onApproved={this.onFormApproved}
                            handleClose={() => this.setState({ openFormModal: false, proofingItem: null })}
                        />
                    )}

                </Grid>
            </Grid>
        );
    }
}
