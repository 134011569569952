import React, { useState, useEffect } from 'react';
import {
    Grid, TextField
} from '@material-ui/core';
import PhoneInput from 'components/PhoneInput';

export default function P2OBO({ onOBOChange, validations: origValidations }) {
    const [agent, setAgent] = useState({});
    const [validations, setValidations] = useState(null);

    useEffect(() => {
        if(origValidations !== validations) {
            setValidations(origValidations);
        }
    }, [origValidations]);

    const onFieldChange = (e) => {
        const a = { ...agent, [e.target.name]: e.target.value };
        setAgent(a);
        onOBOChange(a);
    };

    return (

        <Grid container spacing={2}>
            <Grid item xs={2}>

                <TextField
                    label="First Name"
                    name="firstName"
                    value={agent.firstName || ''}
                    onChange={onFieldChange}
                    required
                    InputLabelProps={{
                        shrink: true
                    }}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    label="Last Name"
                    name="lastName"
                    value={agent.lastName || ''}
                    onChange={onFieldChange}
                    required
                    InputLabelProps={{
                        shrink: true
                    }}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={2}>

                <TextField
                    label="Agent Writing Number"
                    name="agentWritingNumber"
                    value={agent.agentWritingNumber || ''}
                    onChange={onFieldChange}
                    required
                    InputLabelProps={{
                        shrink: true
                    }}
                    variant="outlined"
                />
            </Grid>

            <Grid item xs={3}>

                <TextField
                    error={validations && validations.oboEmail && validations.oboEmail.length > 0}
                    fullWidth={true}
                    label="Email"
                    name="email"
                    value={agent.email || ''}
                    onChange={onFieldChange}
                    required
                    helperText={validations && validations.oboEmail}
                    InputLabelProps={{
                        shrink: true
                    }}
                    variant="outlined"
                />
            </Grid>

            <Grid item xs={2}>
                <TextField
                    label="Phone"
                    name="phone"
                    variant="outlined"
                    InputProps={{
                        inputComponent: PhoneInput,
                        value: agent.phone || '',
                        onChange: onFieldChange
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>

        </Grid>
    );
}
