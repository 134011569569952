import {
    Box, Button,

    CircularProgress,

    FormControl, IconButton, Input,

    InputLabel, MenuItem,

    Paper,

    Select, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,

    TableHead,
    TableRow, TextField
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import UploadIcon from '@material-ui/icons/Backup';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import ProofingModal from 'components/ProofingModal';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { approveAttachment, removeAttachment, setAttachmentError, setAttachmentQty, uploadAttachment } from '../../action';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 600,
        minHeight: 150,
        maxHeight: 600,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    }
}));

const SelectFileModal = (props) => {
    const [modalStyle] = React.useState(getModalStyle);
    const [openPreviewModal, setOpenPreviewModal] = useState(false);
    const [previewPdf, setPreviewPdf] = useState('');
    const [itemType, setItemType] = useState('');
    const {
        open, handleClose, attachments, uploadAttachment: upload,
        removeAttachment, approveAttachment, attachmentLimit, setAttachmentQty,
        attachmentError, setAttachmentError, screenData, uploading
    } = props;
    const classes = useStyles();

    const startUpload = (e) => {
        setAttachmentError('');
        if (!e.target.files.length) return;
        const file = e.target.files[0];
        const fileName = file.name;
        getBase64(file).then(async (fileData) => {
            const ss = await upload(fileName, fileData, itemType);
            setItemType('');
        });
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const deleteFile = (id) => {
        removeAttachment(id);
    };

    const openProofView = (preview) => {
        setPreviewPdf(preview);
        setOpenPreviewModal(true);
    };

    const approvePdf = () => {
        approveAttachment(previewPdf);
        setOpenPreviewModal(false);
    };
    const closeProof = () => {
        setOpenPreviewModal(false);
    };

    const { lob: lobQuery } = useParams();
    let itemTypeOptions = !lobQuery
        ? [{ code: 'BS', name: 'Benefit Summary' }, { code: 'EF', name: 'Enrollment Form' }]
        : ['AE', 'BE', 'BO', 'BR', 'BS', 'CV', 'DA', 'DD', 'DF', 'DR', 'DS', 'EF', 'EN', 'FD', 'FL', 'FO', 'LT', 'MS', 'OB', 'PA', 'PD', 'SC', 'SG', 'SK', 'VF'];
    if (screenData && (screenData.marketSegment === 'SRC' || screenData.marketSegment === 'AVP')) {
        itemTypeOptions = itemTypeOptions.filter((o) => o !== 'CL');
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="simple table" stickyHeader style={{ maxHeight: '800px' }}>
                        <TableHead>
                            <TableRow hover>
                                <TableCell>File Name</TableCell>
                                {!props.disableItemType && (
                                    <TableCell>Item Type</TableCell>
                                )}
                                <TableCell>Qty</TableCell>
                                <TableCell>Is Approved</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ minHeight: '50rem' }}>
                            {attachments.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={20}>
                                        <span style={{ color: '#999', fontWeight: 'bold', fontSize: '1.2rem' }}>No attachment uploaded yet</span>
                                    </TableCell>
                                </TableRow>
                            )}
                            {
                                attachments.map((a) => (
                                    <TableRow key={a.id}>
                                        <TableCell>
                                            <Button onClick={() => openProofView(a.preview)}>{a.name}</Button>
                                        </TableCell>
                                        {!props.disableItemType && (
                                            <TableCell>
                                                {a.itemType}
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            <TextField
                                                style={{ width: '2rem', textAlign: 'center' }}
                                                type="text"
                                                value={a.quantity}
                                                onChange={(e) => setAttachmentQty(a.id, e.target.value)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {a.approved && (<CheckCircleIcon color="primary" />)}
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>
                                            <IconButton onClick={() => deleteFile(a.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan="20">
                                    {!props.disableItemType && (

                                        <FormControl variant="outlined" style={{ width: '10rem', marginRight: '1rem' }} size="small" className={classes.formControl}>
                                            <InputLabel>Item Type</InputLabel>
                                            <Select
                                                value={itemType}
                                                onChange={(e) => setItemType(e.target.value)}
                                                disabled={uploading}
                                                label="Item Type"
                                            >
                                                <MenuItem value="">Select</MenuItem>
                                                {itemTypeOptions.map((t) => <MenuItem key={t.code || t} value={t.code || t}>{t.name || t}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    )}

                                    <label htmlFor="user-address-upload" style={{ cursor: 'pointer' }}>
                                        {uploading
                                            ? <CircularProgress color="secondary" size={12} />
                                            : <UploadIcon color={(!props.disableItemType && !itemType) || (attachments && attachments.length == attachmentLimit) ? 'inherit' : 'primary'} />}

                                        <Input
                                            type="file"
                                            disabled={(!props.disableItemType && !itemType) || (attachments && attachments.length == attachmentLimit)}
                                            inputProps={{ id: 'user-address-upload', accept: '.pdf' }}
                                            style={{ display: 'none' }}
                                            onChange={startUpload}
                                        />
                                    </label>
                                    {attachmentLimit > 1
                                        ? (<span> Up to {attachmentLimit} items</span>)
                                        : (<span> Up to {attachmentLimit} item</span>)}
                                    {attachmentError && <p style={{ color: 'red' }}>{attachmentError}</p>}
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <Box style={{ float: 'right', marginTop: '1rem' }}>
                    <Button onClick={handleClose}>Close</Button>
                </Box>
                <ProofingModal open={openPreviewModal} pdf={previewPdf} onApprove={approvePdf} onCancel={closeProof} />
            </div>
        </Modal>
    );
};

const mapStateToProps = ({
    directOrderView: {
        stepView: {
            attachments, attachmentSettings, attachmentError, screenData, uploading
        }
    }
}) => ({
    attachments, attachmentSettings, attachmentError, screenData, uploading
});
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            uploadAttachment, removeAttachment, approveAttachment, setAttachmentError, setAttachmentQty
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectFileModal);
