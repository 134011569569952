import { Card, CardActionArea, CardContent, Grid, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import BuildIcon from '@material-ui/icons/Build';
import CancelIcon from '@material-ui/icons/Cancel';
import FaceIcon from '@material-ui/icons/Face';
import HomeIcon from '@material-ui/icons/Home';
import SecurityIcon from '@material-ui/icons/Security';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TuneIcon from '@material-ui/icons/Tune';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import history from '../../utils/history';
import ImpersonateModal from './ImpersonateModal';
import ArrivalsModal from './ArrivalsModal';

const AccountView = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const [openArrivalsModal, setOpenArrivalsModal] = useState(false);
    const { user } = props;

    const stopImpersonate = () => {
        props.startImpersonate(null);
        history.push('/catalog');
    };
    const showHistory = user && user.customFields.userType !== 'telesales';
    const showAddresses = user && user.customFields.userType !== 'telesales';
    const isSU = user && user.customFields && user.customFields.userGroups && user.customFields.userGroups.length > 0 && user.customFields.userType === 'su';
    const allowSaveOrder = user && user.customFields.userType !== 'telesales' && !user.customFields.p2_Catalog;
    const showPasswordChangeView = !user.customFields.passthroughLogin;
    const isP2 = !!(user && user.customFields && user.customFields.p2_Catalog);

    return (

        <Grid container spacing={5}>
            {showHistory && (
                <Grid item xs={3}>
                    <Card>
                        <Link to="/orders" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h2">
                                        <AccessTimeIcon />  Order History
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                    </Card>
                </Grid>

            )}
            {showAddresses && (
                <Grid item xs={3}>
                    <Card>
                        <Link to="/addresses" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h2">
                                        <HomeIcon /> Your Addresses
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                    </Card>
                </Grid>
            )}
            {showPasswordChangeView && (
                <Grid item xs={3}>
                    <Card>
                        <Link to="/password" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h2">
                                        <SecurityIcon /> Change Password
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                    </Card>
                </Grid>

            )}
            <Grid item xs={3}>
                <Card>
                    <Link to="/profile" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                        <CardActionArea>
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="h2">
                                    <AccountBoxIcon /> Account Details
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Link>
                </Card>
            </Grid>
            {allowSaveOrder && (

                <Grid item xs={3}>
                    <Card>
                        <Link to="/saved-order" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h2">
                                        <WatchLaterIcon /> Saved Order
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                    </Card>
                </Grid>
            )}
            {user && user.customFields && user.customFields.p2_Catalog === '*' && (
                <Grid item xs={3}>
                    <Card>
                        <CardActionArea
                            onClick={() => setOpenModal(true)}
                        >
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="h2">
                                    <SupervisorAccountIcon /> Impersonate {user.impersonate && (
                                        <span>({user.impersonate}) <CancelIcon onClick={() => stopImpersonate()} /> </span>
                                    )}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <ImpersonateModal open={openModal} handleClose={() => setOpenModal(false)} />
                </Grid>
            )}
            {user && user.customFields && user.customFields.p2_Catalog === '*' && user.customFields.hasArrivalsAccess && (
                <Grid item xs={3}>
                    <Card>
                        <CardActionArea
                            onClick={() => setOpenArrivalsModal(true)}
                        >
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="h2">
                                    <FlightLandIcon/> Arrivals 
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <ArrivalsModal open={openArrivalsModal} handleClose={() => setOpenArrivalsModal(false)} />
                </Grid>
            )}
            {isSU && (
                <>
                    <Grid item xs={3}>
                        <Card>
                            <Link to="/order-management" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                <CardActionArea>
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="h2">
                                            <BuildIcon /> Order Management
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Link>
                        </Card>
                    </Grid>

                    <Grid item xs={3}>
                        <Card>
                            <Link to="/plan-sponsor" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                <CardActionArea>
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="h2">
                                            <TuneIcon /> Plan Sponsors
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Link>
                        </Card>
                    </Grid>

                    <Grid item xs={3}>
                        <Card>
                            <Link to="/user-mgmt" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                <CardActionArea>
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="h2">
                                            <FaceIcon /> User Management
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Link>
                        </Card>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default AccountView;
