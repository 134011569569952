import {
    Box, Button,

    Collapse, IconButton,
    Table, TableBody, TableCell, TableHead, TableRow, Typography
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CancelIcon from '@material-ui/icons/Cancel';
import React from 'react';

export default function OrderRow(props) {
    const { row, hasCustomerName, onCopyClick, isP2, onCancelClick } = props;
    const [open, setOpen] = React.useState(false);

    const toFullName = (s) => {
        switch (s) {
            case 'FJE':
                return 'Fast Job';
            case 'AJEPP':
                return 'Pick & Pack';
            case 'AJEKT':
                return 'Kit Job';
            case 'IM':
                return 'IM';

            default:
                return '';
        }
    };

    return (
        <>
            <TableRow>
                <TableCell>
                    {row.shippingInfo && row.shippingInfo.length > 0 && (
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.orderNumber}
                    <Box style={{ marginTop: '1rem', color: '#555', fontSize: '.8rem' }}>{row.orderedDate}</Box>
                </TableCell>
                <TableCell>{row.ddd}</TableCell>
                {!isP2 ? <>
                    <TableCell>{row.program}</TableCell>
                    <TableCell>{toFullName(row.jobType)}</TableCell>
                    <TableCell>{row.marketSegment}</TableCell>
                    <TableCell>{row.costCenter}</TableCell>
                    </> : null }
                {
                    hasCustomerName && (<TableCell>{row.customerName}</TableCell>)
                }
                <TableCell>{row.orderStatus}</TableCell>
                <TableCell align="left">
                    <Button href={`#/order/${row.id}`} color="primary">
                        <VisibilityIcon /> View
                    </Button>
                    <Button onClick={() => onCopyClick(row.id)} color="primary">
                        <LibraryAddIcon /> Copy
                    </Button>
                    {isP2 && row.orderStatus.toLowerCase() === "approved" || row.orderStatus.toLowerCase() === "pending" ? 
                    <Button onClick={() => onCancelClick(row.id)} color="primary">
                        <CancelIcon /> Cancel
                    </Button> : null
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Shipping
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Tracking Number</TableCell>
                                        <TableCell>Shipped Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.shippingInfo.map((shipInfo, index) => (
                                        <TableRow key={`${shipInfo.trackingNumber}_${index}`}>
                                            <TableCell component="th" scope="row">
                                                {shipInfo.trackingNumber}
                                            </TableCell>
                                            <TableCell>{shipInfo.shippedDate}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
