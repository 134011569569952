import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField,
    Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import PhoneInput from 'components/PhoneInput';
import StateSelect from 'components/UsStateSelect';
import React, { useEffect, useState } from 'react';
import http from 'utils/http';
import Validator from 'utils/ValidateUtil';


function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        height: 500,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        padding: '2rem',
        boxShadow: theme.shadows[5]
    },
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const AddressModal = (props) => {
    const [modalStyle] = React.useState(getModalStyle);
    const {
        open, closeAddress, address, saveAddress, user, showMessage
    } = props;
    const [addr, setAddr] = React.useState(address || { country: 'United States' });
    const [canValidated, setCanValidated] = useState(false);
    const [validated, setValidated] = useState(false);
    const [validating, setValidating] = useState(false);
    const [validations, setValidations] = useState({email: null});
    const [errorMessage, setErrorMessage] = useState('');
    const [invalidAddressCount, setInvalidAddressCount] = useState(0);
    const [showAddressCorrectedDialog, setShowAddressCorrectedDialog] = useState(false);
    const [recommendedAddress, setRecommendedAddress] = useState(undefined);

    useEffect(() => {
        setCanValidated(!!addr.firstName 
            && !!addr.lastName
            && (!addr.phone || addr.phone.replace(/\D+/g, ''))
            && !!addr.email
            && !!addr.address
            && !!addr.zip
            && !!addr.state
            && !!addr.country);
    }, [addr, props]);

    const classes = useStyles();

    const onFieldChange = (e) => {
        setErrorMessage('');
        if (e.target.name === 'zip' && e.target.value && !e.target.value.match(/^\d+$/)) {
            return;
        }
        setAddr({ ...addr, [e.target.name]: e.target.value });

        if (user.customFields.p2_Catalog) {
            setValidated(false);
            setInvalidAddressCount(0);
            setRecommendedAddress(undefined);
        }
    };

    const clearAll = () => {
        const a = {};
        for (const k in addr) {
            a[k] = '';
        }
        a.country = 'United States';
        setAddr(a);
    };

    const showPoBoxMessage = () => {
        showMessage('PO Box is not allowed', 'warning');
    }

    const saveChange = () => {
        setErrorMessage('');

        if ((Validator.isPOBox(addr.address) || Validator.isPOBox(addr.address2))) {
            showPoBoxMessage();
            return;
        }

        setValidated(false);
        setValidating(true);

        if(!validate()) {
            setValidating(false);
            setValidated(false);
            setErrorMessage('Validation Failed.');
            return;
        }

        http.post('store/validate-address', {
            Address1: addr.address,
            Address2: addr.address2,
            City: addr.city,
            State: addr.state,
            Zipcode: addr.zip,
            Country: addr.country
        }).then((r) => {
            // {"StatusMessage":"Not Found","Success":false,"POBox":false}
            const isValid = r.Success && !r.POBox;
            if (!isValid && invalidAddressCount === 0) {
                if (r.POBox) {
                    setErrorMessage('PO Box is not allowed');
                } else {
                    if (user.customFields.p2_Catalog) {
                        setErrorMessage('Suggested address not found. Address may be invalid.');
                    } else {
                        setErrorMessage('Invalid address, please check your input.');
                    }
                }

                if (user.customFields.p2_Catalog && !r.POBox) {
                    setInvalidAddressCount(invalidAddressCount + 1);
                }
            } else {
                if (addr.address !== r.Address1
                    || addr.address2 !== r.Address2
                    || addr.city !== r.City
                    || addr.state !== r.State
                    || addr.zip !== r.Zipcode
                    || (!user.customFields.p2_Catalog && addr.country !== r.Country)) {

                    if (user.customFields.p2_Catalog && invalidAddressCount === 0) {
                        setRecommendedAddress(r);
                        setShowAddressCorrectedDialog(true);
                        setInvalidAddressCount(invalidAddressCount + 1);
                        return;
                    } else if (!user.customFields.p2_Catalog) {
                        addr.address = r.Address1;
                        addr.address2 = r.Address2;
                        addr.city = r.City;
                        addr.state = r.State;
                        addr.zip = r.Zipcode;
                        addr.country = r.Country;
                    }
                }

                saveAddress(addr);
            }
            setValidated(isValid);
        }).finally(() => setValidating(false));
    };

    const setAddress = (value) => {
        if(value) {
            addr.address = recommendedAddress.Address1;
            addr.address2 = recommendedAddress.Address2;
            addr.city = recommendedAddress.City;
            addr.state = recommendedAddress.State;
            addr.zip = recommendedAddress.Zipcode;
        }

        setAddr(addr);
        setShowAddressCorrectedDialog(false);
        saveChange();
    }

    const validate = () => {
        let isValid = false;
        if (addr.email && addr.email.length > 0) {
            validations.email = Validator.validateEmail(addr.email) ? null : "Invalid email address format";
            setValidations(validations);
            isValid = !validations.email;
        }

        return isValid;
    }

    return (
        <Modal
            open={open}
            onClose={closeAddress}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Grid container spacing={1} className="row-item">
                    <Grid item xs={12}>
                        <TextField
                            label="Title"
                            name="name"
                            fullWidth
                            value={addr.name || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="First Name"
                            name="firstName"
                            required
                            value={addr.firstName || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField

                            label="MI"
                            name="middleName"
                            value={addr.middleName || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField

                            label="Last Name"
                            name="lastName"
                            required
                            value={addr.lastName || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Phone#"
                            name="phone"
                            variant="outlined"
                            required
                            InputProps={{
                                inputComponent: PhoneInput,
                                value: addr.phone || '',
                                onChange: onFieldChange
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onFocus={(e) => {
                                e.target.select();
                            }}
                        />
                        {/* <TextField

                            label="Phone#"
                            name="phone"
                            value={addr.phone || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        /> */}
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            error={validations && validations.email}
                            fullWidth={true}
                            label="Email"
                            name="email"
                            value={addr.email || ''}
                            required
                            onChange={onFieldChange}
                            helperText={validations && validations.email}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={user.customFields.p2_Catalog ? "Address Line 1 - No PO Boxes" : "Address Line 1" }
                            name="address"
                            required
                            value={addr.address || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Address Line 2"
                            name="address2"
                            value={addr.address2 || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField

                            label="City"
                            name="city"
                            required
                            value={addr.city || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <StateSelect
                            // size="medium"
                            value={addr.state || ''}
                            onChange={onFieldChange}
                            displayFullStateName={!!user.customFields.p2_Catalog}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField

                            label="Zip"
                            name="zip"
                            required
                            value={addr.zip || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl
                            fullWidth
                            variant="outlined"
                        >
                            <InputLabel id="country-select-outlined-label">Country</InputLabel>
                            <Select
                                labelId="country-select-outlined-label"
                                id="country-select-outlined"
                                fullWidth
                                name="country"
                                value="United States"
                                onChange={onFieldChange}
                                label="Country"
                            >
                                <MenuItem value="United States">United States</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Button
                        className="btn place-order-btn"
                        disabled={!(canValidated)}
                        variant="contained"
                        onClick={saveChange}
                        color="primary"
                    >Save
                    </Button>

                    <Button
                        color="primary"
                        variant="outlined"
                        fontWeight="fontWeightMedium"
                        style={{ marginLeft: '1rem' }}
                        onClick={clearAll}
                    >
                        <ClearAllIcon /> Clear
                    </Button>
                </Box>
                {errorMessage && <span style={{ color: 'red', paddingLeft: '1rem' }}>{errorMessage}</span>}
                <Dialog open={showAddressCorrectedDialog} onClose={() => setShowAddressCorrectedDialog(false) } aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{recommendedAddress ? "Address Corrected" : ""}</DialogTitle>
                    <DialogContent>
                        {recommendedAddress ? <Box>
                            <Box pb={2}>
                                <Typography>
                                    The following address correction is recommended. Would you like to use the recommended address?
                                        </Typography>
                            </Box>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    {recommendedAddress.Address1}
                                </Grid>
                                <Grid item xs={12}>
                                    {recommendedAddress.Address2}
                                </Grid>
                                <Grid item xs={12}>
                                    {recommendedAddress.City}, {recommendedAddress.State} {recommendedAddress.Zipcode}
                                </Grid>
                            </Grid>
                        </Box> : null }
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={() => { setAddress(true); }}>
                            Yes
                            </Button>
                        <Button color="default" onClick={() => { setAddress(false); }}>
                            No
                            </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Modal>
    );
};
export default AddressModal;
