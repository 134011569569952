import { addProductsToCart, addProductToCart } from 'components/basket/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './action';
import ListView from './ListView';

const mapStateToProps = ({ telesalesView, appView: { user, settings } }) => ({
    ...telesalesView, user, settings
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...actions, addProductToCart, addProductsToCart
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
