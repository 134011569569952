import actionCreator from 'utils/actionCreator';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('SAVED_JOB_VIEW', {
    LOADING: null,

    SET_SAVED_JOB: null
});

export default actions;

const setLoading = actionCreator(actions.LOADING, 'loading');

const setSavedJobs = actionCreator(actions.SET_SAVED_JOB, 'savedJobs');
export const getSavedJobs = (pageNumber, pageSize, key) => (dispatch) => {
    dispatch(setLoading(true));
    const api = key
    ? `customer/saved-jobs/${pageNumber}/${pageSize}/${key}`
    : `customer/saved-jobs/${pageNumber}/${pageSize}`;
    http.get(api)
        .then((data) => {
            for (const job of data.results) {
                job.customFields = JSON.parse(job.customFields);
            }

            dispatch(setSavedJobs(data));
        })
        .finally(() => dispatch(setLoading(false)));
};
