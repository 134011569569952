import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        minWidth: '32rem'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

function TabPanel(props) {
    const {
        children, value, index, ...other
    } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const DialogTitle = withStyles(styles)((props) => {
    const {
        children, classes, onClose, ...other
    } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: 0
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export default function Pricing({ open, onClose, priceData }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let prices = priceData;
    if (typeof prices === 'string') {
        prices = JSON.parse(prices);
    }

    const groups = groupBy(prices, 'Spec');
    const gv = Object.values(groups);

    const priceList = (qp, i) => {
        return (
            <TabPanel key={i} value={value} index={i}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Quantity</b></TableCell>
                                <TableCell align="right"><b>Print Price per Unit</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {qp.map((row) => (
                                <TableRow key={row.Id}>
                                    <TableCell>{row.Quantity}</TableCell>
                                    <TableCell align="right">{row.PrintPricePerUnit}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>
        );
    };

    return (
        <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" style={{ textAlign: 'center' }} onClose={onClose}>
                PRICING INFORMATION
            </DialogTitle>
            <DialogContent dividers>
                {gv.length > 1 && (
                    <Tabs
                        hidden={gv.length === 1}
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        {gv.map((g, i) => (
                            <Tab key={i} value={i} label={g[0].Spec} />
                        ))}
                    </Tabs>
                )}
                {gv.map((g, i) => {
                    return priceList(g, i);
                })}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'flex-start' }}>
                <Typography gutterBottom>
                    <i>** Price shown represent a per price cost</i>
                </Typography>
            </DialogActions>
        </Dialog>
    );
}
