import React from 'react';
import {
    Chip, Grid, IconButton
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';


export default function SelectionBar({ selection, onChangeClick }) {
    return (
        <Grid container>
            <Grid item xs={3}>
                Year: <Chip size="small" label={selection.year} variant="outlined" />
            </Grid>
            <Grid item xs={3}>
                State: <Chip size="small" label={selection.state} variant="outlined" />
            </Grid>
            <Grid item xs={3}>
                Product: <Chip size="small" label={selection.product} variant="outlined" />
            </Grid>
            <Grid item xs={3}>
                <IconButton size="small" color="primary" style={{ float: 'right' }} onClick={onChangeClick}>
                    <EditIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
}
