import http from 'utils/http';
import keyMirror from 'utils/keyMirror';
import actionCreator from 'utils/actionCreator';
import message from '../../components/Message';

export const actions = keyMirror('ORDERS_VIEW', {
    LOADING: null,
    SET_ORDERS: null,
    SET_SEARCH_FILTER: null,
    CLEAR_FILTER: null
});

export default actions;

const setSearchFilter = actionCreator(actions.SET_SEARCH_FILTER, 'propertyName', 'propertyValue');
const setClearFilter = actionCreator(actions.CLEAR_FILTER);
const setLoading = actionCreator(actions.LOADING, 'loading');

const setOrders = actionCreator(actions.SET_ORDERS, 'orders');
export const getOrders = (pageNumber, pageSize, criteria) => async (dispatch) => {
    dispatch(setLoading(true));
    await http.post(`store/orders/${pageNumber}/${pageSize}`, criteria || {})
        .then((data) => dispatch(setOrders(data)))
        .finally(() => dispatch(setLoading(false)));
};

export const updateSearchFilter = (propertyName, propertyValue) => (dispatch) => {
    dispatch(setSearchFilter(propertyName, propertyValue));
}

export const clearFilter = () => (dispatch) => {
    dispatch(setClearFilter());
}

export const cancelOrder = (orderId, reason) => async (dispatch) => { 
    dispatch(setLoading(true));
    const formData = new FormData();
    formData.append('orderId', orderId);
    formData.append('reason', reason);
    await http.post('store/cancel-order', formData).then( d => {
        message.success("Cancellation request submitted.");
    }).catch(e => {
        console.error(e);
    });
    dispatch(setLoading(false));
}