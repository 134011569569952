import { Button, Grid, IconButton, Link } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PrintIcon from '@material-ui/icons/Print';
import React from 'react';
import { connect } from 'react-redux';
import fallbackSrc from 'utils/fallbackSrc';
import http from 'utils/http';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        minWidth: 800,
        minHeight: 640,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        padding: '2rem',
        boxShadow: theme.shadows[5]
    }
}));

const QuickviewModal = ({
    open, onCancel, pdf, wh, user, item, printPdfOnLoad = false
}) => {
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    const $wh = wh || {};
    const classes = useStyles();

    if (!pdf) return null;
    let $pdf = pdf;
    // http://localhost:5000/file/preview/406
    let downloadLink = pdf.replace('file/preview', 'file/download').replace('p2-view', 'p2-dl').replace('p2-img', 'p2-dl');
    if (pdf && !pdf.includes('Authorization') && user) {
        $pdf = `${pdf}?Authorization=${user.token}`;
        downloadLink = `${downloadLink}?Authorization=${user.token}`;
    }

    const printPdf = () => {
        http.post('track/save', {
            action: 'PrintFile',
            data: {
                link: pdf
            }
        });
        window.frames["previewFrame"].focus();
        window.frames["previewFrame"].print();
    };

    const isIFrameLoaded = () => {
        var iframe = window.frames["previewFrame"];

        if(iframe) {
            var iframeDoc = iframe.document;

            // Check if loading is complete
            if (iframeDoc.readyState == 'complete') {
                printPdf();
                return;
            }
        }

        window.setTimeout(isIFrameLoaded, 200);
    }

    if(printPdfOnLoad) {
        isIFrameLoaded();
    }

    return (
        <>

            <Dialog
                maxWidth="lg"
                fullWidth
                open={open}
                onClose={() => onCancel()}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    <Grid container>
                        <Grid item xs={6}>
                            Preview
                            <IconButton>
                                {item && item.downloadable === 'YES' && (
                                    <Link
                                        href={downloadLink}
                                        target="_blank"
                                        style={{ marginLeft: '1rem' }}
                                        color="primary"
                                    >
                                        <CloudDownloadIcon />
                                    </Link>
                                )}
                            </IconButton>
                            {item && item.downloadable === 'YES' && <IconButton color="primary" onClick={printPdf}>
                                <PrintIcon />
                            </IconButton>}
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                            <Button onClick={() => onCancel()}>Cancel</Button>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers style={{ textAlign: 'center' }}>
                    {
                        pdf.includes('p2-img')
                            ? (<img src={pdf} style={{ width: '100%' }} onError={fallbackSrc} />)
                            : (
                                <iframe
                                    id="previewFrame"
                                    name="previewFrame"
                                    style={{
                                        width: '100%', height: '100%', minHeight: '640px', ...$wh
                                    }}
                                    title={$pdf}
                                    src={`${$pdf}#toolbar=0`}
                                />
                            )
                    }
                </DialogContent>

            </Dialog>
        </>
    );
};

const mapStateToProps = ({ appView: { user } }) => ({
    user
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(QuickviewModal);
