import orderActions from './action';

export default (
    state = {
        loading: false,
        orders: null,
        saves: null
    },
    action
) => {
    switch (action.type) {
        case orderActions.LOADING:
            return { ...state, loading: action.loading };

        case orderActions.SET_ORDERS:
            return { ...state, orders: action.orders };

        case orderActions.SET_SAVES:
            return { ...state, saves: action.saves };

        default:
            return state;
    }
};
