import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { showMessage } from 'actions/app';
import SaveForLaterDialog from 'components/customize/SaveForLaterDialog';
import React, { Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import http from 'utils/http';
import {
    getMarketSegmentXCostCenters, getPossibleDeliveryDate, refreshAddresses, resetInput,
    saveImInfo, setDomNextStepReady,
    setSavedId,
    setSavedName, updateJobInfo
} from './action';
import ImQuery from './ImQuery';
import JobInformationStep from './steps/JobInformationStep';
import ReviewStep from './steps/ReviewStep';
import SelectCustomerStep from './steps/SelectCustomerStep';
import SelectItemStep from './steps/SelectItemStep';
import SelectScreenStep from './steps/SelectScreenStep';
import ShippingAddressStep from './steps/ShippingAddressStep';
import BuildSummary from './steps/summary/BuildSummary';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    actionsContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2)
    },
    resetContainer: {
        padding: theme.spacing(3)
    }
}));

function getStepContent(stepName, setIsNextReady) {
    switch (stepName) {
        case 'Select Customer':
            return <SelectCustomerStep setIsNextReady={setIsNextReady} />;
        case 'Shipping Address':
            return <ShippingAddressStep setIsNextReady={setIsNextReady} />;
        case 'Select Screen':
            return <SelectScreenStep setIsNextReady={setIsNextReady} />;
        case 'Select Items':
            return <SelectItemStep setIsNextReady={setIsNextReady} />;
        case 'Job Information':
            return <JobInformationStep setIsNextReady={setIsNextReady} />;
        case 'Review & Submit':
            return <ReviewStep setIsNextReady={setIsNextReady} />;
        default:
            return 'Unknown step';
    }
}

const DirectOrder = (props) => {
    const { lob, jobType } = useParams();

    const {
        savedId, savedName, user, imInfo, saveImInfo
    } = props;

    const [isNextReady, setIsNextReady] = useState(false);
    const [savedOrderNumber, setSavedOrderNumber] = useState('');

    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [validatingAddress, setValidatingAddress] = useState(false);
    const [savingJob, setSavingJob] = useState(false);

    const classes = useStyles();
    const {
        activeStep, setActiveStep, attachments, showMessage, steps,
        refreshAddresses, setSavedId, setSavedName, submitting, marketSegmentXCostCenters
    } = props;

    const [showImQuery, setShowImQuery] = useState(false);
    useEffect(() => {
        setShowImQuery(lob === 'cml' && jobType === 'IM' && !imInfo);
    }, [lob, jobType, imInfo]);
    const saveImQuery = (data) => {
        saveImInfo(data);
        setShowImQuery(false);
    };
    const getJobOrderData = () => {
        let selected = props.jobItems;
        selected = selected.some((j) => j.selected === true) ? selected.filter((j) => j.selected === true) : selected;

        return jobType === 'FJE' ? {
            addresses: props.addresses,
            jobItems: selected,
            attachments,
            lob,
            jobType,
            shippingNotes: props.shippingNotes,
            savedId
        } : {
                customer: props.customer,
                addresses: props.addresses,
                jobItems: props.jobItems && selected,
                attachments,
                jobInfo: props.jobInfo,
                lob,
                jobType,
                shippingNotes: props.shippingNotes,
                savedId,
                marketSegment: props.screenData.marketSegment,
                addressFileIds: jobType === 'IM' ? props.addressFileIds : null,
                imInfo
            };
    };

    const handleNext = () => {
        const { addresses, marketSegmentXCostCenters: mscc, getMarketSegmentXCostCenters: loadX } = props;
        if (steps[activeStep + 1] === 'Review & Submit' && addresses && addresses.length && !addresses[0].deliveryDate) {
            props.getPossibleDeliveryDate(lob, jobType);
        }

        if (steps[activeStep + 1] === 'Select Screen' && lob === 'cml' && mscc === null) {
            loadX();
        }
        if (lob === 'cml' && ['AJEPP', 'AJEKT', 'IM'].includes(jobType) && steps[activeStep] === 'Select Screen') {
            const costCenterOptions = mscc[props.screenData.marketSegment];
            const info = { ...props.jobInfo, costCenterOptions }; // , costCenter: costCenterOptions[0]
            if (user.customFields.costCenters && user.customFields.costCenters.length > 0) {
                const costCenter = user.customFields.costCenters.find((cc) => costCenterOptions.some((o) => o.value === cc)) || '';
                info.costCenter = costCenter;
            }
            props.updateJobInfo(info);
        }

        if (steps[activeStep] === 'Shipping Address' && jobType !== 'IM') {
            setIsNextReady(false);
            const addrs = addresses.map((a) => ({
                Address1: a.address,
                Address2: a.address2,
                City: a.city,
                State: a.state,
                Zipcode: a.zip,
                Country: a.country
            }));
            setValidatingAddress(true);
            http.post('store/validate-addresses', addrs).then((result) => {
                for (let i = 0; i < result.length; i += 1) {
                    const r = result[i];
                    const a = addresses[i];
                    a.isValid = r.Success && !r.POBox;
                    if (a.isValid) {
                        if (a.address !== r.Address1
                            || a.address2 !== r.Address2
                            || a.city !== r.City
                            || a.state !== r.State
                            || a.zip !== r.Zipcode
                            // || a.country !== r.Country
                        ) {
                            a.userInput = {
                                address: a.address,
                                address2: a.address2,
                                city: a.city,
                                state: a.state,
                                zip: a.zip
                                // , country: a.country
                            };
                            a.address = r.Address1;
                            a.address2 = r.Address2;
                            a.city = r.City;
                            a.state = r.State;
                            a.zip = r.Zipcode;
                            // a.country = r.Country;
                        }
                    }
                }

                refreshAddresses(addresses);
                const isValid = result.every((r) => r.Success && !r.POBox);
                // {"StatusMessage":"Not Found","Success":false,"POBox":false}
                if (!isValid) {
                    showMessage('Invalid address or PO Box address found, please check your input.', 'warning');
                    setIsNextReady(false);
                } else {
                    setActiveStep(activeStep + 1);
                }
            }).finally(() => setValidatingAddress(false));
        } else if (activeStep === steps.length - 1) {
            const jobOrderData = getJobOrderData();
            props.submitJobOrder(jobOrderData);
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const onSaveJobClicked = () => {
        setOpenSaveDialog(true);
    };

    const saveJobForLater = (savedJobName) => {
        setSavingJob(true);
        setOpenSaveDialog(false);
        const fd = new FormData();
        fd.append('name', savedJobName);
        fd.append('savedId', savedId || 0);
        const savedData = { jobData: { ...props.currentJob, activeStep }, hash: window.location.hash };
        fd.append('data', JSON.stringify(savedData));
        http.post('catalog/save-job-order', fd)
            .then((r) => {
                setSavedId(r.savedId);
                setSavedOrderNumber(r.orderNumber);
                setSavedName(savedJobName);
            })
            .finally(() => setSavingJob(false));
    };

    const getJobName = () => {
        switch (jobType) {
            case 'FJE':
                return 'Add Fast Job';
            case 'AJEPP':
                return 'Add Pick&Pack Job';
            case 'AJEKT':
                return 'Add KIT Job';
            default:
                return 'Add IM Job';
        }
    };

    const okToSave = lob === 'cml' || activeStep === steps.length - 1;
    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h5" component="h6">
                        {getJobName()}
                    </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    {savedOrderNumber && (
                        <span>
                            Saved Order Number: <span style={{ color: '#7d3f98' }}><b>{savedOrderNumber}</b></span>
                        </span>
                    )}
                </Grid>

            </Grid>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => {
                    const SummaryComp = BuildSummary(label, index >= activeStep);
                    return (
                        <Step key={label}>
                            <StepLabel>
                                <Grid
                                    container
                                    alignItems="center"
                                >
                                    <Grid item xs={2}>
                                        {label}
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <Box>
                                                <SummaryComp />
                                            </Box>
                                        </Suspense>
                                    </Grid>
                                </Grid>
                            </StepLabel>
                            <StepContent>
                                {getStepContent(steps[index], setIsNextReady)}
                                <div className={classes.actionsContainer}>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            disabled={!isNextReady || submitting}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                        >
                                            {activeStep === steps.length - 1 ? 'Submit Job Order' : 'Next'}
                                        </Button>
                                        {okToSave && (
                                            <>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={onSaveJobClicked}
                                                    className={classes.button}
                                                    disabled={savingJob}
                                                >
                                                    Save Job
                                                </Button>

                                                {openSaveDialog && (
                                                    <SaveForLaterDialog
                                                        open={openSaveDialog}
                                                        onClose={() => setOpenSaveDialog(false)}
                                                        initName={savedName || `${jobType} drafted ${new Date().toLocaleDateString()}`}
                                                        onSave={saveJobForLater}
                                                        savedId={savedId}
                                                    />
                                                )}
                                            </>
                                        )}
                                        {validatingAddress && (
                                            <span>
                                                <CircularProgress size={12} color="primary" /> validating address...
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} className={classes.button}>
                        Reset
                    </Button>
                </Paper>
            )}
            <Backdrop className={classes.backdrop} open={savingJob} onClick={() => setSavingJob(false)}>
                <CircularProgress color="inherit" /> Saving...
            </Backdrop>
            <ImQuery
                open={showImQuery}
                onClose={() => { }}
                onSave={saveImQuery}
            />
        </div>
    );
};

const mapStateToProps = ({
    appView: { user },
    directOrderView: {
        stepView: {
            isNextDomStepReady,
            customer, addresses, addressFileIds,
            totalAddressCount,
            screenData,
            jobItems,
            jobInfo,
            attachments,
            shippingNotes,
            savedId,
            submitting,
            savedName,
            marketSegmentXCostCenters, imInfo
        }
    }

}) => ({
    isNextDomStepReady,
    customer,

    addresses,
    addressFileIds,
    totalAddressCount,

    screenData,

    jobItems,
    jobInfo,
    attachments,
    shippingNotes,
    savedId,
    submitting,
    savedName,
    marketSegmentXCostCenters,
    user,
    imInfo
});
export default connect(mapStateToProps, {
    setDomNextStepReady,
    resetInput,
    getPossibleDeliveryDate,
    showMessage,
    refreshAddresses,
    setSavedId,
    setSavedName,
    getMarketSegmentXCostCenters,
    updateJobInfo,
    saveImInfo
})(DirectOrder);
