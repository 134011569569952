import http from '../../utils/http';
import keyMirror from '../../utils/keyMirror';
import actionCreator from '../../utils/actionCreator';

export const actions = keyMirror('ADDRESSES_VIEW', {
    LOADING: null,
    SET_ADDRESSES: null
});

export default actions;

const setLoading = actionCreator(actions.LOADING, 'loading');

const setAddresses = actionCreator(actions.SET_ADDRESSES, 'addresses');
export const getAddresses = (pageNumber, pageSize) => (dispatch) => {
    dispatch(setLoading(true));
    http.get(`store/addresses/${pageNumber}/${pageSize}`)
        .then((data) => dispatch(setAddresses(data)))
        .finally(() => dispatch(setLoading(false)));
};

export const saveAddress = (address, cb) => (dispatch) => {
    dispatch(setLoading(true));
    http.post('customer/save-address', address).then((a) => {
        cb();
    });
};

export const deleteAddress = (id, cb) => (dispatch) => {
    dispatch(setLoading(true));
    http.delete(`customer/delete-address/${id}`).then((a) => {
        cb && cb();
    });
};
