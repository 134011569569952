import React from 'react';
import {
    Box, Paper
} from '@material-ui/core';
import NoteIcon from '@material-ui/icons/Note';

export default function SpecialInstruction({ shippingNotes }) {
    if (!shippingNotes) return null;
    return (
        <>
            <div className="table-title-content">
                <span className="table-title">Special Instruction <NoteIcon /></span>
            </div>
            <Box component={Paper} p={3}>
                {shippingNotes}
            </Box>
        </>
    );
}
