import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getSavedJobs } from './action';
import SavedJob from './SavedJob';

const mapStateToProps = ({ savedJobView: { savedJobs } }) => {
    return { savedJobs };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getSavedJobs
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SavedJob);
