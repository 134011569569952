import { Box, Paper, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';

export default function Attachments({ attachments }) {
    if (!attachments || !attachments.length) return null;

    return (
        <Box mt={3} style={{ width: '30rem' }}>
            <Typography variant="h6">
                Attachments
            </Typography>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Qty</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {attachments.map((a) => (
                            <TableRow key={a.id}>
                                <TableCell>{a.name}</TableCell>
                                <TableCell>{a.quantity}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
