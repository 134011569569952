import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import http from 'utils/http';
import ProfileForm from './ProfileForm';
import AWNForm from './AWNForm';
import Validator from 'utils/ValidateUtil';

export default function Content({ user, setUserInfo }) {
    const [profile, setProfile] = useState(null);
    const [saving, setSaving] = useState(false);
    const [showAddAWN, setShowAWN] = useState(false);
    const [showRemoveAwnWarning, setShowRemoveAwnWarning] = useState(false);
    const [saveStatus, setSaveStatus] = useState('');
    const [snackOpen, setSnackOpen] = useState(false);
    const [validations, setValidations] = useState({email: null})

    const allReadOnly = user.customFields.userType === 'telesales';
    useEffect(() => {
        async function getProfile() {
            http.get('customer/profile')
                .then((data) => setProfile(data));
        }
        getProfile();
    }, []);

    if (!profile) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>

                    <Box mb={2}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href="#/account">
                                Account
                            </Link>
                            <Typography color="textPrimary">Account Details</Typography>
                        </Breadcrumbs>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <CircularProgress />
                </Grid>

            </Grid>
        );
    }

    const onFieldChange = (e) => {
        if (allReadOnly) return;

        if(e.target.type === "checkbox") {
            setProfile({ ...profile, [e.target.name]: e.target.checked });
        } else {
            setProfile({ ...profile, [e.target.name]: e.target.value });
        }
    };

    const saveProfile = () => {
        setSaving(true);
        
        if(!validate()) {
            setSaving(false);
            setSaveStatus(false);
            setSnackOpen(true);
            return;
        }

        http.post('customer/profile', profile)
            .then(() => {
                setSaveStatus('success');
                setSnackOpen(true);
            })
            .catch(() => {
                setSaveStatus('error');
                setSnackOpen(true);
            })
            .finally(() => {
                setSaving(false);
                const nu = {
                    ...user,
                    customFields: {
                        ...user.customFields,
                        firstName: profile.firstName,
                        lastName: profile.lastName,
                        profileRequired: false
                    }
                };
                setUserInfo(nu);
            });

        saveAgentWritingNumber();
    };

    const saveAgentWritingNumber = async() => {
        setSaving(true);
        const agentWritingNumber = profile.agentWritingNumber;
        const exists = profile.agentWritingNumbers.some(x => x === agentWritingNumber);
        let writingNumbers = exists ? [...profile.agentWritingNumbers] : [...profile.agentWritingNumbers, agentWritingNumber];

        await http.put(`customer/awn/${encodeURIComponent(agentWritingNumber)}`);

        setSaving(false);
        setUserInfo({
            ...user,
            customFields: {
                ...user.customFields,
                agentWritingNumbers: writingNumbers
            }
        });

        setProfile({...profile,
            agentWritingNumber: agentWritingNumber,
            agentWritingNumbers: writingNumbers });
            
        hideAddAWN();
    };

    const removeAgentWritingNumber = async() => {
        setSaving(true);

        if(profile && profile.agentWritingNumber && profile.agentWritingNumber.length > 0) {
            const awns = profile.agentWritingNumbers.filter(x => x !== profile.agentWritingNumber);
            const awn = awns && awns.length > 0 ? awns[0] : "";

            await http.delete(`customer/awn/${encodeURIComponent(profile.agentWritingNumber)}/${encodeURIComponent(awn)}`);

            setUserInfo({
                ...user,
                customFields: {
                    ...user.customFields,
                    agentWritingNumbers: awns
                }
            });
    
            setProfile({...profile,
                agentWritingNumber: awn,
                agentWritingNumbers: awns });
        }

        setShowRemoveAwnWarning(false);
        setSaving(false);
    }

    const hideAddAWN = () => {
        setShowAWN(false);
    };

    const hideShowRemoveAwnWarning = () => {
        setShowRemoveAwnWarning(false);
    }

    const closeSnack = () => {
        setSnackOpen(false);
    }
    
    const validate = () => {
        let isValid = true;
        if (profile.email && profile.email.length > 0) {
            validations.email = Validator.validateEmail(profile.email) ? null : "Invalid email address format";
            setValidations(validations);
            isValid = !validations.email;
        }

        return isValid;
    }

    let readonly = false;
    if (user.customFields.passthroughLogin && !user.customFields.profileRequired) {
        readonly = true;
    }

    return (
        <Grid container component={Paper} spacing={2}>
            <Grid item xs={12}>

                <Box mb={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/account">
                            Account
                        </Link>
                        <Typography color="textPrimary">Account Details</Typography>
                    </Breadcrumbs>
                </Box>
            </Grid>

            {
                profile && profile.agentWritingNumber && (
                    <>

                        <Grid item xs={3} />
                        <Grid item xs={6}>
                            <PermContactCalendarIcon /> Agent Writing Number: {profile.agentWritingNumber}
                        </Grid>
                        <Grid item xs={3} />
                    </>

                )
            }
            {
                user && user.customFields.profileRequired && (
                    <>
                        <Grid item xs={3} />
                        <Grid item xs={6}>
                            <Alert severity="info">Please create the profile for the agent</Alert>
                        </Grid>
                        <Grid item xs={3} />
                    </>
                )
            }
            <Grid item xs={3} />
            <Grid item xs={6}>
                <ProfileForm
                    onFieldChange={onFieldChange}
                    profile={profile}
                    readonly={readonly}
                    allReadOnly={allReadOnly}
                    user={user}
                    setShowAWN={setShowAWN}
                    isDeleteAwnEnabled={true}
                    setRemoveAgentWritingNumber={setShowRemoveAwnWarning}
                    validations={validations} />
            </Grid>
            <Grid item xs={3} />
            {!allReadOnly && (
                <>
                    <Grid item xs={3} />
                    <Grid item xs={9}>
                        <Button
                            className="btn submit-btn"
                            variant="contained"
                            color="primary"
                            disabled={saving}
                            onClick={saveProfile}
                        >{saving && (
                            <CircularProgress
                                size={12}
                            />
                        )}Save
                        </Button>
                    </Grid>
                </>
            )}
            {user && user.customFields && showAddAWN && (
                <Dialog open aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Please add an Agent Writing Number</DialogTitle>
                    <DialogContent>
                        <AWNForm
                            onFieldChange={onFieldChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={saveAgentWritingNumber} disabled={saving}>
                            Save
                        </Button>
                        <Button color="default" onClick={hideAddAWN} disabled={saving}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            { showRemoveAwnWarning && (
                <Dialog open aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Remove Agent Writing Number</DialogTitle>
                    <DialogContent>
                        Would like to remove the following Agent Writing Number? <Box fontWeight="fontWeightBold">{profile.agentWritingNumber}</Box>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={removeAgentWritingNumber} disabled={saving}>
                            Remove
                        </Button>
                        <Button color="default" onClick={hideShowRemoveAwnWarning} disabled={saving}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Snackbar open={snackOpen} autoHideDuration={5000} onClose={closeSnack}>
                <Alert onClose={closeSnack} severity={saveStatus ? "success" : "error"}>
                    {saveStatus ? "Account Details Saved" : "Account Details Failed to Save"}
                </Alert>
            </Snackbar>
        </Grid>
    );
}
