import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getOrders, updateSearchFilter, clearFilter, cancelOrder } from './action';
import ListView from './ListView';

const mapStateToProps = ({ ordersView }) => {
    return { ...ordersView };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getOrders,
            updateSearchFilter,
            clearFilter,
            cancelOrder
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListView);
