import React, { PureComponent } from 'react';
import DirectOrder from './DirectOrder';

export default class DomView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            steps: []
        };
    }

    componentDidMount() {
        this.initStateData(this.props);
        const { attachmentSettings, loadAttachmentSettings } = this.props;
        if (!attachmentSettings) {
            loadAttachmentSettings();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { match } = this.props;
        if (nextProps.match !== match) {
            this.initStateData(nextProps);
        }
    }

    componentWillUnmount() {
        const { resetInput } = this.props;
        resetInput(null);
    }

    getSteps = (lob, jobType) => {
        if (lob === 'cml') {
            switch (jobType) {
                case 'FJE':
                    return ['Shipping Address', 'Select Items', 'Review & Submit'];
                case 'AJEPP':
                    return ['Select Customer', 'Shipping Address', 'Select Screen', 'Select Items', 'Job Information', 'Review & Submit'];
                default:
                    return ['Select Customer', 'Shipping Address', 'Select Screen', 'Select Items', 'Job Information', 'Review & Submit'];
            }
        } else if (lob === 'p1a') {
            switch (jobType) {
                case 'FJE':
                    return ['Shipping Address', 'Select Items', 'Review & Submit'];
                default:
                    return ['Shipping Address', 'Select Items', 'Job Information', 'Review & Submit'];
            }
        } else if (lob === 'p1b') {
            switch (jobType) {
                case 'FJE':
                    return ['Shipping Address', 'Select Items', 'Review & Submit'];
                default:
                    return ['Select Customer', 'Shipping Address', 'Select Items', 'Job Information', 'Review & Submit'];
            }
        } else {
            return ['Select Customer', 'Shipping Address', 'Select Items', 'Job Information', 'Review & Submit'];
        }
    }

    initStateData = (props) => {
        const {
            resetInput, history: { location: { state } },
            setSavedId, setSavedName, getShippingMethod
        } = props;
        const { match: { params: { lob, jobType } } } = props;
        getShippingMethod(lob, jobType);

        const steps = this.getSteps(lob, jobType);
        this.setState({ steps });

        let activeStep = 0;
        if (state && state.orderData) {
            for (const item of state.orderData.jobItems) {
                item.selected = true;
            }
            resetInput(state.orderData);
            activeStep = steps.length - 2;
        } else {
            const savedJob = state && state.savedJob.jobData;
            const savedId = state && state.savedJob.savedId;
            const savedName = state && state.savedJob.savedName;
            resetInput(savedJob);
            setSavedId(savedId);
            setSavedName(savedName);
            if (savedJob) {
                if (typeof savedJob.activeStep === 'number') {
                    activeStep = savedJob.activeStep;
                } else {
                    activeStep = steps.length - 2;
                }
            }
        }

        this.setActiveStep(activeStep);
    }

    setActiveStep = (step) => {
        this.setState({ activeStep: step });
    }

    submitOrder = (jobOrderData) => {
        const { submitJobOrder, savedId } = this.props;
        submitJobOrder({ ...jobOrderData, savedId });
    }

    getValidates = (stepName) => {
        const { validates } = this.props;
        switch (stepName) {
            case 'Select Customer':
                return validates.selectCustomer;
            case 'Shipping Address':
                return validates.shippingAddress;
            case 'Select Screen':
                return validates.selectScreen;
            case 'Select Items':
                return validates.selectItems;
            case 'Job Information':
                return validates.jobInformation;
            case 'Review & Submit':
                return validates.reviewSubmit;
            default:
                return true;
        }
    }

    isDisableBtn = (steps) => {
        const { activeStep } = this.state;
        const curStep = steps[activeStep];
        return !this.getValidates(curStep);
    }

    render() {
        const { user, currentJob } = this.props;
        if (!user) return null;
        const { activeStep, steps } = this.state;
        return (
            <DirectOrder
                steps={steps}
                activeStep={activeStep}
                setActiveStep={this.setActiveStep}
                isDisableBtn={this.isDisableBtn}
                currentJob={currentJob}
                submitJobOrder={this.submitOrder}
            />
        );
    }
}
