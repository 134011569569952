import actionCreator from 'utils/actionCreator';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('ORDERS_VIEW', {
    LOADING: null,
    SET_SAVES: null,
    SET_ORDERS: null
});

export default actions;

const setLoading = actionCreator(actions.LOADING, 'loading');

const setOrders = actionCreator(actions.SET_ORDERS, 'orders');
export const getOrders = (pageNumber, pageSize, keyword = '') => (dispatch) => {
    dispatch(setLoading(true));
    http.get(`SU/orders/${pageNumber}/${pageSize}/${keyword}`)
        .then((data) => dispatch(setOrders(data)))
        .finally(() => dispatch(setLoading(false)));
};

const setSaves = actionCreator(actions.SET_SAVES, 'saves');
export const getSaves = (pageNumber, pageSize, keyword = '') => (dispatch) => {
    dispatch(setLoading(true));
    http.get(`SU/saves/${pageNumber}/${pageSize}/${keyword}`)
        .then((data) => dispatch(setSaves(data)))
        .finally(() => dispatch(setLoading(false)));
};
