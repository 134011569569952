import { Box, Grid, Paper, Checkbox, Link, IconButton } from '@material-ui/core';
import Category from 'components/Category';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCategoryTree, updateCategoryIsOpen, getFavorites } from './action';
import DetailView from './detail';
import ListView from './ListView';
import history from 'utils/history';

class CatalogView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            refresh: false
        };
    }

    componentDidMount() {
        const {
            categoryTree,
            getCategoryTree: loadTree,
            favorites,
            getFavorites,
            match: {
                params: {
                    product_code: productCode,
                    category_code: categoryCode
                }
            }
        } = this.props;
        if (!categoryTree || !productCode && (!categoryCode || categoryCode === 'dynamic-oe-support-materials')) { // && !categoryTree
            loadTree(categoryCode);
        }
        if (!favorites || favorites.length == 0) {
            getFavorites();
        }
    }

    getCategory = (currentCode) => {
        const { categoryTree, user, categoryIsOpen } = this.props;
        let newCurrcode = currentCode;

        if(categoryIsOpen && currentCode && currentCode.length > 0 && categoryTree && categoryTree.length > 0 
            && user.customFields && user.customFields.p2_Catalog && user.customFields.p2CategoryDefaults) {

            user.customFields.p2CategoryDefaults.forEach(d => {
                let defaults = d.split('!');
                let currentCodes = currentCode.split('!');
                let defaultCodes = [];
                
                for(let x = 0; x < defaults.length; x++) {
                    if(defaults[x] === currentCodes[x] || (defaults[x] === '*' && typeof currentCodes[x] !== 'undefined')) {
                        defaultCodes.push(currentCodes[x]);
                    } else if (!isNaN(defaults[x]) && typeof currentCodes[x] === 'undefined') {
                        defaultCodes.push(defaults[x]);
                    } else {
                        defaultCodes = [];
                        break;
                    }
                }
        
                if(defaultCodes && defaultCodes.length > 0) {
                    let categories = [...categoryTree];
                    let newId = [];
                    let code = [];
                    let index = -1;
            
                    for(let x = 0; x < defaultCodes.length; x++) {
                        if(!isNaN(defaultCodes[x])) {
                            index = Number.parseInt(defaultCodes[x]);
                        } else {
                            code.push(defaultCodes[x]);
                        }
                
                        if(index > -1) {
                            categories = typeof categories[index] !== 'undefined' ? categories[index] : undefined;
                        } else {
                            categories = categories ? categories.filter(c => c.code === code.join('!'))[0] : categoryTree.filter(c => c.code === code.join('!'))[0];
                        }
            
                        if(categories) {
                            newCurrcode = categories.code;
                            categories = categories.inverseParent || [];
                        }
            
                        index = -1;
                    }
            
                    if(newCurrcode && newCurrcode.length > 0 && newCurrcode !== currentCode) {
                        history.push("/catalog/" + newCurrcode);
                        return newCurrcode;
                    }
                }
            });
        }

        return currentCode;

    }

    refreshPage = () => {
        this.setState({refresh: !this.state.refresh});
    }

    render() {
        const {
            categoryTree,
            history,
            products,
            user,
            updateCategoryIsOpen,
            match: {
                params: {
                    category_code: category,
                    product_code: productCode,
                    p2_id, product_type,
                    lineId
                }
            }
        } = this.props;
          const prodData = productCode && products && products.results && products.results.length > 0
            ? products.results.find((p) => p.code === productCode)
            : null;

        let categoryCode = this.getCategory(category);

        return (
            <>
                <Box display={productCode ? 'block' : 'none'}>
                    <Grid container spacing={2}>
                        {productCode && (
                            <DetailView
                                history={history}
                                category={category}
                                product={prodData}
                                productCode={productCode}
                                p2_id={p2_id}
                                product_type={product_type}
                                lineId={lineId}
                            />
                        )}
                    </Grid>
                </Box>
                <Box display={!productCode ? 'block' : 'none'}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            {user && user.customFields && user.customFields.p2_Catalog ? <Box mb={2} p={2} component={Paper}>
                                <Box>
                                    <Link
                                        href="#/catalog/_favorites_"
                                        color="primary"
                                        onClick={() => this.refreshPage() }
                                    >
                                        Favorites
                                    </Link>
                                </Box>
                            </Box> : null}
                            <Box>
                                <Category categoryTree={categoryTree || []} categoryCode={categoryCode} user={user} updateCategoryIsOpen={updateCategoryIsOpen} />
                            </Box>
                        </Grid>
                        <Grid item xs={9} style={{ padding: '.5rem' }}>
                            <ListView categoryCode={categoryCode} refresh={this.state.refresh} />
                        </Grid>
                    </Grid>

                </Box>
            </>
        );
    }
}

const mapStateToProps = ({ catalogView: { categoryTree, products, categoryIsOpen, favoritesOnly }, appView: { user } }) => ({
    categoryTree, products, categoryIsOpen, user, favoritesOnly
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getCategoryTree,
            updateCategoryIsOpen,
            getFavorites
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogView);
