import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

export default function AWNForm({
    onFieldChange, error
}) {

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField required
                    error={error}
                    label="Agent Writing Number"
                    placeholder="Enter an Agent Writing Number"
                    fullWidth
                    margin="normal"
                    name="agentWritingNumber"
                    variant="outlined"
                    size="small"
                    onChange={onFieldChange}
                    helperText={error ? "Agent Writing Number is Required" : ""}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
        </Grid>
    );
}