import {
    Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';


export default function Index({
    open, onClose, addresses, ddd
}) {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Invalid Delivery Date</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Delivery date {ddd} is invalid for these addresses. Please contact the Aetna Fulfillment Team at AetnaFulfillmentTeam@AETNA.com for approval and next steps if you wish to rush shipment.
                </DialogContentText>
                <TableContainer component={Paper} style={{ maxHeight: '400px' }}>
                    <Table aria-label="invalid delivery date table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>Possible Delivery Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {addresses && addresses.map((row) => (
                                <TableRow key={row.address + row.address2}>
                                    <TableCell component="th" scope="row">
                                        {row.first1}  {row.last1}
                                    </TableCell>
                                    <TableCell>{row.address} {row.address2} {row.city} {row.state} {row.zip} </TableCell>
                                    <TableCell style={{ color: 'red' }}>{row.deliveryDate.toLocaleDateString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
