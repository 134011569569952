import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submitChange } from './action';
import PasswordView from './PasswordView';

const mapStateToProps = ({ passwordView: { message, loading }, appView: { user } }) => {
    return { message, loading, user };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            submitChange
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PasswordView);
