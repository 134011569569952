import React, { Component } from 'react';
import {
    Box, TextField, FormControl, Select, MenuItem, InputLabel
} from '@material-ui/core';

class RemarkFieldCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || '',
            errorMessage: '',
            remarkValue: props.value || {},
            pickList: props.args.options || props.pickList || []
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.remarkValue !== this.props.remarkValue) {
            this.setState({ remarkValue: nextProps.remarkValue || {} });
        }

        if (nextProps.pickList !== this.props.pickList && nextProps.pickList) {
            this.setState({ pickList: nextProps.pickList });
        }
    }

    onRadioChange = (item) => {
        this.setState({
            remarkValue: item
        });
    }

    onChangeTextArea = (e) => {
        const { value } = e.target;
        const newRemarkValue = { ...this.state.remarkValue, value };
        this.setState({
            remarkValue: newRemarkValue
        });
        const { row, col, updateRowData } = this.props;
        updateRowData && updateRowData(row, col, newRemarkValue);
    }

    onBlurTextArea = () => {
        const {
            row, col, updateRowData, isReadOnly
        } = this.props;
        const { remarkValue } = this.state;

        const isChangeVal = row && col && row[col.id] ? row[col.id] !== remarkValue : true;
        if (!isReadOnly && updateRowData && isChangeVal) {
            updateRowData(row, col, remarkValue);
        }
    }

    render() {
        const { isReadOnly, prefix, col } = this.props;
        const { remarkValue, pickList } = this.state;
        return (
            <Box component="div" className={`${prefix}-remark-field`}>
                <Box component="div" mb={2}>
                    <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                        <InputLabel>{col.title}</InputLabel>
                        <Select
                            value={remarkValue.title || ''}
                            onChange={null}
                            label={col.title}
                            disabled={isReadOnly}
                        >
                            {pickList && pickList.map((item, index) => {
                                return <MenuItem key={index} value={item.title} onClick={() => this.onRadioChange(item)}>{item.title}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Box component="div">
                    <TextField
                        fullWidth
                        multiline
                        size="small"
                        rows={4}
                        variant="outlined"
                        label={col.title}
                        value={remarkValue.value || ''}
                        disabled={remarkValue.isReadOnly || false}
                        onChange={this.onChangeTextArea}
                        onBlur={this.onBlurTextArea}
                    />
                </Box>
            </Box>
        );
    }
}

RemarkFieldCell.defaultProps = {
    prefix: 'editable-cell'
};
export default RemarkFieldCell;
