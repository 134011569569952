import {
    Box, Button,
    Checkbox,
    Grid,
    Modal, TextField
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import PhoneInput from 'components/PhoneInput';
import StateSelect from 'components/UsStateSelect';
import React, { useEffect, useState } from 'react';
import http from 'utils/http';
import Validator from 'utils/ValidateUtil';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        height: 400,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        padding: '2rem',
        boxShadow: theme.shadows[5]
    },
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const AddressModal = (props) => {
    const [modalStyle] = React.useState(getModalStyle);
    const {
        open, closeAddress, address, saveAddress, showMessage, allowSpouse
    } = props;
    const [addr, setAddr] = React.useState(address || {});
    const [requiredFilled, setRequiredFilled] = useState(false);
    const [validating, setValidating] = useState(false);

    const [includeSpouse, setIncludeSpouse] = useState(false);
    const [promptTitle, setPromptTitle] = useState(false);
    const [title, setTitle] = useState(false);

    useEffect(() => {
        setRequiredFilled(!!addr.lastName
            && !!addr.address
            && !!addr.zip
            && !!addr.state
            && !!addr.country);
    }, [addr, props]);

    const classes = useStyles();

    const onFieldChange = (e) => {
        if (e.target.name === 'zip' && e.target.value && !e.target.value.match(/^\d+$/)) {
            return;
        }
        setAddr({ ...addr, [e.target.name]: e.target.value });
    };

    const showPoBoxMessage = () => {
        showMessage('PO Box is not allowed', 'warning');
    }

    const saveChange = () => {
        if ((Validator.isPOBox(addr.address) || Validator.isPOBox(addr.address2))) {
            showPoBoxMessage();
            return;
        }

        setValidating(true);
        http.post('store/validate-address', {
            Address1: addr.address,
            Address2: addr.address2,
            City: addr.city,
            State: addr.state,
            Zipcode: addr.zip,
            Country: addr.country
        }).then((r) => {
            // {"StatusMessage":"Not Found","Success":false,"POBox":false}
            const isValid = r.Success && !r.POBox;
            if (!isValid) {
                if (r.POBox) {
                    showMessage('PO Box is not allowed', 'warning');
                } else {
                    showMessage('Invalid address', 'warning');
                }
            } else {
                if (addr.address !== r.Address1
                    || addr.address2 !== r.Address2
                    || addr.city !== r.City
                    || addr.state !== r.State
                    || addr.zip !== r.Zipcode
                    || addr.country !== r.Country) {
                    addr.address = r.Address1;
                    addr.address2 = r.Address2;
                    addr.city = r.City;
                    addr.state = r.State;
                    addr.zip = r.Zipcode;
                    addr.country = r.Country;
                }
                saveAddress(addr);
            }
        }).finally(() => setValidating(false));
    };

    const clearAll = () => {
        const a = {};
        for (const k in addr) { a[k] = ''; }
        setAddr(a);
    };

    return (
        <Modal
            open={open}
            onClose={closeAddress}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Grid container spacing={1} className="row-item">
                    <Grid item xs={12}>
                        <TextField
                            label="Title"
                            name="name"
                            fullWidth
                            value={addr.name || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="First Name"
                            name="firstName"
                            value={addr.firstName || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField

                            label="MI"
                            name="middleName"
                            value={addr.middleName || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    {allowSpouse
                        ? (
                            <>
                                <Grid item xs={3}>
                                    <TextField

                                        label="Last Name"
                                        name="lastName"
                                        value={addr.lastName || ''}
                                        onChange={onFieldChange}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        checked={includeSpouse}
                                        onChange={() => setIncludeSpouse(!includeSpouse)}
                                        color="primary"
                                    />
                                    Including Spouse

                                </Grid>
                            </>
                        )
                        : (
                            <Grid item xs={6}>
                                <TextField

                                    label="Last Name"
                                    name="lastName"
                                    value={addr.lastName || ''}
                                    onChange={onFieldChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                />
                            </Grid>

                        )}
                    {includeSpouse ? (
                        <>
                            <Grid item xs={3}>
                                <TextField
                                    label="Spouse First Name"
                                    name="spouseFirstName"
                                    value={addr.spouseFirstName || ''}
                                    onChange={onFieldChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="Spouse First Name"
                                    name="spouseLastName"
                                    value={addr.spouseLastName || ''}
                                    onChange={onFieldChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField

                                    label="Phone#"
                                    name="phone"
                                    value={addr.phone || ''}
                                    onChange={onFieldChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                    onFocus={(e) => {
                                        e.target.select();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField

                                    label="Email"
                                    name="email"
                                    value={addr.email || ''}
                                    onChange={onFieldChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                />
                            </Grid>

                        </>
                    ) : (
                            <>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Phone#"
                                        name="phone"
                                        variant="outlined"
                                        InputProps={{
                                            inputComponent: PhoneInput,
                                            value: addr.phone || '',
                                            onChange: onFieldChange
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}
                                    />
                                    {/* <TextField

                                        label="Phone#"
                                        name="phone"
                                        value={addr.phone || ''}
                                        onChange={onFieldChange}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                    /> */}
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField

                                        label="Email"
                                        name="email"
                                        value={addr.email || ''}
                                        onChange={onFieldChange}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>

                            </>

                        )}
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Address Line 1"
                            name="address"
                            value={addr.address || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Address Line 2"
                            name="address2"
                            value={addr.address2 || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField

                            label="City"
                            name="city"
                            value={addr.city || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <StateSelect
                            size="medium"
                            value={addr.state || ''}
                            onChange={onFieldChange}
                        />

                    </Grid>
                    <Grid item xs={3}>
                        <TextField

                            label="Zip"
                            name="zip"
                            value={addr.zip || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl
                            fullWidth
                            variant="outlined"
                        >
                            <InputLabel id="country-select-outlined-label">Country</InputLabel>
                            <Select
                                labelId="country-select-outlined-label"
                                id="country-select-outlined"
                                fullWidth
                                name="country"
                                value={addr.country || ''}
                                onChange={onFieldChange}
                                label="Country"
                            >
                                <MenuItem value="">Select</MenuItem>
                                <MenuItem value="United States">United States</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Button
                        disabled={!requiredFilled || validating}
                        variant="contained"
                        onClick={saveChange}
                        color="primary"
                    >Save
                    </Button>

                    <Button
                        color="primary"
                        variant="outlined"
                        fontWeight="fontWeightMedium"
                        style={{ marginLeft: '1rem' }}
                        onClick={clearAll}
                    >
                        <ClearAllIcon /> Clear
                    </Button>
                </Box>
            </div>
        </Modal>
    );
};
export default AddressModal;
