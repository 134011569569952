import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { connect } from 'react-redux';
import history from 'utils/history.js';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
    },
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2)
    },
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800]
    }
}));

const mapStateToProps = ({ appView: { user, settings } }) => ({
    user, settings
});

export default connect(mapStateToProps, {})((props) => {
    const classes = useStyles();
    const { user } = props;
    if (user && user.customFields && (user.customFields.npn)) {
        history.push('/broker');
        return null;
    } else if (user && user.customFields && (user.customFields.userType === 'small_group_broker')) {
        history.push('/small-group');
        return null;
    } else if (user && user.customFields && user.customFields.p2_Catalog) {
        history.push('/catalog');
        return null;
    } else if (user && user.customFields && user.customFields.userType === 'telesales') {
        history.push('/telesales');
        return null;
    }

    const { settings } = props;

    let msgs = [];
    if (settings) {
        if (settings.messageBoard && settings.messageBoard.length) {
            msgs = settings.messageBoard;
        } else if (settings.useDefaultMsg) {
            if (user.customFields.userType === 'fsr') {
                msgs.push('Welcome to ONEkit! Our new ordering portal is designed to be easier and more efficient to use. You can learn the new features of our ordering portal by downloading our User Guide from the Need Help page by clicking the question mark icon.');
            } else if (user.customFields.userType.toLowerCase() === 'telesales' || user.customFields.userType.toLowerCase() === 'telesales admins') {
                msgs.push('Welcome to ONEkit! Our new ordering portal is designed to be easier and more efficient to use. You can learn the new features of our ordering portal by downloading our User Guide from the Need Help page by clicking the question mark icon.');
            } else {
                msgs.push('Welcome to Commercial open enrollment site. As we enter into enrollment season, we\'re asking all segments to use a digital first approach for their materials. This approach increases the delivery speed of communications to employees and reduces paper waste. We understand there may be contractual or regulatory requirements that dictate printed pieces must be used, but we ask you  to evaluate the quantity and be thoughtful in the amount you request.');
            }
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container component="main" className={classes.main} maxWidth="md">
                    <Typography variant="body1">ANNOUNCEMENT</Typography>
                    {/* <Typography variant="h5" component="h4" gutterBottom>
                        CMS has lifted it marketing and enrollment sanctions against Aetna Medicare
                    </Typography> */}
                    {
                        msgs.map(m => (
                            <Typography key={m} variant="body1" component="h2" gutterBottom>
                                {m}
                            </Typography>
                        ))
                    }
                </Container>
            </Grid>
            {/* <Grid item xs={3}>
                <Category />
            </Grid>
            <Grid item xs={9} /> */}
        </Grid>
    );
});
