import { Box, Grid, TextField, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AttachmentIcon from '@material-ui/icons/Attachment';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setShippingNotes } from '../action';

const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: '1rem',
        color: theme.palette.primary.main
    },
    th: {
        background: '#eee'
    }
}));

function getStepSummary(stepName, data, classes, jobType, lob) {
    let customer;
    let addresses;
    let totalAddressCount;
    let jobItems;
    let screenData;
    let deliveryDate;
    let job;
    let attachments;
    switch (stepName) {
        case 'Select Customer':
            ({ customer } = data);
            return (
                <>
                    <Typography variant="h6" className={classes.title}>
                        Customer
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead className={classes.th}>
                                <TableRow>
                                    <TableCell>CUSTOMER NAME</TableCell>
                                    <TableCell>CUSTOMER Number</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{customer && customer.customerName}</TableCell>
                                    <TableCell>{customer && customer.customerNumber}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            );
        case 'Shipping Address':
            ({ addresses, deliveryDate, totalAddressCount } = data);
            return (
                <>
                    <Typography variant="h6" className={classes.title}>
                        Shipping Address
                        <span style={{ float: 'right', fontSize: '1.1rem', color: '#111' }}>Shipping Method: {data.shippingMethod || ''}</span>
                    </Typography>

                    <TableContainer style={{ maxHeight: '600px' }} component={Paper}>
                        <Table stickyHeader ize="small" aria-label="address table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ padding: '.5rem' }}>First1</TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>MI1</TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>Last1</TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>First2</TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>MI2</TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>Last2</TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>Company</TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>Address1</TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>Address2</TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>Address3</TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>City</TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>State</TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>Zip</TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>Country</TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>Phone</TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>QTY</TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>Estimated Delivery Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {addresses && addresses.length > 0 && addresses.map((a, i) => (
                                    <TableRow key={i}>
                                        <TableCell style={{ padding: '.5rem' }}>{a.first1}</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>{a.middle1}</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>{a.last1}</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>{a.first2}</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>{a.middle2}</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>{a.last2}</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>{a.company}</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>{a.address}</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>{a.address2}</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>{a.address3}</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>{a.city}</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>{a.state}</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>{a.zip}</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>{a.country}</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>{a.phoneNumber}</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>{a.qty}</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>{deliveryDate && deliveryDate[i]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            {
                                totalAddressCount > addresses.length && (

                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan="20">
                                                <Box>total added addresses: {totalAddressCount}</Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                )
                            }
                        </Table>
                    </TableContainer>
                </>
            );
        case 'Cost Center':
            ({ screenData } = data);
            return (
                <>
                    <Typography variant="h6" className={classes.title}>
                        Market Segment
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead className={classes.th}>
                                <TableRow>
                                    <TableCell>Market Segment</TableCell>
                                    <TableCell>Cost Center</TableCell>
                                    {
                                        (!(lob === 'cml' && lob === 'AJEPP')) && (
                                            <>
                                                <TableCell>Language</TableCell>
                                                <TableCell>Product</TableCell>
                                                <TableCell>State/Model</TableCell>
                                            </>

                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{screenData.marketSegment}</TableCell>
                                    <TableCell>{screenData.costCenter}</TableCell>
                                    {
                                        (!(lob === 'cml' && lob === 'AJEPP')) && (
                                            <>
                                                <TableCell>{screenData.language}</TableCell>
                                                <TableCell>{screenData.product}</TableCell>
                                                <TableCell>{screenData.state}/{screenData.model}</TableCell>
                                            </>

                                        )
                                    }
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            );
        case 'Select Items':
            ({ jobItems, attachments } = data);
            const selected = jobItems
                ? (jobItems.some((j) => j.selected === true)
                    ? jobItems.filter((j) => j.selected === true)
                    : jobItems)
                : [];
            return (
                <>
                    <Typography variant="h6" className={classes.title}>
                        Items
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    {jobType !== 'AJEKT' && <TableCell>Qty</TableCell>}
                                    <TableCell>Item Code</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Version</TableCell>
                                    <TableCell>WHS</TableCell>
                                    <TableCell>INV</TableCell>
                                    <TableCell>VP</TableCell>
                                    <TableCell>DP</TableCell>
                                    <TableCell>TYPE</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selected.map((ji) => (
                                    <TableRow key={ji.sequence}>
                                        {jobType !== 'AJEKT' && <TableCell>{ji.quantity}</TableCell>}
                                        <TableCell>{ji.code}</TableCell>
                                        <TableCell>{ji.name}</TableCell>
                                        <TableCell>{ji.version}</TableCell>
                                        <TableCell>{ji.whs}</TableCell>
                                        <TableCell>{ji.inventoryQuantity === undefined ? '' : Math.max(0, ji.inventoryQuantity)}</TableCell>
                                        <TableCell>{ji.vp}</TableCell>
                                        <TableCell>{ji.dp}</TableCell>
                                        <TableCell>{ji.type}</TableCell>
                                    </TableRow>
                                ))}
                                {
                                    (attachments || []).map((a) => (
                                        <TableRow key={a.id}>
                                            <TableCell>{a.quantity}</TableCell>
                                            <TableCell colSpan={20}><AttachmentIcon color="primary" /> {a.name} ({a.itemType})</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            );
        case 'Job Information':
            ({ jobInfo: job } = data);
            return (
                <>
                    <Typography variant="h6" className={classes.title}>
                        Job Information
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead className={classes.th}>
                                <TableRow>
                                    <TableCell>NAME</TableCell>
                                    <TableCell>DATE REQ</TableCell>
                                    <TableCell>COST CENTER</TableCell>
                                    <TableCell>PHONE</TableCell>
                                    {job.oboBroker && <TableCell>NPN</TableCell>}
                                    <TableCell>{jobType === 'AJEKT' ? ('QTY') : ''}</TableCell>
                                    {job.email1 && <TableCell>Email 1</TableCell>}
                                    {job.email2 && <TableCell>Email 2</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{job.firstName} {job.lastName}</TableCell>
                                    <TableCell>{job.dateReq}</TableCell>
                                    <TableCell>{job.costCenter}</TableCell>
                                    <TableCell>{job.phone} {job.ext}</TableCell>
                                    {job.oboBroker && <TableCell>{job.npn}</TableCell>}
                                    <TableCell>{jobType === 'AJEKT' ? job.quantity : ''}</TableCell>
                                    {job.email1 && <TableCell>{job.email1}</TableCell>}
                                    {job.email2 && <TableCell>{job.email2}</TableCell>}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            );
        default:
            return null;
    }
}

const ReviewStep = (props) => {
    const { lob, jobType } = useParams();
    const classes = useStyles();
    const { shippingNotes, setShippingNotes: saveNotes, imInfo } = props;
    if (lob === 'cml') {
        if (jobType === 'FJE') {
            return (
                <>
                    {getStepSummary('Shipping Address', props, classes)}
                    {getStepSummary('Select Items', props, classes)}
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <TextField
                                style={{ margin: '2rem', display: (jobType === 'FJE' ? 'none' : 'block') }}
                                label="Special Instruction"
                                multiline
                                fullWidth
                                rows={4}
                                column={20}
                                value={shippingNotes}
                                onChange={(e) => saveNotes(e.target.value)}
                                variant="outlined"
                            />

                        </Grid>

                    </Grid>
                </>
            );
        }

        return (
            <>
                {getStepSummary('Select Customer', props, classes)}
                {getStepSummary('Shipping Address', props, classes)}
                {getStepSummary('Cost Center', props, classes, jobType, lob)}
                {getStepSummary('Select Items', props, classes, jobType)}
                {getStepSummary('Job Information', props, classes)}
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <TextField
                            style={{ margin: '2rem', display: (jobType === 'FJE' ? 'none' : 'block') }}
                            label="Special Instruction"
                            multiline
                            fullWidth
                            rows={4}
                            column={20}
                            value={shippingNotes}
                            onChange={(e) => saveNotes(e.target.value)}
                            variant="outlined"
                        />
                    </Grid>
                    {
                        jobType === 'IM' && (
                            <Grid item xs={6} style={{ padding: '2rem' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9}>Name of underwriter approver:</Grid>
                                    <Grid item xs={3}><b>{imInfo.underwriter}</b></Grid>
                                    <Grid item xs={9}>Name of account manager approver:</Grid>
                                    <Grid item xs={3}><b>{imInfo.accountManager}</b></Grid>
                                    <Grid item xs={9}>Your initials indicating you have obtained ECS approval:</Grid>
                                    <Grid item xs={3}><b>{imInfo.initials}</b></Grid>
                                </Grid>
                            </Grid>
                        )
                    }

                </Grid>
            </>
        );
    } else {
        if (jobType === 'FJE') {
            return (
                <>
                    {getStepSummary('Shipping Address', props, classes)}
                    {getStepSummary('Select Items', props, classes)}
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <TextField
                                style={{ margin: '2rem', display: (jobType === 'FJE' ? 'none' : 'block') }}
                                label="Special Instruction"
                                multiline
                                fullWidth
                                rows={4}
                                column={20}
                                value={shippingNotes}
                                onChange={(e) => saveNotes(e.target.value)}
                                variant="outlined"
                            />

                        </Grid>

                    </Grid>
                </>
            );
        }

        return (
            <>
                {getStepSummary('Shipping Address', props, classes)}
                {getStepSummary('Select Items', props, classes, jobType)}
                {getStepSummary('Job Information', props, classes)}
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <TextField
                            style={{ margin: '2rem', display: (jobType === 'FJE' ? 'none' : 'block') }}
                            label="Special Instruction"
                            multiline
                            fullWidth
                            rows={4}
                            column={20}
                            value={shippingNotes}
                            onChange={(e) => saveNotes(e.target.value)}
                            variant="outlined"
                        />

                    </Grid>

                </Grid>
            </>
        );
    }
};

const mapStateToProps = ({
    directOrderView: {
        stepView: {
            customer,
            totalAddressCount,
            addresses,
            screenData,

            costCenter,

            jobItems, attachments,
            jobInfo, deliveryDate,
            shippingNotes,
            shippingMethod,
            imInfo
        }
    }
}) => ({
    customer,
    totalAddressCount,
    addresses,
    screenData,

    costCenter,

    jobItems,
    attachments,
    jobInfo,
    deliveryDate,
    shippingNotes,
    shippingMethod,
    imInfo
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setShippingNotes
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(ReviewStep);
