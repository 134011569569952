import axios from 'axios';
import { showMessage, logout } from 'actions/app';
import store from './createStore';
import history from './history';

class HttpUtil {
    constructor() {
        this.token = null;
    }

    // eslint-disable-next-line class-methods-use-this
    constructRequestURL(url) {
        const apiRoot = process.env.REACT_APP_SERVER_URL || '';
        axios.defaults.crossDomain = true;
        const user = window.localStorage.getItem('user') && window.localStorage.getItem('user') !== 'undefined'
            ? JSON.parse(window.localStorage.getItem('user'))
            : null;
        if (user) {
            axios.defaults.headers.common.Authorization = user.token;
            if (user.impersonate) {
                axios.defaults.headers.common.Impersonate = user.impersonate;
                axios.defaults.headers.common.ImpersonateId = user.impersonateId;
            } else {
                delete axios.defaults.headers.common.Impersonate;
                delete axios.defaults.headers.common.ImpersonateId;
            }
        }

        if (url.startsWith('http://') || url.startsWith('https://')) {
            return url;
        } else {
            return apiRoot + (url.startsWith('/') ? url : `/${url}`);
        }
    }

    get(url, options) {
        return new Promise((resolve, reject) => {
            axios.get(this.constructRequestURL(url), options)
                .then(this.resolveData(resolve, reject))
                .catch(this.handleError(reject));
        });
    }

    post(url, data, options) {
        return new Promise((resolve, reject) => {
            axios.post(this.constructRequestURL(url), data, options)
                .then(this.resolveData(resolve, reject))
                .catch(this.handleError(reject));
        });
    }

    put(url, data, options) {
        return new Promise((resolve, reject) => {
            axios.put(this.constructRequestURL(url), data, options)
                .then(this.resolveData(resolve, reject))
                .catch(this.handleError(reject));
        });
    }

    delete(url, data, options) {
        return new Promise((resolve, reject) => {
            axios.delete(this.constructRequestURL(url), data, options)
                .then(this.resolveData(resolve, reject))
                .catch(this.handleError(reject));
        });
    }

    http(url, options) {
        return new Promise((resolve, reject) => {
            axios(this.constructRequestURL(url), options)
                .then(this.resolveData(resolve, reject))
                .catch(this.handleError(reject));
        });
    }

    // eslint-disable-next-line class-methods-use-this
    showError(error) {
        // eslint-disable-next-line no-console
        store.dispatch(showMessage(error.message || error, 'error'));
    }

    resolveData(resolve, reject) {
        return (result) => {
            if (result.data.isError) {
                const { message } = result.data;
                this.showError(message);
                return reject(message);
            } else {
                return resolve(result.data.result || result.data);
            }
        };
    }

    handleError(reject) {
        return (err) => {
            if (err.status === 401 || (err.response && err.response.status === 401)) {
                store.dispatch(logout());
                history.push('/login');
                return reject(['authentication fail']);
            }
            if (err.status === 403 || (err.response && err.response.status === 403)) {
                history.push('/no-permission');
                return reject(['no permission']);
            }
            if (err.status === 404 || (err.response && err.response.status === 404)) {
                const msg = 'Error: Request failed with status code 404';
                this.showError(msg);
                return reject([{ errorCode: 'notfound', message: msg }]);
            }
            if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.length) {
                const { errors } = err.response.data;
                this.showError(errors);
                return reject(errors);
            }

            this.showError(err);
            return reject([{ errorCode: 'unknown', message: err.message ? err.message : JSON.stringify(err) }]);
        };
    }
}

const http = new HttpUtil();
export default http;
