import {
    AppBar,
    Box, Button,
    Container, Grid, IconButton, Paper, Toolbar, Tooltip
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import MuiAlert from '@material-ui/lab/Alert';
import Logo from 'assets/images/Logo.svg';
import { getBasketItems } from 'pages/cart/action';
import HelpTypeSelect from 'pages/help/HelpTypeSelect';
import { setUserInfo } from 'pages/login/action';
import ProfileForm from 'pages/profile/ProfileForm';
import AWNForm from 'pages/profile/AWNForm';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import appLogo from 'utils/AppLogo';
import history from 'utils/history';
import http from 'utils/http';
import { hideMessage, loadAppSettings, logout } from '../actions/app';
import CartIcon from './CartIcon';
import SimpleMenu from './SimpleMenu';
import Validator from 'utils/ValidateUtil';

const buildTo = (p, c) => `/direct-order/${p.code}/${c.code}`;
// const jobMenus = [
//     { code: 'FJE', label: 'Add Fast Job', to: buildTo },
//     { code: 'AJEPP', label: 'Add Pick&Pack Job', to: buildTo },
//     { code: 'AJEKT', label: 'Add Kit Job', to: buildTo },
//     { code: 'IM', label: 'Add IM Job', to: buildTo }
// ];

const useStyles = makeStyles(() => ({
    logo: {
        maxHeight: '2rem'
    },
    aetnaLogo: {
        maxHeight: '3rem'
    }
}));
function Alert(props) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NavBar = (props) => {
    const classes = useStyles();
    const {
        isAuthUser, user, appMessage,
        basketItems, getBasketItems, hideMessage,
        settings, loadAppSettings: refreshSettings, logoutLogo
    } = props;

    if (isAuthUser && !basketItems) {
        getBasketItems();
        if (!settings) {
            refreshSettings();
        }
    }

    const [saving, setSaving] = useState(false);
    const [helpTypeOpen, setHelpTypeOpen] = useState(false);
    const [showAddAWN, setShowAWN] = useState(false);
    const [profile, setProfile] = useState(null);
    const [awnError, setAwnError] = useState(false);
    const [validations, setValidations] = useState({email: null})

    useEffect(() => {
        async function getProfile() {
            http.get('customer/profile')
                .then((data) => setProfile(data));
        }
        isAuthUser && getProfile();
    }, [isAuthUser]);

    const onFieldChange = (e) => {
        props.setUserInfo({
            ...user,
            customFields: {
                ...user.customFields,
                passthroughProfile: {
                    ...user.customFields.passthroughProfile,
                    [e.target.name]: e.target.value
                }
            }
        });

        setProfile({...profile,
            [e.target.name]: e.target.value});
    };

    const savePassthroughProfile = () => {
        if(!ProfileFormValidate()) {
            return;
        }

        setSaving(true);
        http.post('customer/profile', profile)
            .finally(() => {
                setSaving(false);
                const nu = {
                    ...user,
                    customFields: {
                        ...user.customFields,
                        firstName: profile.firstName,
                        lastName: profile.lastName,
                        profileRequired: false,
                        awnRequired: false
                    }
                };
                props.setUserInfo(nu);
            });
    };

    const saveAgentWritingNumber = async () => {
        if(!ProfileFormValidate() && awnError) {
            return;
        }

        setSaving(true);
        const agentWritingNumber = profile.agentWritingNumber;
        const exists = profile.agentWritingNumbers.some(x => x === agentWritingNumber);
        let writingNumbers = exists ? [...profile.agentWritingNumbers] : [...profile.agentWritingNumbers, agentWritingNumber];
        http.put(encodeURI(`customer/awn/${agentWritingNumber}`));

        setSaving(false);
        props.setUserInfo({
            ...user,
            customFields: {
                ...user.customFields,
                agentWritingNumbers: writingNumbers
            }
        });

        setProfile({...profile,
            agentWritingNumber: agentWritingNumber,
            agentWritingNumbers: writingNumbers });
        hideAddAWN();
    };

    const ProfileFormValidate = () => {
        let isValid = !(user.customFields.awnRequired && (profile.agentWritingNumber == null || profile.agentWritingNumber.length === 0));
        
        if(!isValid) {
            setAwnError(true);
        }
        else if (awnError) {
            setAwnError(false);
        }

        if (profile.email && profile.email.length > 0) {
            validations.email = Validator.validateEmail(profile.email) ? null : "Invalid email address format";
            setValidations(validations);

            isValid = !isValid ? isValid : !validations.email;
        }

        return isValid; 
    }

    const hideAddAWN = () => {
        setShowAWN(false);
    };

    const menuItems = [];

    let helpTypeOptions = [];
    // if (user && user.customFields && user.customFields.userType === 'su') {
    //     const items = [];
    //     items.push({ code: 'FJE', label: 'Add Fast Job', to: buildTo });
    //     items.push({ code: 'AJEPP', label: 'Add Pick&Pack Job', to: buildTo });
    //     // items.push({ code: 'AJEKT', label: 'Add Kit Job', to: buildTo });
    //     // items.push({ code: 'IM', label: 'Add IM Job', to: buildTo });

    //     menuItems.push({
    //         code: 'p1a',
    //         label: 'Medicare Individual',
    //         items
    //     });
    //     const items1 = [];
    //     items1.push({ code: 'FJE', label: 'Add Fast Job', to: buildTo });
    //     items1.push({ code: 'AJEPP', label: 'Add Pick&Pack Job', to: buildTo });
    //     items1.push({ code: 'AJEKT', label: 'Add Kit Job', to: buildTo });
    //     items1.push({ code: 'IM', label: 'Add IM Job', to: buildTo });
    //     menuItems.push({
    //         code: 'p1b',
    //         label: 'Medicare Group',
    //         items: items1
    //     });

    //     const items2 = [];
    //     items2.push({ code: 'FJE', label: 'Add Traditional Fast Job', to: buildTo });
    //     items2.push({ code: 'AJEPP', label: 'Add Traditional Pick&Pack Job', to: buildTo });
    //     items2.push({ code: 'AJEKT', label: 'Add Traditional Kit Job', to: buildTo });
    //     items2.push({ code: 'IM', label: 'Add Traditional IM Job', to: buildTo });
    //     // items2[items2.length - 1].divider = true;
    //     // items2.push({
    //     //     code: 'dynamic-oe-support-materials', label: 'Dynamic OE Support Materials', to: () => '/catalog/dynamic-oe-support-materials'
    //     // });
    //     menuItems.push({
    //         code: 'cml',
    //         label: 'Commercial',
    //         items: items2
    //     });
    //     menuItems.push({
    //         code: 'sg',
    //         label: 'Small Group',
    //         items: [{
    //             code: 'sg-kit', label: 'Small Group Kit', to: () => '/small-group'
    //         }]
    //     });
    //     helpTypeOptions = menuItems.map((m) => ({ code: m.code, label: m.label }));
    // } else
    if (user && user.customFields && user.customFields.userType === 'mso') {
        const items = [];
        items.push({ code: 'FJE', label: 'Add Fast Job', to: buildTo });

        menuItems.push({
            code: 'p1a',
            label: 'Medicare Individual',
            items
        });
        const items1 = [];
        items1.push({ code: 'FJE', label: 'Add Fast Job', to: buildTo });
        menuItems.push({
            code: 'p1b',
            label: 'Medicare Group',
            items: items1
        });

        const items2 = [];
        items2.push({ code: 'FJE', label: 'Add Traditional Fast Job', to: buildTo });
        menuItems.push({
            code: 'cml',
            label: 'Commercial',
            items: items2
        });
        // menuItems.push({
        //     code: 'sg',
        //     label: 'Small Group',
        //     items: [{
        //         code: 'sg-kit', label: 'Small Group Kit', to: () => '/small-group'
        //     }]
        // });
        helpTypeOptions = menuItems.map((m) => ({ code: m.code, label: m.label }));
    } else if (user && user.customFields && user.customFields.marketSegments) {
        if (user.customFields.marketSegments.includes('MCARE') || user.customFields.marketSegments.includes('MIND')) {
            const items = [];
            if (user.customFields.fastJobEntry === 'Y' && user.customFields.userType !== 'fsr') {
                items.push({ code: 'FJE', label: 'Add Fast Job', to: buildTo });
            }
            if (user.customFields.pickPackJobEntry === 'Y') {
                items.push({ code: 'AJEPP', label: 'Add Pick&Pack Job', to: buildTo });
            }

            menuItems.push({
                code: 'p1a',
                label: 'Medicare Individual',
                items
            });
        }
        if (user.customFields.marketSegments.includes('MCARE') || user.customFields.marketSegments.includes('MGRP')) {
            const items = [];
            if (user.customFields.fastJobEntry === 'Y') {
                items.push({ code: 'FJE', label: 'Add Fast Job', to: buildTo });
            }
            if (user.customFields.pickPackJobEntry === 'Y') {
                items.push({ code: 'AJEPP', label: 'Add Pick&Pack Job', to: buildTo });
            }
            if (user.customFields.kitJobEntry === 'Y') {
                items.push({ code: 'AJEKT', label: 'Add Kit Job', to: buildTo });
            }
            if (user.customFields.imJobEntry === 'Y') {
                items.push({ code: 'IM', label: 'Add IM Job', to: buildTo });
            }
            menuItems.push({
                code: 'p1b',
                label: 'Medicare Group',
                items
            });
        }

        helpTypeOptions = menuItems.map((m) => ({ code: m.code, label: m.label }));
        if (user.customFields.marketSegments.filter((s) => s !== 'MCARE' && s !== 'MIND' && s !== 'MGRP').length || user.customFields.oeEntry === 'Y') {
            const items = [];

            let oeItem = null;
            if (user.customFields.oeEntry === 'Y') {
                oeItem = {
                    code: 'dynamic-oe-support-materials',
                    label: 'Dynamic OE Support Materials',
                    to: () => '/catalog/dynamic-oe-support-materials'
                };
                items.push(oeItem);
                helpTypeOptions.push({ code: 'oe', label: 'Dynamic OE' });
            }

            if (user.customFields.fastJobEntry === 'Y') {
                if (oeItem) oeItem.divider = true;
                items.push({ code: 'FJE', label: 'Add Traditional Fast Job', to: buildTo });
            }
            if (user.customFields.pickPackJobEntry === 'Y') {
                if (oeItem) oeItem.divider = true;
                items.push({ code: 'AJEPP', label: 'Add Traditional Pick&Pack Job', to: buildTo });
            }
            if (user.customFields.kitJobEntry === 'Y') {
                if (oeItem) oeItem.divider = true;
                items.push({ code: 'AJEKT', label: 'Add Traditional Kit Job', to: buildTo });
            }
            if (user.customFields.imJobEntry === 'Y') {
                if (oeItem) oeItem.divider = true;
                items.push({ code: 'IM', label: 'Add Traditional IM Job', to: buildTo });
            }
            if (items.length > 0) {
                menuItems.push({
                    code: 'cml',
                    label: 'Commercial',
                    items
                });
            }
            helpTypeOptions.push({ code: 'cml', label: 'Commercial Traditional' });
        }

        if (user.customFields.sgEntry === 'Y') {
            menuItems.push({
                code: 'sg',
                label: 'Small Group',
                items: [{
                    code: 'sg-kit', label: 'Small Group Kit', to: () => '/small-group'
                }]
            });
            helpTypeOptions.push({ code: 'sg', label: 'Small Group' });
        }
    }

    const f = helpTypeOptions.find((o) => o.code === 'cml');
    if (f) {
        f.label = 'Commercial Traditional';
    }

    const handleSnackClose = () => {
        hideMessage();
    };
    const handleLogoutClick = () => {
        props.logout();
    };
    const showHelpOptions = () => {
        if (user && user.customFields && user.customFields.userType && user.customFields.userType.toLowerCase().startsWith('telesales')) {
            history.push('/help/p1a');
            return;
        }
        if (user && user.customFields && user.customFields.userType && user.customFields.userType.toLowerCase().startsWith('broker')) {
            history.push('/help/p1a');
            return;
        }
        if (user && user.customFields && user.customFields.p2_Catalog) {
            history.push('/help/p2');
        } else if (helpTypeOptions.length === 1) {
            history.push(`/help/${helpTypeOptions[0].code}`);
        } else {
            setHelpTypeOpen(true);
        }
    };
    const hideHelpOptions = () => {
        setHelpTypeOpen(false);
    };
    const onHelpTypeSelect = (t) => {
        setHelpTypeOpen(false);
        history.push(`/help/${t}`);
    };

    const createMarkup = (msg) => {
        return { __html: msg };
    };

    let msg = null;
    if (user && user.customFields && user.customFields.p2_Catalog && settings && settings.messageBoard && settings.messageBoard.length) {
        msg = settings.messageBoard[0];
    }

    let logo;

    if (user && user.customFields && user.customFields.brandLogo) {
        logo = user.customFields.brandLogo;
    } else if (user !== null && user && user.customFields && user.customFields.p2_Catalog && user.customFields.p2_Catalog.length > 0 && (!logo || logo.length === 0)) {
        logo = user.customFields.p2AetnaLogo;
    }

    if (!logo) {
        logo = appLogo();
    }

    if (logoutLogo) {
        logo = logoutLogo;
    }

    let awnRequired = ((user != null && user.customFields != null && user.customFields.awnRequired && profile != null && 
        (profile.agentWritingNumber == null || profile.agentWritingNumber.length === 0 || profile.agentWritingNumber == null || profile.agentWritingNumbers == null || profile.agentWritingNumbers.length === 0)));

    return (
        <AppBar position="static" color="transparent" style={{ display: 'flex', height: '7rem', marginBottom: '2rem' }}>
            <Container fixed>
                {appMessage && (
                    <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                        <Alert onClose={handleSnackClose} severity={appMessage.severity}>
                            {appMessage.message}
                        </Alert>
                    </Snackbar>
                )}
                {msg && (
                    <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{ maxWidth: '25%', position: 'absolute' }}>
                        <Box component={Paper} style={{ padding: '.5rem' }}>
                            <span dangerouslySetInnerHTML={createMarkup(msg)} />
                        </Box>
                    </Snackbar>
                )}
                <Toolbar>
                    <Grid container style={{ height: '7rem', padding: '1rem' }}>
                        {user && user.customFields && (
                            <>
                                <Grid className="nav-header-welcome" item xs={6}>
                                    {user && user.customFields && `Welcome, ${(user.customFields.passthroughName || user.customFields.firstName || user.customFields.name || user.userName)}`}
                                </Grid>
                                <Grid className="nav-header-menu" item xs={6}>
                                    <Grid container justify="flex-end">
                                        <Grid item xs={6}>
                                            <Grid container justify="space-around" p={2}>
                                                <Link
                                                    component="button"
                                                    variant="body2"
                                                    onClick={showHelpOptions}
                                                >
                                                    Need Help <ContactSupportIcon color="primary" />
                                                </Link>
                                                <HelpTypeSelect open={helpTypeOpen} handleClose={hideHelpOptions} helpTypes={helpTypeOptions} handleSelect={onHelpTypeSelect} />
                                                <Link href="#/account">
                                                    {user && user.impersonate
                                                        ? (<Tooltip title={`Impersonate: ${user.impersonate}`} aria-label="Impersonate"><SupervisorAccountIcon /></Tooltip>)
                                                        : <AccountCircleIcon color="primary" />}
                                                </Link>
                                                <CartIcon basketItems={basketItems} />
                                                <IconButton
                                                    aria-label="ExitToAppIcon"
                                                    aria-describedby="logout"
                                                    variant="contained"
                                                    size="small"
                                                    onClick={handleLogoutClick}
                                                >
                                                    <Tooltip title="Log out" placement="right">
                                                        <ExitToAppIcon />
                                                    </Tooltip>
                                                </IconButton>

                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </Grid>
                            </>
                        )}

                        <Grid item xs={3} style={{ textAlign: 'center' }}>
                            <Link href="#/">
                                <img src={logo || Logo} alt="Aetna" className={logo && logo.length > 0 ? classes.aetnaLogo : classes.logo} />
                            </Link>
                        </Grid>
                        {isAuthUser && (
                            <Grid item xs={9}>
                                <Grid container justify="flex-end">
                                    {menuItems && menuItems.map((dm) => (
                                        <Box key={dm.code} ml={2}>
                                            {
                                                dm.to
                                                    ? (
                                                        <Button
                                                            onClick={() => history.push(dm.to)}
                                                        >
                                                            {dm.label}
                                                        </Button>

                                                    )
                                                    : <SimpleMenu menu={dm} />
                                            }

                                        </Box>
                                    ))}
                                    {/*
                                    {user.customFields.brandLogo && (
                                        <Box ml={1}>
                                            <img src={user.customFields.brandLogo} alt="Brand Logo" className={classes.logo} />
                                        </Box>
                                    )} */}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Toolbar>
                {user && user.customFields && profile && (user.customFields.profileRequired || user.customFields.awnRequired) && (
                    <Dialog open aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Please review and confirm the AGENT info</DialogTitle>
                        <DialogContent>
                            <ProfileForm
                                onFieldChange={onFieldChange}
                                readonly={false}
                                agent
                                profile={profile || {}}
                                user={user}
                                setShowAWN={setShowAWN}
                                awnError={awnError}
                                validations={validations}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" onClick={savePassthroughProfile} disabled={saving}>
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
                {user && user.customFields && showAddAWN && (
                    <Dialog open aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Please add an Agent Writing Number</DialogTitle>
                        <DialogContent>
                            <AWNForm
                                onFieldChange={onFieldChange}
                                error={awnError}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" onClick={saveAgentWritingNumber} disabled={saving}>
                                    Save
                            </Button>
                            {!awnRequired ? <Button color="default" onClick={hideAddAWN} disabled={saving}>
                                    Cancel
                            </Button> : null}
                        </DialogActions>
                    </Dialog>
                )}
            </Container>
        </AppBar>
    );
};

const mapStateToProps = ({ appView, cartView: { basketItems } }) => {
    return {
        isAuthUser: appView.isAuthUser,
        user: appView.user,
        appMessage: appView.appMessage,
        settings: appView.settings,
        basketItems,
        logoutLogo: appView.logoutLogo
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            hideMessage, logout, getBasketItems, loadAppSettings, setUserInfo
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(
    NavBar
);
