import checkout from '.';
import checkoutActions from './action';

const initialState = {
    loading: false,
    shippingMethods: null,
    shippingAddresses: null,
    basketItems: null,
    planDocuments: null,
    balance: null,
    contactInfo: null,
    used: null,
    marketSegmentXCostCenters: null,
    estimatedDeliveryDate: null,
    deliveryDate: null,
    hasUserDeliveryDate: false,
    loadingMessage: "",
    itemInventory: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case checkoutActions.LOADING:
            return { ...state, isLoading: action.loading };
        case checkoutActions.LOADING_MESSAGE:
            return { ...state, loadingMessage: action.loadingMessage };
        case checkoutActions.SET_MS_X_CC:
            return { ...state, marketSegmentXCostCenters: action.marketSegmentXCostCenters };
        case checkoutActions.SET_BASKET_ITEMS:
            return { ...state, basketItems: action.basketItems };
        case checkoutActions.SET_CONTACT_INFO:
            return { ...state, contactInfo: action.contactInfo };
        case checkoutActions.SET_SHIPPING_METHODS:
            return { ...state, shippingMethods: action.shippingMethods };
        case checkoutActions.SET_SHIPPING_ADDRESSES:
            return { ...state, shippingAddresses: action.shippingAddresses };
        case checkoutActions.SET_PLAN_DOCUMENTS:
            return { ...state, planDocuments: action.planDocuments };
        case checkoutActions.SET_BALANCE:
            return { ...state, balance: action.balance };
        case checkoutActions.SET_USED:
            return { ...state, used: action.used };
        case checkoutActions.SET_EST_DELIVERY_DATE:
            return { ...state, estimatedDeliveryDate: action.estimatedDeliveryDate, deliveryDate: action.deliveryDate, hasUserDeliveryDate: action.hasUserDeliveryDate };
        case checkoutActions.CLEAR_DATA:
            return { ...state, estimatedDeliveryDate: null, deliveryDate: null, hasUserDeliveryDate: false };
        case checkoutActions.SET_ITEM_INVENTORY:
            return { ...state, itemInventory: action.itemInventory }
        default:
            return state;
    }
}
