import { combineReducers } from 'redux';
import appView from './AppReducer';
import basketView from './components/basket/reducer';
import planComparisonView from './components/customize/PlanComparison/reducer';
import accountView from './pages/account/reducer';
import addressesView from './pages/addresses/reducer';
import brokerView from './pages/broker/reducer';
import cartView from './pages/cart/reducer';
import catalogView from './pages/catalog/reducer';
import checkoutView from './pages/checkout/reducer';
import demo from './pages/demo/reducer';
import directOrderView from './pages/direct-order/reducerExport';
import helpView from './pages/help/reducer';
import loginView from './pages/login/reducer';
import orderManagementView from './pages/order-management/reducer';
import ordersView from './pages/orders/reducer';
import passthroughLoginView from './pages/passthrough-login/reducer';
import passwordView from './pages/password/reducer';
import planSponsorView from './pages/plan-sponsor/reducer';
import profileView from './pages/profile/reducer';
import savedJobView from './pages/saved-job/reducer';
import savedOrderView from './pages/saved-order/reducer';
import smallGroupView from './pages/small-group/reducer';
import telesalesView from './pages/telesales/reducer';
import trakView from './pages/trak/reducer';

export default combineReducers({
    demo,
    accountView,
    appView,
    addressesView,
    basketView,
    brokerView,
    cartView,
    catalogView,
    checkoutView,
    directOrderView,
    helpView,
    loginView,
    passthroughLoginView,
    orderManagementView,
    ordersView,
    passwordView,
    planComparisonView,
    planSponsorView,
    profileView,
    savedOrderView,
    savedJobView,
    smallGroupView,
    telesalesView,
    trakView
});
