import {
    Box, Grid, InputAdornment, Paper,
    Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField,
    Typography
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import SearchIcon from '@material-ui/icons/Search';
import ConfirmDeleteDialog from 'pages/cart/ConfirmDeleteDialog';
import React, { PureComponent } from 'react';
import http from 'utils/http';

const pageSize = 12;

class SavedJob extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            pageNumber: 1,
            openDeleteConfirm: false,
            deletingId: null,
            orderId: ''
        };
    }

    componentDidMount() {
        const { pageNumber } = this.state;
        const { getSavedJobs: loadData } = this.props;
        loadData(pageNumber, pageSize);
    }

    handleChangePage = (_event, pageNumber) => {
        const { getSavedJobs: loadData } = this.props;
        loadData(pageNumber + 1, pageSize);
    }

    addSavedItemToCart = (listId, itemId) => {
        const { addSavedItemToCart: addItemToCart } = this.props;
        addItemToCart(listId, itemId);
    }

    handleChangePage = (_event, pageNumber) => {
        const { getSavedJobs: loadData } = this.props;
        loadData(pageNumber + 1, pageSize);
    }

    setOrderId = (orderId) => {
        this.setState({ orderId });
    }

    searchOrder = () => {
        this.setState({ pageNumber: 1 });
        const { orderId } = this.state;
        const { getSavedJobs: loadData } = this.props;
        loadData(1, pageSize, orderId);
    }

    continueWork = (savedName, savedId, savedJob) => {
        const { history } = this.props;
        history.push({
            pathname: savedJob.hash.replace('#', ''),
            state: { savedJob: { ...savedJob, savedId, savedName } }
        });
    }

    deleteWork = (deletingId) => {
        this.setState({ deletingId, openDeleteConfirm: true });
    }

    onConfirmClose = (t) => {
        if (t) {
            const { deletingId } = this.state;
            http.get(`customer/delete-job/${deletingId}`).then(() => {
                this.setState({ deletingId: null, openDeleteConfirm: false, pageNumber: 1 });
                const { getSavedJobs: loadData } = this.props;
                loadData(1, pageSize);
            });
        } else {
            this.setState({ deletingId: null, openDeleteConfirm: false });
        }
    }

    render() {
        const { savedJobs } = this.props;
        const { openDeleteConfirm, orderId } = this.state;
        if (!savedJobs) {
            return (
                <Box>
                    <Typography variant="h6" gutterBottom component="p">
                        loading...
                    </Typography>
                </Box>
            );
        }
        // else if (savedJobs.totalCount === 0 && !orderId) {
        //     return (
        //         <Grid container spacing={1}>
        //             <Grid item xs={12}>
        //                 <Typography variant="h6" gutterBottom component="p">
        //                     You don&apos;t have any saved edit.
        //                 </Typography>
        //             </Grid>
        //         </Grid>
        //     );
        // }
        return (
            <Grid container spacing={1}>
                <Grid item container>
                    <Grid item container>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                label="Saved Order #"
                                                value={orderId}
                                                onKeyPress={(e) => { if (e.key === 'Enter') { this.searchOrder(); } }}
                                                onChange={(e) => this.setOrderId(e.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton aria-label="search" onClick={this.searchOrder}>
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>Saved On</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {savedJobs.results.map((j) => (
                                        <TableRow key={j.id}>
                                            <TableCell>{j.name}</TableCell>
                                            <TableCell>{j.orderNumber || j.orderId}</TableCell>
                                            <TableCell>{new Date(j.createdOn).toLocaleDateString()}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => this.continueWork(j.name, j.id, j.customFields)}>
                                                    <RestoreIcon
                                                        color="primary"
                                                    />
                                                </IconButton>
                                                <IconButton onClick={() => this.deleteWork(j.id)}>
                                                    <DeleteIcon
                                                        color="primary"
                                                    />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan={20}>
                                            <TablePagination
                                                component="div"
                                                count={savedJobs.totalCount}
                                                page={savedJobs.pageNumber - 1}
                                                rowsPerPage={savedJobs.pageSize}
                                                rowsPerPageOptions={[savedJobs.pageSize]}
                                                onChangePage={this.handleChangePage}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        <ConfirmDeleteDialog open={openDeleteConfirm} onClose={this.onConfirmClose} msg="Are you sure you want to remove this saved item?" />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default SavedJob;
