import {
    Box, Button, ButtonGroup, Container, CssBaseline, Divider, Grid, IconButton,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography,
    Link, Breadcrumbs
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Warning';
import CIModal from 'components/ContactInfoModal';
import React, { PureComponent } from 'react';
import http from 'utils/http';
import history from '../../utils/history';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import OECartView from './OECartView';
import P2CartView from './P2CartView';
import SGPlanDocumentPanel from './SGPlanDocumentPanel';
import './shop-cart-view.scss';

export default class CartView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            openSaveOrder: false,
            savedOrderName: '',
            openCI: false,
            ciLineId: null,

            openDeleteConfirm: false,
            deletingLineId: null,

            itemQtys: null,
            showRemovedItemsDialog: false,
            validated: false,
            itemsRemoved: []
        };
    }

    componentDidMount() {
        this.setState({ itemQtys: null });
        const { getBasketItems } = this.props;
        getBasketItems();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getPricing } = this.props;
        const {
            basketItems
        } = nextProps;
        const { itemQtys } = this.state;
        if ((!itemQtys && basketItems && basketItems.length) || (itemQtys && basketItems && itemQtys.length !== basketItems.length)) {
            this.setState({ itemQtys: basketItems.map((b) => ({ lineId: b.lineId, quantity: b.quantity })) });
            if (basketItems && basketItems.length && basketItems[0].customFields && basketItems[0].customFields.program === 'OE') {
                getPricing();
            }
        }
    }

    componentWillUnmount() {
        this.setState({ itemQtys: null });
    }

    // base
    updateItemData = (item, val, key) => {
        const { productList } = this.state;
        const oldProductList = productList;

        const newProductList = oldProductList.map((x) => {
            if (x.id === item.id) {
                return {
                    ...x,
                    [key]: val
                };
            } else {
                return x;
            }
        });
        this.setState({ productList: [...newProductList] });
    }

    removeItem = (deletingLineId, needConfirm) => {
        if (needConfirm) {
            this.setState({ openDeleteConfirm: true, deletingLineId });
        } else {
            const { removeBasketItem } = this.props;
            removeBasketItem(deletingLineId);
        }
    }

    onConfirmClose = (isOK) => {
        if (isOK) {
            const { deletingLineId } = this.state;
            const { removeBasketItem } = this.props;
            removeBasketItem(deletingLineId);
        }

        this.setState({ openDeleteConfirm: false, deletingLineId: null });
    }

    changeItemQty = (lineId, qty, max) => {
        if (qty && !isNaN(qty)) {
            const pi = parseInt(qty, 10);
            let newQty = (!max || pi <= max) ? pi : max;
            newQty = newQty < 1 ? 1 : newQty;
            const { updateBasketItemQty } = this.props;
            updateBasketItemQty(lineId, newQty);
        }
    }

    goCheckout = async () => {
        const { user, basketItems } = this.props;
        let itemsRemoved = [];
        const { validated } = this.state;
        if (user && user.customFields.p2_Catalog && !validated) {
            await http.get('store/validate-basket-items')
                .then((c) => {
                    itemsRemoved = c;

                    if (c && c.length > 0) {
                        this.setState({ itemsRemoved, showRemovedItemsDialog: true });
                    } else {
                        this.setState({ validated: true });
                    }
                });

            if (this.state.validated) {
                history.push('/checkout');
            }
        } else if (basketItems.length && basketItems.some((i) => i.customFields && i.customFields.program === 'Small Group')) {
            const { jobItems: planDocuments, attachments, resetInput } = this.props;
            http.post('OE/save-plan-documents', { systemPlanDocuments: planDocuments, userPlanDocuments: attachments })
                .then(() => {
                    resetInput();
                    history.push('/checkout');
                });
        } else {
            history.push('/checkout');
        }
    }

    fallbackSrc = (e) => {
        e.target.src = '//cdndev.memberdoc.com/f/img_no_thumb.jpg';
        e.target.style.width = '60px';
    }

    onToggle = (lineId) => {
        const { selected } = this.state;
        if (selected.includes(lineId)) {
            this.setState({ selected: selected.filter((s) => s !== lineId) });
        } else {
            selected.push(lineId);
            this.setState({ selected: [...selected] });
        }
    }

    showSaveOrder = () => {
        this.setState({ openSaveOrder: true });
    }

    hideSaveOrder = () => {
        this.setState({ openSaveOrder: false });
    }

    saveOrderForLater = () => {
        const { saveItemsForLater } = this.props;
        const { savedOrderName, selected } = this.state;
        saveItemsForLater(savedOrderName, selected);
        this.setState({ selected: [] });
        this.hideSaveOrder();
    }

    setSavedOrderName = (savedOrderName) => {
        this.setState({ savedOrderName });
    }

    showCI = (ciLineId) => {
        this.setState({ openCI: true, ciLineId });
    }

    hideCI = () => {
        this.setState({ openCI: false, ciLineId: null });
    }

    onCIApproved = (ci) => {
        const { basketItems, setBasketItems } = this.props;
        const {
            ciLineId
        } = this.state;
        const item = basketItems.find((i) => i.lineId === ciLineId);
        item.customFields.CI = ci;
        setBasketItems([...basketItems]);
        this.hideCI();
    }

    renameItem = (lineId, newName) => {
        const { basketItems, setBasketItems, saveBasketItemCustomFields } = this.props;
        const item = basketItems.find((i) => i.lineId === lineId);
        item.customFields.alias = newName;
        setBasketItems([...basketItems]);
        saveBasketItemCustomFields(lineId, item.customFields);
    }

    duplicateItem = (lineId) => {
        const { duplicateBasketItem } = this.props;
        duplicateBasketItem(lineId);
    }

    goBack = (e) => {
        e.preventDefault();
        history.goBack();
        return false;
    }

    getExceededMessage = (user) => {
        if (user && user.customFields.p2_Catalog) {
            return 'Thank you for your interest in Aetna Products. Your order exceeds the allocation limit for this item so it will be routed for review. Thank you!';
        }
        return 'Thank you for your interest in Aetna Medicare Products. Your order exceeds the monthly allocation limit for this item. '
            + 'During the checkout process please explain your need for additional supplies and your local sales team will review the order. Thank you!';
    }

    render() {
        const {
            basketItems, used, user, jobItems, attachments, saveItemsForLater, pricing, resetInput
        } = this.props;
        const {
            selected, openSaveOrder, savedOrderName, openCI, ciLineId, openDeleteConfirm, itemQtys, itemsRemoved, showRemovedItemsDialog
        } = this.state;
        if (!itemQtys || !basketItems) return null;

        if (basketItems.length && basketItems.some((i) => i.customFields && i.customFields.program === 'OE')) {
            return (
                <OECartView
                    basketItems={basketItems}
                    removeItem={this.removeItem}
                    changeItemQty={this.changeItemQty}
                    goBack={this.goBack}
                    goCheckout={this.goCheckout}
                    renameItem={this.renameItem}
                    duplicateItem={this.duplicateItem}
                    planDocuments={jobItems}
                    attachments={attachments}
                    saveItemsForLater={saveItemsForLater}
                    pricing={pricing}
                    resetInput={resetInput}
                />
            );
        }

        const isSmallGroup = basketItems.length && basketItems.some((i) => i.customFields && i.customFields.program === 'Small Group');
        const onlyState = isSmallGroup ? basketItems[0].customFields.state : '';

        let crossWHS = false;
        const whs = [];
        if (isSmallGroup) {
            for (const b of basketItems) {
                if (b.customFields && b.customFields.items && b.customFields.items.length) {
                    for (const bi of b.customFields.items) {
                        if (!whs.includes(bi.warehouse)) {
                            whs.push(bi.warehouse);
                        }
                    }
                }
            }

            crossWHS = whs.length > 1;
        }

        const ciRequired = false && user && user.customFields.npn;// don't show CI for broker here
        let ciItemName = null;
        let ciItemCode = null;
        if (ciRequired && openCI) {
            const item = basketItems.find((i) => i.lineId === ciLineId);
            ciItemName = item.name;
            ciItemCode = item.code;
        }

        const allowSaveOrder = user && user.customFields.userType !== 'telesales' && !user.customFields.p2_Catalog;
        const needQty = user && user.customFields.userType !== 'telesales';

        const exceededMsg = this.getExceededMessage(user);

        return (
            <Container fixed className="shop-cart-view">
                <CssBaseline />
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/catalog" onClick={this.goBack}>
                        {'<< Back'}
                    </Link>
                </Breadcrumbs>
                <Box component="div" mb={4}>
                    <Typography variant="h4">
                        Shopping Cart Contents
                    </Typography>
                </Box>
                {user && user.customFields.p2_Catalog
                    ? (
                        <P2CartView
                            basketItems={basketItems}
                            user={user}
                            onSelectionChange={(s) => this.setState({ selected: s })}
                            removeItem={this.removeItem}
                            changeItemQty={this.changeItemQty}
                        />
                    )
                    : (
                        <Box>

                            <Divider style={{ height: '3px' }} />
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                spacing={1}
                                style={{ padding: '20px 0' }}
                            >
                                <Grid container item xs={1}>
                                    Action
                                </Grid>
                                <Grid container item xs={6}>
                                    Product
                                </Grid>
                                <Grid container item xs={1}>
                                    {needQty && 'Quantity'}
                                </Grid>
                                {isSmallGroup && (<Grid item xs={4}>Warehouse</Grid>)}
                                {ciRequired
                                    ? (<Grid item xs={4} style={{ textAlign: 'right' }}>Contact Information</Grid>)
                                    : (<Grid item xs={4} />)}

                            </Grid>
                            <Divider />
                            {basketItems && basketItems.length > 0 && basketItems.map((item) => {
                                const usedQty = used && used[item.code] ? parseInt(used[item.code], 10) : 0;

                                return (
                                    <Grid
                                        key={item.lineId}
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                        style={{ padding: '20px 0' }}
                                    >
                                        {
                                            (usedQty + parseInt(item.quantity, 10) > item.customFields.limit)
                                                ? (
                                                    <Grid item style={{ background: '#FFC107' }} xs={12}>
                                                        <WarningIcon />  {exceededMsg}
                                                    </Grid>
                                                )
                                                : null
                                        }
                                        <Grid
                                            item
                                            xs={1}
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                            >
                                                {allowSaveOrder
                                                    && (
                                                        <Checkbox
                                                            edge="start"
                                                            tabIndex={-1}
                                                            disableRipple
                                                            size="small"
                                                            checked={selected.includes(item.lineId)}
                                                            color="primary"
                                                            onChange={() => this.onToggle(item.lineId)}
                                                        />
                                                    )}
                                                <IconButton
                                                    aria-label="DeleteIcon"
                                                    aria-describedby="remove"
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => this.removeItem(item.lineId, true)}
                                                >
                                                    <DeleteIcon className="action-btn del-icon" />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}><img
                                                    onError={this.fallbackSrc}
                                                    src={item.customFields.thumbnail
                                                        || '//cdndev.memberdoc.com/f/img_no_thumb.jpg'}
                                                    alt={item.name}
                                                    style={{ width: '100px', maxWidth: '100px' }}
                                                />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <b>{item.name}
                                                    </b>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1}>
                                            {needQty
                                                && (
                                                    <TextField
                                                        label="Qty"
                                                        size="small"
                                                        type="number"
                                                        min={1}
                                                        max={item.customFields.upperLimit || 999999}
                                                        inputProps={{ min: 1, max: item.customFields.upperLimit || 999999 }}
                                                        style={{ width: '6rem' }}
                                                        value={item.quantity}
                                                        InputLabelProps={{ shrink: true }}
                                                        variant="outlined"
                                                        onChange={(e) => this.changeItemQty(item.lineId, e.target.value, item.customFields.upperLimit)}
                                                    />
                                                )}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {isSmallGroup && item.customFields && item.customFields.items && item.customFields.items.length > 0 && (<span style={{ marginLeft: '1rem' }}>{item.customFields.items[0].warehouse}</span>)}
                                            {ciRequired && item.customFields.itemType === 'KT' && (
                                                <Button style={{ float: 'right', color: (item.customFields.CI ? 'green' : 'red') }} onClick={() => this.showCI(item.lineId)}>
                                                    CI
                                                </Button>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Box>
                    )}
                {isSmallGroup && <SGPlanDocumentPanel onlyState={onlyState} onlyWHS={whs.length > 0 ? whs[0] : ''} />}
                <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                    <Grid item xs={6}>
                        {allowSaveOrder && (
                            <>
                                <Button variant="outlined" disabled={selected.length === 0} color="primary" onClick={this.showSaveOrder}>
                                    Save Order
                                </Button>
                                <Dialog open={openSaveOrder} onClose={this.hideSaveOrder} aria-labelledby="form-dialog-title">
                                    <DialogTitle id="form-dialog-title">Save Order</DialogTitle>
                                    <DialogContent>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            label="Name"
                                            type="text"
                                            fullWidth
                                            required
                                            value={savedOrderName}
                                            onChange={(e) => this.setSavedOrderName(e.target.value)}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.hideSaveOrder} color="primary">
                                            Cancel
                                        </Button>
                                        <Button onClick={this.saveOrderForLater} disabled={!savedOrderName} color="primary">
                                            Save
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {crossWHS && <span style={{ color: 'red', float: 'right', fontWeight: 'bold' }}>Can&apos;t place order without different warehouses.</span>}
                        <ButtonGroup className="list-btn-group">
                            <Button
                                className="btn place-order-btn"
                                variant="contained"
                                disabled={!basketItems || basketItems.length === 0 || crossWHS}
                                color="primary"
                                onClick={this.goCheckout}
                            >Check Out
                            </Button>
                        </ButtonGroup>

                    </Grid>

                </Grid>
                <CIModal open={openCI} onApproved={this.onCIApproved} onClose={this.hideCI} user={user} itemName={ciItemName} itemCode={ciItemCode} />

                <ConfirmDeleteDialog open={openDeleteConfirm} onClose={this.onConfirmClose} />
                <Dialog open={showRemovedItemsDialog} onClose={() => this.setState({ showRemovedItemsDialog: false })} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Items Removed</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            The following kits/items are no longer available and must be deleted from cart to continue:
                        </DialogContentText>
                        <TableContainer component={Paper} style={{ maxHeight: '400px' }}>
                            <Table aria-label="items removed table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Item</TableCell>
                                        <TableCell>Description</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {itemsRemoved && itemsRemoved.map((row) => (
                                        <TableRow key={row.code + row.name}>
                                            <TableCell component="th" scope="row">
                                                {row.code}
                                            </TableCell>
                                            <TableCell>{row.name} </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                </Dialog>
            </Container>
        );
    }
}
