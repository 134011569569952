import DateFnsUtils from '@date-io/date-fns';
import {
    Backdrop, Box, Grid,
    Button, CircularProgress,
    Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
    TextField, Typography,
    Dialog, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import SearchIcon from '@material-ui/icons/Search';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import LoadingTableRow from 'components/LoadingTableRow';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import http from 'utils/http';
import { getOrders, updateSearchFilter, clearFilter } from './action';
import OrderRow from './OrderRow';

const pageSize = 10;

class ListView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            pageNumber: 1,
            copying: false,

            searchingMode: false,
            orderNumber: '',
            startDate: null,
            endDate: null,
            recipientName: '',
            showCancelDialog: false,
            cancelReason: '',
            orderId: null
        };
    }

    componentDidMount() {
        const { pageNumber } = this.state;
        const { getOrders: loadOrders } = this.props;

        this.handleSearch();
    }

    handleChangePage = (_event, pageNumber) => {
        const { orderNumber, recipientName, startDate, endDate, getOrders: loadOrders } = this.props;
        const {
            searchingMode
        } = this.state;
        const criteria = searchingMode ? {
            orderNumber,
            recipientName,
            startDate: (startDate ? startDate.toLocaleDateString() : null),
            endDate: (endDate ? endDate.toLocaleDateString() : null)
        } : null;

        this.setState({pageNumber: pageNumber + 1});
        loadOrders(pageNumber + 1, pageSize, criteria);
    }

    handleCopyOrder = (orderId) => {
        const fd = new FormData();
        fd.append('orderId', orderId);
        this.setState({ copying: true });
        http.post('store/copy-order', fd).then((data) => {
            const { history } = this.props;
            if (data.lob && data.jobType) {
                history.push({
                    pathname: `/direct-order/${data.lob}/${data.jobType}`,
                    state: { orderData: data }
                });
            } else {
                history.push('/cart');
            }
        }).finally(() => {
            this.setState({ copying: false });
        });
    }

    handleSearch = async () => {
        this.setState({ searchingMode: true });
        const { orderNumber, recipientName, startDate, endDate, getOrders: loadOrders } = this.props;
        loadOrders(1, pageSize, {
            orderNumber,
            recipientName,
            startDate: (startDate ? startDate.toLocaleDateString() : null),
            endDate: (endDate ? endDate.toLocaleDateString() : null)
        });
    };

    clearSearch = () => {
        this.props.clearFilter();

        const { getOrders: loadOrders } = this.props;
        loadOrders(1, pageSize);
    }

    setCriteria = (name, value) => {
        this.props.updateSearchFilter(name, value);
    };

    onCancelClick = (orderId) => {
        this.setState({orderId: orderId}, () => {
            this.setShowCancelDialog(true);
        });
    }

    setShowCancelDialog = (show) => {
        const {orderId, cancelReason} = this.state;
        this.setState({showCancelDialog: show, cancelReason: show ? cancelReason : '', orderId: show ? orderId : null});
    }

    onCancelReasonChange  = (e) => {
        this.setState({cancelReason: e.target.value});
    }

    submitCancel = async (e) => {
        const {cancelOrder, orders, getOrders: loadOrders} = this.props;
        const {orderId, cancelReason, pageNumber, orderNumber, recipientName, startDate, endDate} = this.state;

        await cancelOrder(orderId, cancelReason);
        await new Promise(r => setTimeout(r, 1000));
        await loadOrders(pageNumber, pageSize, {
            orderNumber,
            recipientName,
            startDate: (startDate ? startDate.toLocaleDateString() : null),
            endDate: (endDate ? endDate.toLocaleDateString() : null)
        });

        this.setShowCancelDialog(false);
    }

    render() {
        const { orderNumber, recipientName, startDate, endDate, orders, loading, user } = this.props;
        const hasCustomerName = orders && orders.results && orders.results.some((o) => o.customerName);
        const {
            copying, showCancelDialog, cancelReason
        } = this.state;

        const isP2 = user && user.customFields && user.customFields && user.customFields.p2_Catalog;

        return (
            <div>

                <Box mb={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/account">
                            Account
                        </Link>
                        <Typography color="textPrimary">Order History</Typography>
                    </Breadcrumbs>
                </Box>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="order table">
                        <TableHead>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <TableRow>
                                    <TableCell>
                                        <TextField
                                            label="ORDER #"
                                            autoFocus
                                            value={orderNumber}
                                            onKeyPress={(e) => { if (e.key === 'Enter') { this.handleSearch(); } }}
                                            onChange={(e) => this.setCriteria('orderNumber', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            label="RECIPIENT NAME"
                                            autoFocus
                                            value={recipientName}
                                            onKeyPress={(e) => { if (e.key === 'Enter') { this.handleSearch(); } }}
                                            onChange={(e) => this.setCriteria('recipientName', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="start_date_picker"
                                            label="Start Date"
                                            name="startDate"
                                            value={startDate}
                                            onChange={(d) => {
                                                this.setCriteria('startDate', d);
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change start date'
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="end_date_picker"
                                            label="End Date"
                                            name="endDate"
                                            value={endDate}
                                            onChange={(d) => {
                                                this.setCriteria('endDate', d);
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change end date'
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button color="primary" onClick={this.handleSearch}>
                                            <SearchIcon /> Search
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={this.clearSearch}>
                                            <ClearAllIcon /> Clear
                                        </Button>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </MuiPickersUtilsProvider>
                        </TableHead>

                    </Table>
                    <Table size="small" aria-label="order table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>ORDER NUMBER</TableCell>
                                <TableCell>REQUESTED DELIVERY DATE</TableCell>
                                {!isP2 ? <>
                                    <TableCell>PROGRAM</TableCell>
                                    <TableCell>JOB TYPE</TableCell>
                                    <TableCell>MARKET SEGMENT</TableCell>
                                    <TableCell>COST CENTER</TableCell>
                                    </> : null }
                                {
                                    hasCustomerName && (<TableCell>CUSTOMER</TableCell>)
                                }

                                <TableCell>ORDER STATUS</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <LoadingTableRow loading={loading} />
                            {!loading && orders && orders.results.length > 0 && orders.results.map((row) => (
                                <OrderRow key={row.id} row={row} hasCustomerName={hasCustomerName} onCopyClick={this.handleCopyOrder} onCancelClick={() => this.onCancelClick(row.id)} isP2={isP2} />
                            ))}
                            {!loading && orders && orders.results.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={20} style={{ textAlign: 'center' }}>
                                        <span style={{ color: '#999', fontWeight: 'bold', fontSize: '1.2rem' }}> No order data.</span>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {orders && (
                    <TablePagination
                        component="div"
                        count={orders.totalCount}
                        page={orders.pageNumber - 1}
                        rowsPerPage={orders.pageSize}
                        rowsPerPageOptions={[orders.pageSize]}
                        onChangePage={this.handleChangePage}
                    />
                )}
                <Backdrop style={{ zIndex: '1555', color: '#fff' }} open={copying}>
                    <CircularProgress color="primary" />
                </Backdrop>
                <Dialog open={showCancelDialog} onClose={() => this.setShowCancelDialog(false)} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
                    <DialogTitle id="form-dialog-title">Cancel Request</DialogTitle>
                    <DialogContent>
                        <Box>
                            <Box pb={2}>
                                <Typography>
                                    Please enter a cancellation reason:
                                        </Typography>
                            </Box>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Cancel Reason"
                                        name="reason"
                                        required
                                        fullWidth
                                        value={cancelReason}
                                        onChange={this.onCancelReasonChange}
                                        // InputLabelProps={{
                                        //     shrink: true
                                        // }}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={this.submitCancel} disabled={!(cancelReason && cancelReason.length > 0)} >
                            Submit
                            </Button>
                        <Button color="default" onClick={() => { this.setShowCancelDialog(false); }}>
                            Cancel
                            </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = ({ ordersView: { orders }, appView: { user } }) => ({
    orders, user
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getOrders,
            updateSearchFilter,
            clearFilter
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
