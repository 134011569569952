import { IconButton, TableFooter } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9'
    }
}))(Tooltip);

export default function AddressTable({
    addresses, handleQtyChange, onRemove, hasAddressQty, totalAddressCount
}) {
    return (

        <TableContainer style={{ maxHeight: '600px', marginTop: '2rem' }} component={Paper}>
            <Table stickyHeader ize="small" aria-label="address table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ padding: '.5rem' }}>First1</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>MI1</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>Last1</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>First2</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>MI2</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>Last2</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>Company</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>Address1</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>Address2</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>Address3</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>City</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>State</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>Zip</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>Country</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>Phone</TableCell>
                        <TableCell style={{ padding: '.5rem' }} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {addresses && addresses.length === 0 && (
                        <TableRow>
                            <TableCell style={{ textAlign: 'center', padding: '1rem' }} colSpan={16}>
                                <span style={{ color: '#999', fontWeight: 'bold', fontSize: '1.2rem' }}> No address added yet</span>
                            </TableCell>
                        </TableRow>
                    )}
                    {addresses && addresses.length > 0 && addresses.map((a, i) => (
                        <TableRow key={i}>
                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.first1}</TableCell>
                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.middle1}</TableCell>
                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.last1}</TableCell>
                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.first2}</TableCell>
                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.middle2}</TableCell>
                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.last2}</TableCell>
                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.company}</TableCell>
                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.address}</TableCell>
                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.address2}</TableCell>
                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.address3}</TableCell>
                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.city}</TableCell>
                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.state}</TableCell>
                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.zip}</TableCell>
                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.country}</TableCell>
                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.phoneNumber}</TableCell>
                            <TableCell style={{ padding: '.5rem', width: '9rem', color: (a.isValid === false ? 'red' : 'black') }}>
                                {hasAddressQty && (
                                    <input value={a.qty} type="number" style={{ width: '3rem' }} onChange={(e) => handleQtyChange(i, e.target.value)} />
                                )}
                                <IconButton
                                    aria-label="delete"
                                    display="inline"
                                    m={1}
                                    style={{ padding: '5px' }}
                                    onClick={() => onRemove(i)}
                                >
                                    <DeleteIcon />
                                </IconButton>

                                {
                                    a.userInput && (
                                        <HtmlTooltip
                                            title={(
                                                <>
                                                    <Box>
                                                        Your input:
                                                    </Box>
                                                    <Box>
                                                        {a.userInput.address} {a.userInput.address2}
                                                    </Box>
                                                    <Box>
                                                        {a.userInput.city} {a.userInput.state} {a.userInput.zip} {a.userInput.country}
                                                    </Box>
                                                </>
                                            )}
                                        >
                                            <IconButton
                                                aria-label="delete"
                                                display="inline"
                                                m={1}
                                                style={{ padding: '5px' }}
                                            >
                                                <InfoIcon />
                                            </IconButton>
                                        </HtmlTooltip>

                                    )
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                {
                    totalAddressCount > addresses.length && (

                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan="20">
                                    <Box>total added addresses: {totalAddressCount}</Box>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    )
                }
            </Table>
        </TableContainer>
    );
}
