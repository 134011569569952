
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';

export const aetnaTheme = createMuiTheme({
    palette: {
        primary: {
            light: '#ae6dc9',
            main: '#7d3f98',
            dark: '#4e1169',
            contrastText: '#ffffff'
        },
        secondary: {
            light: '#5eb8ff',
            main: '#0288d1',
            dark: '#005b9f',
            contrastText: '#ffffff'
        }
    },
    status: {
        danger: 'orange'
    }
});
export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
    },
    main: {
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800]
    }
}));
