import { saveBasketItemCustomFields } from 'pages/cart/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addProductToCart, getCrosswalk, getProduct, setNotReadyForCart, setProduct, addToFavorites, removeFromFavorites } from '../action';
import DetailView from './DetailView';

const mapStateToProps = ({
    appView: { user },
    catalogView: {
        productDetail, newThumbnail, notReadyForCart, crosswalk, editingCustomFields, favorites
    }
}) => ({
    productDetail, newThumbnail, notReadyForCart, crosswalk, editingCustomFields, user, favorites
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getProduct, addProductToCart, getCrosswalk, saveBasketItemCustomFields, setProduct, setNotReadyForCart, addToFavorites, removeFromFavorites
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailView);
