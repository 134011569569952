import {
    Checkbox, Divider, FormControl, Grid,
    InputLabel, MenuItem, Select, TextField,
    Typography, Button
} from '@material-ui/core';
import {
    KeyboardDatePicker ,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import PhoneInput from 'components/PhoneInput';
import StateSelect from 'components/UsStateSelect';
import React, { PureComponent } from 'react';
import http from 'utils/http';

export default class SingleAddressView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            includingSpouse: false,
            validating: false,
            validated: false,
            canValidate: false,

            savedAddresses: [],
            addressSaved: false,
            selectedAddressId: this.props.selectedAddressId || '',

            validations: null,
            deliveryDueDate: this.props.deliveryDate
        };
    }

    componentDidMount() {
        http.get('store/addresses/1/100')
            .then((d) => {
                this.setState({ savedAddresses: d.results });
            });
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.address !== nextProps.address || this.props.selectedAddressId !== nextProps.selectedAddressId || 
            (this.props.addressSaved !== nextProps.addressSaved && nextProps.addressSaved)) {
            http.get('store/addresses/1/100')
            .then((d) => {
                this.setState({ savedAddresses: d.results });
                this.selectAddress(nextProps.selectedAddressId || this.state.selectedAddressId);
            });
        }

        if(this.props.validations !== nextProps.validations) {
            this.setState({validations: nextProps.validations});
        }

        if(this.props.deliveryDate !== nextProps.deliveryDate) {
            this.setState({deliveryDueDate: nextProps.deliveryDate});
        }
    }

    onFieldChange = (e) => {
        const { addr, onAddressChange, refreshEstimatedDeliveryDate } = this.props;
        const name = e.target.name;
        const newAddr = { ...addr, [name]: e.target.value, country: 'United States' };
        
        let isUserDeliveryDate = false;

        if(name === "deliveryDueDate") {
            isUserDeliveryDate = true;
            newAddr["UserDeliveryDate"] = e.target.value;
        }
        else if (addr.state !== newAddr.state || addr.shippingMethodCode !== newAddr.shippingMethodCode) {
            isUserDeliveryDate = false;
        }

        onAddressChange(newAddr, () => {
            if(name === "shippingMethodCode" || name === "country" || name === "state") {
                refreshEstimatedDeliveryDate();
            } else if (name == "address" || name == "zip" || name == "city") {
                this.setState({addressModified: true});
            }
        });

        this.setState({
            canValidate: !!newAddr.lastName
                && !!newAddr.address
                && !!newAddr.zip
                && !!newAddr.state
                && !!newAddr.country,
            validated: false
        });
    }

    refreshDeliverDate = (refresh = false) => {
        const { refreshEstimatedDeliveryDate } = this.props;

        if(this.state.addressModified || refresh) {
            refreshEstimatedDeliveryDate();
            this.setState({addressModified: false});
        }
    }

    setIncludingSpouse = () => {
        const { includingSpouse } = this.state;
        this.setState({ includingSpouse: !includingSpouse });
    }

    selectAddress = async (selectedAddressId) => {
        if (!selectedAddressId) return;
        const { savedAddresses } = this.state;
        const { addr } = this.props;
        this.setState({ selectedAddressId });
        const address = savedAddresses.find((a) => a.id == selectedAddressId);
        
        if(addr.shippingMethodCode) {
            address.shippingMethodCode = addr.shippingMethodCode;
        }
        else if (!address.shippingMethodCode) {
            address.shippingMethodCode = 'Standard';
        }
        const { onAddressChange } = this.props;
        await onAddressChange(address, () => { this.refreshDeliverDate(true); });
        
    }

    clearAll = () => {
        const { clear } = this.props;

        this.setState({ selectedAddressId: '' });

        clear && clear();
    }

    saveAddress = () => {
        const {
            saveAddressToAddressBook
        } = this.props;

        saveAddressToAddressBook();
    }

    render() {
        const {
            addr, spouseSupported, disableShippingMethod, phoneRequired, shippingMethods, user, emailRequired, 
            isAddressFilled, saveAddressToAddressBook, saveToAddressBookEnabled, validations, estimatedDeliveryDate
        } = this.props;
        const {
            includingSpouse, validated, validating, canValidate, savedAddresses, selectedAddressId, deliveryDueDate
        } = this.state;

        const isP2 = !!(user !== null && user && user.customFields && user.customFields.p2_Catalog);

        return (
            <Grid container spacing={1}>
                <Grid item xs={!spouseSupported ? 9 : 12}>
                    <Typography variant="h5">
                        Shipping Address
                        {spouseSupported && (
                            <label style={{ paddingleft: '1.5rem', float: 'right', fontSize: '1.2rem' }}>
                                <Checkbox
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                    checked={includingSpouse}
                                    onChange={this.setIncludingSpouse}
                                    color="primary"
                                />
                                Including Spouse
                            </label>
                        )}
                    </Typography>

                    <Divider style={{
                        height: '1px', width: '100%', marginBottom: '1rem', marginTop: '.5rem'
                    }}
                    />
                </Grid>
                {!spouseSupported && (
                    <Grid item xs={3}>
                        <FormControl
                            fullWidth
                            size="small"
                            variant="outlined"
                        >
                            <InputLabel>Saved Addresses</InputLabel>
                            <Select
                                value={selectedAddressId}
                                fullWidth

                                label="Saved Addresses"
                                onChange={(e) => this.selectAddress(e.target.value)}
                            >
                                <MenuItem value="">Select</MenuItem>
                                {
                                    savedAddresses.map((s) => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)
                                }

                            </Select>
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12}>

                    <Grid container spacing={2} className="row-item">
                        <Grid item xs={12}>
                            <TextField
                                label="Title"
                                name="name"
                                fullWidth
                                value={addr.name || ''}
                                onChange={this.onFieldChange}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                required
                                label="First Name"
                                name="firstName"
                                size="small"
                                value={addr.firstName || ''}
                                onChange={this.onFieldChange}
                                InputLabelProps={{
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"

                                label="MI"
                                name="middleName"
                                value={addr.middleName || ''}
                                onChange={this.onFieldChange}
                                InputLabelProps={{
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                required

                                label="Last Name"
                                name="lastName"
                                value={addr.lastName || ''}
                                onChange={this.onFieldChange}
                                InputLabelProps={{
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                label="Phone#"
                                size="small"
                                name="phone"
                                required={phoneRequired}
                                variant="outlined"
                                InputProps={{
                                    inputComponent: PhoneInput,
                                    value: addr.phone || '',
                                    onChange: this.onFieldChange
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onFocus={(e) => {
                                    e.target.select();
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                error={validations && validations.shippingEmail}
                                fullWidth={true}
                                size="small"
                                required={emailRequired}
                                label="Email"
                                name="email"
                                value={addr.email || ''}
                                onChange={this.onFieldChange}
                                helperText={validations && validations.shippingEmail}
                                InputLabelProps={{
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        {spouseSupported && includingSpouse ? (
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={2}>
                                    <TextField
                                        size="small"
                                        label="Spouse First Name"
                                        name="spouseFirstName"
                                        value={addr.spouseFirstName || ''}
                                        onChange={this.onFieldChange}
                                        InputLabelProps={{
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        size="small"
                                        label="Spouse Last Name"
                                        name="spouseLastName"
                                        value={addr.spouseLastName || ''}
                                        onChange={this.onFieldChange}
                                        InputLabelProps={{
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>

                            </Grid>
                        ) : null}

                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    size="small"
                                    required
                                    fullWidth
                                    label= { isP2 ? "Address Line 1 - No PO Boxes" : "Address Line 1" }
                                    name="address"
                                    value={addr.address || ''}
                                    onChange={this.onFieldChange}
                                    onBlur={() => {this.refreshDeliverDate();}}
                                    InputLabelProps={{
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Address Line 2"
                                    name="address2"
                                    value={addr.address2 || ''}
                                    onChange={this.onFieldChange}
                                    InputLabelProps={{
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                required
                                label="City"
                                name="city"
                                value={addr.city || ''}
                                onChange={this.onFieldChange}
                                onBlur={() => {this.refreshDeliverDate();}}
                                InputLabelProps={{
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <StateSelect
                                required
                                value={addr.state || ''}
                                onChange={this.onFieldChange}
                                displayFullStateName={isP2}
                                name="state"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                required
                                label="Zip"
                                name="zip"
                                value={addr.zip || ''}
                                onChange={this.onFieldChange}
                                onBlur={() => {this.refreshDeliverDate();}}
                                InputLabelProps={{
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>

                            <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                            >
                                <InputLabel id="country-select-outlined-label">Country</InputLabel>
                                <Select
                                    labelId="country-select-outlined-label"
                                    id="country-select-outlined"
                                    fullWidth
                                    name="country"
                                    value={addr.country || 'United States'}
                                    onChange={this.onFieldChange}
                                    label="Country"
                                >
                                    <MenuItem value="United States">United States</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={2}>
                            <Button
                                size="large"
                                color="primary"
                                variant="contained"
                                fontWeight="fontWeightMedium"
                                style={{ marginRight: '1rem' }}
                                disabled={!canValidate || validating}
                                onClick={this.validateInputAddress}
                            >
                                <VerifiedUserIcon /> {validated ? 'Validated' : 'Validate'}
                            </Button>
                        </Grid> */}
                                            {
                    !disableShippingMethod && (
                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                >
                                    <InputLabel id="shipping-method-select-label">Shipping Method</InputLabel>
                                    <Select
                                        labelId="shipping-method-select-label"
                                        value={addr.shippingMethodCode}
                                        fullWidth
                                        onChange={this.onFieldChange}
                                        name="shippingMethodCode"
                                        label="Shipping Method"
                                    >
                                        {
                                            shippingMethods === undefined || shippingMethods === null ? 
                                                <MenuItem value="Standard">Standard</MenuItem> :
                                                isP2 && user.customFields.hasAllShippingMethodsAccess ? shippingMethods.map((m) => <MenuItem key={m.code} value={m.code}>{m.code}</MenuItem>) :
                                                isP2 && !user.customFields.hasAllShippingMethodsAccess ? <MenuItem value="Standard">Standard</MenuItem> :
                                                shippingMethods.map((m) => <MenuItem key={m.code} value={m.code}>{m.code}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>

                            </Grid>
                            {isP2 ? <Grid item xs={3}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <FormControl
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                >
                                    {/* <InputLabel>Estimated Delivery Due Date</InputLabel> */}
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="start_date_picker"
                                        label="Requested Delivery Date"
                                        name="deliveryDueDate"
                                        minDate={new Date(estimatedDeliveryDate)}
                                        value={deliveryDueDate}
                                        onChange={(d) => {
                                            this.onFieldChange({ target: { name: 'deliveryDueDate', value: d } });
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'Change Delivery Due Date'
                                        }}
                                    />
                                </FormControl>
                                </MuiPickersUtilsProvider> 
                            </Grid>: null}
                            {isP2 ? <Grid item xs={2}>
                                <TextField
                                    size="small"
                                    label="Projected Delivery Date"
                                    name="estimatedDeliveryDate"
                                    value={estimatedDeliveryDate ? new Date(estimatedDeliveryDate).toLocaleDateString() : ''}
                                    InputLabelProps={{
                                    }}
                                    // variant="outlined"
                                    disabled={true}
                                />
                            </Grid> : null}
                        </Grid>
                    )
                }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <br></br>
                    {
                        user && user.customFields && user.customFields.p2_Catalog && saveToAddressBookEnabled ? <Button
                            className="btn place-order-btn"
                            disabled={!isAddressFilled}
                            variant="contained"
                            onClick={this.saveAddress}
                            color="primary"
                        >{selectedAddressId && selectedAddressId !== '' ? "Update Selected Address" : "Save Address"}
                        </Button> : null
                    }
                    <Button
                        color="primary"
                        variant="outlined"
                        fontWeight="fontWeightMedium"
                        style={{ marginLeft: '1rem' }}
                        onClick={this.clearAll}
                    >
                        <ClearAllIcon /> Clear
                    </Button>
                </Grid>
            </Grid>
        );
    }
}
