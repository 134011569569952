import React, { useState } from 'react';
import {
    Button, Grid, TextField, MenuItem,
    Select, FormControl, InputLabel
} from '@material-ui/core';

export default function P2SearchBar({ submitSearch }) {
    const [itemCode, setItemCode] = useState('');
    const [stateCode, setStateCode] = useState('');
    const handleSearch = () => {
        submitSearch(itemCode, stateCode);
    };

    const stateOptions = ['AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY',
        'LA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'OH', 'OK', 'OR', 'PA',
        'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'];
    return (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <TextField
                    variant="outlined"
                    label="Item Code"
                    size="small"
                    value={itemCode}
                    onKeyPress={(e) => { if (e.key === 'Enter') { handleSearch(); } }}
                    onChange={(e) => setItemCode(e.target.value)}
                />

            </Grid>
            <Grid item xs={2}>

                <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                >
                    <InputLabel id="country-select-outlined-label">State</InputLabel>
                    <Select
                        labelId="country-select-outlined-label"
                        id="country-select-outlined"
                        fullWidth
                        name="stateCode"
                        value={stateCode}
                        onChange={(e) => setStateCode(e.target.value)}
                        label="Cost Center"
                    >
                        <MenuItem value="">Select</MenuItem>
                        {stateOptions.map((t) => <MenuItem key={t} value={t}>{t}</MenuItem>)}
                    </Select>
                </FormControl>

            </Grid>
            <Grid item xs={2}>
                <Button disabled={!itemCode && !stateCode} onClick={() => handleSearch()}>
                    Search
                </Button>
            </Grid>
        </Grid>
    );
}
