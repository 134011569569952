import {
    Box,
    Grid, IconButton, InputAdornment, Paper, Tab,
    Tabs, TextField,
    Typography
} from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import SearchIcon from '@material-ui/icons/Search';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getOrders } from './action';
import HistoricalOrders from './HistoricalOrders';
import SavedOrders from './SavedOrders';

const pageSize = 10;
class ListView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            pageNumber: 1,
            tabValue: 0,
            keyword: ''
        };
    }

    componentDidMount() {
        const { pageNumber } = this.state;
        const { getOrders: loadOrders, user, history } = this.props;
        if (user && !(user.customFields && user.customFields.userGroups && user.customFields.userGroups.length > 0 && user.customFields.userType === 'su')) {
            history.push('/');
        }

        loadOrders(pageNumber, pageSize);
    }

    handleChangePage = (_event, pageNumber) => {
        const { keyword, tabValue } = this.state;
        if (tabValue) {
            const { getSaves: loadOrders } = this.props;
            loadOrders(pageNumber + 1, pageSize, keyword);
        } else {
            const { getOrders: loadOrders } = this.props;
            loadOrders(pageNumber + 1, pageSize, keyword);
        }
    }

    a11yProps = (index) => {
        return {
            id: `order-tab-${index}`,
            'aria-controls': `order-tabpanel-${index}`
        };
    }

    handleTabChange = (e, tabValue) => {
        this.setState({ tabValue });
        const { saves } = this.props;
        if (tabValue && !saves) {
            const { getSaves: loadOrders } = this.props;
            loadOrders(1, pageSize);
        }
    }

    setKeyword = (keyword) => {
        this.setState({ keyword });
    }

    searchOrder = () => {
        const { keyword, tabValue } = this.state;
        if (tabValue) {
            const { getSaves: loadOrders } = this.props;
            loadOrders(1, pageSize, keyword);
        } else {
            const { getOrders: loadOrders } = this.props;
            loadOrders(1, pageSize, keyword);
        }
    }

    continueWork = (savedName, savedId, savedJob) => {
        const { history } = this.props;
        history.push({
            pathname: savedJob.hash.replace('#', ''),
            state: { savedJob: { ...savedJob, savedId, savedName } }
        });
    }

    render() {
        const { orders, saves, loading } = this.props;
        const { tabValue, keyword } = this.state;
        return (
            <Box>
                <Box mb={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/account">
                            Account
                        </Link>
                        <Typography color="textPrimary">Order Management</Typography>
                    </Breadcrumbs>
                </Box>
                <Box component={Paper} p={2}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Tabs value={tabValue} onChange={this.handleTabChange} aria-label="simple tabs example">
                                <Tab label="Historical Orders" {...this.a11yProps(0)} />
                                <Tab label="Saved Orders" {...this.a11yProps(1)} />
                            </Tabs>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Order Id"
                                value={keyword}
                                onKeyPress={(e) => { if (e.key === 'Enter') { this.searchOrder(); } }}
                                onChange={(e) => this.setKeyword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="search" onClick={this.searchOrder}>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Box display={tabValue === 0 ? 'block' : 'none'}>
                        <HistoricalOrders orders={orders} loading={loading} onChangePage={this.handleChangePage} />
                    </Box>
                    <Box display={tabValue === 1 ? 'block' : 'none'}>
                        <SavedOrders orders={saves} loading={loading} onChangePage={this.handleChangePage} onQuickView={this.continueWork} />
                    </Box>
                </Box>
            </Box>
        );
    }
}

const mapStateToProps = ({ ordersView: { orders } }) => ({
    orders
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getOrders
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
