import React, { useState } from 'react';
import {
    Grid, Paper, Button, TextField, Box, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import http from 'utils/http';

export default function SelectBar({
    planYear, planYears, language, languages, product, products, zip, county, onSearch
}) {
    const [_planYear, setPlanYear] = useState(planYear || '');
    const [_language, setLanguage] = useState(language || '');
    const [_product, setProduct] = useState(product || '');
    const [_zip, setZip] = useState(zip || '');
    const [_county, setCounty] = useState(county || '');
    const [counties, setCounties] = useState([]);
    const [loadingCounty, setLoadingCounty] = useState(false);

    const onZipChange = (e) => {
        const z = e.target.value;
        setZip(z);
        if (z.length === 5) {
            setLoadingCounty(true);
            http.get(`store/p1a/counties/${z}`).then((zipCounties) => {
                setCounties(zipCounties);
                if (zipCounties.length === 1) {
                    setCounty(zipCounties[0]);
                }
            }).finally(() => setLoadingCounty(false));
        }
    };

    return (
        <Grid container component={Paper} spacing={2}>
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel>Plan Year</InputLabel>
                    <Select
                        fullWidth
                        value={_planYear}
                        onChange={(e) => setPlanYear(e.target.value)}
                    >
                        <MenuItem value="">SELECT</MenuItem>
                        {planYears.map((s) => <MenuItem value={s} key={s}>{s}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel>Language</InputLabel>
                    <Select
                        fullWidth
                        value={_language}
                        onChange={(e) => setLanguage(e.target.value)}
                    >
                        <MenuItem value="">SELECT</MenuItem>
                        {languages.map((s) => <MenuItem value={s} key={s}>{s}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel>Product</InputLabel>
                    <Select
                        fullWidth
                        value={_product}
                        onChange={(e) => setProduct(e.target.value)}
                    >
                        <MenuItem value="">SELECT</MenuItem>
                        {products.map((s) => <MenuItem value={s} key={s}>{s}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>

                <TextField
                    fullWidth
                    label="Zip"
                    type="text"
                    value={_zip}
                    onChange={onZipChange}
                />
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel>County</InputLabel>
                    <Select
                        fullWidth
                        disabled={loadingCounty}
                        value={_county}
                        onChange={(e) => setCounty(e.target.value)}
                    >
                        <MenuItem value="">SELECT</MenuItem>
                        {counties.map((s) => <MenuItem value={s} key={s}>{s}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <Button
                    color="primary"
                    disabled={!_county}
                    type="button"
                    fullWidth
                    variant="contained"
                    onClick={() => onSearch({
                        planYear: _planYear,
                        language: _language,
                        product: _product,
                        zip: _zip,
                        county: _county
                    })}
                >
                    Search
                </Button>
            </Grid>
        </Grid>
    );
}
