import {
    Grid, TextField,
    Typography, InputLabel, Select, MenuItem, FormControl, IconButton, Button,
    Dialog, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PhoneInput from 'components/PhoneInput';
import AWNForm from '../profile/AWNForm';
import React, { useState, useEffect } from 'react';
import http from 'utils/http';

export default function P2ContactInfo({ onContactInfoChange, contactInfo, isAddressFilled, validations: origValidations }) {
    const [awn, setAwn] = useState(" ");
    const [previousAwn, setPreviousAwn] = useState("");
    const [showAWN, setShowAWN] = useState(false);
    const [validations, setValidations] = useState(null);

    useEffect(() => {
        if(contactInfo !== null && contactInfo.agentWritingNumbers.length === 1) {
            setAwn(contactInfo.agentWritingNumber);
        } else if (awn === " ") {
            onFieldChange({target: {name: "agentWritingNumber", value: ""}});
        }

        if(origValidations !== validations) {
            setValidations(origValidations);
        }
    }, [isAddressFilled,origValidations]);

    const onFieldChange = (e) => {
        const a = { ...contactInfo, [e.target.name]: e.target.value };

        if(e.target.name === "agentWritingNumber") {
            if(e.target.value === "" || e.target.value === " ") {
                a.agentWritingNumber = "";
                setAwn(" ");
            }
            else {
                setAwn(e.target.value);
            }
        }

        onContactInfoChange(a);
    };

    const saveAgentWritingNumber = async () => {
        const agentWritingNumber = contactInfo.agentWritingNumber;
        const exists = contactInfo.agentWritingNumbers.some(x => x === agentWritingNumber);
        let writingNumbers = exists ? [...contactInfo.agentWritingNumbers] : [...contactInfo.agentWritingNumbers, agentWritingNumber];
        http.put(`customer/awn/${encodeURIComponent(agentWritingNumber)}`);

        onFieldChange({target: { name: "agentWritingNumbers", value: writingNumbers}});

        hideAddAWN();
    };

    const showAddAWN = () => {
        setPreviousAwn(contactInfo.agentWritingNumber);
        setShowAWN(true);
    }

    const hideAddAWN = (isCancel = false) => {
        if(isCancel) {
            setAwn(previousAwn);
        }

        setShowAWN(false);
    };

    return (

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>Contact Info</Typography>
            </Grid>
            <Grid item xs={2}>

                <TextField
                    label="First Name"
                    name="firstName"
                    value={contactInfo.firstName || ''}
                    onChange={onFieldChange}
                    required
                    InputLabelProps={{
                        shrink: true
                    }}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    label="Last Name"
                    name="lastName"
                    value={contactInfo.lastName || ''}
                    onChange={onFieldChange}
                    required
                    InputLabelProps={{
                        shrink: true
                    }}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={2}>
                <FormControl
                    fullWidth
                    variant="outlined"
                    required
                >
                    <InputLabel id="p2ci-select-outlined-label">Agent Writing Number</InputLabel>
                    <Select
                        labelId="p2ci-select-outlined-label"
                        id="p2ci-select-outlined"
                        name={"agentWritingNumber"}
                        value={awn}
                        onChange={onFieldChange}
                        label="Agent Writing Number "
                    >
                        {contactInfo.agentWritingNumbers.length > 1 ? <MenuItem key="awnBlank" value=" "> </MenuItem> : null}
                        {
                            contactInfo.agentWritingNumbers.map((a) => <MenuItem key={a} value={a}>{a}</MenuItem>)
                        }
                        <MenuItem key="awnNA" value="N/A">N/A</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={1} style={{paddingLeft:"0px"}}>
                <IconButton color="primary" onClick={showAddAWN} style={{paddingLeft:"0px"},{flexBasis:"0"}}>
                    <AddIcon />
                </IconButton>
            </Grid>

            <Grid item xs={3}>
                <TextField
                    error={validations && validations.contactEmail && validations.contactEmail.length > 0}
                    fullWidth={true}
                    label="Email"
                    name="email"
                    value={contactInfo.email || ''}
                    onChange={onFieldChange}
                    helperText={validations && validations.contactEmail}
                    required
                    // InputLabelProps={{
                    //     shrink: true
                    // }}
                    variant="outlined"
                />
            </Grid>

            <Grid item xs={2}>
                <TextField
                    label="Phone"
                    name="phone"
                    variant="outlined"
                    InputProps={{
                        inputComponent: PhoneInput,
                        value: contactInfo.phone || '',
                        onChange: onFieldChange
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            {showAWN && (
                    <Dialog open aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Please add an Agent Writing Number</DialogTitle>
                        <DialogContent>
                            <AWNForm
                                onFieldChange={onFieldChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" onClick={saveAgentWritingNumber}>
                                    Save
                            </Button>
                            <Button color="default" onClick={() => { hideAddAWN(true); }}>
                                    Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
        </Grid>
    );
}
