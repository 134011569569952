class ValidateUtil {
    validateEmail(value) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
    }

    validatePhone(value) {
        let reg = new RegExp('^(\\+\\s?)?((?<!\\+.*)\\(\\+?\\d+([\\s\\-\\.]?\\d+)?\\)|\\d+)([\\s\\-\\.]?(\\(\\d+([\\s\\-\\.]?\\d+)?\\)|\\d+))*(\\s?(x|ext\\.?)\\s?\\d+)?$');
        return reg.test(value);
    }

    isPOBox(value) {
        if (value) {
            const ps = value.replace(/\./g, '').toLowerCase().trim();

            return ps.startsWith('po ') || ps.startsWith("p o ") || ps.startsWith("post office box ");
        }
        return false;
    }
}

const Validator = new ValidateUtil();
export default Validator;