import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';

export default function PasswordView(props) {
    const { message, loading, user } = props;
    const [current, setCurrent] = useState('');
    const [new1, setNew1] = useState('');
    const [new2, setNew2] = useState('');
    const [innerError, setInnerError] = useState('');

    const onSubmit = () => {
        if (!current) {
            setInnerError('Please enter current password'); return;
        }
        if (!new1) {
            setInnerError('Please enter new password'); return;
        }
        if (!new2) {
            setInnerError('Please enter confirm new password'); return;
        }
        if (new1 !== new2) {
            setInnerError("Confirm new password doesn't match new password"); return;
        }
        setInnerError('');
        props.submitChange(current, new1);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>

                <Box mb={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/account">
                            Account
                        </Link>
                        <Typography color="textPrimary">Change Password</Typography>
                    </Breadcrumbs>
                </Box>
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={5}>
                {user && user.customFields && user.customFields.isPasswordChangeRequired && (
                    <Box color="warning.main">
                        <b>You must change your password before you can use the site.</b>
                    </Box>
                )}
                {message && (
                    <Box color="success.main">
                        <b>{message}</b>
                    </Box>
                )}
                {innerError && (
                    <Box color="error.main">
                        <b>{innerError}</b>
                    </Box>
                )}
                <TextField
                    id="current"
                    label="Current Password"
                    style={{ margin: 8 }}
                    type="password"
                    placeholder="Enter current password"
                    fullWidth
                    margin="normal"
                    value={current}
                    onChange={(e) => setCurrent(e.target.value)}
                    InputLabelProps={{
                        shrink: true
                    }}
                />

            </Grid>
            <Grid item xs={4} />
            <Grid item xs={3} />
            <Grid item xs={5}>
                <TextField
                    id="new1"
                    label="New Password"
                    style={{ margin: 8 }}
                    type="password"
                    placeholder="Enter new password"
                    fullWidth
                    margin="normal"
                    value={new1}
                    onChange={(e) => setNew1(e.target.value)}
                    InputLabelProps={{
                        shrink: true
                    }}
                />

            </Grid>
            <Grid item xs={4} />
            <Grid item xs={3} />
            <Grid item xs={5}>
                <TextField
                    id="new2"
                    label="Confirm New Password"
                    style={{ margin: 8 }}
                    type="password"
                    placeholder="Enter new password again"
                    fullWidth
                    margin="normal"
                    value={new2}
                    onChange={(e) => setNew2(e.target.value)}
                    InputLabelProps={{
                        shrink: true
                    }}
                />

            </Grid>

            <Grid item xs={4} />
            <Grid item xs={3} />
            <Grid item xs={9}>
                <Button
                    className="btn submit-btn"
                    variant="contained"
                    onClick={onSubmit}
                    disabled={loading}
                    color="primary"
                >Submit
                </Button>

            </Grid>
        </Grid>
    );
}
