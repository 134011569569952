import { Container, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import store from 'utils/createStore';
import { aetnaTheme, useStyles } from 'utils/createTheme';
import GA from 'utils/GA';
import './App.css';
import Footer from './components/Footer';
import NavBar from './components/Nav';
import AuthRoute from './hoc/authRoute';
import checkRequests from './hoc/checkRequests';
import AccountPage from './pages/account';
import AddressesPage from './pages/addresses';
import BrokerPage from './pages/broker';
import CartPage from './pages/cart';
import CatalogPage from './pages/catalog';
import CheckoutPage from './pages/checkout';
import DirectOrderPage from './pages/direct-order';
import HelpView from './pages/help';
import HomePage from './pages/home';
import LoginPage from './pages/login';
import OrderDetailPage from './pages/order-details';
import OrderManagementPage from './pages/order-management';
import OrdersPage from './pages/orders';
import PassthroughLoginPage from './pages/passthrough-login';
import PasswordPage from './pages/password';
import PlanSponsorPage from './pages/plan-sponsor';
import PrivacyPolityPage from './pages/privacy-policy';
import ProfilePage from './pages/profile';
import ResetPasswordPage from './pages/reset-password';
import SavedOrderPage from './pages/saved-order';
import SmallGroupPage from './pages/small-group';
import TelesalesPage from './pages/telesales';
import TermsOfUsePage from './pages/terms-of-use';
import TrakPage from './pages/trak';

function App() {
    const classes = useStyles();

    return (
        <Provider store={store}>
            <Router>
                <CssBaseline />
                <ThemeProvider theme={aetnaTheme}>
                    <div className={classes.root}>
                        <CssBaseline />
                        <NavBar />
                        <Container component="main" className={classes.main}>
                            {GA.init() && <GA.RouteTracker />}
                            <Switch>
                                <Route exact path="/login">
                                    <LoginPage />
                                </Route>
                                <Route exact path="/passthrough-login/:token?" component={PassthroughLoginPage} />
                                <Route exact path="/reset-password/:token" component={ResetPasswordPage} />
                                <Route exact path="/privacy-policy" component={PrivacyPolityPage} />
                                <Route exact path="/terms-of-service" component={TermsOfUsePage} />
                                <AuthRoute path="/" exact component={HomePage} />
                                <AuthRoute path="/catalog/:category_code/:product_code/:lineId?" exact component={CatalogPage} />
                                <AuthRoute path="/catalog/:category_code/:product_type/:product_code/:p2_id" exact component={CatalogPage} />
                                <AuthRoute path="/catalog/:category_code" exact component={CatalogPage} />
                                <AuthRoute path="/catalog" exact component={CatalogPage} />
                                <AuthRoute path="/direct-order/:lob/:jobType" exact component={DirectOrderPage} />
                                <AuthRoute path="/cart" component={CartPage} />
                                <AuthRoute path="/checkout" component={CheckoutPage} />
                                <AuthRoute path="/orders" component={OrdersPage} />
                                <AuthRoute path="/order-management" component={OrderManagementPage} />
                                <AuthRoute path="/order/:orderId/:type?" component={OrderDetailPage} />
                                <AuthRoute path="/broker" component={BrokerPage} />
                                <AuthRoute path="/small-group" component={SmallGroupPage} />
                                <AuthRoute path="/plan-sponsor" component={PlanSponsorPage} />
                                <AuthRoute path="/telesales" component={TelesalesPage} />
                                <AuthRoute path="/addresses" component={AddressesPage} />
                                <AuthRoute path="/password" component={PasswordPage} />
                                <AuthRoute path="/profile" component={ProfilePage} />
                                <AuthRoute path="/user-mgmt" component={TrakPage} />
                                <AuthRoute path="/account" component={AccountPage} />
                                <AuthRoute path="/saved-order" component={SavedOrderPage} />
                                <AuthRoute path="/help/:type" component={HelpView} />

                                {/* <AuthRoute path="/custom-demo" component={CustomizationDemoPage} />
                                <AuthRoute path="/demo" component={DemoPage} /> */}
                            </Switch>

                        </Container>
                        <Footer />
                    </div>
                </ThemeProvider>
            </Router>
        </Provider>
    );
}

export default checkRequests(App);
