import { FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Checkbox,
    FormHelperText, ButtonGroup, Tooltip } from '@material-ui/core';
import PhoneInput from 'components/PhoneInput';
import StateSelect from 'components/UsStateSelect';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import React from 'react';

export default function ProfileForm({
    onFieldChange, profile, readonly, agent, user, setShowAWN, awnError = false, setRemoveAgentWritingNumber, isDeleteAwnEnabled = false, validations 
}) {
    const isP2 = user !== null && user && user.customFields && user.customFields.p2_Catalog && user.customFields.p2_Catalog.length > 0;
    const isP2Admin =  user !== null && user && user.customFields && user.customFields.isAdminUser;
    return (
        <Grid container spacing={2}>
            {agent && profile.agentWritingNumber && (
                <Grid item xs={12}>
                    <h3 style={{ margin: 0, display: 'inline' }} color="primary">
                        Agent Writing Number: <span style={{ margin: 0, display: 'inline', color: '#7d3f98' }}>{profile.agentWritingNumber}</span>
                    </h3>
                </Grid>
            )}
            <Grid item xs={6}>
                <TextField
                    label={agent ? 'Agent First Name' : 'First Name'}
                    placeholder={agent ? 'Agent First Name' : 'First Name'}
                    fullWidth
                    margin="normal"
                    name="firstName"
                    variant="outlined"
                    size="small"
                    onChange={onFieldChange}
                    value={profile.firstName || ''}
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        readOnly: readonly
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label={agent ? 'Agent Last Name' : 'Last Name'}
                    placeholder={agent ? 'Agent Last Name' : 'Last Name'}
                    fullWidth
                    margin="normal"
                    name="lastName"
                    variant="outlined"
                    size="small"
                    onChange={onFieldChange}
                    value={profile.lastName || ''}
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        readOnly: readonly
                    }}
                />

            </Grid>

            <Grid item xs={6}>
                <TextField
                    error={validations && validations.email}
                    label="Email Address"
                    placeholder="Email Address"
                    fullWidth
                    margin="normal"
                    name="email"
                    variant="outlined"
                    size="small"
                    onChange={onFieldChange}
                    value={profile.email || ''}
                    helperText={validations && validations.email}
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        readOnly: readonly
                    }}
                />
            </Grid>
            <Grid item xs={6} style={{ paddingTop: '1rem' }}>
                <TextField
                    label="Phone"
                    name="phone"
                    style={{ marginTop: '.5rem' }}
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputProps={{
                        inputComponent: PhoneInput,
                        value: profile.phone || '',
                        onChange: (e) => onFieldChange(e),
                        readOnly: readonly
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                    onFocus={(e) => {
                        e.target.select();
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    label="Address"
                    placeholder="Address"
                    fullWidth
                    margin="normal"
                    name="address"
                    variant="outlined"
                    size="small"
                    onChange={onFieldChange}
                    value={profile.address || ''}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Address 2"
                    placeholder="Address 2"
                    fullWidth
                    margin="normal"
                    name="address2"
                    variant="outlined"
                    size="small"
                    onChange={onFieldChange}
                    value={profile.address2 || ''}
                    InputLabelProps={{
                        shrink: true
                    }}
                />

            </Grid>

            <Grid item xs={6}>
                <TextField
                    label="City"
                    placeholder="City"
                    fullWidth
                    margin="normal"
                    name="city"
                    variant="outlined"
                    size="small"
                    onChange={onFieldChange}
                    value={profile.city || ''}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid
                item
                xs={6}
                style={{ paddingTop: '1.5rem' }}
            >
                <StateSelect
                    value={profile.state || ''}
                    onChange={onFieldChange}
                    displayFullStateName={isP2}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    label="Zip"
                    placeholder="Zip"
                    fullWidth
                    margin="normal"
                    name="zip"
                    variant="outlined"
                    size="small"
                    onChange={onFieldChange}
                    value={profile.zip || ''}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item xs={6} style={{ paddingTop: '1.5rem' }}>
                <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                >
                    <InputLabel id="country-select-outlined-label">Country</InputLabel>
                    <Select
                        labelId="country-select-outlined-label"
                        id="country-select-outlined"
                        fullWidth
                        name="country"
                        value="United States"
                        onChange={onFieldChange}
                        label="Country"
                    >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="United States">United States</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {isP2 ? <Grid item xs={11} style={{ paddingTop: '1.5rem' }}>
                    <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                        error={awnError}
                    >
                        <InputLabel id="awn-select-outlined-label">Agent Writing Number</InputLabel>
                        <Select
                            labelId="awn-select-outlined-label"
                            id="awn-select-outlined"
                            fullWidth
                            name={"agentWritingNumber"}
                            value={profile.agentWritingNumber || ""}
                            onChange={onFieldChange}
                            label="Agent Writing Number"
                        >
                            <MenuItem value="">Select</MenuItem>
                            {
                                profile && profile.agentWritingNumbers && profile.agentWritingNumbers.length > 0 && profile.agentWritingNumbers.map((a) => <MenuItem key={a} value={a}>{a}</MenuItem>)
                            }
                        </Select>
                        {awnError ? <FormHelperText>Agent Writing Number is Required</FormHelperText> : null}
                    </FormControl>

                </Grid> : null}
            {isP2 ? <Grid item xs={1} style={{ paddingTop: '1.5rem' }}>
                <ButtonGroup size="small" aria-label="small button group">
                    <IconButton color="primary" onClick={setShowAWN}>
                        <Tooltip title="Add Agent Writing Number" enterDelay={500} placement="top">
                            <AddIcon />
                        </Tooltip>
                    </IconButton>
                    {isDeleteAwnEnabled && profile && profile.agentWritingNumbers && profile.agentWritingNumbers.length > 0 ? <IconButton color="primary" onClick={setRemoveAgentWritingNumber}>
                        <Tooltip title="Remove Agent Writing Number" enterDelay={500} placement="top">
                            <RemoveIcon />
                        </Tooltip>
                    </IconButton> : null}
                </ButtonGroup>
            </Grid> : null}
            {isP2Admin ? <Grid item xs={11} >
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={profile.pendingNotificationsDisabled}
                            onChange={onFieldChange}
                            name="pendingNotificationsDisabled"
                            color="primary"
                        />
                    )}
                    label="Disable Pending Order Notifications"
                />
            </Grid> : null}
        </Grid>
    );
}
