import {
    Button, CircularProgress,
    Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';

export default function HistoricalOrders({ loading, orders, onChangePage }) {
    return (

        <TableContainer>
            <Table aria-label="order table">
                <TableHead>
                    <TableRow>
                        <TableCell>ORDER #</TableCell>
                        <TableCell>ORDERED DATE</TableCell>
                        <TableCell>ORDER STATUS</TableCell>
                        <TableCell />
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading && (
                        <TableRow>
                            <TableCell colSpan={20} style={{ textAlign: 'center' }}>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}
                    {!loading && orders && orders.results.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                                {row.orderNumber}
                            </TableCell>
                            <TableCell>{row.orderedDate}</TableCell>
                            <TableCell>{row.orderStatus}</TableCell>
                            <TableCell />
                            <TableCell align="center">
                                <Button href={`#/order/${row.id}`} color="primary">
                                    <VisibilityIcon /> View
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={20}>
                            {orders
                                && (
                                    <TablePagination
                                        component="div"
                                        count={orders.totalCount}
                                        page={orders.pageNumber - 1}
                                        rowsPerPage={orders.pageSize}
                                        rowsPerPageOptions={[orders.pageSize]}
                                        onChangePage={onChangePage}
                                    />
                                )}

                        </TableCell>
                    </TableRow>

                </TableFooter>
            </Table>
        </TableContainer>
    );
}
