import actionCreator from 'utils/actionCreator';
import history from 'utils/history';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('CART_VIEW', {
    LOADING: null,
    SET_BASKET_ITEMS: null,
    UPDATE_ITEM_QTY: null,
    REMOVE_ITEM: null,
    SET_USED: null,
    SET_PRICING: null,
    EMPTY_CART: null
});

export default actions;

const setLoading = actionCreator(actions.LOADING, 'loading');

const setPricing = actionCreator(actions.SET_PRICING, 'pricing');
export const getPricing = () => (dispatch, getState) => {
    const current = getState().cartView.pricing;
    if (current) return;

    http.get('store/get-pricing')
        .then((pricing) => {
            dispatch(setPricing(pricing));
        });
};

export const setBasketItems = actionCreator(actions.SET_BASKET_ITEMS, 'basketItems');

export const getBasketItems = () => (dispatch) => {
    dispatch(setLoading(true));
    http.get('store/get-basket-items')
        .then(({ basketItems, usage }) => {
            for (const bi of basketItems) {
                if (bi.customFields && typeof bi.customFields === 'string') {
                    bi.customFields = JSON.parse(bi.customFields);
                }
            }
            dispatch(setBasketItems(basketItems));
            dispatch(setUsed(usage));
        }).catch(() => dispatch(setBasketItems([]))).finally(() => dispatch(setLoading(false)));
};

const setUsed = actionCreator(actions.SET_USED, 'used');

const setBasketItemQty = actionCreator(actions.UPDATE_ITEM_QTY, 'lineId', 'qty');
export const updateBasketItemQty = (lineId, qty) => (dispatch) => {
    dispatch(setBasketItemQty(lineId, qty));
    dispatch(setLoading(true));
    http.get(`catalog/update-qty/${lineId}/${qty}`)
        .then(() => {
            // dispatch(setBasketItemQty(lineId, qty));
        }).finally(() => dispatch(setLoading(false)));
};

const setBasketSubItemQty = actionCreator(actions.UPDATE_SUB_ITEM_QTY, 'lineId', 'itemId', 'qty');

const deleteBasketItem = actionCreator(actions.REMOVE_ITEM, 'lineId');
export const removeBasketItem = (lineId) => (dispatch) => {
    dispatch(setLoading(true));
    http.get(`catalog/remove-basket-item/${lineId}`)
        .then(() => {
            dispatch(deleteBasketItem(lineId));
        }).finally(() => dispatch(setLoading(false)));
};

export const emptyCart = actionCreator(actions.EMPTY_CART);

export const saveItemsForLater = (savedName, lineIds) => (dispatch) => {
    dispatch(setLoading(true));
    const fd = new FormData();
    fd.append('name', savedName);
    fd.append('lineIds', lineIds);
    http.post('catalog/save-items-for-later', fd)
        .then(() => {
            dispatch(getBasketItems());
        }).finally(() => dispatch(setLoading(false)));
};

export const saveBasketItemCustomFields = (lineId, customFields, gotoCart) => (dispatch) => {
    http.post(`catalog/edit-basket-item/${lineId}`, customFields)
        .then(() => {
            dispatch(getBasketItems());
            if (gotoCart) history.push('/cart');
        });
};

export const duplicateBasketItem = (lineId) => (dispatch) => {
    http.get(`catalog/duplicate-basket-item/${lineId}`)
        .then(() => {
            dispatch(getBasketItems());
        });
};

export const validateBasketItems = () => (dispatch) => {
    http.get('catalog/validate-basket-items')
        .then(() => {
            dispatch(getBasketItems());
        });
};
