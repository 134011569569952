import { Button, CircularProgress, Container, CssBaseline, Grid, Paper, TextField, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import React, { PureComponent } from 'react';
import history from 'utils/history';
import http from 'utils/http';

export default class ResetPassword extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            new1: '',
            new2: '',
            submitting: false,
            token: null,
            isTokenValid: null,

            showChanged: false,
            error: null
        };
    }

    componentDidMount() {
        const { match: { params: { token } } } = this.props;
        http.get(`auth/validate-token/${token}`).then((r) => {
            this.setState({ isTokenValid: r, token });
            if (!r) {
                this.setState({ error: 'The password reset link you followed is not valid or has expired.' });
            }
        });
    }

    onFieldChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    submitForm = () => {
        this.setState({ submitting: true });
        const { new1, token } = this.state;
        const fd = new FormData();
        fd.append('token', token);
        fd.append('password', new1);
        http.post('/auth/reset-password', fd).then((t) => {
            if (t) {
                this.setState({ showChanged: true });
            } else {
                this.setState({ error: 'System failed to reset your password. Please try again or contact us.' });
            }
        }).finally(() => {
            this.setState({ submitting: false });
        });
    }

    render() {
        const {
            new1, new2, submitting, isTokenValid, showChanged, error
        } = this.state;
        return (
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Paper
                    variant="outlined"
                    style={{
                        marginTop: '2rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '3rem'
                    }}
                >

                    <Typography component="h1" variant="h4">Reset Password</Typography>

                    <TextField
                        id="standard-full-width"
                        label="New Password"
                        style={{ margin: 8 }}
                        type="password"
                        placeholder="Enter new password"
                        fullWidth
                        name="new1"
                        margin="normal"
                        value={new1}
                        onChange={this.onFieldChange}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                    <TextField
                        id="standard-full-width"
                        label="Confirm New Password"
                        style={{ margin: 8 }}
                        type="password"
                        placeholder="Enter new password again"
                        fullWidth
                        name="new2"
                        margin="normal"
                        value={new2}
                        onChange={this.onFieldChange}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                    <Grid container>
                        {error && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    {error}
                                </Alert>
                            </Grid>
                        )}
                        <Grid item xs>
                            <Button
                                type="button"
                                style={{ marginTop: '1rem' }}
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={!new1 || !new2 || new1.length < 6 || new1.length !== new2.length || submitting || !isTokenValid}
                                onClick={this.submitForm}
                            >
                                {submitting && (
                                    <CircularProgress
                                        color="secondary"
                                        size={12}
                                    />
                                )} Submit
                            </Button>

                        </Grid>
                    </Grid>
                </Paper>
                {showChanged && (
                    <Dialog open={showChanged} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Password has been reset</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Your password has been reset. Please go to <a href="#/login">login</a> page to login with your new password.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => history.push('/login')} autoFocus color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Container>
        );
    }
}
