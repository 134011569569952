import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { useLocation } from 'react-router-dom';
import history from '../utils/history';

const AuthRoute = (props) => {
    const { isAuthUser, passthroughLogin, user } = props;
    const location = useLocation();
    const passwordRoute = '/password';
    if (!isAuthUser) {
        if (passthroughLogin) {
            history.push('/passthrough-login');
        } else {
            history.push('/login');
        }

        return null;
    } else if (user.customFields && user.customFields.isPasswordChangeRequired) {
        if (location.pathname !== passwordRoute) {
            history.push('/password');
            return null;
        }
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Route {...props} />;
};

const mapStateToProps = ({ appView }) => {
    return {
        isAuthUser: appView.isAuthUser,
        passthroughLogin: appView.passthroughLogin,
        user: appView.user
    };
};

export default connect(mapStateToProps)(AuthRoute);
