import React, { useState } from 'react';
import {
    Grid, IconButton, Box, Tooltip
} from '@material-ui/core';
import PowerInputIcon from '@material-ui/icons/PowerInput';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import history from 'utils/history';
import http from 'utils/http';
import RenameModal from './RenameModal';
import PreviewModal from './PreviewModal';
import EditModal from './EditModal';

export default function ToolBar({
    item, onRenameItem, duplicateItem, customizeCode, crosswalk, lineId
}) {
    const [openRename, setOpenRename] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const preview = item.customFields && item.customFields.crosswalk
        ? item.customFields.crosswalk.preview
        : item.customFields.sample;

    const saveEditCrosswalk = (crosswalk) => {
        const customFields = { ...item.customFields, crosswalk };
        customFields.proofFileId = crosswalk.proofFileId;
        http.post(`catalog/edit-basket-item/${lineId}`, customFields)
            .then((c) => {
                history.go();
            });
    };

    return (
        <Grid
            container
            justify="flex-start"
            alignItems="center"
        >
            <Box width="200">
                <Tooltip title="Rename">
                    <IconButton size="small" onClick={() => setOpenRename(true)}>
                        <PowerInputIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton size="small" onClick={() => setOpenEdit(true)}>
                        <BorderColorIcon />
                    </IconButton>
                </Tooltip>
                {
                    preview && (
                        <Tooltip title="Preview">
                            <IconButton size="small" onClick={() => setOpenPreview(true)}>
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }
                <Tooltip title="Duplicate">
                    <IconButton size="small" onClick={() => duplicateItem(item.lineId)}>
                        <AddToPhotosIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            <RenameModal
                open={openRename}
                onClose={() => setOpenRename(false)}
                name={item.customFields.alias || item.name}
                onSave={onRenameItem}
            />

            <PreviewModal
                open={openPreview}
                onClose={() => setOpenPreview(false)}
                pdf={preview}
            />

            <EditModal
                open={openEdit}
                onClose={() => setOpenEdit(false)}
                crosswalk={crosswalk}
                customizeCode={customizeCode}
                lineId={lineId}
                saveEditCrosswalk={saveEditCrosswalk}
            />

        </Grid>
    );
}
