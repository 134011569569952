import {
    FormControl, Grid,

    InputLabel, MenuItem, Select
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StateSelect from 'components/UsStateSelect';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
    getCostCenters, getProducts, getStateModels, setDomNextStepReady,
    setScreenField,
    setSPMLoaded,
    setValidate
} from '../action';

const useStyles = makeStyles((theme) => ({
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const SelectScreenStep = (props) => {
    const {
        user, screenData, products, costCenters, stateModels, setScreenField: setFieldValue, marketSegmentXCostCenters, setSPMLoaded
    } = props;
    const { lob, jobType } = useParams();

    useEffect(() => {
        const validate = (screen) => {
            let hasValue = true;
            if (lob === 'cml' && jobType === 'AJEPP') {
                hasValue = !!screen.marketSegment;
            } else if (lob === 'cml' && (jobType === 'AJEKT' || jobType === 'IM')) {
                hasValue = !!(screen.marketSegment && screen.language && screen.product && screen.state && screen.model);
            } else {
                // eslint-disable-next-line guard-for-in
                for (const key in screen) {
                    hasValue = hasValue && !!(screen[key]);
                }
            }
            return hasValue;
        };

        const isValid = validate(screenData);
        props.setIsNextReady(isValid);
    }, [screenData, props, lob, jobType]);

    const handleFieldChange = (e) => {
        setFieldValue(e.target.name, e.target.value);
        setSPMLoaded(false);
        switch (e.target.name) {
            case 'marketSegment':
                if (e.target.value) {
                    if (jobType !== 'FJE') {
                        props.getCostCenters(e.target.value);
                    }
                    props.getProducts(e.target.value);
                }
                break;
            case 'language':
                if (e.target.value && screenData.marketSegment && screenData.product) {
                    props.getStateModels(screenData.marketSegment, e.target.value, screenData.product);
                }
                break;
            case 'product':
                if (e.target.value && screenData.marketSegment && screenData.language) {
                    props.getStateModels(screenData.marketSegment, screenData.language, e.target.value);
                }
                break;
            default:
                break;
        }
    };

    if (user.customFields && user.customFields.costCenters && user.customFields.costCenters.length === 1 && !screenData.costCenter && jobType === 'FJE') {
        setFieldValue('costCenter', user.customFields.costCenters[0]);
    }

    let costCenterItems;
    if (jobType === 'FJE') {
        costCenterItems = user.customFields.costCenters.map((c) => ({ value: c, label: c }));
    } else {
        costCenterItems = costCenters || [];
    }
    let states = [];
    let models = [];
    if (stateModels && stateModels.length > 0) {
        states = Array.from(new Set(stateModels.map((sm) => sm.split('[')[0].trim())));
        if (screenData.state) {
            models = Array.from(new Set(stateModels.filter((s) => s.startsWith(`${screenData.state} `)).map((sm) => sm.split(']')[1].split('[')[0].trim())));
        }
    }

    const classes = useStyles();

    let marketSegmentItems;
    if (lob === 'cml') {
        if (marketSegmentXCostCenters) {
            marketSegmentItems = Object.keys(marketSegmentXCostCenters);
            costCenterItems = screenData.marketSegment ? marketSegmentXCostCenters[screenData.marketSegment] : [];
        } else {
            marketSegmentItems = [];
            costCenterItems = [];
        }
    } else {
        marketSegmentItems = user.customFields.marketSegments.filter((ms) => lob !== 'cml' || ms !== 'MCARE');
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <FormControl
                    fullWidth
                    required
                    size="small"
                    variant="outlined"
                    className={classes.formControl}
                >
                    <InputLabel id="market-segment-select-outlined-label">Market Segment</InputLabel>
                    <Select
                        labelId="market-segment-select-outlined-label"
                        id="market-segment-select-outlined"
                        value={screenData.marketSegment}
                        fullWidth
                        name="marketSegment"
                        onChange={handleFieldChange}
                        label="Market Segment"
                    >

                        <MenuItem value="">Select</MenuItem>
                        {marketSegmentItems.map((s) => <MenuItem key={s} value={s}>{s}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>

            {!(lob === 'cml' && jobType === 'AJEPP') && (
                <>

                    <Grid item xs={4}>
                        <FormControl
                            fullWidth
                            required
                            size="small"
                            variant="outlined"
                            className={classes.formControl}
                        >
                            <InputLabel id="language-select-outlined-label">Language</InputLabel>
                            <Select
                                labelId="language-select-outlined-label"
                                id="language-select-outlined"
                                value={screenData.language}
                                fullWidth
                                name="language"
                                onChange={handleFieldChange}
                                label="Language"
                            >
                                <MenuItem value="">Select</MenuItem>
                                <MenuItem value="English">English</MenuItem>
                                <MenuItem value="Spanish">Spanish</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl
                            fullWidth
                            size="small"
                            required
                            variant="outlined"
                            className={classes.formControl}
                            style={{ display: ((lob === 'cml' && (jobType === 'AJEKT' || jobType === 'IM') ? 'none' : 'block')) }}
                        >
                            <InputLabel id="cost-center-select-outlined-label">Cost Center</InputLabel>
                            <Select
                                labelId="cost-center-select-outlined-label"
                                id="cost-center-select-outlined"
                                value={screenData.costCenter}
                                fullWidth
                                name="costCenter"
                                onChange={handleFieldChange}
                                label="Cost Center"
                            >
                                <MenuItem value="">Select</MenuItem>
                                {costCenterItems.map((s) => <MenuItem key={s.value} value={s.value}>{s.value} ({s.label})</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl
                            fullWidth
                            required
                            size="small"
                            variant="outlined"
                            className={classes.formControl}
                        >
                            <InputLabel id="product-select-outlined-label">Product</InputLabel>
                            <Select
                                labelId="product-select-outlined-label"
                                id="product-select-outlined"
                                value={screenData.product}
                                fullWidth
                                name="product"
                                onChange={handleFieldChange}
                                label="Product"
                            >
                                <MenuItem value="">Select</MenuItem>
                                {products && products.map((s) => (
                                    <MenuItem key={s} value={s}>
                                        {s}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        {
                            lob === 'cml'
                                ? (
                                    <FormControl
                                        fullWidth
                                        size="small"
                                        required
                                        variant="outlined"
                                        className={classes.formControl}
                                    >
                                        <InputLabel id="state-select-outlined-label">State</InputLabel>
                                        <Select
                                            labelId="state-select-outlined-label"
                                            id="state-select-outlined"
                                            value={screenData.state}
                                            fullWidth
                                            name="state"
                                            onChange={handleFieldChange}
                                            label="State"
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            {states && states.map((s) => (
                                                <MenuItem key={s} value={s}>
                                                    {s}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )
                                : (
                                    <StateSelect
                                        value={screenData.state || ''}
                                        onChange={handleFieldChange}
                                    />
                                )
                        }
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl
                            fullWidth
                            size="small"
                            required
                            variant="outlined"
                            className={classes.formControl}
                        >
                            <InputLabel id="model-select-outlined-label">Model</InputLabel>
                            <Select
                                labelId="model-select-outlined-label"
                                id="model-select-outlined"
                                value={screenData.model}
                                fullWidth
                                name="model"
                                onChange={handleFieldChange}
                                label="Model"
                            >
                                <MenuItem value="">Select</MenuItem>
                                {models && models.map((s) => (
                                    <MenuItem key={s} value={s}>
                                        {s}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

const mapStateToProps = ({
    appView: { user },
    directOrderView: {
        stepView: {
            screenData, products, costCenters, stateModels,
            marketSegmentXCostCenters
        }
    }
}) => ({
    user,
    screenData,
    products,
    costCenters,
    stateModels,
    marketSegmentXCostCenters
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setDomNextStepReady,
            setScreenField,
            getProducts,
            getCostCenters,
            getStateModels,
            setSPMLoaded,
            setValidate
        },
        dispatch
    );
};

// const mapStateToProps = ({
// }) => ({
//     user, marketSegment, costCenter, language, products, product, stateModels, stateModel
// });
export default connect(mapStateToProps, mapDispatchToProps)(SelectScreenStep);
