import {
    Box, Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Modal, Select, Switch, TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState } from 'react';
import http from 'utils/http';
import AutoSelect from './AutoSelect';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        maxHeight: 600,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        padding: '2rem',
        boxShadow: theme.shadows[5]
    },
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const FormModal = (props) => {
    const [modalStyle] = React.useState(getModalStyle);
    const {
        open, onClose, trak, onSave, meta: { costCenters, marketSegments, userGroups }, savedResult
    } = props;
    const [formData, setFormData] = React.useState(trak || {});
    const [errorMessage, setErrorMessage] = useState('');

    const classes = useStyles();

    const onFieldChange = (e) => {
        const value = Array.isArray(e.target.value) ? e.target.value.filter((v) => !!v).join(',') : e.target.value;
        setErrorMessage('');
        setFormData({ ...formData, [e.target.name]: value });
        if (e.target.name === 'userGroupId') {
            if (value) {
                const newUserGroup = userGroups.find((g) => g.id === value).name;
                const jobEntries = {
                    orderManagement: 'N',
                    oeJobEntry: 'N',
                    fastJobEntry: 'N',
                    kitJobEntry: 'N',
                    ppJobEntry: 'N',
                    imJobEntry: 'N',
                    sgJobEntry: 'N'
                };
                switch (newUserGroup) {
                    case 'SU':
                        for (const k in jobEntries) {
                            jobEntries[k] = 'Y';
                        }
                        break;
                    case 'FSR':
                        jobEntries.oeJobEntry = 'Y';
                        jobEntries.kitJobEntry = 'Y';
                        jobEntries.ppJobEntry = 'Y';
                        jobEntries.imJobEntry = 'Y';
                        break;
                    case 'OE':
                        jobEntries.oeJobEntry = 'Y';
                        break;
                    case 'SG Broker':
                        jobEntries.sgJobEntry = 'Y';
                        break;
                    default:
                        break;
                }

                setFormData({
                    ...formData,
                    [e.target.name]: value,
                    ...jobEntries
                });
            }
        }
    };

    const saveChange = () => {
        setErrorMessage('');
        if (userGroups.find((g) => g.id === formData.userGroupId).name === 'FSR'
            && formData.oeJobEntry === 'Y'
            && formData.marketSegments.split(',').every((m) => !['KEY', 'SEL', 'NAT', 'SGB'].includes(m))
        ) {
            setErrorMessage('When OE Job Entry is checked market segments must contains one of these: KEY, SEL, NAT, SGB');
            return;
        }

        onSave(formData);
    };

    const [confirming, setConfirming] = useState(false);
    const confirmReset = () => {
        setConfirming(true);
    };

    const [resetting, setResetting] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const resetPassword = () => {
        if (successMsg) {
            setSuccessMsg('');
            setErrorMsg('');
            setConfirming(false);
            return;
        }

        setResetting(true);

        const fd = new FormData();
        fd.append('id', formData.id);
        http.post('su/reset', fd).then((sent) => {
            if (sent) {
                setSuccessMsg('Reset password email has been sent.');
                setTimeout(() => {
                    setConfirming(false);
                    setResetting(false);
                }, 1500);
            } else {
                setErrorMsg('Fail to reset password.');
                setResetting(false);
            }
        }).catch((eee) => {
            console.log('#ODS#: resetPassword -> eee', eee);
            setErrorMsg('Fail to reset password.');
            setResetting(false);
        });
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Grid container spacing={1} className="row-item">
                    <Grid item xs={5}>
                        <TextField
                            label="User ID"
                            name="userName"
                            required
                            fullWidth
                            value={formData.userName || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            label="Email"
                            name="email"
                            fullWidth
                            value={formData.email || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        style={{
                            display: !formData.id || trak.status !== 'Active' ? 'none' : 'block'
                        }}
                    >
                        <Button color="primary" disabled={!formData.email} onClick={confirmReset}>
                            Reset Password
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: formData.id ? 'none' : 'block'
                        }}
                    >
                        <TextField
                            label="Password"
                            name="password"
                            fullWidth
                            value={formData.password || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            type="password"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: formData.id ? 'none' : 'block'
                        }}
                    >
                        <TextField
                            label="Confirm Password"
                            name="password2"
                            fullWidth
                            value={formData.password2 || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            type="password"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="First Name"
                            name="firstName"
                            value={formData.firstName || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Middle Initial"
                            name="middleInitial"
                            value={formData.middleInitial || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Last Name"
                            name="lastName"
                            value={formData.lastName || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl fullWidth required variant="outlined">
                            <InputLabel id="user-type-simple-select-outlined-label">User Group</InputLabel>
                            <Select
                                labelId="user-type-simple-select-outlined-label"
                                id="user-type-simple-select-outlined"
                                value={formData.userGroupId}
                                onChange={onFieldChange}
                                name="userGroupId"
                                label="User Group"
                            >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                {userGroups.filter((g) => g.name !== 'MSO').map((g) => <MenuItem key={g.id} value={g.id}>{g.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <AutoSelect
                            options={costCenters.map((cc) => ({ inputValue: cc.value, title: cc.value }))}
                            label="Cost Center"
                            name="costCenters"
                            value={(formData.costCenters || '').split(',').filter((s) => !!s).map((s) => ({ inputValue: s, title: s }))}
                            onChange={onFieldChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutoSelect
                            options={marketSegments.map((cc) => ({ inputValue: cc.value, title: cc.value }))}
                            label="Market Segments"
                            name="marketSegments"
                            value={(formData.marketSegments || '').split(',').filter((s) => !!s).map((s) => ({ inputValue: s, title: s }))}
                            onChange={onFieldChange}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: formData.userGroupId && ['FSR'].includes(userGroups.find((g) => g.id == formData.userGroupId).name)
                                ? 'block' : 'none'
                        }}
                    >
                        <Grid container>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={formData.oeJobEntry === 'Y'}
                                            onChange={(e) => onFieldChange({ target: { name: 'oeJobEntry', value: e.target.checked ? 'Y' : 'N' } })}
                                            name="oeJobEntry"
                                            color="primary"
                                        />
                                    )}
                                    label="OE Job Entry"
                                />
                            </Grid>
                            {/* <Grid item xs={4}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={formData.fastJobEntry === 'Y'}
                                            onChange={(e) => onFieldChange({ target: { name: 'fastJobEntry', value: e.target.checked ? 'Y' : 'N' } })}
                                            name="fastJobEntry"
                                            color="primary"
                                        />
                                    )}
                                    label="Fast Job Entry"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={formData.kitJobEntry === 'Y'}
                                            onChange={(e) => onFieldChange({ target: { name: 'kitJobEntry', value: e.target.checked ? 'Y' : 'N' } })}
                                            name="kitJobEntry"
                                            color="primary"
                                        />
                                    )}
                                    label="Kit Job Entry"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={formData.ppJobEntry === 'Y'}
                                            onChange={(e) => onFieldChange({ target: { name: 'ppJobEntry', value: e.target.checked ? 'Y' : 'N' } })}
                                            name="ppJobEntry"
                                            color="primary"
                                        />
                                    )}
                                    label="Pick&Pack Job Entry"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={formData.imJobEntry === 'Y'}
                                            onChange={(e) => onFieldChange({ target: { name: 'imJobEntry', value: e.target.checked ? 'Y' : 'N' } })}
                                            name="imJobEntry"
                                            color="primary"
                                        />
                                    )}
                                    label="IM Job Entry"
                                />
                            </Grid> */}
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={formData.sgJobEntry === 'Y'}
                                            disabled={formData.userGroupId && ['SU'].includes(userGroups.find((g) => g.id == formData.userGroupId).name)}
                                            onChange={(e) => onFieldChange({ target: { name: 'sgJobEntry', value: e.target.checked ? 'Y' : 'N' } })}
                                            name="sgJobEntry"
                                            color="primary"
                                        />
                                    )}
                                    label="Small Group Entry"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{
                                    display: formData.userGroupId && ['SU'].includes(userGroups.find((g) => g.id == formData.userGroupId).name)
                                        ? 'block' : 'none'
                                }}
                            >
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={formData.orderManagement === 'Y'}
                                            onChange={(e) => onFieldChange({ target: { name: 'orderManagement', value: e.target.checked ? 'Y' : 'N' } })}
                                            name="orderManagement"
                                            color="primary"
                                        />
                                    )}
                                    label="Order Management"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box mt={2} mb={1}>
                    <Button
                        className="btn place-order-btn"
                        disabled={!(formData.userName) || (!formData.userGroupId) || (!formData.id && (!formData.password || formData.password !== formData.password2))}
                        variant="contained"
                        onClick={saveChange}
                        color="primary"
                    >Save
                    </Button>
                    <Button
                        className="btn"
                        variant="contained"
                        onClick={onClose}
                        style={{ float: 'right' }}
                    >Close
                    </Button>
                </Box>
                {errorMessage && <span style={{ color: 'red', paddingLeft: '1rem' }}>{errorMessage}</span>}
                {savedResult && savedResult.error && (
                    <Alert severity="error">
                        <AlertTitle>{savedResult.error}</AlertTitle>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>User Id: <b>{savedResult.userName}</b></Grid>
                            <Grid item xs={6}>Email: <b>{savedResult.email}</b></Grid>
                            <Grid item xs={6}>Name: <b>{savedResult.firstName} {savedResult.middleName} {savedResult.lastName}</b></Grid>
                            <Grid item xs={6}>User Group: <b>{savedResult.userGroup}</b></Grid>
                        </Grid>
                    </Alert>
                )}

                <Dialog
                    open={confirming}
                    onClose={() => setConfirming(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{'Are you sure you want to reset password of this account? '}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You must save your change before reset if you have edited the password email.
                            {successMsg !== '' && <Alert severity="success">{successMsg}</Alert>}
                            {errorMsg !== '' && <Alert severity="error">{errorMsg}</Alert>}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={resetting} onClick={() => setConfirming(false)} color="primary">
                            No
                        </Button>
                        <Button disabled={resetting} onClick={() => resetPassword()} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Modal>
    );
};

export default FormModal;
