import { Box, Grid, Typography } from '@material-ui/core';
import SelectItemStep from 'pages/direct-order/steps/SelectItemStep';
import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
    settings: state.appView.settings
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)((props) => {
    const { settings } = props;

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Typography variant="h5" component="h6">
                        Add Plan Document
                    </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }} />
                <Grid item xs={12}>
                    <SelectItemStep
                        disableChild
                        disableItemType
                        shellLimit={settings && settings.shellLimit && settings.shellLimit.oe}
                        lob="OE"
                        jobType="OEJ"
                        setIsNextReady={() => { }}
                    />
                </Grid>
            </Grid>
        </Box>
        //     </div>
        // </Modal>
    );
});
