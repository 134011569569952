import {
    Button,
    CircularProgress,
    Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow
} from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';
import React from 'react';

export default function SavedOrders({
    loading, orders, onChangePage, onQuickView
}) {
    const toProgramName = (hash) => {
        if (hash.includes('p1a')) return 'Medical Individual';
        if (hash.includes('p1b')) return 'Medical Group';
        return 'Commercial';
    };

    const toJobType = (hash) => {
        if (hash.includes('FJE')) return 'Fast Job';
        if (hash.includes('AJEPP')) return 'Pick&Pack Job';
        if (hash.includes('AJEKT')) return 'Kit Job';
        return 'IM Job';
    };

    return (

        <TableContainer>
            <Table aria-label="order table">
                <TableHead>
                    <TableRow>
                        <TableCell>ORDER #</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>Program</TableCell>
                        <TableCell>Job Type</TableCell>
                        <TableCell>SAVED DATE</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading && (
                        <TableRow>
                            <TableCell colSpan={20} style={{ textAlign: 'center' }}>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}
                    {!loading && orders && orders.results.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                                {row.orderNumber}
                            </TableCell>
                            <TableCell>{row.customerName}</TableCell>
                            <TableCell>{toProgramName(row.data.hash)}</TableCell>
                            <TableCell>{toJobType(row.data.hash)}</TableCell>
                            <TableCell>{row.orderedDate}</TableCell>
                            <TableCell align="center">
                                <Button onClick={() => onQuickView(row.name, row.id, row.data)} color="primary">
                                    <RestoreIcon /> View
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={20}>
                            {orders
                                && (
                                    <TablePagination
                                        component="div"
                                        count={orders.totalCount}
                                        page={orders.pageNumber - 1}
                                        rowsPerPage={orders.pageSize}
                                        rowsPerPageOptions={[orders.pageSize]}
                                        onChangePage={onChangePage}
                                    />
                                )}

                        </TableCell>
                    </TableRow>

                </TableFooter>
            </Table>
        </TableContainer>
    );
}
