import savedJobActions from './action';

export default (
    state = {
        loading: false,
        savedJobs: null
    },
    action
) => {
    switch (action.type) {
        case savedJobActions.LOADING:
            return { ...state, loading: action.loading };

        case savedJobActions.SET_SAVED_JOB:
            return { ...state, savedJobs: action.savedJobs };

        default:
            return state;
    }
};
