import {
    Button, Grid,

    Paper, Radio, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { searchCustomer, selectCustomer, setDomNextStepReady } from '../action';

const useStyles = makeStyles((theme) => ({
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const SelectCustomerStep = (props) => {
    const [customerName, setCustomerName] = useState('');
    const [customerNumber, setCustomerNumber] = useState('');

    const { customers, customer } = props;
    const { lob, jobType } = useParams();

    useEffect(() => {
        const isValid = lob === 'p1a' || (lob === 'cml' && jobType === 'AJEPP') || !!customer;
        props.setIsNextReady(isValid);
    }, [customer, lob, jobType, props]);

    const classes = useStyles();

    const submitSearch = () => {
        props.searchCustomer({ customerName, customerNumber, lob });
    };

    const toggleSelected = (id) => {
        const selected = customer && customer.id === id
            ? null
            : customers.find((c) => c.id === id);
        props.selectCustomer(selected);
        // props.setDomNextStepReady(validate(selected));
    };

    return (
        <>
            <Grid container alignItems="center">
                <Grid item xs={4}>
                    <TextField
                        id="customer-name-search"
                        size="small"
                        fullWidth
                        label="Customer Name"
                        type="search"
                        variant="outlined"
                        value={customerName}
                        onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(); } }}
                        onChange={(e) => { setCustomerName(e.target.value); setCustomerNumber(''); }}
                    />
                </Grid>
                <Grid item xs={1} className={classes.middleOr}>OR</Grid>
                <Grid item xs={4}>
                    <TextField
                        id="customer-number-search"
                        size="small"
                        fullWidth
                        label="Customer Number"
                        type="search"
                        variant="outlined"
                        value={customerNumber}
                        onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(); } }}
                        onChange={(e) => { setCustomerNumber(e.target.value); setCustomerName(''); }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button color="primary" variant="outlined" size="large" fontWeight="fontWeightMedium" className={classes.inlineSearchButton} onClick={submitSearch}>Search</Button>
                </Grid>
            </Grid>
            {customers && (
                <TableContainer component={Paper} style={{ marginTop: '1rem', maxHeight: '30rem' }}>
                    <Table size="small" stickyHeader aria-label="found customers table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Customer Name</TableCell>
                                <TableCell>Customer Number</TableCell>
                                <TableCell align="right" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customers.map((row) => {
                                const selected = !!(customer && row.id === customer.id);
                                return (
                                    <TableRow
                                        hover

                                        key={row.id}
                                        onClick={() => toggleSelected(row.id)}
                                    >
                                        <TableCell scope="row">
                                            {row.customerName}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.customerNumber}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Radio
                                                checked={selected}
                                                value={row.id}
                                                color="primary"
                                                name="radio-button-customer"
                                                inputProps={{ 'aria-label': 'customer' }}
                                            />

                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

const mapStateToProps = ({ directOrderView: { stepView } }) => ({
    customers: stepView.customers, customer: stepView.customer
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { searchCustomer, selectCustomer, setDomNextStepReady },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectCustomerStep);
