import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React, { useState } from 'react';

export default function EditBrokerSelect({
    saveBrokerSelection, meta
}) {
    const yearOptions = meta.map((m) => m.Year);
    const [stateOptions, setStateOptions] = useState(meta[0].States.split(',').filter((s) => !!s));
    const [productOptions, setProductOptions] = useState(meta[0].Product.split(',').filter((s) => !!s));
    const [selectedYear, setSelectedYear] = useState(yearOptions[0]);
    const [selectedState, setSelectedState] = useState(stateOptions[0]);
    const [selectedProduct, setSelectedProduct] = useState(productOptions[0]);

    const saveChange = () => {
        saveBrokerSelection({
            year: selectedYear,
            state: selectedState,
            product: selectedProduct
        });
    };

    const onPlanYearChange = (newYear) => {
        setSelectedYear(newYear);
        const m = meta.find((t) => t.Year === newYear);
        setStateOptions(m.States.split(',').filter((s) => !!s));
        if (!stateOptions.includes(selectedState)) {
            setSelectedState(stateOptions[0]);
        }
        setProductOptions(m.Product.split(',').filter((s) => !!s));
        if (!productOptions.includes(selectedProduct)) {
            setSelectedProduct(productOptions[0]);
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    Medicare Broker Materials
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="year-select-outlined-label">Year</InputLabel>
                    <Select
                        labelId="year-select-outlined-label"
                        id="year-select-outlined"
                        value={selectedYear}
                        onChange={(e) => onPlanYearChange(e.target.value)}
                        name="year"
                        label="Year"
                    >
                        {yearOptions.map((y) => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="state-select-outlined-label">State</InputLabel>
                    <Select
                        labelId="state-select-outlined-label"
                        id="state-select-outlined"
                        value={selectedState}
                        onChange={(e) => setSelectedState(e.target.value)}
                        name="state"
                        label="State"
                    >
                        {stateOptions.map((y) => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="product-select-outlined-label">Product</InputLabel>
                    <Select
                        labelId="product-select-outlined-label"
                        id="product-select-outlined"
                        value={selectedProduct}
                        onChange={(e) => setSelectedProduct(e.target.value)}
                        name="product"
                        label="Product"
                    >
                        {productOptions.map((y) => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="flex-end" alignItems="center">
                    <Grid item xs={3}>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            float="right"
                            size="large"
                            onClick={saveChange}
                        >Next <NavigateNextIcon />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
