import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';

export default function ShippingAddressView({
    a, i, isPartial, total
}) {
    const hasInventoryQty = a.items.some((ai) => ai.inventoryQuantity);
    const toSpan = (t) => {
        if (t) return <span> | {t}</span>;
        return null;
    };

    return (
        <Grid key={a.id} container spacing={3} style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <Grid item xs={2}>
                <Typography variant="h6">
                    Address {i + 1} {isPartial && (<span>{`/${total}`}</span>)}
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <span className="table-description">
                    <span>
                        {`Ship to: ${(a.firstName || '')} ${(a.lastName || '')} `}
                    </span>
                    {a.email
                        && <span className="email">{`<${a.email}>`}</span>}
                    {toSpan(a.phone)}
                    {toSpan(a.address)}
                    {toSpan(a.address2)}
                    {toSpan(a.city)}
                    {toSpan(a.state)}
                    {toSpan(a.zip)}
                </span>
            </Grid>
            {/* <Grid item xs={2}>
                Status: <b>{a.status || 'Processing'}</b>
            </Grid> */}

            <Table component={Paper}>
                <TableHead>
                    <TableRow>
                        <TableCell>Code</TableCell>
                        <TableCell>Product</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Quantity</TableCell>
                        {hasInventoryQty && (
                            <TableCell style={{ textAlign: 'center' }}>Inventory Quantity</TableCell>
                        )}
                        <TableCell>Tracking</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {a.items.map((c) => (
                        <TableRow key={c.code}>
                            <TableCell>{c.code}</TableCell>
                            <TableCell>{c.name}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>{c.quantity}</TableCell>
                            {hasInventoryQty && (
                                <TableCell style={{ textAlign: 'center' }}>{c.inventoryQuantity}</TableCell>
                            )}
                            <TableCell>
                                {c.navShippingDate ? (<div>Shipped on: {c.navShippingDate}</div>) : ''}
                                {c.navTrackingNumber || ''}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </Grid>
    );
}
